import { Box, DialogContent, DialogTitle } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { invalidateCustomerCache, useGetCustomer, useUpdateCustomer } from '../../api/customer-admin'
import { CenteredSpinner } from '../../components/Spinner'
import { CustomerForm } from '../../forms/CustomerForm'
import { ShowDialogProps } from '../../show-dialog'
import { invalidateCustomer } from 'src/api/customer'

interface UpdateCustomerProps extends ShowDialogProps {
  id: EntityId
}

const UpdateCustomerDialog: React.FC<UpdateCustomerProps> = ({ pop, id }) => {
  const { t: translate } = useTranslation(['admin-customer', 'common'])
  const mutation = useUpdateCustomer(id as EntityId)

  const onSubmit = async form => {
    await mutation.mutateAsync(form, {
      onSuccess: () => {
        invalidateCustomerCache.getCustomers()
        invalidateCustomer.getUniqueAdminCustomers()
      },
    })
    pop(true)
  }

  const { data, isLoading } = useGetCustomer(id)
  if (isLoading) return <CenteredSpinner />

  return (
    <Box>
      <DialogTitle
        sx={{
          fontWeight: 'bold',
          fontSize: '1.8rem',
        }}
        className="text-primary"
      >
        {translate('updateCustomer', { ns: 'admin-customer' })}
      </DialogTitle>
      <DialogContent>
        <CustomerForm onSubmit={onSubmit} onCancel={() => pop()} initialValues={data}/>
      </DialogContent>
    </Box>
  )
}

export default UpdateCustomerDialog
