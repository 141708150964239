import { AccordionDetails } from '@mui/material'
import AccordionCommon from '../../../components/AccordionCommon'
import { GlobalDocumentsTable } from './GlobalDocumentTable'
import { DocumentCount } from 'src/screens/localities/documents/components/DocumentsTableByDocumentCategory'
import { Flex } from 'src/styles/flexComponent'
import { DynamicTextTypography } from 'src/components/DynamicTextTypography'
import { useTranslation } from 'react-i18next'

export interface IDocumentsTableProps {
  customerId: EntityId
  documentCategoryId: EntityId
  documentCategoryName: string
  documentsCount: number
  IsApprovedDocs: boolean
}
export const GlobalDocumentTableByDocumentCategory: React.FC<IDocumentsTableProps> = ({
  customerId,
  documentCategoryId,
  documentCategoryName,
  documentsCount,
  IsApprovedDocs,
}) => {
  const { t } = useTranslation(['Document', 'common'])
  return (
    <>
      <AccordionCommon
        id={documentCategoryId}
        title={
          <>
            <Flex.Row gap={1} justifyContent={'center'} alignItems={'center'}>
              <DynamicTextTypography
                maxLength={25}
                variant="subtitle2"
                fontWeight={500}
                text={t(documentCategoryName, { ns: 'document' })}
              />
              <DocumentCount hasExpired={false}>{documentsCount}</DocumentCount>
            </Flex.Row>
          </>
        }
      >
        <AccordionDetails>
          <GlobalDocumentsTable
            customerId={customerId}
            documentCategoryId={documentCategoryId}
            IsApprovedDocs={IsApprovedDocs}
          />
        </AccordionDetails>
      </AccordionCommon>
    </>
  )
}
