import { Box } from '@mui/material'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { invalidateCertificateCache, useAddCertificate } from '../../../../api/Certificates'
import { CreateCertificateForm } from '../../../../forms/certificate-forms/CreateCertificateForm'
import { ShowDialogProps } from '../../../../show-dialog'
import { ScreenTitleBar } from '../../components/ScreenTitleBar'
import { CertificateTable } from './CertificateTable'
import { invalidateMainComponentCache } from '../../../../api/main-component'
import { validateCertificateResponse } from '../../../../utils'
import { useDialogCtx } from 'src/hooks/context-hooks'

interface CreateCertificateDialogProps extends ShowDialogProps {
  mainComponentId: EntityId
  customerId: EntityId
}

export const CreateCertificateDialog: React.FC<CreateCertificateDialogProps> = ({
  pop,
  mainComponentId,
  customerId,
}) => {
  const { t } = useTranslation(['certificate', 'common'])
  const mutation = useAddCertificate(mainComponentId)

  useEffect(() => {
    if (mutation.error != null) {
      validateCertificateResponse(mutation.error['data'])
    }
  }, [mutation.error])

  const onSubmit = async form => {
    await mutation.mutateAsync(form).then(() => {
      invalidateCertificateCache.getCertificates(mainComponentId)
      invalidateMainComponentCache.getMainComponentById(customerId, mainComponentId)
      pop()
    })
  }

  return (
    <CreateCertificateForm
      onSubmit={onSubmit}
      onCancel={pop}
      customerId={customerId}
      title={t('addNewCertificate', { ns: 'certificate' })}
    />
  )
}

export const CertificatesContainer = () => {
  const { t } = useTranslation(['certificate', 'common'])
  const { mainComponentId, customerId } = useParams()
  const { showDialog } = useDialogCtx()

  const onAdd = async () => {
    const added = await showDialog(CreateCertificateDialog, {
      componentProps: {
        mainComponentId: mainComponentId as EntityId,
        customerId: customerId as EntityId,
      },
    })
    if (!added) return
  }

  return (
    <>
      <ScreenTitleBar
        title={t('certificates', { ns: 'certificate' })}
        addNewItemText={t('add', { ns: 'common' })}
        onAddNewItemClick={onAdd}
      />
      <Box className="mt-3 bg-white" borderRadius={2}>
        <CertificateTable mainComponentId={mainComponentId!} certificatesParams={{}} />
      </Box>
    </>
  )
}
