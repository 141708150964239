import { Box, Grid } from '@mui/material'
import React from 'react'
import { FormField, FormLayout } from '../../generated-types/form-field'
import { makeStyles } from '@material-ui/core'
import FieldSelectorViewer from './FieldSelectorViewer'

interface ILayoutProps {
    layout: FormLayout
    size: number
    values: object
}

const useStyles = makeStyles(() => ({
    grid: {
        minHeight: 50,
    },
    container: {
        width: 'calc(100% - 30px)',
        height: '100%',
        minHeight: 50
    },
    deleteButton: {
        color: '#88c2f3',
        cursor: 'pointer'
    }
}))

const LayoutBoxViewer: React.FC<ILayoutProps> = ({ layout, size, values }) => {
    const { fields } = layout
    const classes = useStyles()
    return (
        <Grid md={size} className={classes.grid} marginTop={1} padding={0.1} >
            <Box display={'flex'} flexDirection={'row'} height={'100%'}
                borderRadius={1} >
                <Box
                    className={classes.container}
                >
                    {fields.map((field: FormField, index: number) => {
                        return <Box key={index} >
                            <FieldSelectorViewer field={field} value={values && values[field.name]} />
                        </Box>
                    })}
                </Box>
            </Box>
        </Grid>
    )
}

export default LayoutBoxViewer