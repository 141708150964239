import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import { MdDelete, MdEdit } from 'react-icons/md'
import { showDialog, ShowDialogProps, showConfirmDialog } from '../../../../show-dialog'
import { useMemo } from 'react'
import { GridActionsCellItem, GridColumns, GridNativeColTypes, useGridApiRef } from '@mui/x-data-grid-pro'
import {
  invalidateFrequencyCache,
  useDeleteFrequency,
  useGetFrequencies,
  useGetFrequency,
  useUpdateFrequency,
} from '../../../../api/frequency'
import { FrequencyForm } from './FrequencyForm'
import { IFrequency } from '../../../../generated-types/frequency'
import { IFrequencyPeriodType } from '../../../../generated-types/frequency-period-type'
import { IMooring, IPositionType } from '../../../../generated-types'
import { FormDialog } from '../../../../forms/FormDialog'
import { CenteredSpinner } from '../../../../components/Spinner'
import GenericTable from '../../../../components/table/GenericTable'
import { invalidateLocalityCache } from '../../../../api/localities'
import { invalidateLocalityFrequencyCache } from '../../../../api/locality-frequency'
import { useGetMooringsForService } from '../../../../api/moorings'
import { APIResultCount } from 'src/constant'
import { toast } from 'react-toastify'

interface UpdateFrequencyProps extends ShowDialogProps {
  customerId: EntityId
  serviceId: EntityId
  frequency: IFrequency
  selectedMoorings: IMooring[]
}
const UpdateFrequencyDialog: React.FC<UpdateFrequencyProps> = ({ pop, customerId, serviceId, frequency,selectedMoorings}) => {
  const { t } = useTranslation(['frequency', 'common'])

  const {mutateAsync,isLoading:isUpdating} = useUpdateFrequency(customerId as EntityId, serviceId, frequency.id)
  const onSubmit = async form => {
    const data = await mutateAsync(form, {
      onSuccess: () => {
        const localityIdList = [] as number[]
        selectedMoorings.forEach(mooring => {
          if (localityIdList.indexOf(mooring.localityId) == -1) {
            localityIdList.push(mooring.localityId)
            invalidateLocalityFrequencyCache.getLocalityFrequenciesAndDetails(customerId, mooring.localityId)
          }
        })
        invalidateFrequencyCache.getFrequencies(customerId as EntityId, serviceId as EntityId)
        invalidateFrequencyCache.getFrequency(customerId as EntityId, serviceId as EntityId, frequency.id)
        invalidateLocalityFrequencyCache.getAllLocalityFrequencies(customerId)
        invalidateFrequencyCache.getFrequencies(customerId as EntityId, serviceId)
        toast.success(t('frequencyUpdatedSuccessfully', { ns: 'service' }))
        pop()
      },
      onError: (_) => {
        toast.error(t('updatingError', { ns: 'common' }))
      },
    })
    return data
  }

  const { data, isLoading } = useGetFrequency(customerId as EntityId, serviceId, frequency.id)

  if (isLoading) return <CenteredSpinner />

  return (
    <FormDialog
      title={t('updateFrequency', { ns: 'frequency' })}
      isLoading={isUpdating || isLoading}
      form={<FrequencyForm onSubmit={onSubmit} onCancel={() => pop()} initialValues={data} />}
    />
  )
}

interface FrequencyTableProps {
  customerId: EntityId
  serviceId: EntityId
}
export const FrequencyTable: React.FC<FrequencyTableProps> = ({ customerId, serviceId }) => {
  const { t: translate } = useTranslation(['frequency', 'common'])
  const apiRef = useGridApiRef()

  const { data: frequencies, isLoading } = useGetFrequencies(customerId as EntityId, serviceId as EntityId)

  const STATIC_COLUMNS: GridColumns = [
    {
      field: 'title',
      headerName: translate('title', { ns: 'common' }),
      flex: 1,
    },
    {
      field: 'periodFrequency',
      headerName: translate('frequency', { ns: 'frequency' }),
      flex: 1,
      valueGetter: ({ value, row }) => {
        return row.periodFrequency + ' ' + IFrequencyPeriodType[row.periodType]
      },
    },
    {
      field: 'timeThreshold',
      headerName: translate('timeThreshold', { ns: 'frequency' }),
      flex: 1,
    },
    {
      field: 'positionTypes',
      headerName: translate('toBeControlled', { ns: 'frequency' }),
      flex: 1,
      valueGetter: ({ value, row }) => {
        return (value?.map(x => IPositionType[x]).join(',') as string) || '-'
      },
    },
  ]

  const { mutateAsync, isLoading: isDeleting } = useDeleteFrequency(customerId as EntityId, serviceId as EntityId)
  const {data: mooringsForService} = useGetMooringsForService(customerId as EntityId, serviceId,0,APIResultCount.Max)

  const onDelete = (id: EntityId) => {
    showConfirmDialog(
      translate('deleteFrequency', { ns: 'frequency' }),
      translate('areYouSureDeleteFrequency', { ns: 'frequency' }),
    ).then(e => {
      if (e) {
        mutateAsync(id,{
          onSuccess: () => {
            invalidateLocalityCache.getLocalities(customerId as EntityId)
            invalidateFrequencyCache.getFrequencies(customerId as EntityId, serviceId as EntityId)
            invalidateLocalityFrequencyCache.getAllLocalityFrequencies(customerId)
    
            const localityIdList = [] as number[]
            mooringsForService?.items.forEach(mooring => {
              if (localityIdList.indexOf(mooring.localityId) == -1) {
                localityIdList.push(mooring.localityId)
                invalidateLocalityFrequencyCache.getLocalityFrequenciesAndDetails(customerId, mooring.localityId)
              }
            })
            invalidateFrequencyCache.getFrequency(customerId as EntityId, serviceId as EntityId, id)
            invalidateLocalityCache.getLocalitiesWithInfoCount(customerId as EntityId)
            toast.success(translate('frequencyDeletedSuccessfully', { ns: 'service' }))
            
          },
          onError: () => {
            toast.error(translate('deletingError', { ns: 'common' }))
          },
        })
      }
    })
  }

  const onUpdate = async (frequency: IFrequency) => {
    await showDialog(UpdateFrequencyDialog, {
      componentProps: {
        customerId: customerId as EntityId,
        serviceId: serviceId as EntityId,
        frequency,
        selectedMoorings: mooringsForService?.items as IMooring[],
      },
    })
  }

  const columns: GridColumns = useMemo(
    () => [
      ...STATIC_COLUMNS,
      {
        field: 'actions',
        type: 'actions' as GridNativeColTypes,
        width: 10,
        getActions: params => [
          <GridActionsCellItem
            key={params.id}
            label={translate('edit', { ns: 'common' })}
            icon={<MdEdit size={24} />}
            onClick={() => onUpdate(params.row as IFrequency)}
            disabled={isDeleting}
            color="primary"
            showInMenu
          />,
          <GridActionsCellItem
            key={params.id}
            label={translate('delete', { ns: 'common' })}
            icon={<MdDelete size={24} />}
            onClick={() => onDelete(params.id)}
            disabled={isDeleting}
            color="primary"
            showInMenu
          />,
        ],
      },
    ],
    [isDeleting, mooringsForService , UpdateFrequencyDialog],
  )

  return (
    <>
      {frequencies?.items?.length ? (
        <>
          <GenericTable
            id="frequencies-table"
            enablePagination={true}
            columns={columns}
            data={frequencies.items || []}
            isDownloading={isDeleting}
            apiRef={apiRef}
            enableFilter={false}
          />
        </>
      ) : (
        <Box className="font-semibold text-center text-gray-600 text-l">
          {translate('noFrequenciesData', { ns: 'frequency' })}
        </Box>
      )}
    </>
  )
}
