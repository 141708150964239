import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { invalidateFrequencyCache, useAddFrequency } from '../../../../api/frequency'
import { MainButton } from '../../../../components/MainButton'
import { ScreenTitle } from '../../../../components/ScreenTitle'
import { showDialog, ShowDialogProps } from '../../../../show-dialog'
import { FrequencyForm } from './FrequencyForm'
import { FrequencyTable } from './FrequenciesTable'
import { useState } from 'react'
import { FormDialog } from '../../../../forms/FormDialog'
import { toast } from 'react-toastify'

interface CreateFrequencyProps extends ShowDialogProps {
  customerId: EntityId
  serviceId: EntityId
}

interface FrequenciesContainerProps {
  serviceId: EntityId
}

const CreateFrequencyDialog: React.FC<CreateFrequencyProps> = ({ pop, customerId, serviceId }) => {
  const { t } = useTranslation(['frequency', 'common'])
  const [errorMessage, setErrorMessage] = useState('')

  const { mutateAsync, isLoading } = useAddFrequency(customerId as EntityId, serviceId as EntityId)
  const onSubmit = async form => {
    const data = await mutateAsync(form,{
      onSuccess: () => {
        toast.success(t('frequencyAddedSuccessfully', { ns: 'service' }))
        invalidateFrequencyCache.getFrequencies(customerId as EntityId, serviceId as EntityId)
        pop()
      },
      onError: (_) => {
        toast.error(t('addingError', { ns: 'common' }))
      },
    })
    return data
  }

  return (
    <FormDialog
      title={t('addFrequency', { ns: 'frequency' })}
      isLoading={isLoading}
      form={<FrequencyForm onSubmit={onSubmit} onCancel={() => pop()} />}
    />
  )
}

export const FrequenciesContainer = ({ serviceId }: FrequenciesContainerProps) => {
  const { t: translate } = useTranslation(['frequency', 'common'])
  const { id: customerId } = useParams()

  const onAddFrequency = async () => {
    const added = await showDialog(CreateFrequencyDialog, {
      componentProps: {
        customerId: customerId as EntityId,
        serviceId: serviceId as EntityId,
      },
    })
    if (!added) return
  }

  return (
    <>
      <Box className="flex justify-between mb-12">
        <ScreenTitle title={translate('frequencies', { ns: 'frequency' })} textSize={'text-xl'} />
        <MainButton onClick={() => onAddFrequency()} data-cy='addFrequency'>{translate('addFrequency', { ns: 'frequency' })}</MainButton>
      </Box>
      <FrequencyTable serviceId={serviceId as EntityId} customerId={customerId as EntityId} />
    </>
  )
}
