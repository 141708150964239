import { z } from 'zod'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import {
  Autocomplete,
  Box,
  Chip,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useZodForm } from '../../../../hooks/zod-form'
import { CancelButton } from '../../../../components/CancelButton'
import { AppForm, FormSubmitButton, FormTextField, AppFormProps } from '../../../../components/FormCore'
import { IPositionType } from '../../../../generated-types'
import { useCallback, useEffect, useState } from 'react'
import { IFrequencyPeriodType } from '../../../../generated-types/frequency-period-type'
import { decode, encode } from 'html-entities'
import { Controller } from 'react-hook-form'
import { validateString } from '../../../../utils'
import { decodeFrequencyInstructionHTML } from 'src/utils/function'

export const FrequencyForm: React.FC<AppFormProps> = ({ onSubmit, onCancel, initialValues }) => {
  const parsedInitialValues = initialValues
    ? {
        ...initialValues,
        periodFrequency: initialValues.periodFrequency ? String(initialValues.periodFrequency) : '',
      }
    : undefined

  const { t } = useTranslation(['frequency', 'common'])

  const schema = z.object({
    title: z
      .string()
      .nonempty(t('provideValidInput', { ns: 'common' }))
      .max(150, t('max_length', { ns: 'form-validation' }))
      .refine(validateString, {
        message: t('provideValidInput', { ns: 'common' }),
      }),
    instructions: z
      .string()
      .default('')
      .refine(
        value => {
          const decodedValue = decodeFrequencyInstructionHTML(value)
          return decodedValue.trim().length > 0
        },
        {
          message: t('instructionsAreRequired', { ns: 'frequency' }),
        },
      ),
    periodFrequency: z
      .string()
      .nonempty(t('provideValidInput', { ns: 'common' }))
      .or(z.number())
      .transform(v => Number(v))
      .refine(n => n >= 0),
    timeThreshold: z
      .string()
      .nonempty(t('provideValidInput', { ns: 'common' }))
      .or(z.number())
      .transform(v => Number(v))
      .refine(n => n >= 0),
    periodType: z.union([z.number(), z.string().nonempty(t('provideValidInput', { ns: 'common' }))]),
    positionTypes: z.any(),
  })

  const [positionTypes, setPositionTypes] = useState<IPositionType[]>(parsedInitialValues?.positionTypes)
  const [instructions, setInstructions] = useState<string>(parsedInitialValues?.instructions || '')
  const [periodType, setPeriodType] = useState<number>(parsedInitialValues?.periodType)

  const form = useZodForm(schema, {
    defaultValues: parsedInitialValues,
  })

  const { setValue, control } = useZodForm(schema)

  const isUpdateForm = parsedInitialValues?.id

  const handleSubmit = useCallback(
    async formValue => {
      if (positionTypes.length === 0) return
      formValue.positionTypes = positionTypes.map(positionType => positionType)
      await onSubmit(formValue)
    },
    [positionTypes, onSubmit, form, t],
  )

  useEffect(() => {
    if (instructions) {
      form.setValue('instructions', instructions)
      form.clearErrors('instructions')
    }
  }, [form, instructions])

  useEffect(() => {
    if (periodType || periodType == 0) {
      setValue('periodType', periodType)
      form.setValue('periodType', periodType)
      form.clearErrors('periodType')
    }
  }, [form, periodType, setValue])

  const positionTypesOptions = Object.keys(IPositionType)
    .filter(v => isNaN(Number(v)))
    .map(t => t)

  const periodTypesOptions = Object.keys(IFrequencyPeriodType)
    .filter(v => isNaN(Number(v)))
    .map(t => t)

  return (
    <AppForm form={form} onSubmit={handleSubmit} hasInitialValues={parsedInitialValues}>
      {isUpdateForm && <FormTextField name="id" type="hidden" />}
      <Box py={1}>
        <FormTextField name="title" label={t('title', { ns: 'common' })} data-cy='title'/>
      </Box>
      <Box py={1}>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={3}>
            <FormTextField name="periodFrequency" type={'number'} label={t('frequency', { ns: 'frequency' })} data-cy='periodFrequency'/>
          </Grid>
          <Grid item xs={6} sm={3}>
            <FormControl variant="filled" fullWidth error={!!form.formState.errors.periodType} data-cy='periodType'>
              <InputLabel id="periodType">{t('periodType', { ns: 'frequency' })}</InputLabel>
              <Controller
                name="periodType"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value } }) => {
                  if (periodType || periodType == 0) {
                    value = periodType
                  }
                  return (
                    <Select
                      sx={{ height: '49px' }}
                      fullWidth
                      name="periodType"
                      value={IFrequencyPeriodType[value]}
                      onChange={event => {
                        setPeriodType(Number(IFrequencyPeriodType[event.target.value.toString()]))
                        onChange(event.target.value.toString())
                      }}
                      defaultValue={IFrequencyPeriodType[periodType]}
                      displayEmpty
                      label={t('periodType', { ns: 'component' })}
                      variant="filled"
                      inputProps={{ 'aria-label': 'Without label' }}
                    >
                      {periodTypesOptions?.map(periodType => (
                        <MenuItem key={periodType} value={periodType}>
                          {periodType}
                        </MenuItem>
                      ))}
                    </Select>
                  )
                }}
              />
              {form.formState.errors.periodType && (
                <FormHelperText>
                  {t('periodTypeIs', { ns: 'frequency' })}
                  {form.formState.errors.periodType.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      <Box py={1}>
        <Autocomplete
          multiple
          options={positionTypesOptions}
          defaultValue={positionTypes?.map(positionType => IPositionType[positionType])}
          onChange={(_, v) => setPositionTypes(v.map(v => Number(IPositionType[v])))}
          freeSolo
          renderTags={(value: readonly string[], getTagProps) =>
            value?.map((option, index) => (
              // eslint-disable-next-line react/jsx-key
              <Chip
                sx={{ blockSize: 25, fontSize: 12 }}
                variant="outlined"
                label={option}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={params => (
            <TextField
              {...params}
              name="positionTypes"
              variant="filled"
              required
              error={form.formState.isSubmitted && (!positionTypes || positionTypes?.length == 0)}
              helperText={
                form.formState.isSubmitted && (!positionTypes || positionTypes?.length == 0)
                  ? t('provideValidInput', { ns: 'common' })
                  : ''
              }
              label={t('positionTypes', { ns: 'frequency' })}
              placeholder={t('addPositionType', { ns: 'frequency' })}
              data-cy='positionTypes'
            />
          )}
        />
      </Box>
      <Box py={1}>
        <FormTextField name="timeThreshold" type={'number'} label={t('timeThreshold', { ns: 'frequency' })} data-cy='timeThreshold'/>
      </Box>
      <Box p={1} mt={2} sx={{ backgroundColor: 'lightgray', boxShadow: '0px 3px 9px #888888', borderRadius: 1 }}>
        <Box
          sx={{
            padding: '5px 0px 8px 5px',
          }}
        >
          <label
            style={{
              color: form.formState.errors.instructions ? '#d32f2f' : 'rgba(0, 0, 0, 0.6)',
              fontSize: '1.1rem',
              fontWeight: '400',
            }}
          >
            {t('instructions', { ns: 'frequency' })}
          </label>
        </Box>
        <ReactQuill
          value={decode(instructions)}
          onChange={value => {
            setInstructions(encode(value))
          }}
          id="instructions"
          modules={{
            clipboard: {
              matchVisual: true,
            },
          }}
        />
        {form.formState.errors.instructions && (
          <FormHelperText sx={{ color: '#d32f2f' }}>
            {t(form.formState.errors.instructions.message as string, { ns: 'frequency' })}
          </FormHelperText>
        )}
      </Box>
      <Box className="flex justify-end gap-4 pt-4">
        <CancelButton onClick={onCancel} data-cy='cancel'>{t('cancel', { ns: 'common' })}</CancelButton>
        <FormSubmitButton createText={isUpdateForm ? t('update', { ns: 'common' }) : t('add', { ns: 'common' })} data-cy='submit'/>
      </Box>
    </AppForm>
  )
}
