import { useMediaQuery, useTheme } from '@mui/material'
import { useCallback, useMemo, useState } from 'react'
import GenericTable from '../../../components/table/GenericTable'
import { GridColumns, GridNativeColTypes } from '@mui/x-data-grid-pro'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { AppRoutesNavigation } from '../../../app-routes'
import { AlertDialogPopup } from '../../../show-dialog'
import { invalidateMainComponentCache, useReleaseMainComponent } from '../../../api/main-component'
import { MainComponentSendModalComponent } from '../positions/components/MainComponentSendModal'
import { invalidatePositionCache } from '../../../api/positions'
import { IMainComponent } from '../../../generated-types/main-component'
import { useDialogCtx } from '../../../hooks/context-hooks'
import { Padding } from 'src/styles/paddingComponent'
import { GridActionButton } from 'src/components/common/GridActionButton'
import { mainComponentsSectionHeight } from './CageContainer'
import { toast } from 'react-toastify'
import { invalidationActivityLogCache } from 'src/api/activity-log'
import { APIResultCount } from 'src/constant'
import { Visibility } from '@mui/icons-material'

const AdditionalEquipment = ({ additionalEquipment }) => {
  const { localityId, customerId, mooringId, positionId } = useParams()
  const { showDialog } = useDialogCtx()
  const { t } = useTranslation('main-component')
  const { t: translate } = useTranslation('common')
  const [mainComponent, setMainComponent] = useState<IMainComponent>()
  const [releaseMainComponent, setReleaseMainComponent] = useState(false)
  const { mutateAsync } = useReleaseMainComponent(customerId as EntityId)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const navigate = useNavigate()

  const onSend = async (id: EntityId) => {
    const updated = await showDialog(MainComponentSendModalComponent, {
      title: translate('sendToServiceStation', { ns: 'main-component' }),
      opts: {
        maxWidth: 'md',
        fullWidth: true,
      },
      componentProps: {
        customerId: customerId as EntityId,
        localityId: localityId as EntityId,
        mooringId: mooringId as EntityId,
        positionId: positionId as EntityId,
        id,
      },
    })
    if (!updated) return
  }

  const columns: GridColumns = useMemo(
    () => [
      {
        field: 'id',
        headerName: '#',
        flex: 1,
      },
      {
        field: 'name',
        headerName: t('name').toUpperCase(),
        flex: 1,
      },
      {
        field: 'expires',
        headerName: t('expires').toUpperCase(),
        flex: 1,
      },
      {
        field: 'actions',
        type: 'actions' as GridNativeColTypes,
        width: 330,
        renderCell: params => [
          <Padding.p4 key={2}>
            <GridActionButton
              buttonIcon={<Visibility />}
              onClick={() => {
                navigate(AppRoutesNavigation.Localities.mainComponent(customerId as EntityId, localityId as EntityId, params?.row?.id))
              }}
              variant="outlined"
              height='32px'
              shadowWeight={0}
            />
          </Padding.p4>,
          <Padding.p4 key={2}>
            <GridActionButton
              buttonText={t('move-send')}
              onClick={() => {
                onSend(params.row.id)
              }}
              height='32px'
              variant="outlined"
              shadowWeight={0}
            />
          </Padding.p4>,
          <Padding.p4 key={3}>
            <GridActionButton
              buttonText={t('release')}
              onClick={() => {
                setMainComponent(params.row)
                setReleaseMainComponent(true)
              }}
              height='32px'
              variant="outlined"
              shadowWeight={0}
            />
          </Padding.p4>,
        ],
      },
    ],
    [],
  )


  const handleConfirm = useCallback(async () => {
    if (!mainComponent) return
    await mutateAsync(
      { id: mainComponent?.id as number, localityId: localityId as EntityId },
      {
        onSuccess: () => {
          toast.success(translate('main-component-released-from-cage-toast', { ns: 'main-component' }))
          invalidateMainComponentCache.getMainComponents(
            customerId as EntityId,
            localityId as EntityId,
            mooringId as EntityId,
            positionId as EntityId,
          )

          invalidationActivityLogCache.getCageActivityLogs({
            page: 0,
            pageSize: APIResultCount.Max,
          })
          invalidatePositionCache.getCagePositionDeviations(
            customerId as EntityId,
            localityId as EntityId,
            positionId as EntityId,
          )

          setReleaseMainComponent(false)
        },
      })
  }, [mainComponent])

  return (
    <>
      <GenericTable
        id={'id'}
        columns={columns}
        data={additionalEquipment}
        height={mainComponentsSectionHeight - 119}
        styledTable={true}
        getRowHeight={() => 44}
        autoHeight={isMobile ? true : false}
        onRowClick={params => {
          navigate(AppRoutesNavigation.Localities.mainComponent(customerId as EntityId, localityId as EntityId, params?.row?.id))
        }}
      />
      <AlertDialogPopup
        open={releaseMainComponent}
        pop={setReleaseMainComponent}
        acceptText={t('yes')}
        cancelText={t('no')}
        onConfirm={handleConfirm}
        title={t('release-from-cage')}
        text={t('release-from-cage-description')}
      />
    </>
  )
}

export default AdditionalEquipment
