import { Stack, StepLabel, styled } from '@mui/material'

//styled
export const StepLabelStyled = styled(StepLabel)`
  .MuiStepIcon-root{
    font-size:16px !important;
    height:44px;
    width: 44px;
  }
  .MuiStepLabel-label{
    font-size:16px !important;
    width: 160px;
    padding-left: 10px;
  }
`

export const DetailContainer = styled(Stack)`
    background-color: ${props => props.theme.palette.secondaryLight[props.theme.palette.mode]};
    padding: 5px;
    border-radius: 4px;
`
