import { Box } from '@mui/material'
import { useParams } from 'react-router-dom'
import { MainComponentDetailTable } from './MainComponentDetailTable'
import { CreateMainComponentDialog } from './CreateMainComponentDialog'
import { useDialogCtx } from 'src/hooks/context-hooks'

export const MainComponentAddContainer = ({ mainComponentTypeName, componentTypeId,itemCount }) => {
  const { id: customerId, localityId, mooringId, positionId } = useParams()
  const { showDialog } = useDialogCtx()

  const onAdd = async () => {
    const added = await showDialog(CreateMainComponentDialog, {
      componentProps: {
        customerId: customerId as EntityId,
        mainComponentTypeName: mainComponentTypeName,
        componentTypeId: componentTypeId,
      },
    })
    if (!added) return
  }

  return (
    <>
      <Box className="p-3 mt-3 bg-white" borderRadius={2}>
        <MainComponentDetailTable
          customerId={customerId as EntityId}
          componentTypeId={componentTypeId as number}
          onClickMethod={onAdd}
          mainComponentTypeName={mainComponentTypeName}
          localityId={localityId as EntityId}
          mooringId={mooringId as EntityId}
          positionId={positionId as EntityId}
          itemCount={itemCount}
        />
      </Box>
    </>
  )
}
