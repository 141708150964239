import * as React from 'react'
import { Theme, styled, useTheme } from '@mui/material/styles'
import OutlinedInput from '@mui/material/OutlinedInput'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import ListItemText from '@mui/material/ListItemText'
import Checkbox from '@mui/material/Checkbox'
import Menu from '@mui/material/Menu'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

function getStyles(name: string, personName: readonly string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  }
}

interface MultipleSelectChipProps {
  areas: string[]
  setAreas: React.Dispatch<React.SetStateAction<string[]>>
  otherItems: string[]
  setOtherItems: React.Dispatch<React.SetStateAction<string[]>>
  localityGroups?: string[]
}

const StyledMenuItem = styled(MenuItem)(() => ({
  '& .MuiPopover-paper': { overflow: 'hidden', maxWidth: '225px', maxHeight: '250px', overflowY: 'auto' }
}))

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  '& .MuiInputBase-root': {
    boxShadow: ` 0px 3px 6px ${theme.palette.secondaryLight[theme.palette.mode]}`,
    borderRadius: '4px',
    backgroundColor: `${theme.palette.white[theme.palette.mode]} !important`
  },
  width: '100%'
}))

const StyledSelect = styled(Select)(({ theme }) => ({
  '& .MuiSvgIcon-root': { color: theme.palette.primaryDark[theme.palette.mode] },
  height: '44px'
}))

export const MultipleSelectChip: React.FC<MultipleSelectChipProps> = ({ areas, setAreas, otherItems, setOtherItems, localityGroups }) => {
  const theme = useTheme()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [openSubMenu, setOpenSubMenu] = React.useState(false)
  const { t } = useTranslation('localities')

  const names = {
    areas: t('areas'),
    has_due_documents: t('has_due_documents'),
    has_deviations: t('has_deviations'),
    has_due_services: t('has_due_services')
  }

  const nameKeys = Object.keys(names)

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event

    let filteredValue = typeof value === 'string' ? value.split(',') : value
    filteredValue = filteredValue.filter(
      (item) => !localityGroups?.includes(item) && item !== 'areas'
    )
    setOtherItems(filteredValue)
  }

  const handleItemClick =
    (name: string) => (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation()
      if (name === 'areas') {
        handleOpenSubMenu(event)
      } else {
        setOtherItems((prevItems) =>
          prevItems.includes(name)
            ? prevItems.filter((item) => item !== name)
            : [...prevItems, name]
        )
      }
    }

  const handleOpenSubMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
    setOpenSubMenu(true)
  }

  const handleCloseSubMenu = () => {
    setAnchorEl(null)
    setOpenSubMenu(false)
  }

  const handleSubMenuClick = (
    event: React.MouseEvent<HTMLElement>,
    item: string
  ) => {
    event.stopPropagation()
    setAreas((prevAreas) => {
      if (prevAreas.includes(item)) {
        return prevAreas.filter((name) => name !== item)
      } else {
        return [...prevAreas, item]
      }
    })
  }

  return (
    <Box>
      <StyledFormControl sx={{ width: 174 }}>
        <StyledSelect
          multiple
          value={[...areas, ...otherItems]}
          onChange={(event: SelectChangeEvent<unknown>) => handleChange(event as SelectChangeEvent<string[]>)}
          input={<OutlinedInput />}
          displayEmpty
          renderValue={(selected) => {
            const selectedValues = selected as string[]
            if (selectedValues.length === 0) {
              return t('all_localities')
            }

            return (selected as string[]).map((value) => t(value)).join(', ')
          }}
          inputProps={{ 'aria-label': 'Without label' }}
        >
          {Object.keys(names).map((name) => (
            <MenuItem
              key={name}
              value={name}
              style={getStyles(name, [...areas, ...otherItems], theme)}
              onClick={handleItemClick(name)}
            >
              {name !== 'areas' && (
                <Checkbox checked={[...areas, ...otherItems].includes(name)} />
              )}
              <ListItemText primary={names[name]} />
              {name === 'areas' && <ArrowRightIcon />}
            </MenuItem>
          ))}
        </StyledSelect>
      </StyledFormControl>
      <Menu
        anchorEl={anchorEl}
        open={openSubMenu}
        onClose={handleCloseSubMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {localityGroups?.map((item) => (
          <MenuItem
            key={item}
            value={item}
            onClick={(event) => handleSubMenuClick(event, item)}
          >
            <Checkbox checked={areas.includes(item)} />
            <ListItemText primary={item} />
          </MenuItem>
        ))}
      </Menu>
    </Box>
  )
}

export default MultipleSelectChip  
