import { ScreenTitleBar } from '../../localities/components/ScreenTitleBar'
import { LocalitiesTable } from './LocalitiesTable'
import { useTranslation } from 'react-i18next'
import { endpoints } from '../../../api/localities'
import { useCustomerCtx } from '../../../hooks/context-hooks'
import { Box } from '@mui/material'
import { FC, useCallback } from 'react'
import LocalityDetailPanel from './LocalityDetailPanel'
import { ILocality } from '../../../generated-types'
import { UpdateLocalityDialog } from '../../localities/UpdateLocalityDialog'
import { useDialogCtx } from 'src/hooks/context-hooks'
import { CreateLocalitiesDialog } from './CreateLocalityDialog'
import { ResourceScreenProps } from '../../../generated-types/resource-screen'
import { localityQueryKeys } from '../../../query-keys'

export const LocalitiesScreen: FC<ResourceScreenProps> = ({
  renderTableExpand,
  query,
  params,
  generateQueryKey,
  handleAdd,
  handleUpdate,
  isActionAvailable = true,
  isTableExpandable = true,
}) => {
  const { customer } = useCustomerCtx()
  const { t: translate } = useTranslation(['admin-locality', 'common'])
  const { showDialog } = useDialogCtx()

  const onAdd = async () => {
    const added = await showDialog(
      CreateLocalitiesDialog,
      {
        componentProps: {
          customerId: customer.id as EntityId,
        },
      },
      undefined,
      true,
    )
    if (!added) return
  }

  const onUpdate = useCallback(
    async locality => {
      await showDialog(
        UpdateLocalityDialog,
        {
          title: translate('updateLocality', { ns: 'customer-locality' }),
          componentProps: { customerId: customer.id, locality: locality, t: translate },
        },
        undefined,
        true,
      )
    },
    [customer.id, translate],
  )

  return (
    <>
      <Box className="px-4 py-2 mt-2 mb-2" borderRadius={2}>
        <ScreenTitleBar
          title={translate('localities')}
          addNewItemText={translate('add', { ns: 'common' })}
          onAddNewItemClick={handleAdd ?? onAdd}
        />
        <LocalitiesTable
          getDetailPanelContent={
            isTableExpandable
              ? renderTableExpand ?? (params => <LocalityDetailPanel locality={params.row as ILocality} />)
              : undefined
          }
          queryUrl={query ?? endpoints.getLocalities(customer.id)}
          queryParams={params ? { ...params, isIgnoreQueryFilters: true } : { isIgnoreQueryFilters: true }}
          generateQueryKey={
            generateQueryKey ??
            ((page: number, pageSize: number) => localityQueryKeys.GET_LOCALITIES(customer.id, { page, pageSize, ...params }))
          }
          onUpdate={isActionAvailable ? handleUpdate ?? onUpdate : undefined}
        />
      </Box>
    </>
  )
}
