import { Box, Grid } from '@mui/material'
import { useEffect, useState } from 'react'
import AllLocalities from './container/AllLocalities'
import { useGetCustomersNameWithPermissions } from '../../api/customer'
import { CenteredSpinner } from '../../components/Spinner'
import { useLocation } from 'react-router-dom'
import { LocalitiesMapComponent } from './LocalitiesMapComponent'

const LocalityMainPage = () => {
  const [open, setOpen] = useState(0)
  const [searchValue, setSearchValue] = useState('')
  const { data, isLoading, isSuccess } = useGetCustomersNameWithPermissions()
  const { hash } = useLocation()

  useEffect(() => {
    if (data && data?.length > 0) {
      setOpen(data[0]?.id)
    }
  }, [data])

  useEffect(() => {
    setSearchValue(hash.replace('#', ''))
  }, [hash])

  return (
    <>
      <Box className="relative z-10 h-full overflow-hidden bg-white" sx={{ width: '100%' }}>
        {isLoading && <CenteredSpinner />}
        {isSuccess && (
          <Box className="pt-1">
            <Grid container>
              <Grid item md={7} lg={7} sm={12}>
                <AllLocalities customers={data} open={open} setOpen={setOpen} />
              </Grid>
              <LocalitiesMapComponent selectedCustomer={open} searchValue={searchValue} />
            </Grid>
          </Box>
        )}
      </Box>
    </>
  )
}
export default LocalityMainPage
