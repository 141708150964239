import { useTranslation } from 'react-i18next'
import { Autocomplete, Box } from '@mui/material'
import { z } from 'zod'
import { AppForm, FormTextField, AppFormProps } from '../../../components/FormCore'
import { useZodForm } from '../../../hooks/zod-form'
import { useGetAdminSuppliers } from '../../../api/supplier'
import { ISupplier } from '../../../generated-types'
import { GenericSupplierFormFields } from './constants'
import { AppTextField } from '../../../components/AppTextField'
import { GenericDialogLayout } from '../../../components/GenericDialogLayout'
import { MainButton } from '../../../components/MainButton'
import { useEffect } from 'react'
import { APIResultCount } from '../../../constant'

interface SupplierTableProps extends AppFormProps {
  isSuperAdmin: boolean
  title?: string
}

export const SupplierForm: React.FC<SupplierTableProps> = ({
  onSubmit,
  onCancel,
  initialValues,
  isSuperAdmin,
  title,
}) => {
  const { t: translate } = useTranslation(['supplier', 'common'])
  const { data: suppliers } = useGetAdminSuppliers(0, APIResultCount.Max)

  const schema = isSuperAdmin
    ? z.object({
        ...GenericSupplierFormFields,
        urlHomepage: z.string().or(z.null()).optional(),
        phone: z.string().or(z.null()).optional(),
        streetAddress: z.string().or(z.null()).optional(),
        postaCode: z.string().or(z.null()).optional(),
        city: z.string().or(z.null()).optional(),
        land: z.string().or(z.null()).optional(),
        contactPerson: z.string().or(z.null()).optional(),
      })
    : z.object({
        ...GenericSupplierFormFields,
        parentSupplierId: z
          .object({
            id: z.number(),
          })
          .or(z.null())
          .transform(value => (value !== null ? value.id : value))
          .optional(),
        contactPerson: z.string().or(z.null()).optional(),
      })

  const form = useZodForm(schema, {
    defaultValues: {
      ...initialValues,
      contactPersonId: initialValues?.contactPerson,
      parentSupplierId: initialValues?.parentSupplier,
    },
  })

  useEffect(() => {
    form.reset({
      ...initialValues,
      contactPersonId: initialValues?.contactPerson,
      parentSupplierId: initialValues?.parentSupplier,
    })
  }, [form, initialValues])

  const isUpdateForm = initialValues?.id

  const displayValue = isSuperAdmin ? 'box' : 'none'

  return (
    <GenericDialogLayout
      title={title}
      pop={onCancel}
      removedCloseButton={false}
      actions={[
        <MainButton
          key={'submit-btn'}
          onClick={() => {
            form.handleSubmit(onSubmit)()
          }}
        >
          {isUpdateForm ? translate('update', { ns: 'common' }) : translate('add', { ns: 'common' })}
        </MainButton>,
      ]}
    >
      <AppForm form={form} onSubmit={onSubmit} hasInitialValues={isUpdateForm}>
        {isUpdateForm && <FormTextField name="id" type="hidden" />}
        <Box py={1}>
          <FormTextField name="name" label={translate('name', { ns: 'common' })} />
        </Box>
        <Box py={1}>
          <FormTextField name="organizationNumber" label={translate('organizationNumber', { ns: 'supplier' })} />
        </Box>
        <Box py={1} display={!isSuperAdmin ? 'box' : 'none'}>
          <Autocomplete
            options={(suppliers?.items as ISupplier[]) || []}
            getOptionLabel={option => option.name}
            renderOption={(props: any, option) => (
              <Box {...props} key={option.id}>
                {option.name}
              </Box>
            )}
            onChange={(e, value) => {
              form.setValue('parentSupplierId', value as any)
            }}
            isOptionEqualToValue={(o, v) => o.id === v.id}
            defaultValue={(form.getValues('parentSupplierId') as any) ?? null}
            renderInput={params => (
              <AppTextField
                {...params}
                name="parentSupplierId"
                label={translate('globalSupplier', { ns: 'supplier' })}
                inputProps={{
                  ...params.inputProps,
                }}
              />
            )}
          />
        </Box>

        <Box py={1} display={displayValue}>
          <FormTextField name="urlHomepage" label={translate('urlHomepage', { ns: 'supplier' })} />
        </Box>
        <Box py={1} display={displayValue}>
          <FormTextField name="phone" label={translate('phone', { ns: 'supplier' })} />
        </Box>
        <Box py={1} display={displayValue}>
          <FormTextField name="streetAddress" label={translate('streetAddress', { ns: 'supplier' })} />
        </Box>
        <Box py={1} display={displayValue}>
          <FormTextField name="postaCode" label={translate('postaCode', { ns: 'supplier' })} />
        </Box>
        <Box py={1} display={displayValue}>
          <FormTextField name="city" label={translate('city', { ns: 'supplier' })} />
        </Box>
        <Box py={1} display={displayValue}>
          <FormTextField name="land" label={translate('land', { ns: 'supplier' })} />
        </Box>
        <Box py={1}>
          <FormTextField name="notes" label={translate('notes', { ns: 'supplier' })} multiline minRows={4} />
        </Box>
        <Box py={1}>
          <FormTextField name="contactPerson" label={translate('contactPerson', { ns: 'supplier' })} />
        </Box>
      </AppForm>
    </GenericDialogLayout>
  )
}
