import { useEffect, useState } from 'react'
import { invalidateSchemaForm, useAddFormSchemaReport, useGetFormSchema } from '../../api/form-schema'
import { IFormSchema, IFormSchemaReport } from '../../generated-types/form-schema'
import { Box, Button, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core'
import { ArrowRight } from '@mui/icons-material'
import FormSchemaForm from './FormSchemaForm'
import { ZodObject, z } from 'zod'
import { FormFieldType } from '../../enums'
import { FormRow } from '../../generated-types/form-field'
import { Spinner } from '../Spinner'
import { invalidateDeviation } from '../../api/deviation'
import { invalidateLocalityFrequencyCache } from '../../api/locality-frequency'
import { ShowDialogProps } from 'src/show-dialog'

const useStyles = makeStyles(_theme => ({
    SchemaContainer: {
        border: '1px solid lightBlue',
        cursor: 'pointer',
        padding: 10,
        borderRadius: 5,
        display: 'flex',
        flexDirection: 'row',

    }
}))

interface IFormSchemaDialog extends ShowDialogProps {
    customerId: EntityId
    localityId?: EntityId
    property: any
    formInitialValues: any
    selectedSchemaForm?: IFormSchema | null
}

const FormSchemasDialog: React.FC<IFormSchemaDialog> = ({ customerId, localityId = null, pop, property, formInitialValues, selectedSchemaForm }) => {
    const { t } = useTranslation(['form-schema', 'common', 'forms'])
    const { data, isLoading } = useGetFormSchema(customerId as EntityId)
    const mutate = useAddFormSchemaReport(customerId as EntityId)
    const [selectedSchema, setSelectedSchema] = useState<IFormSchema>()
    const [viewForm, setViewForm] = useState(false)
    const [schema, setSchema] = useState<ZodObject<any>>(z.object({}))
    const [initialValues, setInitialValues] = useState({})
    const classes = useStyles()
    const onSubmit = async (form) => {
        const formSchemaReport: IFormSchemaReport = {
            formSchemaVersionId: selectedSchema?.versions[selectedSchema?.versions.length - 1].id,
            values: JSON.stringify(form),
            type: property.type,
            deviationId: property.deviationId,
            localityFrequencyId: property.localityFrequencyId,
            groupId: property.groupId,
            mainComponentId: property.mainComponentId,
            scheduledTaskId: property.scheduledTaskId
        }
        await mutate.mutateAsync(formSchemaReport)
        invalidateSchemaForm.getFormSchemaReports(customerId, property.type, property.localityFrequencyIds, property.deviationId, property.mainComponentId)
        if (property.deviationId) {
            invalidateDeviation.getDeviationDocuments(customerId, property.deviationId)
        }
        if (localityId && (property.localityFrequencyId || property.groupId)) {
            invalidateLocalityFrequencyCache.getAllLocalityFrequencies(customerId as EntityId, localityId as EntityId)
        }
        pop()
    }

    const openFormSchema = (form) => {
        let schema = z.object({})
        const layouts: FormRow[] = JSON.parse(form.versions[form.versions.length - 1].layout)
        const initialValueObject = {}
        layouts.forEach(row => {
            row.columns?.forEach(col => {
                col?.fields.forEach(field => {
                    if (field.type != FormFieldType.title) {
                        if (field.defaultValue !== undefined && ((formInitialValues[field.defaultValue] == undefined) || (formInitialValues[field.defaultValue] == 'n/a'))) {
                            initialValueObject[field.name] = t('n/a', { ns: 'common' })
                            field.disabled = true
                        } else {
                            initialValueObject[field.name] = formInitialValues[field.defaultValue || '']
                            field.disabled = false
                        }
                    }
                    if (field.isRequired) {
                        schema = schema.merge(z.object({ [field.name]: z.string().min(1, { message: t('requiredField', { ns: 'forms' }).replace('{0}', field.name) }) }))
                    } else {
                        schema = schema.merge(z.object({ [field.name]: z.string().optional() }))
                    }
                })
            })
        })
        form.versions[form.versions.length - 1].layout = JSON.stringify(layouts)
        setInitialValues(initialValueObject)
        setSchema(schema)
        setSelectedSchema(form)
    }

    useEffect(() => {
        if (selectedSchemaForm && data) {
            const form = data.find(f => f.id == selectedSchemaForm.id)
            if (form) {
                openFormSchema(form)
            }
        }
    }, [selectedSchemaForm, data])

    useEffect(() => {
        if (schema && selectedSchema) {
            setViewForm(true)
        }
    }, [schema])

    return (<>
        {
            isLoading ?
                <Spinner />
                :
                <>
                    <DialogTitle >{t('form-schema')}</DialogTitle>
                    <DialogContent>
                        {
                            !viewForm ? ((data?.length && data?.length > 0) ? data?.map((form: IFormSchema) => {
                                return <Box key={form.id} className={classes.SchemaContainer} marginTop={1}
                                    onClick={() => openFormSchema(form)}>
                                    <Box flex={1}>
                                        <Typography>{form.name}</Typography>
                                    </Box>
                                    <Box flex={1}>
                                        <Typography>{form.description}</Typography>
                                    </Box>
                                    <Box display={'flex'} flexDirection={'row'} justifyContent={'flex-end'}>
                                        <ArrowRight />
                                    </Box>
                                </Box>
                            }) :
                                (
                                    <Box display={'flex'} flexDirection={'row'} justifyContent={'center'} >
                                        {t('noDataFound', { ns: 'common' })}
                                    </Box>
                                )
                            ) : selectedSchema && schema &&
                            <FormSchemaForm
                                schema={schema}
                                version={selectedSchema?.versions[selectedSchema?.versions.length - 1]}
                                title={selectedSchema?.name}
                                initialValues={initialValues}
                                pop={pop}
                                onSubmit={onSubmit}
                            />
                        }
                    </DialogContent>
                    {
                        !selectedSchema &&
                        <DialogActions>
                            <Button onClick={pop}>
                                {t('cancel', { ns: 'common' })}
                            </Button>
                        </DialogActions>
                    }
                </>
        }
    </>
    )
}

export default FormSchemasDialog