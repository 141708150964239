import React, { useEffect } from 'react'
import Form from '../../screens/customer/form/Form'
import { IFormSchemaVersion } from '../../generated-types/form-schema'
import { AppForm, FormSubmitButton } from '../FormCore'
import { ZodObject } from 'zod'
import { useZodForm } from '../../hooks/zod-form'
import { Box, DialogActions } from '@mui/material'
import { MainButton } from '../MainButton'
import { useTranslation } from 'react-i18next'

interface IFormSchemaForm {
    version: IFormSchemaVersion
    title: string
    schema: ZodObject<any>
    pop: () => void
    onSubmit: (values: any) => void
    initialValues: object
}

const FormSchemaForm: React.FC<IFormSchemaForm> = ({ version, title, schema, pop, onSubmit, initialValues }) => {
    const { t } = useTranslation(['common'])

    const form = useZodForm(schema, { defaultValues: initialValues })
    const onSubmitHandler = async formVal => {
        onSubmit(form.getValues())
    }

    useEffect(() => {
        form.reset()
    }, [schema])

    return (<AppForm form={form} onSubmit={onSubmitHandler} hasInitialValues={false}>
        <Box display={'flex'} flexDirection={'column'}>
            <Box>
                <Form
                    layouts={JSON.parse(version.layout)}
                    form={form}
                    disabled={undefined}
                    onDrop={undefined}
                    addLayout={undefined}
                    deleteLayout={undefined}
                    selectFormField={undefined}
                    title={title}
                    onRearrange={undefined}
                />
            </Box>
            <DialogActions>
                <Box width={130} display={'flex'} flexDirection={'row'}>
                    <Box marginLeft={1}>
                        <MainButton variant='outlined' onClick={pop}>{t('cancel')}</MainButton>
                    </Box>
                    <Box marginLeft={1}>
                        <FormSubmitButton createText={t('save')} />
                    </Box>
                </Box>
            </DialogActions>
        </Box>
    </AppForm>
    )
}

export default FormSchemaForm