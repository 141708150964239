import { makeStyles } from '@material-ui/core'
import { Box, IconButton, Stack, TextField, Typography } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MdClose, MdKeyboardReturn } from 'react-icons/md'

const useStyle = makeStyles(theme => ({
  optionsField: {
    backgroundColor: 'rgb(245, 245, 245)',
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  optionItemContainer: {
    paddingLeft: theme.spacing(2),
    backgroundColor: 'rgb(245, 245, 245)',
    '&:hover': {
      backgroundColor: '#fdfdfd',
      opacity: [0.9, 0.8, 0.7],
    },
    borderRadius: theme.spacing(1),
  },
  optionItem: {
    borderRadius: theme.spacing(1),
    boxShadow: theme.shadows[4],
  },
}))

export const DropdownOptionsField = ({ dropdownOptions, setDropdownOptions, isFirstTime }) => {
  const classes = useStyle()
  const [dropdownOptionText, setDropdownOptionText] = useState<string>('')
  const { t } = useTranslation(['custom-property'])

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      dropdownOptionText.trim().length != 0 && setDropdownOptions(prevOptions => [...prevOptions, dropdownOptionText.trim()])
      setDropdownOptionText('')
    }
  }

  const removeDropdownOption = (optionToRemove: string) => {
    const updatedOptions = dropdownOptions.filter(item => item !== optionToRemove)
    setDropdownOptions(updatedOptions)
  }

  return (
    <>
      <Box className={classes.optionsField}>
        <Stack spacing={2}>
          <Typography>{t('dropdownOptions')}</Typography>
          {dropdownOptions.length !== 0 && (
            <Stack className={classes.optionItem} direction={'column'} borderRadius={2}>
              {dropdownOptions.map(option => {
                return (
                  <>
                    <Stack
                      className={classes.optionItemContainer}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={2}
                    >
                      <Typography>{option}</Typography>
                      <IconButton onClick={() => removeDropdownOption(option)}>
                        <MdClose />
                      </IconButton>
                    </Stack>
                  </>
                )
              })}
            </Stack>
          )}
          <TextField
            fullWidth={true}
            id="standard-basic"
            label={t('addDropdownOption')}
            variant="standard"
            InputProps={{ endAdornment: <MdKeyboardReturn /> }}
            onChange={event => setDropdownOptionText(event.target.value)}
            onKeyDown={handleKeyDown}
            value={dropdownOptionText}
            error={!isFirstTime && dropdownOptions.length === 0}
            helperText={!isFirstTime && dropdownOptions.length === 0 ? t('dropdownOptionsAreEmpty') : ''}
          />
        </Stack>
      </Box>
    </>
  )
}
