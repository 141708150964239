import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { invalidateDeviation, useAddDeviation } from '../../../api/deviation'
import { invalidateMainComponentCache } from '../../../api/main-component'
import { invalidateMooringCache, useGetMooring } from '../../../api/moorings'
import { DeviationStepperForm } from '../../../forms/DeviationStepperForm/DeviationStepperForm'
import { IComponent, IPosition, IMooring } from '../../../generated-types'
import { IDeviationType } from '../../../generated-types/deviation-type'
import { IMainComponent } from '../../../generated-types/main-component'
import { ShowDialogProps } from '../../../show-dialog'
import { IDeviationFormWizardType } from '../../../generated-types/form-wizard'
import { invalidatePositionCache } from 'src/api/positions'
import { invalidateComponentsCache } from 'src/api/components'
import { toast } from 'react-toastify'
import { invalidationActivityLogCache } from 'src/api/activity-log'
import { APIResultCount } from 'src/constant'

export interface ICreateDeviationDialogProps extends ShowDialogProps {
  customerId: EntityId
  localityId: EntityId
  mooringId?: EntityId
  positionId?: EntityId
  component?: IComponent
  mainComponent?: IMainComponent
  position?: IPosition
  disableSteps?: any
  type: IDeviationType
  formWizard?: IDeviationFormWizardType
  scheduledTaskId?: EntityId
}

export const CreatePositionDeviationDialog: React.FC<ICreateDeviationDialogProps> = ({
  pop,
  customerId,
  localityId,
  mooringId,
  disableSteps = true,
  type,
  position,
  positionId,
  component,
  mainComponent,
  formWizard,
  scheduledTaskId,
}) => {
  const { t } = useTranslation(['locality', 'common'])
  const { data: mooring } = useGetMooring(customerId as EntityId, localityId as EntityId, mooringId as EntityId, {
    isIgnoreQueryFilters: sessionStorage.getItem('readModeEnabledLocalityId') == localityId,
  })

  const { mutateAsync, isLoading } = useAddDeviation(customerId)
  const onSubmit = useCallback(
    async form => {
      await mutateAsync(form, {
        onSuccess: async () => {
          invalidatePositionCache.getCagePositionDeviations(customerId, localityId, positionId)
          invalidateDeviation.invalidateAllDeviations(
            customerId as EntityId,
            localityId,
            mainComponent?.id ?? undefined,
          )
          invalidateMainComponentCache.getDeviationCounts(customerId as EntityId, mainComponent?.id as EntityId)
          invalidateMainComponentCache.getMainComponentById(customerId as EntityId, mainComponent?.id as EntityId)
          invalidateComponentsCache.getComponents(customerId as EntityId, positionId as EntityId)
          invalidateDeviation.invalidateAllDeviations(customerId as EntityId, localityId)
          invalidateMooringCache.getMooringsForMap(customerId as EntityId, localityId)
          invalidationActivityLogCache.getCageActivityLogs({
            page: 0,
            pageSize: APIResultCount.Max,
          })
          toast.success(t('added-deviation-successfully-toast', { ns: 'deviation' }))
          pop()
        },
      })
    },
    [customerId, localityId, mainComponent?.id, mutateAsync, pop, positionId, t],
  )

  return (
    <>
      {formWizard == IDeviationFormWizardType.Position ? (
        <DeviationStepperForm
          onSubmit={onSubmit}
          onCancel={() => pop()}
          customerId={customerId}
          localityId={localityId}
          mooring={mooring ?? ({ id: mooringId } as IMooring)}
          isDisableWizard={disableSteps}
          type={type}
          formWizard={formWizard}
          positionId={position?.id}
          position={position}
          title={t('addNewDeviation', { ns: 'deviation' })}
          isLoading={isLoading}
        />
      ) : (
        <DeviationStepperForm
          onSubmit={onSubmit}
          onCancel={() => pop()}
          customerId={customerId}
          localityId={localityId}
          mooring={mooring ?? ({ id: mooringId } as IMooring)}
          positionId={positionId}
          position={position}
          component={component}
          mainComponent={mainComponent}
          isDisableWizard={disableSteps}
          type={type}
          formWizard={formWizard}
          title={t('addNewDeviation', { ns: 'deviation' })}
          isLoading={isLoading}
          scheduledTaskId={scheduledTaskId}
        />
      )}
    </>
  )
}
