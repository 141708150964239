import { memo } from 'react'
import { Box, BoxProps, Typography, TypographyProps, styled } from '@mui/material'

interface StatusIndicatorProps extends Omit<BoxProps & TypographyProps, 'color'>{
  statusText: string
  fontColor: string
}

const StatusBox = styled(Box)(({ height, width, bgcolor, borderRadius, color }) => ({
  minHeight: `${height}`,
  width: `${width}`,
  backgroundColor: `${bgcolor}`,
  borderRadius: `${borderRadius}`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: `${color}`,
}))

export const StatusIndicator: React.FC<StatusIndicatorProps> = memo(({
  statusText,
  fontColor,
  variant = 'body2',
  fontWeight = '400',
  ...rest
}) => {
  return (
    <StatusBox color={fontColor} {...rest}>
      <Typography variant={variant} fontWeight={fontWeight}>
        {statusText}
      </Typography>
    </StatusBox>
  )
})
