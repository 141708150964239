import { useTranslation } from 'react-i18next'
import { Box, Grid, Modal, Stack, Typography } from '@mui/material'
import { makeStyles } from '@material-ui/core'
import { any, z } from 'zod'
import { useZodForm } from '../../hooks/zod-form'
import { AppForm, AppFormProps, FormSelectField, FormSubmitButton, FormTextField } from '../../components/FormCore'
import { LanguageSpecificTextField } from '../../components/LanguageSpecificTextField'
import { CancelButton } from '../../components/CancelButton'
import { MultipleSelectCheckmark } from '../../components/MultipleSelectCheckmark'
import React, { useEffect, useState } from 'react'
import { ICustomProperty } from '../../generated-types/customProperty'
import { ICustomPropertyMainComponentType } from '../../generated-types/custom-property-main-component-type'
import { ControllerTypes, IPrivilegeLevel, IMainComponentType as Type } from '../../enums'
import { IMainComponentType } from '../../generated-types/main-component-type'
import { IFormType } from '../../generated-types/form-type'
import i18n from 'i18next'
import { CreateCustomPropertyDialog } from '../admin/custom-type/CustomPropertyAddDialog'
import { CUSTOM_PROPERTY_LABELS } from 'src/constant'
import { checkAlphaNumerics } from 'src/utils/function'

const useStyle = makeStyles(_ => ({
  subTitle: {
    fontWeight: 'bold',
    fontSize: '1.2rem',
    color: '#00526B',
  },
  style: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: 'innerHeight',
    width: window.innerWidth * 0.5,
    backgroundColor: '#fff',
  },
}))
interface MainComponentTypeFormProps extends AppFormProps {
  componentTypeCustomProperties?: ICustomProperty[]
  initialValues?: IMainComponentType
  userCustomProperties: ICustomProperty[]
  formType?: IFormType
  privilegeLevel?: IPrivilegeLevel
  customerId?: EntityId
  isLoading?: boolean
}
export const MainComponentTypeForm: React.FC<MainComponentTypeFormProps> = ({
  onSubmit,
  onCancel,
  initialValues,
  userCustomProperties,
  componentTypeCustomProperties,
  formType = IFormType.Create,
  privilegeLevel = IPrivilegeLevel.Customer,
  customerId,
  isLoading,
}) => {
  const [selectedPropertyIndex, setSelectedPropertyIndex] = useState(0)
  const getTranslatedLabel = React.useCallback(
    (property: ICustomProperty) =>
      i18n.language == 'en' ? JSON.parse(property.label as string).en : JSON.parse(property.label as string).no,
    [i18n.language],
  )

  const { t: translate } = useTranslation(['main-component-type', 'common'])

  const componentType = Object.keys(Type)
    .filter(v => isNaN(Number(v)))
    .map(t => Type[t])

  const classes = useStyle()
  const schema = z.object({
    label_en: z
      .string()
      .min(1)
      .refine(value => {
        return checkAlphaNumerics(value)
      }),
    label_no: z
      .string()
      .min(1)
      .refine(value => {
        return checkAlphaNumerics(value)
      }),
    componentType: z.number().optional().default(Type.Custom),
    customerId: z
      .number()
      .or(z.null())
      .or(z.string())
      .transform(value => {
        if (typeof value === 'string') return parseInt(value)
        if (typeof value === 'number') return value
        return null
      })
      .optional(),
    maxCountForCage: z
      .string()
      .min(1)
      .or(z.number())
      .transform(value => (typeof value == 'string' ? parseInt(value) : value))
      .refine(n=>n>0),
    customPropertyMainComponentTypes: z.array(any()).optional(),
  })

  const form = useZodForm(schema, {
    defaultValues: initialValues,
  })

  const handleSubmit = async form => {
    const { label_en, label_no } = form
    form['name'] = JSON.stringify({ en: label_en, no: label_no })
    onSubmit(form)
  }

  const [customProperties, setCustomProperties] = React.useState<ICustomProperty[]>(
    componentTypeCustomProperties ?? ([] as ICustomProperty[]),
  )

  useEffect(() => {
    if (componentTypeCustomProperties) {
      const customPropertyMainComponentTypes: ICustomPropertyMainComponentType[] = componentTypeCustomProperties?.map(
        c => ({
          customPropertyId: c.id,
        }),
      )
      form.setValue('customPropertyMainComponentTypes', customPropertyMainComponentTypes)
      setCustomProperties(componentTypeCustomProperties as ICustomProperty[])
    }
  }, [componentTypeCustomProperties])

  useEffect(() => {
    const customPropertyMainComponentTypes: ICustomPropertyMainComponentType[] = customProperties?.map(c => ({
      customPropertyId: c.id,
    }))
    form.setValue('customPropertyMainComponentTypes', customPropertyMainComponentTypes)
  }, [customProperties])

  const [openAddCustomProperty, setOpenAddCustomProperty] = React.useState(false)

  const handleChange = (e, value) => {
    form.setValue('componentType', value)
    if (value === Type.Net) {
      const circumferenceCustomProperty = userCustomProperties?.find(
        c => JSON.parse(c.label.toString()).en === CUSTOM_PROPERTY_LABELS.CIRCUMFERENCE,
      )
      circumferenceCustomProperty && setCustomProperties([circumferenceCustomProperty] as ICustomProperty[])
    } else if (value === Type.Ring) {
      const pipeDiameterCustomProperty = userCustomProperties?.find(
        c => JSON.parse(c.label.toString()).en === CUSTOM_PROPERTY_LABELS.PIPE_DIAMETER,
      )
      pipeDiameterCustomProperty && setCustomProperties([pipeDiameterCustomProperty] as ICustomProperty[])
    } else if (value === Type.LiceSkirt) {
      const perimeterCustomProperty = userCustomProperties?.find(
        c => JSON.parse(c.label.toString()).en === CUSTOM_PROPERTY_LABELS.PERIMETER,
      )
      perimeterCustomProperty && setCustomProperties([perimeterCustomProperty] as ICustomProperty[])
    } else {
      setCustomProperties([]) // when selected value is null
    }
  }

  return (
    <>
      <Modal
        open={openAddCustomProperty}
        onClose={() => {
          setOpenAddCustomProperty(false)
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={classes.style} sx={{ borderRadius: 1 }}>
          <CreateCustomPropertyDialog
            customerId={customerId as EntityId}
            pop={() => {
              setOpenAddCustomProperty(false)
            }}
            isAdmin={privilegeLevel == IPrivilegeLevel.SuperAdmin}
          />
        </Box>
      </Modal>

      <AppForm form={form} onSubmit={handleSubmit}>
        <Box py={1}>
          {<FormTextField name="customerId" type="hidden" />}
          <LanguageSpecificTextField
            data={initialValues?.name || '{}'}
            label={translate('label', { ns: 'common' })}
            form={form}
          />
        </Box>
        <Box py={1}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormTextField
                type="number"
                inputProps={{min:1}}
                name="maxCountForCage"
                label={translate('maxCount', { ns: 'main-component-type' })}
              />
            </Grid>
            <Grid item xs={6}>
              <FormSelectField
                hidden={privilegeLevel != IPrivilegeLevel.SuperAdmin}
                data={componentType!}
                name="componentType"
                label={translate('componentType', { ns: 'main-component-type' })}
                getOptionLabel={option => Type[option]}
                renderOption={(props: any, option) => (
                  <Box {...props} key={option}>
                    {translate(Type[option])}
                  </Box>
                )}
                isOptionEqualToValue={(o, v) => o === v}
                defaultValue={Type.Custom}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </Box>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} width={'100%'}>
          <Typography className={classes.subTitle}>
            {translate('mainComponentForm', { ns: 'main-component-type' })}
          </Typography>
          {userCustomProperties && (
            <MultipleSelectCheckmark
              customProperties={customProperties}
              setCustomProperties={setCustomProperties}
              data={userCustomProperties as ICustomProperty[]}
              setOpen={setOpenAddCustomProperty}
            />
          )}
        </Stack>
        <Box sx={{ border: 1, padding: 2 }} py={1} minHeight={500}>
          {customProperties?.map((customProperty, index) => (
            <>
              {selectedPropertyIndex != 0 && (
                <Box
                  height={15}
                  border={'3px dotted lightGray'}
                  onDrop={evt => {
                    evt.preventDefault()
                    setCustomProperties(value => {
                      const selectedProperty = value[selectedPropertyIndex]
                      value.splice(selectedPropertyIndex, 1)
                      value.splice(index, 0, selectedProperty)
                      return value
                    })
                    setSelectedPropertyIndex(0)
                  }}
                  onDragOver={event => {
                    event.preventDefault()
                  }}
                  onDragEnter={event => {
                    event.preventDefault()
                  }}
                ></Box>
              )}
              <Box
                draggable
                onDrag={() => {
                  setSelectedPropertyIndex(index)
                }}
                onDragEnd={() => {
                  setSelectedPropertyIndex(0)
                }}
                key={index}
                py={1}
              >
                {(customProperty.controllerType === ControllerTypes.TextBox ||
                  customProperty.controllerType === ControllerTypes.NumberTextBox) && (
                  <FormTextField name={getTranslatedLabel(customProperty)} label={getTranslatedLabel(customProperty)} />
                )}
                {customProperty.controllerType === ControllerTypes.Dropdown && (
                  <FormSelectField
                    data={JSON.parse(customProperty.dropdownOptions)}
                    name={getTranslatedLabel(customProperty)}
                    label={getTranslatedLabel(customProperty)}
                  />
                )}
              </Box>
            </>
          ))}
        </Box>

        <Box className="flex gap-4 pt-4 justify-end">
          {formType == IFormType.Create ? (
            <FormSubmitButton createText={translate('add', { ns: 'common' })} isLoading={isLoading} />
          ) : (
            <FormSubmitButton createText={translate('update', { ns: 'common' })} />
          )}
          <CancelButton onClick={onCancel}>{translate('cancel', { ns: 'common' })}</CancelButton>
        </Box>
      </AppForm>
    </>
  )
}