import { useTranslation } from 'react-i18next'
import { AlertDialogContent } from '../../../../show-dialog'
import { invalidateTaskCache, useDeleteTask } from '../../../../api/tasks'
import { useCustomerCtx, useDialogCtx } from '../../../../hooks/context-hooks'
import { IconButton } from '@mui/material'
import { MdDelete } from 'react-icons/md'
import { toast } from 'react-toastify'

const TaskDeleteButton = ({ task }) => {
  const { t } = useTranslation('task')
  const { customer } = useCustomerCtx()
  const { mutateAsync } = useDeleteTask(task?.localityId)
  const { showDialog } = useDialogCtx()

  const onDelete = id => {
    showDialog(AlertDialogContent, {
      componentProps: {
        onConfirm: async () => {
          await mutateAsync(id,{
            onSuccess: () => {
              invalidateTaskCache.getCustomerAdminTaskByFilter(customer?.id)
              toast.success(t('taskDeletedSuccessfully', { ns: 'task' }))
            },
            onError: () => {
              toast.error(t('updatingError', { ns: 'common' }))
            },
          })
        },
        text: t('delete-task-description'),
        acceptText: t('yes', { ns: 'common' }),
        cancelText: t('no', { ns: 'common' }),
      },
      opts: {
        maxWidth: 'sm',
        fullWidth: true,
      },
      title: t('delete-task'),
    })
  }

  return (
    <IconButton onClick={() => onDelete(task.id)}>
      <MdDelete size={24} />
    </IconButton>
  )
}

export default TaskDeleteButton
