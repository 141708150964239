import { Box, Collapse, Grid } from '@mui/material'
import { ICustomer } from '../../../generated-types'
import { AppRoutesNavigation } from '../../../app-routes'
import { useNavigate } from 'react-router-dom'
import { IServiceStation } from '../../../generated-types/service-station'
import ServiceStationCard from './ServiceStationCard'
import { useGetServiceStations } from '../../../api/service-station'
import { CustomerHeader } from 'src/components/sidemenu/CustomerHeader'


const AllServiceStations: React.FC<{ customers: ICustomer[] | undefined, open: number, setOpen: (value: any) => void }> = ({ customers, open, setOpen }) => {
    const { data } = useGetServiceStations(open)
    const navigate = useNavigate()
    return (
        <Box >
            {customers?.map((customer: ICustomer, index: number) => {
                return <Box key={index}>
                    <Box padding={0.5}>
                        <CustomerHeader hasAdminAccess={customer.isAdminCustomer} hasActiveColor={false} {...customer} onClick={() => { setOpen(value => value == customer.id ? 0 : customer.id) }} active={open == customer.id} />
                    </Box>
                    <Collapse in={open == customer.id} >
                        <Grid container className='overflow-y-auto ' maxHeight={'82vh'}>
                            {
                                customer.serviceStations?.map((serviceStation: IServiceStation, index: number) => {
                                    return <Grid item md={4} key={index} spacing={2} className='w-full '>
                                        <ServiceStationCard serviceStation={serviceStation}
                                            onClick={() => { navigate(AppRoutesNavigation.serviceStation.serviceStation(serviceStation.customerId, serviceStation.id)) }} />
                                    </Grid>
                                })
                            }
                        </Grid>
                    </Collapse>
                </Box>
            })}
        </Box>
    )
}

export default AllServiceStations