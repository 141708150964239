import React from 'react'
import { FormField } from '../../generated-types/form-field'
import { FormFieldType } from '../../enums'
import { Box, Typography } from '@mui/material'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
interface IFieldSelector {
    field: FormField
    value?: string
}

const useStyle = makeStyles(() => ({
    valueContainer: {
        minHeight: 40,
        paddingTop: 5,
        border: '1px solid lightBlue'
    },
    valueText: {
        marginLeft: 10
    },
    labelContainer: {
        height: 30
    },
    uploadedFiles: {
        color: 'darkblue',
        marginLeft: 5,
        fontStyle: 'italic',
        borderBottom: '1px solid darkblue'
    }
}))

const FieldSelectorViewer: React.FC<IFieldSelector> = ({ field, value }) => {
    const classes = useStyle()
    const { t } = useTranslation(['common', 'overall-reports'])
    const selectField = () => {
        switch (field.type) {
            case (FormFieldType.textField):
                return <Box>
                    <div className={classes.labelContainer}>
                        <Typography variant='subtitle2'>{field.name}</Typography>
                    </div>
                    <div className={classes.valueContainer} >
                        <Typography className={classes.valueText} >{value}</Typography>
                    </div>
                </Box>
            case (FormFieldType.numberField):
                return <Box>
                    <div className={classes.labelContainer} >
                        <Typography variant='subtitle2'>{field.name}</Typography>
                    </div>
                    <div className={classes.valueContainer} >
                        <Typography className={classes.valueText}>{value}</Typography>
                    </div>
                </Box>
            case (FormFieldType.datePicker):
                return <Box>
                    <div className={classes.labelContainer} >
                        <Typography variant='subtitle2'>{field.name}</Typography>
                    </div>
                    <div className={classes.valueContainer} >
                        <Typography className={classes.valueText}>{value && new Date(value).toLocaleDateString()}</Typography>
                    </div>
                </Box>
            case (FormFieldType.textArea):
                return <Box>
                    <div className={classes.labelContainer} >
                        <Typography variant='subtitle2'>{field.name}</Typography>
                    </div>
                    <div className={classes.valueContainer} >
                        <Typography className={classes.valueText}>{value}</Typography>
                    </div>
                </Box>
            case (FormFieldType.checkBox):
                return <Box>
                    <div className={classes.labelContainer} >
                        <Typography variant='subtitle2'>{field.name}</Typography>
                    </div>
                    <div className={classes.valueContainer} >
                        <Typography className={classes.valueText}>{value ? t('yes') : t('no')}</Typography>
                    </div>
                </Box>
            case (FormFieldType.dateTime):
                return <Box>
                    <div className={classes.labelContainer} >
                        <Typography variant='subtitle2'>{field.name}</Typography>
                    </div>
                    <div className={classes.valueContainer} >
                        <Typography className={classes.valueText}>{value && new Date(value).toLocaleString()}</Typography>
                    </div>
                </Box>
            case (FormFieldType.fileUpload):
                return <Box>
                    <div className={classes.labelContainer} >
                        <Typography variant='subtitle2'>{field.name}</Typography>
                    </div>
                    <div className={classes.valueContainer} >
                        {
                            value &&
                            <a rel="noreferrer" className={classes.uploadedFiles} target='_blank' href={value}>{t('uploaded-file-link', { ns: 'overall-reports' })}</a>
                        }
                    </div>
                </Box>
            case (FormFieldType.timePicker):
                return <Box>
                    <div className={classes.labelContainer} >
                        <Typography variant='subtitle2'>{field.name}</Typography>
                    </div>
                    <div className={classes.valueContainer} >
                        <Typography className={classes.valueText}>{value}</Typography>
                    </div>
                </Box>
            case (FormFieldType.checkBoxList):
                return <Box>
                    <div className={classes.labelContainer} >
                        <Typography variant='subtitle2'>{field.name}</Typography>
                    </div>
                    <div className={classes.valueContainer} >
                        {
                            JSON.parse(value || '[]')?.map((singleValue, index) => {
                                return <Typography key={index} className={classes.valueText}>{singleValue}</Typography>
                            })
                        }
                    </div>
                </Box>
            case (FormFieldType.radio):
                return <Box>
                    <div className={classes.labelContainer} >
                        <Typography variant='subtitle2'>{field.name}</Typography>
                    </div>
                    <div className={classes.valueContainer} >
                        <Typography className={classes.valueText}>{value}</Typography>
                    </div>
                </Box>
            case (FormFieldType.selectField):
                return <Box>
                    <div className={classes.labelContainer} >
                        <Typography variant='subtitle2'>{field.name}</Typography>
                    </div>
                    <div className={classes.valueContainer} >
                        <Typography className={classes.valueText}>{value}</Typography>
                    </div>
                </Box>
            case (FormFieldType.title):
                return <Typography variant='h6'>{field.name}</Typography>
            default:
                return null
        }
    }
    return <Box padding={1}>
        {selectField()}
    </Box>

}

export default FieldSelectorViewer