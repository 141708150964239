import { Box, Collapse, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSidebarCtx, useIdentityServerUserCtx } from '../../hooks/context-hooks'
import { To, useNavigate, useParams } from 'react-router-dom'
import { AppRoutesNavigation } from '../../app-routes'
import { CenteredSpinner } from '../../components/Spinner'
import { ICustomer, ILocality } from '../../generated-types'
import { useGetAllWithInfoCount } from '../../api/localities'
import { useGetCustomersNameWithPermissions } from '../../api/customer'
import { Profile } from 'oidc-client'
import { t } from 'i18next'
import {
  AnchorOutlined,
  CheckCircleOutline,
  Checklist,
  FolderOutlined,
  GridView,
  HomeRepairServiceOutlined,
  ListOutlined,
  SvgIconComponent,
  WarningAmberOutlined,
} from '@mui/icons-material'
import { CustomerHeader } from '../../components/sidemenu/CustomerHeader'
import { CollapsedSideMenu } from '../../components/sidemenu/CollapsedSideMenu'
import { IconType } from 'react-icons'
import { ExpandedSideMenu } from '../../components/sidemenu/ExpandedSideMenu'
import { sumTwoNumbers } from 'src/utils/function'
import { useNavigation } from 'src/context/NavigationContext'

export interface IMenuItem {
  title: string
  Icon: SvgIconComponent | IconType
  isMinified: boolean
  onClick?: (e) => void
  activePath?: string
  disabled?: boolean
  className?: string
  path?: string
  count?: number
  countBgColor?: string
  children?: IMenuItem[]
}

export const LocalitiesSidebar = () => {
  return <Sidebar />
}

export const Sidebar = () => {
  const { userProfile } = useIdentityServerUserCtx()
  const { data, isLoading } = useGetCustomersNameWithPermissions()
  const navigate = useNavigate()
  const { navigateTo } = useNavigation()
  const [path, setPath] = useState<string>('')
  const { collapsibleSidebarOpen, setCollapsibleSidebarOpen } = useSidebarCtx()
  const { customerId, localityId } = useParams()
  const theme = useTheme()
  const { t } = useTranslation(['common', 'sidebar'])

  const getOnClick = (e: { stopPropagation: () => void }, navigationPath: To) => {
    e.stopPropagation()
    navigateTo(navigationPath as string)
  }

  function GetMenuItems(location?: ILocality) {
    const menuItems: IMenuItem[] = [
      {
        title: t('dashboard', { ns: 'sidebar' }),
        Icon: GridView,
        isMinified: true,
        onClick: e =>
          getOnClick(e, AppRoutesNavigation.Localities.locality(customerId as EntityId, localityId as EntityId)),
      },
      {
        title: t('documents', { ns: 'sidebar' }),
        countBgColor: theme.palette.primaryDark[theme.palette.mode],
        count: sumTwoNumbers(location?.expiredDocumentsCount, location?.expiredDocumentsIn3MonthsCount),
        path: 'documents',
        Icon: FolderOutlined,
        isMinified: true,
        onClick: e =>
          getOnClick(e, AppRoutesNavigation.Localities.documents(customerId as EntityId, localityId as EntityId)),
      },
      {
        title: t('deviation', { ns: 'sidebar' }),
        countBgColor: theme.palette.error[theme.palette.mode],
        count: location?.deviationCount ?? 0,
        path: 'deviations',
        Icon: WarningAmberOutlined,
        isMinified: true,
        onClick: e =>
          getOnClick(e, AppRoutesNavigation.Localities.deviations(customerId as EntityId, localityId as EntityId)),
      },
      {
        title: t('moorings', { ns: 'sidebar' }),
        path: 'moorings',
        Icon: AnchorOutlined,
        isMinified: true,
        onClick: e =>
          getOnClick(e, AppRoutesNavigation.Localities.moorings(customerId as EntityId, localityId as EntityId))
      },
      {
        title: t('equipments', { ns: 'sidebar' }),
        path: 'equipments',
        Icon: HomeRepairServiceOutlined,
        isMinified: true,
        onClick: e =>
          getOnClick(e, AppRoutesNavigation.Localities.equipments(customerId as EntityId, localityId as EntityId)),
      },
      {
        title: t('tasks', { ns: 'sidebar' }),
        path: 'tasks',
        Icon: Checklist,
        isMinified: true,
        onClick: e =>
          getOnClick(e, AppRoutesNavigation.Localities.tasks(customerId as EntityId, localityId as EntityId)),
      },
      {
        title: t('services', { ns: 'sidebar' }),
        countBgColor: theme.palette.primaryDark[theme.palette.mode],
        count: location?.servicesCount ?? 0,
        path: 'locality-services',
        Icon: CheckCircleOutline,
        isMinified: true,
        onClick: e =>
          getOnClick(
            e,
            AppRoutesNavigation.Localities.localityServices(customerId as EntityId, localityId as EntityId),
          ),
      },
      {
        title: t('workAssignments', { ns: 'sidebar' }),
        path: 'work-assignments',
        Icon: ListOutlined,
        isMinified: true,
        onClick: e =>
          getOnClick(e, AppRoutesNavigation.Localities.workAssignments(customerId as EntityId, localityId as EntityId)),
      },
    ]

    return menuItems
  }

  useEffect(() => {
    if (path && path != '') {
      window.location.pathname != '/localities' && window.location.pathname != '/localities/*' ? null : navigate(path)
    }
  }, [path])

  if (isLoading) return <CenteredSpinner />
  return (
    <>
      <Box sx={{ width: '84px' }} className={!collapsibleSidebarOpen ? 'block h-full' : 'hidden'}>
        <CollapsedSideMenu
          menuItems={GetMenuItems()}
          customer={data?.find(value => value.id == (customerId as EntityId))}
          setPath={setPath}
          setToggleSidebar={setCollapsibleSidebarOpen}
          collapsibleSidebarOpen={!collapsibleSidebarOpen}
        />
      </Box>
      <Box
        height={'calc(100vh - 100px)'}
        className={collapsibleSidebarOpen ? 'block overflow-x-hidden overflow-y-auto' : 'hidden h-0'}
      >
        {data?.map(customer => (
          <React.Fragment key={customer.id}>
            <CustomersContainer userProfile={userProfile} menuItems={GetMenuItems()} getMenuItems={GetMenuItems} customer={customer} setPath={setPath} />
          </React.Fragment>
        ))}
      </Box>
    </>
  )
}

const CustomersContainer: React.FC<{
  userProfile: Profile
  customer: ICustomer
  menuItems: IMenuItem[]
  getMenuItems: (location: ILocality) => IMenuItem[]
  setPath: React.Dispatch<React.SetStateAction<string>>
}> = ({ setPath, customer, getMenuItems }) => {
  const { customerId } = useParams()
  const { localityId } = useParams()
  const [open, setOpen] = useState(customer.id == (customerId as EntityId))
  const { data, isLoading } = useGetAllWithInfoCount(customer.id)

  useEffect(() => {
    if (data?.length ?? 0 > 0) {
      setPath(prev =>
        prev == '' ? AppRoutesNavigation.Localities.locality(customer.id, data?.[0].id as EntityId) : prev,
      )
    }
  }, [data])

  useEffect(() => {
    setOpen(customer.id == (customerId as EntityId))
  }, [customerId])

  const selectedLocality = data?.find(value => value.id == (localityId as EntityId))

  if (data && selectedLocality) {
    const index = data.indexOf(selectedLocality)
    data.splice(index, 1)
    data.sort((a, b) => {
      if (a.name < b.name) return -1
      if (a.name > b.name) return 1
      return 0
    })
    data.unshift(selectedLocality)
  }

  return (
    <Box key={customer.id}>
      <Box sx={{ padding: '8px' }} onClick={() => setOpen(!open)}>
        <CustomerHeader hasAdminAccess={customer.isAdminCustomer} hasActiveColor={true} {...customer} active={open} />
      </Box>
      <Box>
        {isLoading && open ?
          <CenteredSpinner />
          :
          <Collapse in={open}>
            <>
              {data?.length ? (
                data.map(location => (
                  <Box key={location.id} className="pt-0 pb-0 pr-0">
                    <ExpandedSideMenu customerId={customer.id} location={location} menuItems={getMenuItems(location)} />
                  </Box>
                ))
              ) : (
                <Box className="font-semibold text-center text-gray-600 text-l">
                  {t('thisCustomerHasNoLocalities', { ns: 'sidebar' })}
                </Box>
              )}
            </>
          </Collapse>
        }
      </Box>
    </Box>
  )
}
