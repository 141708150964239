import { Box, DialogContent, DialogTitle } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { invalidateSensorTypeCache, useAddSensorType } from '../../../api/sensortype'
import { MainButton } from '../../../components/MainButton'
import { ScreenTitle } from '../../../components/ScreenTitle'
import { showDialog, ShowDialogProps } from '../../../show-dialog'
import { SensorTypeForm } from './SensorTypeForm'
import { SensorTypeTable } from './SensorTypeTable'

const CreateSensorTypeDialog: React.FC<ShowDialogProps> = ({ pop }) => {
  const { t: translate } = useTranslation(['sensortype', 'common'])
  const mutation = useAddSensorType()

  const onSubmit = async form => {
    await mutation.mutateAsync(form)

    invalidateSensorTypeCache.useGetSensorTypes()
    pop(true)
  }

  return (
    <Box>
      <DialogTitle
        sx={{
          fontWeight: 'bold',
          fontSize: '1.8rem',
        }}
        className="text-primary"
      >
        {translate('addSensorType', { ns: 'sensor-type' })}
      </DialogTitle>
      <DialogContent>
        <SensorTypeForm onSubmit={onSubmit} onCancel={() => pop()} />
      </DialogContent>
    </Box>
  )
}

export const SensorTypeContainer = () => {
  const { t: translate } = useTranslation(['sensortype', 'common'])

  const onAddSensorType = async () => {
    const added = await showDialog(CreateSensorTypeDialog)
    if (!added) return
  }

  return (
    <>
      <Box className="flex justify-between mb-12">
        <ScreenTitle title={translate('sensorTypes', { ns: 'sensor-type' })} />
        <MainButton onClick={() => onAddSensorType()}>{translate('add', { ns: 'common' })}</MainButton>
      </Box>
      <SensorTypeTable />
    </>
  )
}
