import React, { FC, createContext, useCallback, useState } from 'react'
import { DialogContextType } from 'src/enums'
import { AlertDialog, AlertDialogCore, DialogContainer, ShowDialogParams, ShowDialogProps } from 'src/show-dialog'
import { generateUUID } from 'src/utils/function'
import { WarningDialog } from 'src/components/WarningDialog'
import { ShowFileDialog } from 'src/dialogs/ShowFileDialog'
export interface UIProps {
  Icon: React.ReactElement
  iconBGColor: string
  buttonTextColor: string
  buttonBGColor: string
}

export interface DialogContextProps {
  showDialog: (Content: any, options: any, dialogContextType?: DialogContextType, isWithLayout?: boolean) => any
  dialogsCustom: any
  showConfirmDialog: (
    title: string,
    text?: string,
    buttonTexts?: { acceptText?: string | undefined; cancelText?: string | undefined },
    onConfirm?: (callback: any) => void,
    UIProps?: UIProps
  ) => any
  showWarningDialog: (
    title: string,
    text?: string,
    buttonTexts?: { acceptText?: string | undefined },
    onConfirm?: (callback: any) => void,
  ) => any
  openFilePreviewDialog: (fileUrl: string) => any
}

interface DialogContextParams<T> extends ShowDialogParams<T> {
  title?: string
}

export const DialogContextCtx = createContext<DialogContextProps>(null!)

export const DialogContextProvider = ({ children }) => {
  const [dialogs, setDialogs] = useState({})
  const [dialogsCustom, setDialogCustom] = useState({})

  const closeDialog = useCallback(
    (data, id, pop, contextType = null) => {
      if (pop) {
        pop(data)
      }
      if (contextType) {
        setDialogCustom(dialogsCustomList => {
          const dialogList = dialogsCustomList
          if (dialogList[contextType]) {
            delete dialogList[contextType][id]
            return { ...dialogList }
          }
          return dialogList
        })
      } else {
        const dialogList = dialogs
        delete dialogList[id]
        setDialogs(dialogList)
      }
    },
    [dialogs],
  )

  function showDialog<T = any, K = any>(
    Content: FC<K & ShowDialogProps<T>>,
    options: DialogContextParams<K> = {},
    contextType?: DialogContextType,
    isWithLayout?: boolean,
  ) {
    const { opts, componentProps, pop, isAutoCloseDisabled, title } = options
    if (contextType) {
      const idVal = `custom-${generateUUID()}`
      setDialogCustom(d => ({
        ...d,
        ...{
          [contextType]: {
            [idVal]: (
              <DialogContainer
                pop={data => closeDialog(data, idVal, pop, contextType)}
                content={Content}
                key={idVal}
                opts={opts}
                contentProps={componentProps as any}
                isAutoCloseDisabled={isAutoCloseDisabled}
                title={title}
                isWithLayout={isWithLayout}
              />
            ),
          },
        },
      }))
    } else {
      const id = `locality-${generateUUID()}`
      setDialogs(prevDialog => {
        return {
          ...prevDialog,
          ...{
            [id]: (
              <DialogContainer
                pop={data => closeDialog(data, id, pop)}
                content={Content}
                opts={opts}
                contentProps={componentProps as any}
                isAutoCloseDisabled={isAutoCloseDisabled}
                title={title}
                isWithLayout={isWithLayout}
              />
            ),
          },
        }
      })
    }
  }

  function showConfirmDialog(
    title: string,
    text?: string,
    {
      acceptText = undefined,
      cancelText = undefined,
    }: { acceptText?: string | undefined; cancelText?: string | undefined } = {},
    onConfirm?: (callback: any) => void,
    UIProps?: UIProps
  ) {
    if (UIProps) {
      showDialog(AlertDialogCore, {
        componentProps: { title: title, acceptText, cancelText, text: text, onConfirm: onConfirm, UIProperties: UIProps },
        opts: { maxWidth: 'xs', fullWidth: true },
      })
    } else {
      showDialog(AlertDialog, {
        componentProps: { title: title, acceptText, cancelText, text: text, onConfirm: onConfirm },
      })
    }
  }

  function showWarningDialog(
    title: string,
    description?: string,
    { acceptText = undefined }: { acceptText?: string | undefined } = {},
    onConfirm?: (callback: any) => void,
  ) {
    showDialog(
      WarningDialog,
      {
        componentProps: { title: title, description: description, acceptText, onConfirm: onConfirm } as any,
      },
      undefined,
      true,
    )
  }

  async function openFilePreviewDialog(fileUrl: string) {
    await showDialog(
      ShowFileDialog,
      {
        opts: {
          maxWidth: '80vw',
          fullWidth: true,
        } as any,
        componentProps: {
          fileUrl: fileUrl,
        },
      },
      undefined,
      true,
    )
  }

  const contextValue: DialogContextProps = {
    showDialog,
    showConfirmDialog,
    showWarningDialog,
    openFilePreviewDialog,
    dialogsCustom: { ...dialogsCustom },
  }

  return (
    <DialogContextCtx.Provider value={contextValue}>
      {Object.values(dialogs)}
      {children}
    </DialogContextCtx.Provider>
  )
}
