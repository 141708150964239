import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { invalidateServiceCache, useAddService } from '../../../api/service'
import { MainButton } from '../../../components/MainButton'
import { ScreenTitle } from '../../../components/ScreenTitle'
import { FormDialog } from '../../../forms/FormDialog'
import { showDialog, ShowDialogProps } from '../../../show-dialog'
import { ServiceForm } from './ServiceForm'
import { ServiceTable } from './ServiceTable'
import { toast } from 'react-toastify'

interface CreateServiceProps extends ShowDialogProps {
  customerId: EntityId
}

const CreateServiceDialog: React.FC<CreateServiceProps> = ({ pop, customerId }) => {
  const { t } = useTranslation(['supplier', 'common'])

  const { mutateAsync, isLoading } = useAddService(customerId as EntityId)
  const onSubmit = async form => {
    await mutateAsync(form,{
      onSuccess: () => {
        invalidateServiceCache.getServices(customerId as EntityId)
        toast.success(t('serviceAddedSuccessfully', { ns: 'service' }))
        pop()
        
      },
      onError: (_) => {
        toast.error(t('addingError', { ns: 'common' }))
      },
    })
  }

  return (
    <FormDialog
      title={t('addservice', { ns: 'service' })}
      isLoading={isLoading}
      form={<ServiceForm onSubmit={onSubmit} onCancel={() => pop()} />}
    />
  )
}

export const ServicesContainer = () => {
  const { t: translate } = useTranslation(['service', 'common'])
  const { id: customerId } = useParams()

  const onAddSupplier = async () => {
    const added = await showDialog(CreateServiceDialog, {
      componentProps: {
        customerId: customerId as EntityId,
      },
    })
    if (!added) return
  }

  return (
    <>
    <Box className="px-4 py-2 mt-2 mb-2 bg-white shadow-md">
      <Box className="flex justify-between mb-5">
        <ScreenTitle title={translate('services', { ns: 'service' })} />
        <MainButton onClick={() => onAddSupplier()} data-cy='addService'>{translate('add', { ns: 'common' })}</MainButton>
      </Box>
      <ServiceTable customerId={customerId as EntityId} />

    </Box>
    </>
  )
}
