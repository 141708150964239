import { Checkbox, Stack, Typography, styled } from '@mui/material'
import { IMooringType, IPositionType } from '../../../generated-types'
import { useTranslation } from 'react-i18next'

const CheckboxTypography = styled(Typography)`
  color: ${props => props.theme.palette.primaryDark[props.theme.palette.mode]};
  font-variant: ${props => props.theme.typography.h6};
  font-weight: 400;
  padding-right: 8px;
  margin-left: -5px;
  cursor: pointer;
`
interface PositionTableFilterProps {
  filteredTypes: IPositionType[]
  mooringType?: IMooringType
  setFilteredTypes: React.Dispatch<React.SetStateAction<IPositionType[]>>
}

export const PositionTableFilter: React.FC<PositionTableFilterProps> = ({
  setFilteredTypes,
  filteredTypes,
  mooringType,
}) => {
  const { t } = useTranslation(['position', 'common'])
  const positionTypesArray: IPositionType[] = [
    IPositionType.MooringLine,
    mooringType != IMooringType.Barge ? IPositionType.Corner : IPositionType.BargeCorner,
    IPositionType.Bridle,
    IPositionType.FrameLines,
    IPositionType.Cage,
  ]

  const handleCheckboxChange = type => {
    if (filteredTypes.includes(type)) {
      setFilteredTypes(filteredTypes.filter(t => t !== type))
    } else {
      setFilteredTypes([...filteredTypes, type])
    }
  }

  return (
    <Stack direction="row" justifyContent="center" alignItems="center">
      {positionTypesArray?.map(type => (
        <Stack key={type} direction="row" justifyContent="flex-start" alignItems="center">
          <Checkbox checked={filteredTypes.includes(type)} onChange={() => handleCheckboxChange(type)} />
          <CheckboxTypography onClick={() => handleCheckboxChange(type)}>
            {t(IPositionType[type], { ns: 'position' })}
          </CheckboxTypography>
        </Stack>
      ))}
    </Stack>
  )
}
