import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { CenteredSpinner } from '../../../components/Spinner'
import { ShowDialogProps } from '../../../show-dialog'
import { useGetComponents, useAddComponent, invalidateComponentsCache } from '../../../api/components'
import { ComponentForm } from './ComponentForm'
import { useDialogCtx } from 'src/hooks/context-hooks'
import { invalidatePositionCache } from 'src/api/positions'
import { permissionValue } from '../../../enums/permissionValue'
import { CommonPageLayout } from 'src/components/common/CommonPageLayout'
import { AddCircleOutline } from '@mui/icons-material'
import { IComponent } from 'src/generated-types'
import { useEffect, useState } from 'react'
import { ComponentsTable } from '../moorings/Components/ComponentsTable'
import { UpdateComponentDialog } from './UpdateComponentDialog'
import { Card } from '@mui/material'

interface CreateComponentProps extends ShowDialogProps {
  customerId: EntityId
  localityId: EntityId
  mooringId: EntityId
  positionId: EntityId
}

const CreateComponentDialog: React.FC<CreateComponentProps> = ({
  pop,
  customerId,
  localityId,
  mooringId,
  positionId,
}) => {
  const { t } = useTranslation(['component', 'common'])
  const mutation = useAddComponent(customerId, positionId as EntityId)
  const onSubmit = async form => {
    form.positionId = positionId
    if (form.overriddenLifetime == '') {
      form.overriddenLifetime = 0
    }
    if (form.minimumBreakingLoad == '') {
      form.minimumBreakingLoad = 0
    }

    await mutation.mutateAsync(form, {
      onSuccess: () => {
        invalidateComponentsCache.getComponents(customerId, positionId as EntityId)
        invalidatePositionCache.getPositions(customerId, localityId, mooringId as EntityId)
        pop()
      },
    })
  }

  return (
    <ComponentForm
      title={t('addComponent', { ns: 'component' })}
      onSubmit={onSubmit}
      onCancel={() => pop()}
      customerId={customerId}
      positionId={positionId}
      isLoading={mutation.isLoading}
    />
  )
}

interface IComponentContainer {
  onSelectDeviation?: (componentId: number) => void
}

export const ComponentsContainer = ({ onSelectDeviation = undefined }: IComponentContainer) => {
  const { t } = useTranslation(['component', 'common'])
  const { customerId, localityId, id, mooringId, positionId } = useParams()
  const { showDialog } = useDialogCtx()

  const [componentsList, setComponentsList] = useState([] as IComponent[])
  const { data, isLoading } = useGetComponents(customerId as EntityId, positionId as EntityId, 0, 10000)

  useEffect(() => {
    setComponentsList((data?.items as IComponent[]) ?? [])
  }, [data])

  if (isLoading) return <CenteredSpinner />

  const onAdd = async () => {
    const added = await showDialog(CreateComponentDialog, {
      componentProps: {
        customerId: customerId as EntityId,
        localityId: localityId as EntityId,
        mooringId: mooringId as EntityId,
        positionId: positionId as EntityId,
        id: id as EntityId,
      },
      opts: {
        maxWidth: 'md',
        fullWidth: true,
      },
    })
    if (!added) return
  }

  const onEdit = (id: EntityId) => {
    showDialog(UpdateComponentDialog, {
      componentProps: {
        customerId: customerId as EntityId,
        localityId: localityId as EntityId,
        mooringId: mooringId as EntityId, positionId:
          positionId as EntityId, id
      },
    })
  }

  //This will implemented in future for component details view
  // const onComponentRowClick = (component: IComponent) => {
  //   showDialog(ComponentDetailRoutes, {
  //     componentProps: {
  //       customerId: customerId as EntityId,
  //       localityId: localityId as EntityId,
  //       component: component,
  //     },
  //     opts: {
  //       maxWidth: '80vw',
  //       fullWidth: true,
  //     },
  //   })
  // }


  return (
    <CommonPageLayout titleSection={{ subtitle: t('components', { ns: 'component' }) }}
      topAction={[
        {
          buttonText: t('addComponents', { ns: 'component' }),
          buttonIcon: <AddCircleOutline />,
          onClick: onAdd,
          permissionBinaryValues: [permissionValue.Locality.Documents.Write],
          variant: 'outlined',
          shadowWeight: 0,
        }
      ]}
    >
      <Card className='ph-8-core'>
        <ComponentsTable
          components={componentsList as IComponent[] || []}
          setComponents={setComponentsList}
          customerId={customerId as EntityId}
          positionId={positionId as EntityId}
          localityId={localityId as EntityId}
          mooringId={mooringId as EntityId}
          height={window.innerHeight / 2}
          enableToolbar={false}
          onEdit={onEdit}
          rowClassName={'pt-8-core pb-8-core'}
          onSelectDeviation={onSelectDeviation}
        />
      </Card>
    </CommonPageLayout>
  )
}
