import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { MainButton } from '../../../../components/MainButton'
import { ScreenTitle } from '../../../../components/ScreenTitle'
import { showDialog, ShowDialogProps } from '../../../../show-dialog'
import { FormDialog } from '../../../../forms/FormDialog'
import { AddLocalityToServiceForm } from './AddLocalityToServiceForm'
import { useAddMooringToService } from '../../../../api/locality-service'
import { invalidateServiceCache } from '../../../../api/service'
import { invalidateLocalityFrequencyCache } from '../../../../api/locality-frequency'
import { invalidateMooringCache } from '../../../../api/moorings'
import { ServiceMooringTable } from './ServiceMooringTable'
import { invalidateLocalityCache } from 'src/api/localities'
import { toast } from 'react-toastify'

interface ServiceMooringContainerProps {
  serviceId: EntityId
}

interface CreateMooringServiceProps extends ShowDialogProps {
  customerId: EntityId
  serviceId: EntityId
}

const AddMooringToServiceDialog: React.FC<CreateMooringServiceProps> = ({ pop, customerId, serviceId }) => {
  const { t: translate } = useTranslation(['customer-locality', 'common'])

  const { mutateAsync, isLoading } = useAddMooringToService(customerId, serviceId)

  const onSubmit = async form => {
    await mutateAsync(form, {
      onSuccess: () => {
        toast.success(translate('mooringAddedSuccessfully', { ns: 'mooring-service' }))
        invalidateServiceCache.getServices(customerId)
        invalidateMooringCache.getMooringsForService(customerId, serviceId)
        invalidateLocalityFrequencyCache.getAllLocalityFrequencies(customerId, form?.localityId)
        invalidateLocalityCache.getLocalitiesWithInfoCount(customerId as EntityId)
        pop()
      },
      onError: (_) => {
        toast.error(translate('deletingError', { ns: 'common' }))
      },
    })
  }

  return (
    <FormDialog
        title={translate('addMooring', { ns: 'mooring-service' })}
        isLoading={isLoading}
        form={
          <AddLocalityToServiceForm
            onSubmit={form => {
              onSubmit(form)
            }}
            onCancel={() => pop()}
            customerId={customerId}
            serviceId={serviceId}
          />
        }
      />
  )
}

export const ServiceMooringContainer = ({ serviceId }: ServiceMooringContainerProps) => {
  const { t: translate } = useTranslation(['mooring-service', 'common'])
  const { id: customerId } = useParams()

  const onAddMooring = async () => {
    const added = await showDialog(AddMooringToServiceDialog, {
      componentProps: {
        customerId: customerId as EntityId,
        serviceId: serviceId as EntityId,
      },
    })
    if (!added) return
  }

  return (
    <>
      <Box className="flex justify-between mb-12">
        <ScreenTitle title={translate('moorings', { ns: 'mooring-service' })} textSize={'text-xl'} />
        <MainButton onClick={() => onAddMooring()} data-cy='addMoorings'>{translate('addMooring', { ns: 'mooring-service' })}</MainButton>
      </Box>
      <ServiceMooringTable serviceId={serviceId as EntityId} customerId={customerId as EntityId} />
    </>
  )
}
