import { Box, Grid, IconButton, Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ScreenTitleBar } from '../../localities/components/ScreenTitleBar'
import TasksFilter from './tasks/TasksFilter'
import GenericTable from '../../../components/table/GenericTable'
import { Add, CalendarMonth, List } from '@mui/icons-material'
import { GridColumns, GridNativeColTypes } from '@mui/x-data-grid-pro'
import { useCallback, useMemo, useState } from 'react'
import { MdEdit } from 'react-icons/md'
import { useCustomerCtx, useDialogCtx } from '../../../hooks/context-hooks'
import TaskResources from './tasks/TaskResources'
import TaskCalendar from './tasks/TaskCalendar'
import { taskEndpoints, useGetFilterTask } from '../../../api/tasks'
import TaskForm from './tasks/TaskForm'
import TaskEditForm from './tasks/TaskEditForm'
import { getFormattedDate } from '../../../utils'
import TaskDeleteButton from './tasks/TaskDeleteButton'

const Tasks = () => {
  const { t } = useTranslation('task')
  const { showDialog } = useDialogCtx()
  const [isCalendar, setIsCalendar] = useState(false)
  const { customer } = useCustomerCtx()
  const [queryParams, setQueryParams] = useState({})
  const [page, setPage] = useState(10)
  const { data } = useGetFilterTask(customer?.id, 0, page, queryParams)
  const [defaultFilterValues, setDefaultFilterValues] = useState({})

  const showResources = useCallback(
    row => {
      showDialog(TaskResources, {
        componentProps: {
          taskResources: row.taskResources,
          customerId: customer.id,
          taskId: row.id,
          localityId: row.localityId,
          task: row,
        },
        opts: {
          maxWidth: 'md',
          fullWidth: true,
        },
        title: t('resources'),
      })
    },
    [customer.id, showDialog, t],
  )

  const editTask = useCallback(
    row => {
      showDialog(TaskEditForm, {
        componentProps: {
          initialValues: row,
          customerId: customer?.id,
        },
        opts: {
          maxWidth: 'sm',
          fullWidth: true,
        },
        title: t('edit-task'),
      })
    },
    [customer?.id, showDialog, t],
  )

  const columns: GridColumns = useMemo(
    () => [
      {
        field: 'title',
        flex: 1,
        headerName: t('title', { ns: 'common' }),
        cellClassName: 'font-weight-semi-bold',
      },
      {
        field: 'locality.name',
        headerName: t('locality', { ns: 'common' }),
        flex: 1,
        cellClassName: 'font-weight-semi-bold',
        renderCell: row => row.row.locality?.name,
      },
      {
        field: 'description',
        flex: 1,
        headerName: t('description', { ns: 'common' }),
      },
      {
        field: 'startDate',
        flex: 1,
        headerName: t('startDate', { ns: 'common' }),
        renderCell: params => getFormattedDate(params?.row?.startDate),
      },
      {
        field: 'endDate',
        flex: 1,
        headerName: t('endDate', { ns: 'common' }),
        renderCell: params => getFormattedDate(params?.row?.endDate),
      },
      {
        field: 'actions',
        flex: 1,
        headerName: t('actions', { ns: 'common' }),
        type: 'actions' as GridNativeColTypes,
        getActions: ({ row }) => [
          <Tooltip title={`${t('edit', { ns: 'common' })}`} key={'edit'}>
            <IconButton onClick={() => editTask(row)}>
              <MdEdit size={24} />
            </IconButton>
          </Tooltip>,
          <Tooltip title={`${t('delete', { ns: 'common' })}`} key={'delete'}>
            <TaskDeleteButton task={row} />
          </Tooltip>,
        ],
      },
    ],
    [editTask, t],
  )

  const dateRangeChange = useCallback(date => {
    const monthStartDate = new Date(date)
    monthStartDate.setDate(1)
    const monthEndDate = new Date(date)
    monthEndDate.setMonth(monthEndDate.getMonth() + 1)
    monthEndDate.setDate(1)
    setDefaultFilterValues({ startDate: monthStartDate, endDate: monthEndDate })
  }, [])

  const onAdd = useCallback(() => {
    showDialog(
      TaskForm,
      {
        componentProps: {
          customerId: customer?.id,
        },
        opts: {
          maxWidth: 'sm',
          fullWidth: true,
        },
      },
      undefined,
      true,
    )
  }, [customer?.id, showDialog, t])

  const onCalendarIconClick = useCallback(() => {
    setPage(1000)
    setIsCalendar(true)
    dateRangeChange(new Date(Date.now()))
  }, [])

  const taskFilterChange = useCallback(params => {
    params.resourceIds = params.resource.map(re => re.id).join(',')
    params.localityIds = params.locality.map(re => re.id).join(',')
    delete params.locality
    delete params.resource
    setQueryParams(params)
  }, [])

  return (
    <Box className="px-4 py-2 mt-2 mb-2 bg-white shadow-md" borderRadius={2}>
      <ScreenTitleBar title={t('task-management')} />
      <Grid container component={Box}>
        <TasksFilter onChange={taskFilterChange} defaultValues={defaultFilterValues} isDisabled={isCalendar} />
      </Grid>
      <Box paddingTop={2}>
        <Box display={'flex'} flexDirection={'row'} justifyContent={'end'}>
          <Box display={'flex'} flexDirection={'row'} width={100} justifyContent={'space-around'} paddingBottom={1}>
            {!isCalendar ? (
              <Tooltip title={`${t('task-calendar')}`}>
                <CalendarMonth className="cursor-pointer" onClick={onCalendarIconClick} />
              </Tooltip>
            ) : (
              <List
                className="cursor-pointer"
                onClick={() => {
                  setPage(10)
                  setIsCalendar(false)
                  setQueryParams({
                    startDate: defaultFilterValues['startDate'],
                    endDate: defaultFilterValues['endDate'],
                  })
                }}
              />
            )}
            <Tooltip title={`${t('add-task')}`}>
              <Add className="cursor-pointer" onClick={onAdd} />
            </Tooltip>
          </Box>
        </Box>
        {isCalendar ? (
          <TaskCalendar
            onClick={showResources}
            events={data?.items}
            onMonthChange={date => {
              dateRangeChange(date)
            }}
          />
        ) : (
          <GenericTable
            queryUrl={taskEndpoints.filterTask(customer?.id)}
            queryParams={queryParams}
            columns={columns}
            onRowClick={({ row }) => showResources(row)}
            enableServerPagination
            enablePagination
            id={'customer-resources'}
            pageSize={10}
            pageOptions={[10, 15, 20]}
          />
        )}
      </Box>
    </Box>
  )
}

export default Tasks
