import { t as translate } from 'i18next'
import { DataGridPro, GridColumns, GridToolbar } from '@mui/x-data-grid-pro'
import { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo } from 'react'
import { useGetComponents } from '../../../api/components'
import { CenteredSpinner } from '../../../components/Spinner'
import { APIResultCount, QuickFilterBounceTime } from '../../../constant'
import { IComponent } from '../../../generated-types'
import { useFormContext } from 'react-hook-form'
import { Box } from '@mui/material'

interface ISelectComponentStepProps {
  customerId: EntityId
  setDisabled: Dispatch<SetStateAction<boolean>>
  handleNext: () => void
}
const SelectComponentStep: FC<ISelectComponentStepProps> = ({ customerId, setDisabled, handleNext }) => {
  const { getValues, reset } = useFormContext()

  useEffect(() => {
    if (getValues('component')?.id === undefined) {
      setDisabled(true)
    } else {
      setDisabled(false)
    }
  }, [getValues('component')])

  const STATIC_COLUMNS: GridColumns = useMemo(
    () => [
      { field: 'productNumber', headerName: translate('productNumber', { ns: 'component' }), flex: 1 },
      {
        field: 'productDescription',
        headerName: translate('productDescription', { ns: 'component' }),
        flex: 1,
        cellClassName: 'font-weight-semi-bold',
      },
      {
        field: 'positionDrawingNumber',
        headerName: translate('positionDrawingNumber', { ns: 'component' }),
        flex: 1,
        align: 'center',
      },
      { field: 'quantity', headerName: translate('quantity', { ns: 'component' }), flex: 1 },
      {
        field: 'installationDate',
        headerName: translate('installationDate', { ns: 'component' }),
        flex: 1,
        renderCell: params =>
          !params.value && typeof params.value !== 'string' ? (
            translate('n/a', { ns: 'common' })
          ) : (
            <h5>{new Date(params.value).toLocaleDateString()}</h5>
          ),
      },
    ],
    [],
  )

  const { data: components, isLoading } = useGetComponents(
    customerId,
    getValues('position')?.id as EntityId,
    0,
    APIResultCount.Max,
  )

  const handleRowClick = useCallback(
    params => {
      reset({ ...getValues(), component: params.row as IComponent })
      setDisabled(false)
      handleNext()
    },
    [setDisabled, handleNext],
  )

  if (isLoading) return <CenteredSpinner />

  return (<Box width={'100%'}>
    <DataGridPro
      columns={STATIC_COLUMNS}
      rows={components?.items as IComponent[] || []}
      rowCount={components?.totalCount}
      pagination={true}
      pageSize={10}
      rowsPerPageOptions={[5]}
      sx={{
        minHeight: '50vh',
      }}
      disableColumnFilter
      selectionModel={getValues('component') ? [getValues('component').id] : []}
      loading={isLoading}
      onRowClick={handleRowClick}
      components={{ Toolbar: GridToolbar }}
      disableDensitySelector
      disableColumnSelector
      componentsProps={{
        toolbar: {
          printOptions: { disableToolbarButton: true },
          csvOptions: { disableToolbarButton: true },
          showQuickFilter: true,
          quickFilterProps: { debounceMs: QuickFilterBounceTime },
        },
      }}
    />
  </Box>
  )
}

export default SelectComponentStep
