import { Box } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'
import { CancelButton } from '../../components/CancelButton'
import BasicDatePicker from '../../components/DateTimePicker'
import { AppFormProps, AppForm, FormTextField, FormSubmitButton, FormFileCoreUpload } from '../../components/FormCore'
import { ICertificateExtension } from '../../generated-types/CertificateExtension'
import { useZodForm } from '../../hooks/zod-form'

const schema = z.object({
  extensionNumber: z.string().trim().min(1),
  issuedDate: z.date(),
  issuedBy: z.string(),
  numberOfDays: z
    .number()
    .or(
      z
        .string()
        .min(1)
        .transform(value => parseInt(value)),
    )
    .refine(n => n < 92, {
      message: 'Number of days should be less than 92.',
    }),
  fileId: z.union([z.string(), z.number()]),
})

export const AddCertificateExtensionForm: React.FC<AppFormProps<ICertificateExtension>> = ({ onSubmit, onCancel }) => {
  const initialValues = {
    issuedDate: new Date(),
  }
  const form = useZodForm(schema, { defaultValues: initialValues })
  const { t: translate } = useTranslation(['admin-customer', 'common'])

  return (
    <AppForm
      form={form}
      onSubmit={form => {
        onSubmit(form)
      }}
    >
      <Box py={1}>
        <FormTextField fullWidth name="extensionNumber" label={translate('number', { ns: 'certificateExtension' })} />
      </Box>
      <Box py={1}>
        <FormTextField
          fullWidth
          name="numberOfDays"
          label={translate('numberOfDays', { ns: 'certificateExtension' })}
        />
      </Box>
      <Box py={1}>
        <BasicDatePicker
          label={translate('issueDate', { ns: 'certificateExtension' })}
          name="issuedDate"
          shouldDisableDate={date => date > new Date()}
          shouldDisableYear={date => new Date(date).getFullYear() > new Date().getFullYear()}
        />
      </Box>
      <Box py={1}>
        <FormTextField fullWidth name="issuedBy" label={translate('issuedBy', { ns: 'certificateExtension' })} />
      </Box>
      <Box py={1}>
        <FormFileCoreUpload
          label={translate('fileName', { ns: 'common' })}
          name="fileId"
          required
          mapFileKey="id"
          height="150px"
        />
      </Box>
      <Box className="flex justify-end gap-4 pt-4">
        <FormSubmitButton createText={translate('add', { ns: 'common' })} />
        <CancelButton onClick={onCancel}>{translate('cancel', { ns: 'common' })}</CancelButton>
      </Box>
    </AppForm>
  )
}
