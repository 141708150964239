import { ILocality } from '../../generated-types'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { Box, Collapse } from '@mui/material'
import classNames from 'classnames'
import { LocationHeader } from './LocationHeader'
import { AppRoutesNavigation } from '../../app-routes'
import MenuItemTemplate from './MenuItemTemplate'
import { IMenuItem } from 'src/screens/localities/LocalitiesSidebar'

export const ExpandedSideMenu: React.FC<{ location: ILocality; menuItems: IMenuItem[], customerId: EntityId }> = ({
  location,
  customerId,
  menuItems
}) => {
  const navigate = useNavigate()
  const { localityId } = useParams()
  const [open, setOpen] = useState(location.id == (localityId as EntityId))
  const { pathname } = useLocation()

  useEffect(() => {
    setOpen(location.id == (localityId as EntityId))
  }, [localityId, location.id])

  const active = !!pathname && pathname.split('/')[6]

  const menuItemList = menuItems.map((menuItem, index) => (
    <MenuItemTemplate key={index}
      {...menuItem}
      isMinified={false}
      active={active === menuItem.path}
    />
  ))

  return (
    <Box
      id={`locality-${location.id}`}
      className={classNames('bg-white', {
        'cursor-pointer  ': !open
      })}
    >
      <LocationHeader active={open} locality={location} onClick={e => {
        e.stopPropagation()
        navigate(AppRoutesNavigation.Localities.locality(customerId, location.id))
      }}></LocationHeader>

      <Collapse in={open}>
        {menuItemList}
      </Collapse>
    </Box>
  )
}