import { Box, Button, ButtonGroup, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { AccordionGlobalDocumentTable } from './AccordionGlobalDocumentTable'
import { useState } from 'react'
import { DocumentViewMode } from '../../../enums'
import { MdCategory, MdList } from 'react-icons/md'
import { GlobalDocumentsTable } from './GlobalDocumentTable'

export const GlobalDocumentApprovedContainer = () => {
  const { t } = useTranslation(['global-documents', 'common'])
  const { id: customerId } = useParams()
  const [viewMode, setViewMode] = useState<DocumentViewMode>(DocumentViewMode.Category)

  return (
    <Box className="" padding={1}>
      <Stack direction="column" justifyContent="center" spacing={2} marginBottom={1}>
        <ButtonGroup variant="outlined">
          <Button
            onClick={() => setViewMode(DocumentViewMode.Category)}
            variant={viewMode === DocumentViewMode.Category ? 'contained' : 'outlined'}
            startIcon={<MdCategory />}
          >
            {t('category', { ns: 'common' })}
          </Button>
          <Button
            onClick={() => setViewMode(DocumentViewMode.List)}
            variant={viewMode === DocumentViewMode.List ? 'contained' : 'outlined'}
            startIcon={<MdList />}
          >
            {t('list', { ns: 'common' })}
          </Button>
        </ButtonGroup>
      </Stack>
      <Box className="mb-2 bg-slate-50 shadow-md p-3" borderRadius={2}>
        {viewMode === DocumentViewMode.Category ? (
          <>
            <AccordionGlobalDocumentTable customerId={customerId!} IsApprovedDocs={true} />
          </>
        ) : (
          <GlobalDocumentsTable customerId={customerId as EntityId} IsApprovedDocs />
        )}
      </Box>
    </Box>
  )
}
