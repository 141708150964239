export const AppRoutesRouter = {
  Home: 'locality',
  Login: '/login',
  UserRegistration: '/register/:code',
  Profile: '/profile',
  RegisterComplete: '/register/complete',
  Localities: {
    main: 'locality',
    basePath: 'localities/customer/:customerId/locality/:localityId/*',
    sidebarPath: 'localities/customer/:customerId/locality/:localityId/*',
    locality: '',
    deviations: 'deviations/*',
    deviation: 'deviations/:deviationId',
    componentDeviation: 'positions/:positionId/components/:componentId/deviations',
    documents: 'documents/*',
    document: 'documents/:documentId',
    mainComponent: 'main-component/:mainComponentId',
    mooringsNew: 'mooring-new/*',
    moorings: 'moorings/*',
    mooringDashboard: 'moorings/dashboard',
    mooringList: 'moorings/list',
    mooring: 'moorings/:mooringId',
    positions: 'moorings/:mooringId/positions',
    position: 'moorings/:mooringId/positions/:positionId',
    createPosition: 'moorings/:mooringId/position/create',
    localityServices: 'locality-services/*',
    localityService: 'locality-services/:localityFrequencyId',
    localityServiceGroup: 'locality-services-group/:groupId',
    workAssignments: 'work-assignments/*',
    workAssignment: 'work-assignments/:workAssignmentId',
    equipments: 'equipments/*',
    tasks: 'tasks/*',
    task: 'tasks/:taskId',
  },
  Boats: '/boats',
  serviceStation: {
    main: '/service-station',
    basePath: '/service-stations/customer/:customerId/service-station/:serviceStationId/*',
    sidebarPath: '/service-stations/customer/:customerId/service-station/:serviceStationId/*',
    deviation: 'deviations/:deviationId',
    documents: 'documents/:documentId',
    dashboard: '',
    mainComponent: 'main-component/:mainComponentId',
    certifications: 'certifications',
    history: 'history',
  },
  Map: '/map',
  Admin: {
    basePath: '/admin/*',
    customers: 'customers',
    createCustomer: 'customers/create',
    updateCustomer: 'customers/:id',
    materialFactor: 'material-factor',
    deviations: 'deviations',
    accessControl: 'access-control',
    deviation: 'deviations/:deviationId',
    product: 'product',
    productCategory: 'productCategory',
    supplier: 'supplier',
    customerUsers: 'customers/:id/users',
    customerUserResources: 'customers/:id/users/:userId/resources',
    sensorType: 'sensor-type',
    distributor: 'distributor',
    positionDrawing: 'position-drawing',
    documentCategory: 'document-category',
    auditLogs: 'audit-logs',
    customProperty: 'custom-property',
    mainComponentTypes: 'main-component-types',
    globalDocuments: 'global-documents',
    timeReportTypes: 'time-report-types',
  },
  Customer: {
    basePath: '/customer/:id/manage/*',
    accessControl: 'access-control',
    accessControls: 'access-controls',
    users: 'users',
    userResources: 'users/:userId/resources',
    tasks: 'tasks',
    resources: 'resources',
    timeReports: 'time-reports',
    services: 'services',
    localities: 'localities',
    serviceStations: 'service-stations',
    createLocality: 'localities/new',
    updateLocality: 'localities/:localityId',
    localityGroup: 'locality-groups',
    serviceStationGroup: 'service-station-group',
    accessLevelGroups: 'access-level-groups',
    createAccessLevelGroup: 'access-level-groups/new',
    updateAccessLevelGroup: 'access-level-groups/:id',
    userInvites: 'user-invites',
    customProperty: 'custom-property',
    mainComponentTypes: 'main-component-types',
    formGenerator: 'form-generator',
    formSchemas: 'form-schemas',
    editFormSchema: 'form-schemas/:formSchemaId/edit',
    mainComponents: 'main-components',
    mainComponent: 'main-components/:mainComponentId',
    deviation: 'deviations/:deviationId',
    globalDocumentsAdmin: 'global-documents-admin',
    globalDocumentsCustomer: 'global-documents-customer',
    suppliers: 'suppliers',
    document: 'documents/:documentId',
  },
}

export const AppRoutesNavigation = {
  Home: '/',
  Login: '/login',
  Vessel: process.env.REACT_APP_VESSEL_URL || '',
  Profile: '/profile',
  UserRegistration: (code: string) => `/register/${code}`,
  RegisterComplete: '/register/complete',

  Localities: {
    basePath: '/localities',
    main: '/locality',
    locality: (customerId: EntityId, localityId: EntityId) =>
      `/localities/customer/${customerId}/locality/${localityId}?#locality-${localityId}`,
    deviations: (customerId: EntityId, localityId: EntityId) =>
      `/localities/customer/${customerId}/locality/${localityId}/deviations`,
    deviation: (customerId: EntityId, localityId: EntityId, deviationId: EntityId) =>
      `/localities/customer/${customerId}/locality/${localityId}/deviations/${deviationId}`,
    componentDeviations: (customerId: EntityId, localityId: EntityId, positionId: EntityId, componentId: EntityId) =>
      `/localities/customer/${customerId}/locality/${localityId}/positions/${positionId}/components/${componentId}/deviations`,
    documents: (customerId: EntityId, localityId: EntityId) =>
      `/localities/customer/${customerId}/locality/${localityId}/documents`,
    document: (customerId: EntityId, localityId: EntityId, documentId: EntityId) =>
      `/localities/customer/${customerId}/locality/${localityId}/documents/${documentId}`,
    mainComponent: (customerId: EntityId, localityId: EntityId, mainComponentId: EntityId) =>
      `/localities/customer/${customerId}/locality/${localityId}/main-component/${mainComponentId}`,
    moorings: (customerId: EntityId, localityId: EntityId) =>
      `/localities/customer/${customerId}/locality/${localityId}/moorings`,
    mooringsDashboard: (customerId: EntityId, localityId: EntityId) =>
      `/localities/customer/${customerId}/locality/${localityId}/moorings/dashboard`,
    mooringsList: (customerId: EntityId, localityId: EntityId) =>
      `/localities/customer/${customerId}/locality/${localityId}/moorings/list`,
    positions: (customerId: EntityId, localityId: EntityId, mooringId: EntityId) =>
      `/localities/customer/${customerId}/locality/${localityId}/moorings/${mooringId}/positions`,
    mooringMap: (customerId: EntityId, localityId: EntityId, mooringId: EntityId) =>
      `/localities/customer/${customerId}/locality/${localityId}/moorings/${mooringId}`,
    position: (customerId: EntityId, localityId: EntityId, mooringId: EntityId, positionId: EntityId) =>
      `/localities/customer/${customerId}/locality/${localityId}/moorings/${mooringId}/positions/${positionId}`,
    createPosition: (customerId: EntityId, localityId: EntityId, id: EntityId) =>
      `/localities/customer/${customerId}/locality/${localityId}/moorings/${id}/position/create`,
    localityServices: (customerId: EntityId, localityId: EntityId) =>
      `/localities/customer/${customerId}/locality/${localityId}/locality-services`,
    localityService: (customerId: EntityId, localityId: EntityId, localityFrequencyId: EntityId) =>
      `/localities/customer/${customerId}/locality/${localityId}/locality-services/${localityFrequencyId}`,
    localityServiceGroup: (customerId: EntityId, localityId: EntityId, groupId: string) =>
      `/localities/customer/${customerId}/locality/${localityId}/locality-services-group/${groupId}`,
    workAssignments: (customerId: EntityId, localityId: EntityId) =>
      `/localities/customer/${customerId}/locality/${localityId}/work-assignments`,
    workAssignment: (customerId: EntityId, localityId: EntityId, workAssignmentId: EntityId) =>
      `/localities/customer/${customerId}/locality/${localityId}/work-assignments/${workAssignmentId}`,
    equipments: (customerId: EntityId, localityId: EntityId) =>
      `/localities/customer/${customerId}/locality/${localityId}/equipments`,
    tasks: (customerId: EntityId, localityId: EntityId) =>
      `/localities/customer/${customerId}/locality/${localityId}/tasks`,
    task: (customerId: EntityId, localityId: EntityId, taskId: EntityId) =>
      `/localities/customer/${customerId}/locality/${localityId}/tasks/${taskId}`
  },
  serviceStation: {
    basePath: '/service-stations',
    main: '/service-station',
    serviceStation: (customerId: EntityId, serviceStationId: EntityId) =>
      `/service-stations/customer/${customerId}/service-station/${serviceStationId}`,
    dashboard: (customerId: EntityId, serviceStationId: EntityId) =>
      `/service-stations/customer/${customerId}/service-station/${serviceStationId}/dashboard`,
    deviation: (customerId: EntityId, serviceStationId: EntityId, deviationId: EntityId) =>
      `/service-stations/customer/${customerId}/service-station/${serviceStationId}/deviations/${deviationId}`,
    document: (customerId: EntityId, serviceStationId: EntityId, documentId: EntityId) =>
      `/service-stations/customer/${customerId}/service-station/${serviceStationId}/documents/${documentId}`,
    certifications: (customerId: EntityId, serviceStationId: EntityId) =>
      `/service-stations/customer/${customerId}/service-station/${serviceStationId}/certifications`,
    mainComponent: (serviceStationId: EntityId, customerId: EntityId, mainComponentId: EntityId) =>
      `/service-stations/customer/${customerId}/service-station/${serviceStationId}/main-component/${mainComponentId}`,
    history: (customerId: EntityId, serviceStationId: EntityId) =>
      `/service-stations/customer/${customerId}/service-station/${serviceStationId}/history`,
  },
  Boats: '/boats',
  Map: '/map',
  Admin: {
    basePath: '/admin',
    customers: '/admin/customers',
    createCustomer: '/admin/customers/create',
    updateCustomer: (id: EntityId) => `/admin/customers/${id}`,
    customerUsers: (id: EntityId) => `/admin/customers/${id}/users`,
    customerUserResources: (id: EntityId, userId: string) => `/admin/customers/${id}/users/${userId}/resources`,
    materialFactor: '/admin/material-factor',
    deviations: '/admin/deviations',
    deviation: (deviationId: EntityId) => `/admin/deviations/${deviationId}`,
    document: (documentId: EntityId) => `/admin/documents/${documentId}`,
    product: '/admin/product',
    productCategory: '/admin/productCategory',
    supplier: '/admin/supplier',
    accessControl: '/admin/access-control',
    sensorType: '/admin/sensor-type',
    distributor: '/admin/distributor',
    positionDrawing: '/admin/position-drawing',
    documentCategory: '/admin/document-category',
    auditLogs: '/admin/audit-logs',
    customProperty: '/admin/custom-property',
    mainComponentTypes: '/admin/main-component-types',
    globalDocuments: '/admin/global-documents',
    timeReportTypes: '/admin/time-report-types',
  },
  Customer: {
    basePath: (id: EntityId) => `/customer/${id}/manage`,
    accessControls: (id: EntityId) => `/customer/${id}/manage/access-controls`,
    globalDocumentsAdmin: (id: EntityId) => `/customer/${id}/manage/global-documents-admin`,
    globalDocumentsCustomer: (id: EntityId) => `/customer/${id}/manage/global-documents-customer`,
    document: (customerId: EntityId, documentId: EntityId) => `/customer/${customerId}/manage/documents/${documentId}`,
    customerGlobalDocuments: (id: EntityId) => `/customer/${id}/manage/global-documents`,
    suppliers: (id: EntityId) => `/customer/${id}/manage/suppliers`,
    users: (id: EntityId) => `/customer/${id}/manage/users`,
    userResources: (customerId: EntityId, id: EntityId) => `/customer/${customerId}/manage/users/${id}/resources`,
    services: (id: EntityId) => `/customer/${id}/manage/services`,
    localities: (id: EntityId) => `/customer/${id}/manage/localities`,
    serviceStations: (id: EntityId) => `/customer/${id}/manage/service-stations`,
    createLocality: (id: EntityId) => `/customer/${id}/manage/localities/new`,
    updateLocality: (customerId: EntityId, id: EntityId) => `/customer/${customerId}/manage/localities/${id}`,
    localityGroups: (id: EntityId) => `/customer/${id}/manage/locality-groups`,
    serviceStationGroup: (id: EntityId) => `/customer/${id}/manage/service-station-group`,
    accessLevelGroups: (customerId: EntityId) => `/customer/${customerId}/manage/access-level-groups`,
    createAccessLevelGroup: (customerId: EntityId) => `/customer/${customerId}/manage/access-level-groups/new`,
    updateAccessLevelGroup: (customerId: EntityId, id: EntityId) =>
      `/customer/${customerId}/manage/access-level-groups/${id}`,
    userInvites: (customerId: EntityId) => `/customer/${customerId}/manage/user-invites`,
    userInvite: (customerId: EntityId, id: EntityId) => `/customer/${customerId}/manage/user-invites/${id}`,
    customProperty: (customerId: EntityId) => `/customer/${customerId}/manage/custom-property`,
    mainComponentTypes: (customerId: EntityId) => `/customer/${customerId}/manage/main-component-types`,
    formGenerator: (customerId: EntityId) => `/customer/${customerId}/manage/form-generator`,
    formSchema: (customerId: EntityId) => `/customer/${customerId}/manage/form-schemas`,
    editFormSchema: (customerId: EntityId, formSchemaId: number) =>
      `/customer/${customerId}/manage/form-schemas/${formSchemaId}/edit`,
    mainComponents: (customerId: EntityId) => `/customer/${customerId}/manage/main-components`,
    tasks: (customerId: EntityId) => `/customer/${customerId}/manage/tasks`,
    resources: (customerId: EntityId) => `/customer/${customerId}/manage/resources`,
    timeReports: (customerId: EntityId) => `/customer/${customerId}/manage/time-reports`,
    mainComponent: (customerId: EntityId, mainComponentId: EntityId) =>
      `/customer/${customerId}/manage/main-components/${mainComponentId}`,
    deviation: (customerId: EntityId, deviationId: EntityId) =>
      `/customer/${customerId}/manage/deviations/${deviationId}`,
  },
}
