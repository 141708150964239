import { Navigate, Route, Routes } from 'react-router-dom'
import { AppRoutesNavigation, AppRoutesRouter } from '../../app-routes'
import { AdminCustomersScreen } from '../../screens/admin/customers/AdminCustomersScreen'
import { Stack, useTheme } from '@mui/material'
import { ProductContainer } from '../../screens/admin/product/ProductContainer'
import { SupplierContainer } from '../../screens/admin/supplier/SupplierContainer'
import { MaterialFactorScreen } from '../../screens/admin/material-factor/materialFactorScreen'
import { PositionDrawingScreen } from '../../screens/admin/position-drawing/positionDrawingScreen'
import { SensorTypeContainer } from '../../screens/admin/sensor-type/SensorTypeContainer'
import { DistributorContainer } from '../../screens/admin/distributor/DistributorContainer'
import { DocumentCategoryContainer } from '../../screens/admin/document-category/DocumentCategoryContainer'
import { AuditLogsContainer } from '../../screens/admin/audit-logs/AuditLogsContainer'
import { CustomPropertyContainer } from '../../screens/admin/custom-type/CustomPropertyContainer'
import { MainComponentTypesContainer } from '../../screens/admin/main-component-types/AdminMainComponentTypesContainer'
import { DeviationContainerAdmin } from '../../screens/localities/deviations/DeviationContainer'
import { GlobalDocumentAdminContainer } from '../../screens/admin/global-documents/GlobalDocumentAdminContainer'
import { TimeReportTypeContainer } from '../../screens/admin/time-report-type/TimeReportTypeContainer'
import { UsersContainer } from '../../screens/customer/users/UsersContainer'
import { permissionBinding, permissionLevel } from '../../constant'
import { UserResources } from '../../screens/customer/users/UserResources'
import { UserRoleGuard } from '../../guards/UserRoleGuard'
import { ProductCategoryContainer } from '../../screens/admin/product-category/ProductCategoryContainer'
import { Spinner } from '@lovoldsystem/ui'
import { useState, useEffect } from 'react'
import { useDialogCtx, useUserCtx } from 'src/hooks/context-hooks'
import { axios } from 'src/axios'
import DeviationsPage from 'src/screens/customer/DeviationsPage'
import { RoleTypes } from 'src/enums'
import { AccessControls } from '@lovoldsystem/authorization.client'

export const AdminRoutes = () => {
  const { user, isLoadingUser } = useUserCtx()
  const [permissions, setPermissions] = useState(-1)
  const theme = useTheme()
  const { showDialog } = useDialogCtx()

  useEffect(() => {
    if (user) {
      axios.defaults.headers['X-Permissions'] = user.encryptedPermission
      setPermissions(user.permission)
    }
  }, [user])

  if (isLoadingUser || permissions == -1) return <Spinner size={'small'} />

  return (
    <Stack
      style={{ backgroundColor: theme.palette.white[theme.palette.mode] }}
      width={'100%'}
      minHeight={'100vh'}
      className="pl-24-core pr-16-core pt-8-core"
    >
      <UserRoleGuard isNavigateToUnAuthorized>
        <Routes>
          <Route path={AppRoutesRouter.Admin.customers} element={<AdminCustomersScreen />} />
          <Route path={AppRoutesRouter.Admin.customerUsers} element={<UsersContainer isAdminLayout={true} />} />
          <Route path={AppRoutesRouter.Admin.customerUserResources} element={<UserResources isAdminLayout={true} />} />
          <Route path="*" element={<Navigate to={AppRoutesNavigation.Admin.customers} />} />
          <Route path={AppRoutesRouter.Admin.materialFactor} element={<MaterialFactorScreen />} />
          <Route path={AppRoutesRouter.Admin.deviations} element={<DeviationsPage />} />
          <Route path={AppRoutesRouter.Admin.accessControl}
            element={<AccessControls roleTypes={RoleTypes} permissionLevels={permissionLevel} isAdminUser={true} permissionBinding={permissionBinding} showDialog={showDialog} />} />
          <Route path={AppRoutesRouter.Admin.deviation} element={<DeviationContainerAdmin />} />
          <Route path={AppRoutesRouter.Admin.productCategory} element={<ProductCategoryContainer />} />
          <Route path={AppRoutesRouter.Admin.product} element={<ProductContainer />} />
          <Route path={AppRoutesRouter.Admin.supplier} element={<SupplierContainer />} />
          <Route path={AppRoutesRouter.Admin.sensorType} element={<SensorTypeContainer />} />
          <Route path={AppRoutesRouter.Admin.distributor} element={<DistributorContainer />} />
          <Route path={AppRoutesRouter.Admin.positionDrawing} element={<PositionDrawingScreen />} />
          <Route path={AppRoutesRouter.Admin.documentCategory} element={<DocumentCategoryContainer />} />
          <Route path={AppRoutesRouter.Admin.auditLogs} element={<AuditLogsContainer />} />
          <Route
            path={AppRoutesRouter.Admin.customProperty}
            element={<CustomPropertyContainer isSuperAdmin={true} />}
          />
          <Route path={AppRoutesRouter.Admin.mainComponentTypes} element={<MainComponentTypesContainer />} />
          <Route path={AppRoutesRouter.Admin.globalDocuments} element={<GlobalDocumentAdminContainer />} />
          <Route path={AppRoutesRouter.Admin.timeReportTypes} element={<TimeReportTypeContainer />} />
        </Routes>
      </UserRoleGuard>
    </Stack>
  )
}
