import { makeStyles } from '@material-ui/core'
import { Button, ButtonTypeMap } from '@mui/material'
import React, { ButtonHTMLAttributes } from 'react'
import { useTranslation } from 'react-i18next'

interface ExtraProps {
  loading?: boolean
  onClick?: (...args: any[]) => any
  type?: ButtonHTMLAttributes<any>['type']
  className?: string
}

const useStyle = makeStyles({
  cancelButton: {
    fontSize: '14px',
    fontWeight: 600,
    textTransform: 'none',
  },
})

export type CancelButtonProps = ButtonTypeMap<ExtraProps>['props']

export const CancelButton: React.FC<CancelButtonProps> = ({ loading, ...rest }) => {
  const { t } = useTranslation(['common'])
  const classes = useStyle()

  return (
    <Button {...rest} className={classes.cancelButton}>
      {t('cancel', { ns: 'common' })}
    </Button>
  )
}
