import { Box, CssBaseline, Drawer, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { TopBar } from '../containers/TopBar/Index'
import { DrawerLayout } from './SidebarLayout'
import { SidebarCtx } from 'src/context/SidebarContext'
import { drawerWidth } from 'src/styles/constants'
import { AppBar, styled } from '@mui/material'
import { NavigationContainer } from 'src/containers/TopBar/Index.style'
import { mainTopBarItems } from 'src/screens/main-top-bar-items'
import { Flex } from 'src/styles/flexComponent'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation, To } from 'react-router-dom'
import { AppRoutesNavigation } from 'src/app-routes'
import { closeAllDialog } from 'src/show-dialog'
import { padding } from 'src/styles/paddingCss'

const Index = ({ children }) => {
    const [collapsibleSidebarOpen, setCollapsibleSidebarOpen] = useState(true)
    const theme = useTheme()
    const { pathname } = useLocation()
    const isMedium = useMediaQuery<any>(theme.breakpoints.down('md'))
    const isSmall = useMediaQuery<any>(theme.breakpoints.down('sm'))
    const isISdebarHidden = ['locality', 'service-station'].includes(pathname.split('/')[1])

    useEffect(() => {
        closeAllDialog()
    }, [location])

    return (
        <>
            <SidebarCtx.Provider value={{ collapsibleSidebarOpen, setCollapsibleSidebarOpen }}>
                <Box sx={{ display: 'flex' }}>
                    <CssBaseline />
                    <AppTopBar position="fixed" color='inherit'>
                        <TopBar />
                    </AppTopBar>
                    <DrawerLayout />
                    <LayoutContainer
                        sx={{ paddingBottom: { xs: '120px', md: 0 } }}
                        drawerWidth={isISdebarHidden || isMedium ? 0 : !collapsibleSidebarOpen ? 80 : drawerWidth}>
                        {children}
                    </LayoutContainer>
                    {
                        isSmall &&
                        <BottomDrawer />
                    }
                </Box >
            </SidebarCtx.Provider >
        </>
    )
}

export default Index

export const BottomDrawer = () => {
    const { t: translate } = useTranslation(['header', 'common'])
    const navigate = useNavigate()
    const { pathname } = useLocation()

    const handleNavigate = (path: To) => {
        if (path == AppRoutesNavigation.Vessel) window.location.href = path
        navigate(path)
    }
    return <Drawer open={true} anchor='bottom' variant='permanent'
        ModalProps={{
            keepMounted: true,
        }}
    >
        <Flex.Row justifyContent={'space-evenly'} height={'80px'}>
            {mainTopBarItems.map((nav, index) => (
                <NavigationContainer
                    key={index}
                    onClick={() => {
                        handleNavigate(nav.path)
                    }}
                    active={!!pathname && [nav.path, nav.main].includes(`/${pathname.split('/')[1]}`)}
                >
                    <Flex.Column justifyContent={'center'} alignItems={'center'}>
                        <nav.Icon height={29} width={29} />
                        <Typography
                            variant="h6"
                            fontSize={'14px'}
                        >
                            {translate(nav.name)}
                        </Typography>
                    </Flex.Column>
                </NavigationContainer>
            ))}
        </Flex.Row>
    </Drawer>
}

//styles
export const LayoutContainer = styled(Box) <{ drawerWidth: number }>`
    ${padding.p16}
    padding-top:96px;
    width:${props => `calc(100vw - ${props.drawerWidth}px)`};
    overflow-y:auto;
    height:'100%';
    overflow-y:auto;
`

export const AppTopBar = styled(AppBar)`
    z-index: ${props => props.theme.zIndex.drawer - 1};
    background-color:${props => props.theme.palette.white[props.theme.palette.mode]};
`