import { FC, useCallback, useMemo } from 'react'
import { GlobalDocumentStatus, IForApprovalGlobalDocument } from '../../../generated-types/global-documents'
import { ShowDialogProps } from '../../../show-dialog'
import { Box, Chip, Divider, Link, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { getGlobalDocumentStatus, getLocalDateTime } from '../../../utils/function'
import moment from 'moment'
import { z } from 'zod'
import { useZodForm } from '../../../hooks/zod-form'
import { AppForm, FormFileUpload, FormTextField } from '../../../components/FormCore'
import { GenericDialogLayout } from '../../../components/GenericDialogLayout'
import { MainButton } from '../../../components/MainButton'
import { Launch } from '@mui/icons-material'
import {
  invalidateGlobalDocument,
  useApproveGlobalDocumentVersion,
  useSaveDraftGlobalDocumentVersion,
  useWithdrawGlobalDocumentVersion,
} from '../../../api/global-documents'
import {
  SUCCESSFULLY_APPROVED,
  SUCCESSFULLY_CREATED,
  SUCCESSFULLY_UPDATED,
  SUCCESSFULLY_WITHDREW,
} from '../../../constant'
import { toast } from 'react-toastify'
import { invalidateAllGlobalDocuments } from './GlobalDocumentTable'
import { GlobalDocumentRuleContainerDialog } from '../../admin/global-documents/GlobalDocumentRuleContainerDialog'
import { useDialogCtx } from '../../../hooks/context-hooks'

interface GlobalDocumentDialogProps extends ShowDialogProps {
  customerId: EntityId
  globalDocument: IForApprovalGlobalDocument
  title: string
}
export const GlobalDocumentDialog: FC<GlobalDocumentDialogProps> = ({ pop, customerId, globalDocument, title }) => {
  const { mutateAsync: approveDocument, isLoading: isApproving } = useApproveGlobalDocumentVersion()
  const { mutateAsync: saveAsDraft, isLoading: isSaving } = useSaveDraftGlobalDocumentVersion()
  const { mutateAsync: withdrawDocument, isLoading: isWithdrawing } = useWithdrawGlobalDocumentVersion()
  const schema = z.object({
    changesNote: z.string().optional().nullable(),
    fileId: z.union([z.string(), z.number()]).optional().nullable(),
  })
  const form = useZodForm(schema, {
    defaultValues: { changesNote: globalDocument.changesNote, fileId: globalDocument.file?.id },
  })
  const { showDialog } = useDialogCtx()

  const { t } = useTranslation(['global-document', 'common'])
  const getStatusColor = useCallback((status: GlobalDocumentStatus) => {
    switch (status) {
      case GlobalDocumentStatus.Approved:
        return 'success'
      case GlobalDocumentStatus.Withdrawn:
        return 'error'
      case GlobalDocumentStatus.SaveDraft:
        return 'warning'
      default:
        return 'default'
    }
  }, [])
  const onApprove = useCallback(
    f => {
      approveDocument({ customerId: customerId, documentVersionId: globalDocument.documentVersionId, ...f }).then(
        ({ data }) => {
          if (data === SUCCESSFULLY_APPROVED) {
            toast.success(t('successfullyApproved', { ns: 'global-documents' }))
          }
          invalidateAllGlobalDocuments()
          pop()
        },
        () => {
          toast.error(t('unExpectedError', { ns: 'common-api-message' }))
        },
      )
    },
    [customerId, globalDocument.documentVersionId],
  )
  const onSaveDraft = useCallback(
    f => {
      saveAsDraft({ customerId: customerId, documentVersionId: globalDocument.documentVersionId, ...f }).then(
        ({ data }) => {
          if (data === SUCCESSFULLY_CREATED) {
            toast.success(t('successfullySaved', { ns: 'global-documents' }))
          }
          if (data === SUCCESSFULLY_UPDATED) {
            toast.success(t('SuccessfullyUpdated', { ns: 'common-api-message' }))
          }
          invalidateGlobalDocument.forApproval()
          pop()
        },
        () => {
          toast.error(t('unExpectedError', { ns: 'common-api-message' }))
        },
      )
    },
    [customerId, globalDocument.documentVersionId],
  )
  const onWithdraw = useCallback(() => {
    withdrawDocument({ customerId: customerId, documentVersionId: globalDocument.documentVersionId }).then(
      ({ data }) => {
        if (data === SUCCESSFULLY_WITHDREW) {
          toast.success(t('successfullyWithdrew', { ns: 'global-documents' }))
        }
        invalidateAllGlobalDocuments()
        pop()
      },
      () => {
        toast.error(t('unExpectedError', { ns: 'common-api-message' }))
      },
    )
  }, [customerId, globalDocument.documentVersionId, pop, t, withdrawDocument])

  const saveAndApprove = useMemo(() => {
    return [
      <MainButton
        disabled={isSaving || isApproving}
        key="save-draft"
        onClick={() => {
          form.handleSubmit(onSaveDraft)()
        }}
      >
        {t('save-draft', { ns: 'global-documents' })}
      </MainButton>,
      <MainButton
        disabled={isSaving || isApproving}
        key="approve-doc"
        onClick={() => {
          form.handleSubmit(onApprove)()
        }}
      >
        {t('approve-document', { ns: 'global-documents' })}
      </MainButton>,
    ]
  }, [form, isApproving, isSaving, onApprove, onSaveDraft, t])

  const withdraw = useMemo(() => {
    return [
      <MainButton disabled={isWithdrawing} key="withdraw" onClick={onWithdraw}>
        {t('withdraw-document', { ns: 'global-documents' })}
      </MainButton>,
    ]
  }, [isWithdrawing, onWithdraw, t])

  const handleViewRule = useCallback(
    (customerId: EntityId, globalDocumentId: EntityId) => {
      showDialog(GlobalDocumentRuleContainerDialog, {
        title: t('Document rules', { ns: 'global-documents' }),
        opts: {
          maxWidth: 'lg',
          fullWidth: true,
        },
        componentProps: {
          globalDocumentId: globalDocumentId,
          customerId: customerId,
          isViewOnly: true,
        },
      })
    },
    [showDialog, t],
  )

  return (
    <GenericDialogLayout
      title={title}
      pop={pop}
      removedCloseButton={false}
      sx={{ px: 1.5 }}
      actions={globalDocument.status === GlobalDocumentStatus.Approved ? withdraw : saveAndApprove}
    >
      <Stack width={'100%'} spacing={1}>
        <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
          <Typography variant="h6">{globalDocument.title}</Typography>
          <Typography variant="body1" sx={{ color: 'lightgray', fontSize: 16, fontWeight: 'bold' }}>
            {globalDocument.version}
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
          <Typography variant="body1">{`${t('registeredBy', { ns: 'common' })}`}</Typography>
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
            {globalDocument.createdUserName}
          </Typography>
          <Typography variant="body1">
            {`${t('on', { ns: 'common' })} ${moment(getLocalDateTime(globalDocument.createdAt)).format(
              'DD/MM/YYYY HH:mm',
            )}`}
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1} width={'100%'}>
          <Box key={'add-rule'} paddingLeft={1}>
            <MainButton
              variant="contained"
              className="!py-1 !h-[24px] !text-xs"
              onClick={() => handleViewRule(customerId as number, globalDocument.id)}
            >
              {t('view-rules', { ns: 'global-document-rules' })}
            </MainButton>
          </Box>
          <Chip
            sx={{ fontWeight: 'bold', minWidth: 80 }}
            label={t(`${getGlobalDocumentStatus(globalDocument.status)}`, { ns: 'global-document-status' })}
            color={getStatusColor(globalDocument.status)}
          />
        </Stack>
        <Divider />
        <Stack minHeight={80} justifyContent={'center'} spacing={1}>
          <Typography variant="body1">{globalDocument.description}</Typography>
          {globalDocument.documentUrl && (
            <Link href={globalDocument.documentUrl} target="_blank" underline="hover" letterSpacing={2}>
              {t('preview-document', { ns: 'global-documents' })}
              <Launch />
            </Link>
          )}
        </Stack>
        <Divider />
        {globalDocument.status !== GlobalDocumentStatus.Approved ? (
          <AppForm form={form}>
            <Typography variant="body1">{t('describe-change', { ns: 'global-documents' })}</Typography>
            <FormTextField name="changesNote" multiline rows={4} />
            <Typography variant="body1">{t('upload-document', { ns: 'global-documents' })}</Typography>
            <FormFileUpload
              label={t('fileName', { ns: 'common' })}
              name="fileId"
              mapFileKey="id"
              initialFiles={globalDocument.file}
            />
          </AppForm>
        ) : (
          <Stack justifyContent={'center'} spacing={1}>
            <Typography variant="h5">{t('changelog', { ns: 'global-documents' })}</Typography>
            <Typography variant="body1">{globalDocument.changesNote}</Typography>
            {globalDocument.file && (
              <Link href={globalDocument.file?.url} target="_blank" underline="hover" letterSpacing={2}>
                {t('show-upload-attachment', { ns: 'global-documents' })}
                <Launch />
              </Link>
            )}
          </Stack>
        )}
      </Stack>
    </GenericDialogLayout>
  )
}
