import { useTranslation } from 'react-i18next'
import { Box} from '@mui/material'
import { z } from 'zod'
import { AppForm, FormSubmitButton, FormTextField, AppFormProps } from '../../../components/FormCore'
import { MdDelete, MdEdit } from 'react-icons/md'
import { showDialog, ShowDialogProps, showConfirmDialog } from '../../../show-dialog'
import { useZodForm } from '../../../hooks/zod-form'
import { CancelButton } from '../../../components/CancelButton'
import { GridActionsCellItem, GridColumns, GridNativeColTypes } from '@mui/x-data-grid-pro'
import { useMemo } from 'react'
import { DataGridWithServerPagination } from '../../../components/DataGridWithServerPagination'
import {
  invalidateMaterialFactorCache,
  materialFactorEndpoints,
  useAddMaterialFactor,
  useDeleteMaterialFactor,
  useGetMaterialFactor,
  useUpdateMaterialFactor,
} from '../../../api/material-factor'
import { CenteredSpinner } from '../../../components/Spinner'
import { ScreenTitleBar } from '../../localities/components/ScreenTitleBar'
import { GenericDialogLayout } from '../../../components/GenericDialogLayout'

const MaterialFactorForm: React.FC<AppFormProps> = ({ onSubmit, onCancel, initialValues }) => {
  const parsedInitialValues = initialValues
    ? {
        ...initialValues,
        intactSafetyFactor: initialValues.intactSafetyFactor ? String(initialValues.intactSafetyFactor) : '',
        accidentSafetyFactor: initialValues.accidentSafetyFactor ? String(initialValues.accidentSafetyFactor) : '',
        intactMaterialFactor: initialValues.intactMaterialFactor ? String(initialValues.intactMaterialFactor) : '',
        accidentMaterialFactor: initialValues.accidentMaterialFactor
          ? String(initialValues.accidentMaterialFactor)
          : '',
      }
    : undefined

  const { t: translate } = useTranslation(['material-factor', 'common'])

  const schema = z.object({
    description: z.string().nonempty(translate('descriptionIsRequired')),
    intactSafetyFactor: z.string().nonempty(translate('intactSafetyFactorIsRequired')),
    accidentSafetyFactor: z.string().nonempty(translate('accidentSafetyFactorIsRequired')),
    intactMaterialFactor: z.string().nonempty(translate('intactMaterialFactorIsRequired')),
    accidentMaterialFactor: z.string().nonempty(translate('accidentMaterialFactorIsRequired')),
  })
  const form = useZodForm(schema, {
    defaultValues: parsedInitialValues,
  })

  const isUpdateForm = parsedInitialValues?.id

  return (
    <AppForm form={form} onSubmit={onSubmit} hasInitialValues={parsedInitialValues}>
      {isUpdateForm && <FormTextField name="id" type="hidden" />}
      <Box py={1}>
        <FormTextField name="description" label="Description" />
      </Box>
      <Box py={1}>
        <FormTextField
          inputProps={{ type: 'number' }}
          name="intactSafetyFactor"
          label={translate('intactSafetyFactor', { ns: 'material-factor' })}
        />
      </Box>
      <Box py={1}>
        <FormTextField
          inputProps={{ type: 'number' }}
          name="accidentSafetyFactor"
          label={translate('accidentSafetyFactor', { ns: 'material-factor' })}
        />
      </Box>
      <Box py={1}>
        <FormTextField
          inputProps={{ type: 'number' }}
          name="intactMaterialFactor"
          label={translate('intactMaterialFactor', { ns: 'material-factor' })}
        />
      </Box>
      <Box py={1}>
        <FormTextField
          inputProps={{ type: 'number' }}
          name="accidentMaterialFactor"
          label={translate('accidentMaterialFactor', { ns: 'material-factor' })}
        />
      </Box>
      <Box className="flex gap-4 pt-4 justify-end">
        <FormSubmitButton
          createText={isUpdateForm ? translate('update', { ns: 'common' }) : translate('add', { ns: 'common' })}
        />
        <CancelButton onClick={onCancel}>{translate('cancel', { ns: 'common' })}</CancelButton>
      </Box>
    </AppForm>
  )
}

interface UpdateMaterialFactorProps extends ShowDialogProps {
  id: EntityId
}

const CreateMaterialFactorDialog: React.FC<ShowDialogProps> = ({ pop }) => {
  const { t: t } = useTranslation(['material-factor', 'common'])
  const addMutate = useAddMaterialFactor()

  const onSubmit = async form => {
    await addMutate.mutateAsync(form)
    invalidateMaterialFactorCache.getMaterialFactors()
    pop(true)
  }

  return (
    <Box> 
      <GenericDialogLayout title={t('addMaterialFactor', { ns: 'material-factor' })} pop={pop}>
        <MaterialFactorForm onSubmit={onSubmit} onCancel={() => pop()} />
      </GenericDialogLayout>
    </Box>
  )
}

const UpdateMaterialFactorDialog: React.FC<UpdateMaterialFactorProps> = ({ pop, id }) => {
  const { t: t } = useTranslation(['material-factor', 'common'])
  const updateMutate = useUpdateMaterialFactor(id)
  const { data, isLoading } = useGetMaterialFactor(id)

  if (isLoading) return <CenteredSpinner />

  const onSubmit = async form => {
    await updateMutate.mutateAsync(form)
    invalidateMaterialFactorCache.getMaterialFactors()
    pop(true)
  }

  return (
    <Box>
      <GenericDialogLayout title={t('updateMaterialFactor', { ns: 'material-factor' })} pop={pop}>
        <MaterialFactorForm onSubmit={onSubmit} onCancel={() => pop()} initialValues={data} />
      </GenericDialogLayout>
    </Box>
  )
}

export const MaterialFactorContainer = () => {
  const { t: t } = useTranslation(['material-factor', 'common'])

  const onAddMaterialFactor = async () => {
    const added = await showDialog(CreateMaterialFactorDialog)
    if (!added) return
  }

  return (
    <>
      <Box className="mb-2 bg-white shadow-md p-3" borderRadius={2}>
            <ScreenTitleBar
              title={t('materialFactorList', { ns: 'material-factor' })}
              addNewItemText={t('add', { ns: 'common' })}
              onAddNewItemClick={onAddMaterialFactor}
            />
          <MaterialFactorTable />
          </Box>
    </>
  )
}

export const MaterialFactorTable: React.FC = () => {
  const { t } = useTranslation(['material-factor', 'common'])
  const { mutate } = useDeleteMaterialFactor()

  const onDelete = id => {
    showConfirmDialog(
      t('deleteMaterialFactor', { ns: 'material-factor' }),
      t('areYouSureDeleteMaterialFactor', { ns: 'material-factor' }),
    ).then(async e => {
      if (e) {
        await mutate(id)
        invalidateMaterialFactorCache.getMaterialFactors()
      }
    })
  }

  const onUpdate = async id => {
    await showDialog(UpdateMaterialFactorDialog, {
      componentProps: { id: id as EntityId },
    })
  }

  const STATIC_COLUMNS: GridColumns = [
    {
      field: 'description',
      headerName: t('description', { ns: 'common' }),
      flex: 1,
      cellClassName: 'font-weight-semi-bold',
    },
    { field: 'accidentMaterialFactor', headerName: t('accidentMaterialFactor', { ns: 'material-factor' }), flex: 1 },
    { field: 'accidentSafetyFactor', headerName: t('accidentSafetyFactor', { ns: 'material-factor' }), flex: 1 },
    { field: 'intactMaterialFactor', headerName: t('intactMaterialFactor', { ns: 'material-factor' }), flex: 1 },
    { field: 'intactSafetyFactor', headerName: t('intactSafetyFactor', { ns: 'material-factor' }), flex: 1 },
  ]
  const columns: GridColumns = useMemo(
    () => [
      ...STATIC_COLUMNS,
      {
        field: 'actions',
        type: 'actions' as GridNativeColTypes,
        width: 10,
        getActions: ({ id }) => [
          <GridActionsCellItem
            key={id}
            label={t('edit', { ns: 'common' })}
            icon={<MdEdit size={24} />}
            onClick={() => onUpdate(id)}
            color="primary"
            showInMenu
          />,
          <GridActionsCellItem
            key={id}
            label={t('delete', { ns: 'common' })}
            icon={<MdDelete size={24} />}
            onClick={() => onDelete(id)}
            color="primary"
            showInMenu
          />,
        ],
      },
    ],
    [],
  )

  return (
    <DataGridWithServerPagination
      queryUrl={materialFactorEndpoints.getMaterialFactors()}
      onCellClick={cell => console.log(cell)}
      columns={columns}
    />
  )
}
