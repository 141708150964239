import { Box, Typography, useTheme } from '@mui/material'
import { IMainComponentType } from '../../../enums'
import { AppRoutesNavigation } from '../../../app-routes'
import { getCageLetter, getCageName } from '../../../utils/function'
import Cage from '../../../assets/svg/not.png'
import { ArrowRightAltRounded } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { BackButton } from 'src/components/common/CommonPageLayout'
import { useMemo } from 'react'
import { useNavigation } from 'src/context/NavigationContext'

interface ComponentCardViewProps {
  data: any
  localityId: EntityId
  customerId: EntityId
  mooringName?: string
}
const ComponentCardView: React.FC<ComponentCardViewProps> = ({ data, localityId, customerId, mooringName }) => {
  const { navigateBack, navigateTo } = useNavigation()
  const { t } = useTranslation('common')
  const theme = useTheme()
  const customProperties = useMemo(() => JSON.parse(data?.customPropertyValues), [data])

  return (
    <Box display={'flex'} flexDirection={'row'} height={50} padding={1} sx={{ flexGrow: 1 }}>
      <BackButton onClick={navigateBack} />
      {data?.positionId != null && (
        <>
          <Typography variant="h5" color={theme.palette.primary[theme.palette.mode]}>
            {mooringName && mooringName + ' / '}
          </Typography>
          <img src={Cage} height={40} width={40} />
          <Typography
            className="cursor-pointer"
            variant="h5"
            marginLeft={2}
            color={theme.palette.primary[theme.palette.mode]}
            onClick={() => {
              navigateTo(
                AppRoutesNavigation.Localities.position(
                  customerId as EntityId,
                  localityId as EntityId,
                  data?.position?.mooringId,
                  data?.positionId,
                ),
              )
            }}
          >
            {t('cage')} {getCageLetter(data?.position?.name)}
          </Typography>
          <Typography marginLeft={2} variant="h5" color={theme.palette.gray[theme.palette.mode]}>
            {getCageName(data?.position?.name)}
          </Typography>
          <Box paddingX={3}>
            <ArrowRightAltRounded fontSize="large" fontWeight="800" />
          </Box>
        </>
      )}
      <Typography variant="h5" className="text-primary">
        {data?.name}
        {' / '} #{data?.serialNumber}
        {data?.type.componentType === IMainComponentType.Net
          ? customProperties?.circumference
            ? ` / ${customProperties?.circumference}m`
            : ''
          : data.type.componentType === IMainComponentType.Ring
          ? customProperties?.pipediameter
            ? ` / ${customProperties?.pipediameter}m`
            : ''
          : data.type.componentType === IMainComponentType.LiceSkirt
          ? customProperties?.perimeter
            ? ` / ${customProperties?.perimeter}m`
            : ''
          : ''}
        {data?.lifetime ? ` / ${data?.lifetime} ${t('years')}` : ''}
      </Typography>
    </Box>
  )
}

export default ComponentCardView
