import { IGetCertificatesParams, useGetCertificatesByPosition } from '../../../../api/Certificates'
import { CertificatesTableGeneric } from '../../../../components/CertificatesTableGeneric'
import { CenteredSpinner } from '../../../../components/Spinner'
export interface ICertificatesTableProps {
  mainComponentId?: EntityId
  customerId?: EntityId
  positionId?: EntityId
  certificatesParams: IGetCertificatesParams
  showcertificatesHeader?: boolean
  height?: number
}
export const CertificateTable: React.FC<ICertificatesTableProps> = ({
  mainComponentId,
  customerId,
  positionId,
  showcertificatesHeader = false,
  height
}) => {

  return (
    <CertificatesTableGeneric
      mainComponentId={mainComponentId as EntityId}
      customerId={customerId ?? 0}
      positionId={positionId ?? 0}
      showCertificatesHeader={showcertificatesHeader}
      height={height}
    />
  )
}

export const CertificateTableLocality: React.FC<ICertificatesTableProps> = ({
  customerId,
  positionId,
  certificatesParams,
  showcertificatesHeader = true,
  height
}) => {
  const { data, isLoading } = useGetCertificatesByPosition(
    customerId as EntityId,
    positionId as EntityId,
    certificatesParams,
  )
  if (isLoading) return <CenteredSpinner />

  return (
    <CertificatesTableGeneric
      mainComponentId={0}
      customerId={customerId as EntityId}
      positionId={positionId as EntityId}
      showCertificatesHeader={showcertificatesHeader}
      height={height}
    />
  )
}
