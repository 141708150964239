import { Box, Typography, useTheme } from '@mui/material'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { DynamicTextTypography } from 'src/components/DynamicTextTypography'
import { Flex } from 'src/styles/flexComponent'
import { getFormattedDate } from 'src/utils'

export interface VesselDetail {
  key: number
  name: string
  certificationDate: Date
  clientName: string
  yearBuilt: number
  imageUrl: string
}

export const VesselCard: FC<VesselDetail> = ({ key, name, certificationDate, clientName, yearBuilt, imageUrl }) => {
  const theme = useTheme()
  const { t } = useTranslation('common')
  return (
    <Box
      key={key}
      width={'100%'}
      minHeight={'180px'}
      height={'100%'}
      sx={{
        backgroundImage: `url(${imageUrl})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'flex-end'}
    >
      <Flex.Column
        height={'60px'}
        width={'100%'}
        sx={{ backgroundColor: theme.palette.black[theme.palette.mode], opacity: '72%' }}
        className="pl-12-core pr-12-core"
      >
        <Flex.Row justifyContent={'space-between'} alignItems={'center'}>
          <DynamicTextTypography
            variant="h5"
            color={theme.palette.white[theme.palette.mode]}
            text={name}
            maxLength={12}
            fontWeight={700}
          />
          <Typography variant="caption" color={theme.palette.white[theme.palette.mode]} fontWeight={400}>
            {t('certification', { ns: 'product-certification' })}: {getFormattedDate(certificationDate)}
          </Typography>
        </Flex.Row>
        <Typography variant="caption" color={theme.palette.white[theme.palette.mode]} fontWeight={400}>
          {t('builtBy', { ns: 'common' })} {clientName} | {yearBuilt}
        </Typography>
      </Flex.Column>
    </Box>
  )
}
