import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import { GridActionsCellItem, GridColumns, GridNativeColTypes } from '@mui/x-data-grid-pro'
import { MdEdit } from 'react-icons/md'
import { Delete } from '@mui/icons-material'
import GenericTable from '../../../../components/table/GenericTable'
import { ScreenTitleBar } from '../../../localities/components/ScreenTitleBar'
import {
  invalidateTaskResourceCache,
  resourceEndpoints,
  useAddResource,
  useDeleteResource,
  useUpdateResource,
} from '../../../../api/task-resource'
import { useCustomerCtx, useDialogCtx } from '../../../../hooks/context-hooks'
import ResourceFrom from './ResourceFrom'
import { getUserDetail } from '../../../../utils/getUserDetails'
import { CANNOT_ADD, SUCCESSFULLY_ADDED } from '../../../../constant'
import { toast } from 'react-toastify'

const Resources = () => {
  const { customer } = useCustomerCtx()
  const { showDialog, showConfirmDialog } = useDialogCtx()
  const [selectedId, setSelectedId] = useState(0)
  const { mutateAsync } = useAddResource(customer?.id)
  const { mutateAsync: updateMutate } = useUpdateResource(customer?.id, selectedId)
  const { mutateAsync: deleteMutate } = useDeleteResource(customer?.id)
  const { t: translate } = useTranslation(['task', 'common'])

  const onAdd = useCallback(() => {
    showDialog(ResourceFrom, {
      title: translate('add-resource'),
      opts: {
        maxWidth: 'sm',
        fullWidth: true,
      },
      componentProps: {
        onSubmit: async values => {
          await mutateAsync(values,{
            onSuccess: (data) => {
              if (data.data === SUCCESSFULLY_ADDED) {
                toast.success(translate('resourceAddedSuccessfully', { ns: 'task' }))
                invalidateTaskResourceCache.getAllResources(customer?.id)
              }
            },
            onError: (error) => {
              if (error['data'] === CANNOT_ADD) {
                toast.error(translate('cannot-add-same-resource-name'))
              }
              else{
                toast.error(translate('addingError', { ns: 'common' }))
              }
            },
          })
        },
        customerId: customer.id,
        initialValues: {},
      },
    })
  }, [customer, translate])

  const onUpdate = useCallback(
    row => {
      setSelectedId(row.id)
      showDialog(ResourceFrom, {
        title: translate('edit-resource'),
        opts: {
          maxWidth: 'sm',
          fullWidth: true,
        },
        componentProps: {
          onSubmit: async values => {
            await updateMutate(values,{
              onSuccess: () => {
                toast.success(translate('resourceUpdatedSuccessfully', { ns: 'task' }))
                invalidateTaskResourceCache.getAllResources(customer?.id)
              },
              onError: () => {
                toast.error(translate('updatingError', { ns: 'common' }))
              },
            })
        },
          customerId: customer.id,
          initialValues: { ...row },
          isUpdateForm: true,
        },
      })
    },
    [customer, translate],
  )

  const onDelete = useCallback(
    row => {
      setSelectedId(row.id)
      showConfirmDialog(
        translate('delete-resource-title'),
        translate('delete-resource-content'),
        {
          acceptText: translate('yes', { ns: 'common' }),
          cancelText: translate('no', { ns: 'common' }),
        },
        async pop => {
          await deleteMutate(row.id, {
            onSuccess: () => {
              toast.success(translate('resourceDeletedSuccessfully', { ns: 'task' }))
              invalidateTaskResourceCache.getAllResources(customer?.id)
              pop()
            },
            onError: () => {
              toast.error(translate('deletingError', { ns: 'common' }))
            },
          })
        },
      )
    },
    [customer, translate],
  )
  
  const STATIC_COLUMNS: GridColumns = useMemo(
    () => [
      {
        field: 'name',
        flex: 1,
        headerName: translate('name', { ns: 'common' }),
        cellClassName: 'font-weight-semi-bold',
      },
      {
        field: 'description',
        flex: 1,
        headerName: translate('description', { ns: 'common' }),
      },
      {
        field: 'user',
        flex: 1,
        headerName: translate('responsibleUser', { ns: 'common' }),
        renderCell: row => (row?.row?.user == null ? '' : getUserDetail(row?.row?.user)),
      },
      {
        field: 'actions',
        type: 'actions' as GridNativeColTypes,
        width: 10,
        getActions: ({ row }) => [
          <GridActionsCellItem
            key={'edit'}
            label={translate('edit', { ns: 'common' })}
            icon={<MdEdit size={24} />}
            onClick={() => onUpdate(row)}
            color="primary"
            showInMenu
          />,
          <GridActionsCellItem
            key={'delete'}
            label={translate('delete', { ns: 'common' })}
            icon={<Delete fontSize={'medium'} />}
            onClick={() => onDelete(row)}
            color="primary"
            showInMenu
          />,
        ],
      },
    ],
    [translate],
  )

  return (
    <Box className="px-4 py-2 mt-2 mb-2 bg-white shadow-md" borderRadius={2}>
      <ScreenTitleBar
        title={translate('resources')}
        addNewItemText={translate('add', { ns: 'common' })}
        onAddNewItemClick={onAdd}
      />
      <GenericTable
        queryUrl={resourceEndpoints.baseURL(customer?.id)}
        columns={STATIC_COLUMNS}
        queryParams={{ isIgnoreQueryFilters: true }}
        enableServerPagination
        enablePagination
        id={'customer-resources'}
      />
    </Box>
  )
}

export default Resources
