import { IGetDocumentsParams } from '../../../../api/document'
import { useTranslation } from 'react-i18next'
import AccordionCommon from '../../../../components/AccordionCommon'
import { IDocumentCategoryInfoDto } from '../../../../generated-types'
import { Stack, Typography, styled, useTheme } from '@mui/material'
import DocumentsTableGeneric from 'src/components/documents/DocumentsTableGeneric'
import { Flex } from 'src/styles/flexComponent'
import { DynamicTextTypography } from 'src/components/DynamicTextTypography'
import { getFormattedDate } from 'src/utils'
import { Context } from 'react'
import { MooringDropdownDto } from 'src/generated-types/mooring-drop-down-dto'

export interface IDocumentsTableProps {
  customerId: EntityId
  localityId: EntityId
  documentCategoryInfo: IDocumentCategoryInfoDto
  documentsParams: IGetDocumentsParams
  showDocumentsHeader?: boolean
  showAddDocumentButton?: boolean
  showEditDocumentButton?: boolean
  context?: React.Context<any>
  moorings?: MooringDropdownDto[]
}
export const DocumentsTableByDocumentCategory: React.FC<IDocumentsTableProps> = ({
  customerId,
  localityId,
  documentCategoryInfo,
  documentsParams,
  showDocumentsHeader = true,
  showAddDocumentButton = false,
  showEditDocumentButton = false,
  context,
  moorings,
}) => {
  const { t } = useTranslation(['document', 'common'])
  const theme = useTheme()
  return (
    <AccordionCommon
      id={documentCategoryInfo.id}
      title={
        <Flex.Row className="p-8-core" justifyContent={'space-between'} alignItems={'center'} width="100%">
          <Flex.Row gap={1} justifyContent={'center'} alignItems={'center'}>
            <DynamicTextTypography
              maxLength={25}
              variant="subtitle2"
              fontWeight={500}
              text={t(documentCategoryInfo.categoryName, { ns: 'document' })}
            />
            <DocumentCount hasExpired={documentCategoryInfo.hasExpiredDocuments || false}  data-cy='DocumentCount'  data-value={documentCategoryInfo.documentCount || 0}>
              {documentCategoryInfo.documentCount || 0}
            </DocumentCount>
          </Flex.Row>
          <Typography
            variant="body2"
            fontWeight={500}
            color={
              documentCategoryInfo.hasExpiredDocuments
                ? theme.palette.errorMain[theme.palette.mode]
                : theme.palette.primaryMain[theme.palette.mode]
            }
            data-cy='FirstDueDate' data-value={documentCategoryInfo.hasExpiredDocuments} >
            {documentCategoryInfo.firstDueDate &&
              `${t('first-due-date', { ns: 'document' })}: ${getFormattedDate(documentCategoryInfo.firstDueDate)}`}
          </Typography>
        </Flex.Row>
      }
    >
      <DocumentsTableGeneric
        customerId={customerId}
        localityId={localityId}
        documentsParams={documentsParams}
        showAddDocumentButton={showAddDocumentButton}
        showDocumentsHeader={showDocumentsHeader}
        showEditDocumentButton={showEditDocumentButton}
        pageSize={documentCategoryInfo.documentCount || 0}
        autoHeight
        isViewAllRecords
        context={context as Context<any>}
        moorings={moorings}
      />
    </AccordionCommon>
  )
}

//styled
export const DocumentCount = styled(Stack) <{ hasExpired: boolean }>`
  min-width:24px;
  min-height:18px;
  justify-content:center;
  align-items: center;
  border-radius: 2px;
  color:${props => props.theme.palette.secondaryLight[props.theme.palette.mode]};
  background-color:${props => props.hasExpired ? props.theme.palette.errorLight[props.theme.palette.mode] : props.theme.palette.warningLight[props.theme.palette.mode]};
`