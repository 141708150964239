import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { invalidateSupplierCache, useAddAdminSupplier } from '../../../api/supplier'
import { showDialog, ShowDialogProps } from '../../../show-dialog'
import { ScreenTitleBar } from '../../localities/components/ScreenTitleBar'
import { SupplierForm } from './SupplierForm'
import { SupplierTable } from './SupplierTable'
import { ISupplier } from '../../../generated-types'
import { IApiResult } from '../../../generated-types/api-result'
import { toast } from 'react-toastify'

const CreateSupplierDialog: React.FC<ShowDialogProps> = ({ pop }) => {
  const { t: translate } = useTranslation(['supplier', 'common'])
  const { mutateAsync } = useAddAdminSupplier()

  const onSubmit = async form => {
    await mutateAsync(form)
      .then(({ data }: { data: IApiResult<ISupplier> }) => {
        toast.success(translate(data.message, { ns: 'supplier' }))
        invalidateSupplierCache.useGetSuppliers()
        pop(true)
      })
      .catch(({ data }: { data: string }) => {
        toast.error(translate(data, { ns: 'supplier' }))
      })
  }

  return (
    <SupplierForm
      isSuperAdmin={true}
      title={translate('addSupplier', { ns: 'supplier' })}
      onSubmit={onSubmit}
      onCancel={pop}
    />
  )
}

export const SupplierContainer = () => {
  const { t: translate } = useTranslation(['supplier', 'common'])

  const onAddSupplier = async () => {
    const added = await showDialog(CreateSupplierDialog)
    if (!added) return
  }

  return (
    <>
      <Box className="mb-2 bg-white shadow-md p-3" borderRadius={2}>
        <ScreenTitleBar
          title={translate('suppliers', { ns: 'supplier' })}
          addNewItemText={translate('add', { ns: 'common' })}
          onAddNewItemClick={onAddSupplier}
        />
        <SupplierTable isSuperAdmin={true} />
      </Box>
    </>
  )
}
