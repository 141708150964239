import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import { z } from 'zod'
import { AppForm, FormSubmitButton, FormTextField, AppFormProps, FormSelectField } from '../../../components/FormCore'
import { useZodForm } from '../../../hooks/zod-form'
import { CancelButton } from '../../../components/CancelButton'
import { LanguageSpecificTextField } from '../../../components/LanguageSpecificTextField'
import { ControllerTypes, DataTypes } from '../../../enums'
import { useEffect, useState } from 'react'
import { DropdownOptionsField } from './DropdownOptionsField'

export const CustomPropertyForm: React.FC<AppFormProps> = ({ onSubmit, onCancel, initialValues }) => {
  const { t: translate } = useTranslation(['custom-property', 'common'])
  const [controllerType, setControllerType] = useState(initialValues?.id ? initialValues['controllerType'] : null)
  const [dropdownOptions, setDropdownOptions] = useState<string[]>([])
  const [isFirstTime, setIsFirstTime] = useState(true)

  const schema = z.object({
    label_en: z.string().min(1),
    label_no: z.string().min(1),
    dataType: z.number(),
    controllerType: z.number(),
    dropdownOptions: z.string().min(1).or(z.null()).optional(),
    customerId: z
      .number()
      .or(z.null())
      .or(z.string())
      .transform(value => {
        if (typeof value === 'string') return parseInt(value)
        if (typeof value === 'number') return value
        return null
      })
      .optional(),
  })

  const form = useZodForm(schema, {
    defaultValues: initialValues,
  })

  const isUpdateForm = initialValues?.id

  const handleSubmit = async form => {
    const { label_en, label_no } = form
    form['label'] = JSON.stringify({ en: label_en, no: label_no })
    if (controllerType === ControllerTypes.Dropdown) {
      setIsFirstTime(false)
      form['dropdownOptions'] = JSON.stringify(dropdownOptions)
    } else {
      form['dropdownOptions'] = ' '
    }
    if (
      (controllerType === ControllerTypes.Dropdown && dropdownOptions.length !== 0) ||
      controllerType === ControllerTypes.NumberTextBox ||
      controllerType === ControllerTypes.TextBox
    ) {
      onSubmit(form)
    }
  }

  useEffect(() => {
    if (controllerType === ControllerTypes.Dropdown) form['dataType'] = DataTypes.String
    form['dropdownOptions'] = JSON.stringify(dropdownOptions)
  }, [controllerType])

  const dataTypes = Object.keys(DataTypes)
    .filter(v => isNaN(Number(v)))
    .map(t => DataTypes[t])
  const controllerTypes = Object.keys(ControllerTypes)
    .filter(v => isNaN(Number(v)))
    .map(t => ControllerTypes[t])

  useEffect(() => {
    if (initialValues?.id) {
      setControllerType(initialValues['controllerType'])
      if (controllerType === ControllerTypes.Dropdown) {
        setDropdownOptions(JSON.parse(initialValues['dropdownOptions']))
      } else {
        form['dropdownOptions'] = JSON.stringify(dropdownOptions)
      }
    }
  }, [])

  return (
    <AppForm form={form} onSubmit={handleSubmit} hasInitialValues={initialValues}>
      {isUpdateForm && <FormTextField name="id" type="hidden" />}
      {<FormTextField name="customerId" type="hidden" />}
      <Box py={1}>
        <LanguageSpecificTextField
          data={initialValues?.label || '{}'}
          label={translate('label', { ns: 'custom-property' })}
          form={form}
        />
      </Box>
      <Box py={1}>
        <FormSelectField
          name="controllerType"
          data={controllerTypes}
          label={translate('controllerType')}
          getOptionLabel={option => translate(ControllerTypes[option], { ns: 'custom-property' })}
          onChange={(e, value) => {
            setControllerType(value)
            form.setValue('controllerType', value)
            form.setValue('dropdownOptions', JSON.stringify(dropdownOptions))
            if (value === ControllerTypes.Dropdown) form.setValue('dataType', DataTypes.String)
          }}
          renderOption={(props: any, option) => (
            <Box {...props} key={option}>
              {translate(ControllerTypes[option], { ns: 'custom-property' })}
            </Box>
          )}
          defaultValue={controllerType}
        />
      </Box>
      {(controllerType === ControllerTypes.TextBox || controllerType === ControllerTypes.NumberTextBox) && (
        <Box py={1}>
          <FormSelectField
            name="dataType"
            data={dataTypes}
            label={translate('dataType')}
            getOptionLabel={option => DataTypes[option]}
            renderOption={(props: any, option) => (
              <Box {...props} key={option}>
                {DataTypes[option]}
              </Box>
            )}
            defaultValue={null}
          />
        </Box>
      )}
      {controllerType === ControllerTypes.Dropdown && (
        <DropdownOptionsField dropdownOptions={dropdownOptions} setDropdownOptions={setDropdownOptions} isFirstTime = {isFirstTime}/>
      )}

      <Box className="flex gap-4 pt-4 justify-end">
        <CancelButton onClick={onCancel}>{translate('cancel', { ns: 'common' })}</CancelButton>
        <FormSubmitButton
          createText={isUpdateForm ? translate('update', { ns: 'common' }) : translate('add', { ns: 'common' })}
        />
      </Box>
    </AppForm>
  )
}
