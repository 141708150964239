import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import Button from '@mui/material/Button'
import { AppForm, AppFormProps } from '../../components/FormCore'
import { useZodForm } from '../../hooks/zod-form'
import { useTranslation } from 'react-i18next'
import { IDeviationType } from '../../generated-types/deviation-type'
import { FC, ReactNode, useEffect, useState } from 'react'
import { IComponent, IMooring, IPosition, IPositionType } from '../../generated-types'
import { CancelButton } from '../../components/CancelButton'
import { IDeviationFormWizardType, IDeviationStepperFormStep } from '../../generated-types/form-wizard'
import { IFormType } from '../../generated-types/form-type'
import { IMainComponent } from '../../generated-types/main-component'
import DeviationFinalForm, { useDeviationStyles } from './DeviationFinalForm'
import RenderStepContent from './RenderStepContent'
import { GenericDialogLayout } from '../../components/GenericDialogLayout'
import { Stack, Typography } from '@mui/material'
import { MainButton } from '../../components/MainButton'
import { deviationSchema } from './deviationSchema'
import { getTranslatedText } from '../../utils/translations'
import { useGetLocality } from '../../api/localities'
import { Padding } from 'src/styles/paddingComponent'
import { DetailContainer, StepLabelStyled } from './DeviationStepperForm.style'
import { GlobalStyled } from 'src/styles/globalPaddingStyles'
import { ILocalityFrequency } from 'src/generated-types/locality-frequency'

export interface IDeviationStepperFormProps extends AppFormProps {
  customerId?: EntityId
  localityId?: EntityId
  mooring?: IMooring
  positionId?: EntityId
  component?: IComponent
  mainComponent?: IMainComponent
  position?: IPosition
  localityFrequencyIds?: Array<EntityId>
  isDisableWizard?: boolean
  type: IDeviationType
  formWizard?: IDeviationFormWizardType
  formType?: IFormType
  form?: any
  title: string
  isLoading: boolean
  scheduledTaskId?: EntityId
  service?: ILocalityFrequency
}

export const DeviationStepperForm: FC<IDeviationStepperFormProps> = ({
  onSubmit,
  onCancel,
  customerId,
  localityId,
  mooring,
  positionId,
  component,
  mainComponent,
  position,
  localityFrequencyIds,
  isDisableWizard,
  type,
  initialValues,
  formWizard = IDeviationFormWizardType.Common,
  formType = IFormType.Create,
  form,
  title,
  isLoading,
  scheduledTaskId,
  service,
}) => {
  const { data } = useGetLocality(customerId as EntityId, localityId as EntityId)
  const classes = useDeviationStyles()
  const { t: translate } = useTranslation(['deviation', 'common', 'task'])
  const [disabled, setDisabled] = useState(false)
  const [stepHistory, setStepHistory] = useState([IDeviationStepperFormStep.Step_1])

  const defaultForm = useZodForm(deviationSchema, {
    defaultValues: {
      customerId,
      localityId,
      mooringId: mooring?.id,
      positionId,
      localityFrequencyIds,
      scheduledTaskId,
    },
  })

  const commonDeviationSteps = [
    translate('selectPositionTask'),
    translate('selectComponentResource'),
    translate('fillDeviationForm'),
  ]

  const mooringDeviationSteps = [
    translate('selectPositionTask'),
    translate('selectComponentResource'),
    translate('fillDeviationForm'),
  ]

  const positionDeviationSteps = [translate('selectComponentResource'), translate('fillDeviationForm')]
  const localityServiceDeviationSteps = [translate('selectPositionTask'),translate('selectComponentResource'), translate('fillDeviationForm')]

  const steps =
    formWizard == IDeviationFormWizardType.Common
      ? commonDeviationSteps
      : formWizard == IDeviationFormWizardType.Component
      ? localityServiceDeviationSteps
      : formWizard == IDeviationFormWizardType.Position
      ? positionDeviationSteps
      : mooringDeviationSteps

  const [activeStep, setActiveStep] = useState(
    isDisableWizard
      ? IDeviationStepperFormStep.Step_5
      : formWizard == IDeviationFormWizardType.Component
      ? IDeviationStepperFormStep.Step_4
      : IDeviationStepperFormStep.Step_1,
  )

  const addValueToHistory = (value) => {
    setStepHistory(stepsHistory => {
      const stepHistoryArray = stepsHistory
      if (stepHistoryArray[stepHistoryArray.length - 1] != value) {
        stepHistoryArray.push(value)
        return stepHistoryArray
      } else {
        return stepHistoryArray
      }
    })
  }

  const handleNext = () => {
    addValueToHistory(activeStep)
    if (formWizard == IDeviationFormWizardType.Common && defaultForm.getValues('type') == IDeviationType.Position) {
      setActiveStep(prevActiveStep => (prevActiveStep == 0 ? prevActiveStep + 2 : prevActiveStep + 1))
    } else if (
      (formWizard == IDeviationFormWizardType.Common &&
        (defaultForm.getValues('type') == IDeviationType.Equipment ||
          defaultForm.getValues('type') == IDeviationType.MainComponent)) ||
      formWizard == IDeviationFormWizardType.Position
    ) {
      setActiveStep(prevActiveStep => (prevActiveStep == 0 ? prevActiveStep + 3 : prevActiveStep + 1))
    } else {
      setActiveStep(prevActiveStep => prevActiveStep + 1)
    }
  }

  const handleBack = () => {
    setStepHistory(stepHistory => {
      const stepHistoryArray = stepHistory
      const lastStep = stepHistoryArray.pop()
      if (lastStep) {
        setActiveStep(lastStep)
      } else {
        setActiveStep(IDeviationStepperFormStep.Step_1)
      }
      return stepHistoryArray
    })
  }

  useEffect(() => {
    defaultForm.reset({
      ...defaultForm.getValues(),
      position,
      mooring,
      component,
      mainComponent,
      type,
      locality: data,
      localityFrequencyIds: localityFrequencyIds as EntityId[],
    })
  }, [position, mooring, component, mainComponent, type, data])

  return (
    <GenericDialogLayout
      title={title}
      pop={onCancel}
      sx={{ p: 2 }}
      topActions={
        isDisableWizard ||
        (!(formWizard == IDeviationFormWizardType.Position) && activeStep == IDeviationStepperFormStep.Step_1)
          ? undefined
          : [
              <Padding.ph16 key="stepper-header" style={{ width: '100%' }}>
                <Stepper activeStep={activeStep - 2} sx={{ paddingTop: 1, width: '100%' }}>
                  {steps.map((label, index) => {
                    const stepProps: { completed?: boolean } = {}
                    const labelProps: {
                      optional?: ReactNode
                    } = {}
                    return (
                      <Step key={index} {...stepProps}>
                        <StepLabelStyled {...labelProps}>{activeStep == index + 2 ? label : label}</StepLabelStyled>
                      </Step>
                    )
                  })}
                </Stepper>
              </Padding.ph16>,
            ]
      }
      actions={[
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          key={'stepper-form-actions'}
          width={'100%'}
        >
          {!isDisableWizard &&
            activeStep != IDeviationStepperFormStep.Step_1 &&
            !(formWizard == IDeviationFormWizardType.Component && activeStep == IDeviationStepperFormStep.Step_4) && (
              <Button color="inherit" onClick={handleBack} sx={{ mr: 1 }}>
                {translate('back', { ns: 'common' })}
              </Button>
            )}
          <Box sx={{ flex: '1 1 auto' }} />
          {activeStep == IDeviationStepperFormStep.Step_5 ||
          isDisableWizard ||
          (formWizard == IDeviationFormWizardType.Position && activeStep == IDeviationStepperFormStep.Step_4) ? (
            <>
              <CancelButton onClick={onCancel}>{translate('cancel', { ns: 'common' })}</CancelButton>
              <MainButton
                variant="contained"
                onClick={() => {
                  const submit = (form ?? defaultForm).handleSubmit(onSubmit)
                  submit()
                }}
                loading={isLoading}
                data-cy="Submit"
              >
                {formType == IFormType.Update
                  ? translate('update', { ns: 'common' })
                  : translate('save', { ns: 'common' })}
              </MainButton>
            </>
          ) : (
            activeStep != IDeviationStepperFormStep.Step_1 && (
              <>
                <CancelButton onClick={onCancel}>{translate('cancel', { ns: 'common' })}</CancelButton>
                <Button disabled={disabled} variant="contained" onClick={handleNext}>
                  {translate('next', { ns: 'common' })}
                </Button>{' '}
              </>
            )
          )}
        </Stack>,
      ]}
    >
      <GlobalStyled>
        <Padding.ph16>
          <AppForm form={form ? form : defaultForm} onSubmit={onSubmit} hasInitialValues={initialValues}>
            {activeStep != 0 &&
              (defaultForm.getValues('locality')?.id ||
                defaultForm.getValues('mooring')?.id ||
                defaultForm.getValues('position')?.id ||
                defaultForm.getValues('component')?.id ||
                defaultForm.getValues('mainComponent')?.id ||
                localityFrequencyIds) &&
              formType == IFormType.Create && (
                <Box className={classes.detail_box}>
                  <DetailContainer direction={'row'} spacing={2} alignItems={'center'}>
                    <Stack className="pl-24-core pt-12-core pb-12-core">
                      {activeStep == IDeviationStepperFormStep.Step_3 &&
                      !(formWizard == IDeviationFormWizardType.Component) ? (
                        <Typography variant="subtitle1">{translate('mooring', { ns: 'common' })}</Typography>
                      ) : (
                        <>
                          {localityFrequencyIds && (
                            <Typography variant="h6">{translate('locality-frequency', { ns: 'common' })}</Typography>
                          )}
                          {defaultForm.getValues('scheduledTaskId') && (
                            <Typography variant="h6">{`${translate('task-deviation', {
                              ns: 'task',
                            })}`}</Typography>
                          )}
                          {(defaultForm.getValues('position')?.id || defaultForm.getValues('mooring')?.id) && (
                            <Typography>
                              {translate('mooring', { ns: 'common' })}
                              {` - ${
                                defaultForm.getValues('mooring')?.name ??
                                defaultForm.getValues('position')?.mooringName ??
                                translate('n/a', { ns: 'common' })
                              }`}
                            </Typography>
                          )}
                          {defaultForm.getValues('position')?.id &&
                            formWizard == IDeviationFormWizardType.Component &&
                            service && (
                              <Typography>
                                {translate('service', { ns: 'deviation' })}
                                {` : ${service?.name} - ${
                                  service?.frequency?.title ?? translate('n/a', { ns: 'common' })
                                }`}
                              </Typography>
                            )}
                          {defaultForm.getValues('position')?.id && (
                            <Typography>
                              {translate('position', { ns: 'common' })}:
                              {` ${translate(IPositionType[defaultForm.getValues('position').type])} - ${
                                defaultForm.getValues('position')?.name ?? translate('n/a', { ns: 'common' })
                              }`}
                            </Typography>
                          )}
                          {defaultForm.getValues('component')?.id && (
                            <Typography>
                              {translate('component', { ns: 'common' })}:
                              {` ${
                                defaultForm.getValues('component')?.productId ?? translate('n/a', { ns: 'common' })
                              } - ${
                                defaultForm.getValues('component').productDescription ??
                                translate('n/a', { ns: 'common' })
                              }`}
                            </Typography>
                          )}
                          {defaultForm.getValues('mainComponent')?.id && (
                            <Typography>
                              {translate('main-component', { ns: 'common' })}:
                              {` ${getTranslatedText(defaultForm.getValues('mainComponent')?.type?.name)} ${
                                defaultForm.getValues('mainComponent')?.name ?? translate('n/a', { ns: 'common' })
                              }`}
                            </Typography>
                          )}
                        </>
                      )}
                    </Stack>
                  </DetailContainer>
                </Box>
              )}
            {isDisableWizard ? (
              <DeviationFinalForm
                customerId={customerId as EntityId}
                localityId={localityId}
                formType={formType}
                scheduledTaskId={scheduledTaskId}
              />
            ) : (
              <RenderStepContent
                step={activeStep}
                setActiveStep={setActiveStep}
                customerId={customerId as EntityId}
                localityId={localityId as EntityId}
                formWizard={formWizard}
                setDisabled={setDisabled}
                handleNext={handleNext}
              />
            )}
          </AppForm>
        </Padding.ph16>
      </GlobalStyled>
    </GenericDialogLayout>
  )
}
