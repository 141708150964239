import { Box } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { invalidateMainComponentTypeCache, useAddMainComponentTypeAdmin } from '../../../api/main-component-type'
import { CenteredSpinner } from '../../../components/Spinner'
import { ICustomProperty } from '../../../generated-types/customProperty'
import { ShowDialogProps } from '../../../show-dialog'
import { MainComponentTypeForm } from '../../main-component-type/MainComponentTypeForm'
import { useEffect } from 'react'
import { toast } from 'react-toastify'
import { ALREADY_EXISTS } from '../../../constant'
import { IPrivilegeLevel } from '../../../enums'
import { useGetCustomPropertiesByAdmin } from '../../../api/custom-property'
import { GenericDialogLayout } from '../../../components/GenericDialogLayout'

export interface AdminCreateMainComponentTypeProps extends ShowDialogProps {
  privilegeLevel?: IPrivilegeLevel
}
export const CreateMainComponentTypeDialogAdmin: React.FC<AdminCreateMainComponentTypeProps> = ({
  pop,
  privilegeLevel,
}) => {
  const { t: translate } = useTranslation(['main-component-type', 'common'])
  const { mutateAsync, error, isLoading: isLoadingAdd } = useAddMainComponentTypeAdmin()
  useEffect(() => {
    if (error && error['data'] == ALREADY_EXISTS) {
      toast(translate('already-exists'), { type: 'error' })
    }
  }, [error])
  const onSubmit = async form => {
    await mutateAsync(form,{
      onSuccess: () => {
        toast(translate('successfully-added'), { type: 'success' })
        invalidateMainComponentTypeCache.useGetMainComponentTypesAdmin()
        pop(true)
      },
      onError: () => {
        toast.error(translate('addingError', { ns: 'common' }))
      },
    })  
  }

  const { data, isLoading } = useGetCustomPropertiesByAdmin()
  if (isLoading) return <CenteredSpinner />

  return (
    <Box>
      <GenericDialogLayout title={translate('addMainComponentType')} pop={pop}>
        <MainComponentTypeForm
          onSubmit={onSubmit}
          onCancel={() => pop()}
          userCustomProperties={(data?.items as ICustomProperty[]) ?? []}
          privilegeLevel={privilegeLevel}
          isLoading={isLoadingAdd}
        />
      </GenericDialogLayout>
    </Box>
  )
}
