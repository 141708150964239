export enum UserRole {
  Admin = 'Admin',
  Regular = 'Regular',
}

export enum CustomerAccessLevel {
  Admin = 'customer_admin',
  User = 'customer_user',
}

export enum FileType {
  Image = 'image',
  File = 'file',
}

export enum TaskResourceIntervals {
  EveryDay = 0,
  EveryMonday = 1,
  FirstWeek = 2,
  SecondWeek = 3,
  Custom = 4,
}

export enum FormFieldType {
  textField = 1,
  numberField = 2,
  checkBox = 3,
  datePicker = 4,
  textArea = 5,
  dateTime = 6,
  fileUpload = 7,
  timePicker = 8,
  radio = 9,
  checkBoxList = 10,
  selectField = 11,
  title = 12,
}

export enum DialogContextType {
  localityContext = 'locality-context-dialog',
  serviceStationContext = 'service-station-context-dialog',
  customerAdminContext = 'customer-admin-context-dialog',
}

export enum FacilityTypes {
  client = 0,
  locality = 1,
  localityGroup = 2,
  serviceStation = 3,
  serviceStationGroup = 4
}

export enum RoleTypes {
  Client = 0,
  Locality = 1,
  ServiceStation = 2,
}

export enum MainComponentStatus {
  Active = 0,
  Incoming = 1,
  InService = 2,
  InStock = 3,
  Outgoing = 4,
  NewlyRegistered = 5,
}

export enum ServiceStationType {
  ServiceStation = 0,
  Storage = 1,
  Service = 2,
}

export enum DataTypes {
  String,
  Integer,
}

export enum ControllerTypes {
  TextBox,
  NumberTextBox,
  Dropdown,
}

export enum TaskTypes {
  Once,
  Repeatable,
}

export enum TaskIntervals {
  Daily,
  Weekdays,
  Weekly,
  BiWeekly,
  Every3Weeks,
  Monthly,
  Bimonthly,
  Every3months,
  Every4months,
  Every6months,
  Yearly,
}

export enum Months {
  January,
  February,
  March,
  April,
  May,
  June,
  July,
  August,
  September,
  October,
  November,
  December,
}

export enum IMainComponentType {
  Net,
  Ring,
  LiceSkirt, // need to remove this and refactor the code
  Custom,
}

export enum IMainComponentStatus {
  Active,
  InService,
  InStorage,
}

export enum MaintenanceType {
  Deviation,
  Document,
  Service,
  WorkAssignment,
  Task
}

export enum IPrivilegeLevel {
  Customer,
  Admin,
  SuperAdmin,
}

export enum MooringWizardStep {
  MooringForm = 0,
  MooringComponentTemplates = 1,
  AssignTemplateToPositionDialog = 2,
  GenerateComponents = 3,
  PositionDetail = 4,
  Completed = 5,
}

export enum ReportType {
  UTLEGGSRAPPORT = 0,
  FORANKRINGINSPEKSJONSRAPPORT = 1,
  MANEDERSKONTROLL = 2,
  ARSKONTROLL = 3,
}

export enum CoordinateMethod {
  ROV = 0,
  Diver = 1,
  Vessel = 2,
  HandheldGPS = 3,
}

export enum CoordinateSource {
  Manual = 0,
  Olex = 1,
}

export enum BottomFasten {
  Anchor = 0,
  Bolt = 1,
}

export enum Sediment {
  Mountain = 0,
  Clay = 1,
  Sandy = 2,
  SandAndStone = 3,
}

export enum ConfirmationStatus {
  Null = -1,
  Yes = 1,
  No = 0,
}

export enum DocumentViewMode {
  Category,
  List,
}

export enum MooringViewMode {
  DashBoard,
  List,
}

export enum FileType {
  PDF = 'application/pdf',
  DOC = 'doc',
}

export enum PositionViewMode {
  Map,
  List,
}

export enum MapViewMode {
  Map,
  TechnicalDrawing,
}

export enum CertificationValidationPeriod{
  NA = -1,
  LOW = 8.3,
  MEDIUM = 49,
  HIGH = 100,
}

export enum ServiceCardInfoType {
  future,
  previous
}

export enum DocumentExpirationType {
  None,
  Expired,
  Expiring,
  NoneExpired,
}

export enum BaseActivityType {
  RemoveFromCage = 3,
  AttachedToCage = 4,
  SendToServiceStation = 5,
  SendToLocality = 6,
  AddDeviation = 7,
  AddDocument = 8,
}

export enum CustomActivityType {
  Recorded = 0,
  MovingFish = 1,
  DeLiceRemoval = 2,
}

export const ActivityType = {
  ...BaseActivityType,
  ...CustomActivityType,
}

export enum ActivityLogOriginKey {
  ServiceStation,
  Cage,
  Locality,
}

export enum ActivityLogReferenceKey {
  Deviation,
  Document,
  Task,
  ServiceStation,
  Cage,
  Locality,
}
