import { FC } from 'react'
import { IDocument } from 'src/generated-types/document'
import { Box, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useUserGetDocumentCategories } from 'src/api/document-category'
import { useGetFile } from 'src/api/file'
import { useGetAllMooringsByLocalityId } from 'src/api/moorings'
import BasicDatePicker from 'src/components/DateTimePicker'
import { FormFileCoreUpload, FormSelectField, FormTextField } from 'src/components/FormCore'
import { IDocumentCategory } from 'src/generated-types'
import { MooringDropdownDto } from 'src/generated-types/mooring-drop-down-dto'

interface DocumentEditSectionProps {
  customerId: EntityId
  localityId: EntityId
  document: IDocument
}

export const DocumentEditSection: FC<DocumentEditSectionProps> = ({ customerId, localityId, document }) => {
  const { t } = useTranslation(['document', 'common'])

  const documentTypes = useUserGetDocumentCategories()
  const { data: moorings } = useGetAllMooringsByLocalityId(customerId as EntityId, localityId as EntityId)

  const { data: file } = useGetFile(document?.fileId)

  return (
    <>
      <Box mb={2} mt={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} data-cy="docTitle">
            <FormTextField name="title" label={t('title', { ns: 'common' })} fullWidth />
          </Grid>
          <Grid item xs={12} sm={4} data-cy="docType">
            <FormSelectField
              name="documentCategoryId"
              data={(documentTypes?.data?.items as IDocumentCategory[]) ?? []}
              label={t('type', { ns: 'common' })}
              getOptionLabel={option => t(option.categoryName)}
              renderOption={(props: any, option) => (
                <Box {...props} key={option}>
                  {t(option.categoryName)}
                </Box>
              )}
              isOptionEqualToValue={(o, v) => o === v}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <BasicDatePicker label={t('dueDate', { ns: 'common' })} name="dueDate" />
          </Grid>
        </Grid>
      </Box>
      <Box mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <FormTextField name="version" label={t('version', { ns: 'global-documents' })} fullWidth />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormSelectField
              name="mooringId"
              data={moorings as MooringDropdownDto[]}
              label={t('mooring', { ns: 'common' })}
              getOptionLabel={option => t(option.name)}
              renderOption={(props: any, option) => (
                <Box {...props} key={option}>
                  {t(option.name)}
                </Box>
              )}
              isOptionEqualToValue={(o, v) => o === v}
            />
          </Grid>
        </Grid>
      </Box>
      <Box mb={2}>
        <FormTextField name="notes" label={t('notes', { ns: 'common' })} fullWidth multiline rows={4} />
      </Box>
      <Box mb={2}>
        <FormFileCoreUpload
          required
          label={t('fileName', { ns: 'common' })}
          name="fileId"
          mapFileKey="id"
          initialFiles={file}
          isDeletable={false}
          height="150px"
        />
      </Box>
    </>
  )
}
