import { Box } from '@mui/material'
import { CenteredSpinner } from '../components/Spinner'
import { GenericDialogLayout } from '../components/GenericDialogLayout'
import { Padding } from 'src/styles/paddingComponent'

export interface IFormDialogProps {
  title: string
  form: React.ReactNode
  isLoading?: boolean
  pop?: () => void
  actions?: React.ReactNode[]
  removedCloseButton?: boolean
}
export const FormDialog: React.FC<IFormDialogProps> = ({
  title,
  form,
  isLoading,
  pop,
  actions,
  removedCloseButton,
}) => {
  return (
    <Padding.p16>
      <GenericDialogLayout title={title} pop={pop} actions={actions} removedCloseButton={removedCloseButton}>
        {isLoading && (
          <Box
            width={'100%'}
            height={'100%'}
            position={'absolute'}
            style={{ opacity: 0.8, backgroundColor: 'white', zIndex: 500 }}
          >
            <CenteredSpinner />
          </Box>
        )}
        {form}
      </GenericDialogLayout>
    </Padding.p16>
  )
}
