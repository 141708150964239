import { useTranslation } from 'react-i18next'
import { z } from 'zod'
import { useZodForm } from '../../../hooks/zod-form'
import { AppForm, FormSubmitButton, FormTextField } from '../../../components/FormCore'
import { Box, Button } from '@mui/material'

const TimeReportTypeFrom = ({ initialValues, onSubmit, pop, isUpdateForm = false }) => {
  const { t: translate } = useTranslation(['time-report-types', 'common'])

  const schema = z.object({
    typeName: z
      .string()
      .min(1, translate('nameIsRequired', { ns: 'common' }))
      .max(200, translate('exceededMaximunCharactorLength', { ns: 'common' })),
  })

  const form = useZodForm(schema, { defaultValues: initialValues })

  const handleSubmit = async form => {
    if (isUpdateForm) {
      form.id = initialValues.id
    }
    onSubmit(form)
    pop()
  }

  return (
    <>
      <AppForm form={form} onSubmit={handleSubmit} hasInitialValues={isUpdateForm}>
        <Box py={1}>
          <FormTextField
            name="typeName"
            label={translate('typeName', { ns: 'time-report-types' })}
            defaultValue={initialValues?.typeName}
          />
        </Box>
        <Box display={'flex'} flexDirection={'row'} justifyContent={'end'}>
          <FormSubmitButton createText={translate('add', { ns: 'common' })} />
          <Button key="locality-form-cancel" onClick={pop}>
            {translate('cancel', { ns: 'common' })}
          </Button>
        </Box>
      </AppForm>
    </>
  )
}

export default TimeReportTypeFrom
