import { useMemo } from 'react'
import { deviationsEndpoints, invalidateDeviation, useDeleteDeviationAdmin } from '../../../api/deviation'
import { GridActionsCellItem, GridColumns, GridNativeColTypes } from '@mui/x-data-grid-pro'
import { MdDelete, MdEdit } from 'react-icons/md'
import { static_column } from './DeviationsTable'
import { useTranslation } from 'react-i18next'
import GenericTable from '../../../components/table/GenericTable'
import { IDeviation } from '../../../generated-types/deviation'
import UpdateDeviationAdminDialog from '../../../dialogs/deviation/UpdateDeviationAdminDialog'
import { useNavigate } from 'react-router-dom'
import { AppRoutesNavigation } from '../../../app-routes'
import { toast } from 'react-toastify'
import { useDialogCtx } from 'src/hooks/context-hooks'
import { useTheme } from '@mui/material'

export const AdminDeviationTable: React.FC = () => {
  const { t } = useTranslation(['deviation', 'common'])
  const navigate = useNavigate()
  const theme = useTheme()
  const { showDialog, showConfirmDialog } = useDialogCtx()

  const mutation = useDeleteDeviationAdmin()
  const onDelete = async (id: EntityId) => {
    showConfirmDialog(
      t('deleteDeviation'),
      t('areYouSureDeleteDeviation'),
      {
        acceptText: t('yes', { ns: 'common' }),
        cancelText: t('no', { ns: 'common' }),
      },
      async pop => {
        await mutation.mutateAsync(id).then(() => {
          toast.success(t('deviationDeletedSuccessfully'))
          invalidateDeviation.getDeviationsAdmin()
          pop()
        })
      },
    )
  }

  const updateDeviation = async (deviation: IDeviation) => {
    const updated = await showDialog(UpdateDeviationAdminDialog, {
      componentProps: {
        deviation: deviation,
      },
    })
    if (!updated) return
  }

  const columns: GridColumns = useMemo(
    () => [
      ...static_column(t, theme),
      {
        field: 'actions',
        headerName: t('actions', { ns: 'common' }),
        type: 'actions' as GridNativeColTypes,
        getActions: ({ id, row }) => {
          return [
            <GridActionsCellItem
              key={id}
              label={t('edit', { ns: 'common' })}
              icon={<MdEdit size={24} />}
              onClick={() => updateDeviation(row)}
              color="primary"
              showInMenu
            />,
            <GridActionsCellItem
              key={id}
              label={t('delete', { ns: 'common' })}
              icon={<MdDelete size={24} />}
              onClick={() => onDelete(id)}
              color="primary"
              showInMenu
            />,
          ]
        },
      },
    ],
    [],
  )
  return (
    <GenericTable
      id={'deviations-table-admin'}
      columns={columns}
      defaultColumnOption={columns.map(c => c.headerName as string)}
      enableToolbar={true}
      enablePagination={true}
      pageSize={10}
      pageOptions={[5, 10, 15, 20]}
      queryUrl={deviationsEndpoints.getDeviationsAdmin()}
      enableServerPagination={true}
      enableFilter={true}
      rowColor={'#e1e1e1'}
      onRowClick={({ id }) => navigate(AppRoutesNavigation.Admin.deviation(id))}
      enableQuickFilter={true}
    />
  )
}
