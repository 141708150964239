import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'
import { ShowDialogProps } from '../../../show-dialog'
import { useZodForm } from '../../../hooks/zod-form'
import { invalidateGlobalDocument, useUpdateGlobalDocument } from '../../../api/global-documents'
import { AppForm, FormCheckbox, FormSelectField, FormSubmitButton, FormTextField } from '../../../components/FormCore'
import { toast } from 'react-toastify'
import { CancelButton } from '../../../components/CancelButton'
import { IDocumentCategory } from '../../../generated-types'
import { invalidateDocumentCategoryCache, useAdminGetDocumentCategories } from '../../../api/document-category'
import { useEffect } from 'react'
import { invalidateDocument } from '../../../api/document'

export interface GlobalDocumentEditDialogProps extends ShowDialogProps {
  documentId: EntityId
  initialValues: any
  customerId?: EntityId
}

export const GlobalDocumentEditDialog: React.FC<GlobalDocumentEditDialogProps> = ({
  pop,
  documentId,
  initialValues,
  customerId,
}) => {
  const { t } = useTranslation(['global-documents', 'common'])
  const { data: documentTypes } = useAdminGetDocumentCategories()

  const schema = z.object({
    title: z.string().nonempty(t('titleIsRequired', { ns: 'common' })),
    notes: z.string().optional(),
    documentCategoryId: z
      .object({
        id: z.number(),
      })
      .transform(value => value.id),
    isActive: z.boolean().optional(),
  })

  const form = useZodForm(schema, {
    defaultValues: {
      ...initialValues,
      documentCategoryId: documentTypes?.items?.find(x => x.id === initialValues.documentCategoryId),
    },
  })

  useEffect(() => {
    form.reset({
      ...initialValues,
      documentCategoryId: documentTypes?.items?.find(x => x.id === initialValues.documentCategoryId),
    })
  }, [documentTypes, form, initialValues])

  const mutation = useUpdateGlobalDocument(documentId)

  const onSubmit = async form => {
    form.id = documentId
    await mutation.mutateAsync(form).then(
      () => {
        invalidateGlobalDocument.getDocuments()
        customerId && invalidateDocumentCategoryCache.getDocumentCategoriesWithDocumentCountByKey()
        customerId && invalidateDocument.getDocuments(customerId)
        toast.success(t('updatedSuccessfully', { ns: 'common' }))
        pop()
      },
      () => {
        toast.error(t('updatingError', { ns: 'common' }))
      },
    )
  }

  return (
    <AppForm form={form} onSubmit={onSubmit}>
      <Box mb={2}>
        <FormTextField name="title" label={t('title', { ns: 'common' })} fullWidth />
      </Box>
      <Box mb={2}>
        <FormTextField name="notes" label={t('description', { ns: 'common' })} fullWidth multiline rows={4} />
      </Box>
      <Box mb={2}>
        <FormSelectField
          name="documentCategoryId"
          data={documentTypes?.items as IDocumentCategory[]}
          label={t('category', { ns: 'common' })}
          getOptionLabel={option => option.categoryName}
          renderOption={(props: any, option) => (
            <Box {...props} key={option.id}>
              {option.categoryName}
            </Box>
          )}
          isOptionEqualToValue={(o, v) => o.id === v.id}
        />
      </Box>
      <Box mb={2}>
        <FormCheckbox name="isActive" label={t('isActive', { ns: 'common' })} defaultValue={true} />
      </Box>
      <Box className="flex gap-4 pt-4 justify-end">
        <CancelButton onClick={pop}>{t('cancel', { ns: 'common' })}</CancelButton>
        <FormSubmitButton createText={t('update', { ns: 'common' })} />
      </Box>
    </AppForm>
  )
}
