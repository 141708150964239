import { useCallback, useEffect, useState } from 'react'
import { AppForm, AppFormProps, FormSubmitButton } from '../../../../components/FormCore'
import { CenteredSpinner } from '../../../../components/Spinner'
import { useZodForm } from '../../../../hooks/zod-form'
import { useTranslation } from 'react-i18next'
import { Autocomplete, Box, MenuItem, TextField } from '@mui/material'
import { ShowDialogProps } from '../../../../show-dialog'
import { z } from 'zod'
import { CancelButton } from '../../../../components/CancelButton'
import {
  invalidateComponentGetByStatus,
  invalidateGetServiceStationById,
  invalidateServiceStationCache,
  useGetAllTypeServiceStations,
} from '../../../../api/service-station'
import { IServiceStation } from '../../../../generated-types/service-station'
import {
  invalidateMainComponentCache,
  useAssignMainComponentToServiceStation,
  useGetMainComponentById,
} from '../../../../api/main-component'
import { styled } from '@mui/system'
import { invalidateCertificateCache } from '../../../../api/Certificates'
import { invalidateCustomersServiceStationDetails } from '../../../../api/customer'
import { toast } from 'react-toastify'
import { MainComponentStatus, ServiceStationType } from '../../../../enums'
import { invalidatePositionCache } from '../../../../api/positions'
import { getServiceStationType } from '../../../../utils/function'
import { invalidateLocalityCache } from '../../../../api/localities'
import { IMainComponent } from '../../../../generated-types/main-component'
import { invalidateMooringCache } from 'src/api/moorings'
import { useNavigate } from 'react-router-dom'
import { invalidationActivityLogCache } from 'src/api/activity-log'
import { APIResultCount } from 'src/constant'

export const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  color: theme.palette.primaryMain[theme.palette.mode],
  fontWeight: 700,
}))

export const GroupItems = styled('ul')({
  padding: 0,
})

const MainComponentSchema = z.object({
  serviceStationId: z.number(),
  serviceStationType: z.number().optional(),
})

export interface MainComponentSendFormProps extends AppFormProps {
  customerId: EntityId
  localityId: EntityId
  mooringId: EntityId
  positionId: EntityId
  id?: EntityId
  renderMainComponentSelectComponent?: (form: any) => JSX.Element
}

const MainComponentSendForm: React.FC<MainComponentSendFormProps> = ({
  onSubmit,
  onCancel,
  customerId,
  initialValues,
  renderMainComponentSelectComponent,
}) => {
  const { t } = useTranslation(['position', 'common'])

  const form = useZodForm(MainComponentSchema, {
    defaultValues: { ...initialValues },
  })

  const { data, isLoading } = useGetAllTypeServiceStations(customerId)

  if (isLoading) return <CenteredSpinner />

  return (
    <AppForm form={form} onSubmit={onSubmit} hasInitialValues={initialValues}>
      {renderMainComponentSelectComponent && renderMainComponentSelectComponent(form)}
      <Box py={1}>
        <Autocomplete
          onChange={(_, value) => {
            form.setValue('serviceStationId', (value as IServiceStation).id)
            form.setValue('serviceStationType', (value as IServiceStation).type)
          }}
          options={(data?.items ?? []).sort((a, b) => b.type.toString().localeCompare(a.type.toString()))}
          groupBy={(option: any) => option.type}
          renderGroup={params => (
            <li>
              <GroupHeader>{getServiceStationType(params.group)}</GroupHeader>
              <GroupItems>{params.children}</GroupItems>
            </li>
          )}
          autoHighlight
          getOptionLabel={o => o.name}
          renderOption={(props, o) => (
            <MenuItem key={o.id} value={o.id} {...props}>
              {o.name}
            </MenuItem>
          )}
          renderInput={params => (
            <TextField
              {...params}
              label="Select a service station"
              inputProps={{
                ...params.inputProps,
              }}
            />
          )}
          isOptionEqualToValue={(option, value) => option.id === value.id}
        />
      </Box>
      <Box py={4}></Box>
      <Box className="flex justify-end gap-4 pt-4">
        <FormSubmitButton createText={t('Send', { ns: 'common' })} />
        <CancelButton onClick={onCancel}>{t('cancel', { ns: 'common' })}</CancelButton>
      </Box>
    </AppForm>
  )
}

interface MainComponentCreateProps extends ShowDialogProps {
  customerId: EntityId
  localityId: EntityId
  mooringId: EntityId
  positionId: EntityId
}

interface MainComponentUpdateProps extends MainComponentCreateProps {
  id?: EntityId
  mainComponents?: IMainComponent[]
  localityIdFromURL?: EntityId
}

export const MainComponentSendModalComponent: React.FC<MainComponentUpdateProps> = ({ id, ...props }) => {
  if (id) {
    return <MainComponentSendModalComponentWithSingleComponent id={id} {...props} />
  }
  return <MainComponentSendModalGenericComponent {...props} />
}

interface MainComponentSendModalComponentWithSingleComponentProps extends MainComponentCreateProps {
  id: EntityId
}

export const MainComponentSendModalComponentWithSingleComponent: React.FC<
  MainComponentSendModalComponentWithSingleComponentProps
> = ({ customerId, id, ...rest }) => {
  const { data: mainComponent } = useGetMainComponentById(customerId, id)
  return (
    <MainComponentSendModalGenericComponent
      mainComponent={mainComponent}
      customerId={customerId}
      {...rest}
      isMainComponentAvailable={true}
    />
  )
}

interface MainComponentSendModalGenericComponentProps extends MainComponentCreateProps {
  mainComponent?: IMainComponent
  mainComponents?: IMainComponent[]
  isMainComponentAvailable?: boolean
  localityIdFromURL?: EntityId
}

export const MainComponentSendModalGenericComponent: React.FC<MainComponentSendModalGenericComponentProps> = ({
  pop,
  customerId,
  localityId,
  mooringId,
  positionId,
  mainComponent,
  mainComponents,
  isMainComponentAvailable = false,
  localityIdFromURL,
}) => {
  const { t } = useTranslation(['position', 'common', 'main-component'])
  const [selectedMainComponent, setSelectedMainComponent] = useState(mainComponent)
  const navigate = useNavigate()

  useEffect(() => {
    if (mainComponent) {
      setSelectedMainComponent(mainComponent)
    }
  }, [mainComponent])

  useEffect(() => {
    if (mainComponents) {
      setSelectedMainComponent(mainComponents[0])
    }
  }, [mainComponents])

  const updateServiceStationComponents = useCallback(
    serviceStationId => {
      Object.keys(MainComponentStatus).filter(key =>
        invalidateComponentGetByStatus(customerId, serviceStationId, MainComponentStatus[key]),
      )
      invalidateGetServiceStationById(customerId, serviceStationId)
      invalidateMainComponentCache.getMainComponentsByComponentType(
        customerId,
        selectedMainComponent?.typeId as EntityId,
      )
      invalidateLocalityCache.getCageStatus(customerId, localityId)
      invalidateMooringCache.getGetMoorings(customerId as EntityId, localityId as EntityId, true, 0, 100000)
    },
    [customerId, selectedMainComponent],
  )

  const mutation = useAssignMainComponentToServiceStation(customerId, selectedMainComponent?.id as EntityId)
  const onSubmit = async form => {
    const serviceStationStatus =
      form.serviceStationType == ServiceStationType.Storage ? MainComponentStatus.InStock : MainComponentStatus.Incoming
    await mutation.mutateAsync(form).then(() => {
      toast(t('sendToServiceStationSuccess', { ns: 'main-component' }), { type: 'success' })
      invalidateMainComponentCache.getMainComponents(customerId, localityId, mooringId, positionId)
      invalidateCertificateCache.getCertificatesByPosition(customerId, positionId)
      invalidateCustomersServiceStationDetails()
      invalidateServiceStationCache.getServiceStations(customerId)
      invalidationActivityLogCache.getCageActivityLogs({
        page: 0,
        pageSize: APIResultCount.Max,
      })
      updateServiceStationComponents(form['serviceStationId'])
      invalidateComponentGetByStatus(customerId, form.serviceStationId, serviceStationStatus)
      invalidatePositionCache.getCagePositionDeviations(customerId, localityId, positionId)
      invalidateMainComponentCache.getMainComponentById(
        customerId as EntityId,
        (selectedMainComponent?.id as EntityId) ?? 0,
      )

      // navigate back to cage page after the main component is sent to the service station
      // we cannot show main component details page since the main component does not belong to the cage anymore.
      if (localityIdFromURL) {
        navigate(-1)
      }
      pop()
    })
  }

  const renderMainComponentSelectComponent = useCallback(form => {
    return (
      <Box py={1}>
        <Autocomplete
          onChange={(_, value) => {
            form.setValue('mainComponentId', (value as IMainComponent).id)
            setSelectedMainComponent(value as IMainComponent)
          }}
          options={mainComponents ?? []}
          autoHighlight
          getOptionLabel={o => o.name}
          renderOption={(props, o) => (
            <MenuItem key={o.id} value={o.id} {...props}>
              {o.name}
            </MenuItem>
          )}
          renderInput={params => (
            <TextField
              label={t('mainComponent', { ns: 'main-component' })}
              {...params}
              inputProps={{
                ...params.inputProps,
              }}
            />
          )}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          defaultValue={(mainComponents ?? [])[0] ?? undefined}
        />
      </Box>
    )
  }, [])

  return (
    <MainComponentSendForm
      onSubmit={onSubmit}
      onCancel={() => pop()}
      customerId={customerId}
      localityId={localityId}
      mooringId={mooringId}
      positionId={positionId}
      renderMainComponentSelectComponent={!isMainComponentAvailable ? renderMainComponentSelectComponent : undefined}
    />
  )
}
