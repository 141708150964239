import { Box, Grid, Stack, Typography } from '@mui/material'
import GenericCard from '../../../../components/GenericCard'
import { useTranslation } from 'react-i18next'
import weatherHeaderIcon from '../../../../assets/svg/weather.svg'
import { FC, useEffect, useMemo } from 'react'
import { useGetWeatherData } from '../../../../api/weather'
import { ArrowRightAlt } from '@mui/icons-material'
import { muiTheme } from '../../../../mui-theme'

type WeatherProps = {
  lat: number
  lon: number
}

export function getBackgroundImageUrl({ weatherSymbol }) {
  return `url(/weather_backgrounds/${weatherSymbol}.png),url(/weather_backgrounds/partlycloudy_day.png)`
}

const WeatherCard: FC<WeatherProps> = props => {
  const { t: translate } = useTranslation(['weather'])
  const { lat, lon } = props
  const { data, refetch } = useGetWeatherData(lat, lon)

  useEffect(() => {
    FetchData()
  }, [lat, lon])

  useEffect(() => {
    const intervalId = setInterval(FetchData, 5 * 60 * 1000)
    return () => clearInterval(intervalId)
  }, [])

  const FetchData = () => {
    refetch()
  }

  const weather = useMemo(() => data?.properties?.timeseries[0]?.data, [data])

  return (
    <GenericCard
      title={translate('weather')}
      headerIcon={
        <Box>
          <img src={weatherHeaderIcon} />
        </Box>
      }
      actions={[]}
      backgroundImage={getBackgroundImageUrl({ weatherSymbol: weather?.next_1_hours?.summary?.symbol_code })}
    >
      <Grid container xs={12} sx={{ color: muiTheme.palette.white.main }}>
        <Grid item xs={6} display={'flex'} justifyContent={'center'} padding={4} pt={5}>
          <Typography variant="h1">{weather?.instant?.details?.air_temperature}</Typography>
          <Typography variant="h6">°C</Typography>
        </Grid>
        <Grid item xs={6} display={'flex'} alignItems={'center'} justifyContent={'center'} padding={2}>
          <img
            src={`../../../../../icons/weather/${weather?.next_1_hours?.summary?.symbol_code}.png`}
            width={80}
            height={80}
          />
        </Grid>
      </Grid>
      <Grid container xs={12} sx={{ color: muiTheme.palette.white.main }}>
        <Grid item xs={4} display={'flex'} justifyContent={'center'}>
          <Stack>
            <Box>
              <Typography variant="subtitle1" lineHeight={1}>
                {translate('pressure')}
              </Typography>
            </Box>
            <Box>
              <Typography variant="h6">{weather?.instant?.details?.air_pressure_at_sea_level}hPa</Typography>
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={4} display={'flex'} justifyContent={'center'} pl={3}>
          <Stack>
            <Box>
              <Typography variant="subtitle1" lineHeight={1}>
                {translate('wind')}
              </Typography>
            </Box>
            <Box>
              <Stack direction={'row'} spacing={1} justifyContent={'center'} alignContent={'center'}>
                <Typography variant="h6">{weather?.instant?.details?.wind_speed}m/s</Typography>
                <ArrowRightAlt style={{ rotate: `-${90 + weather?.instant?.details?.wind_from_direction}deg` }} />
              </Stack>
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={4} display={'flex'} justifyContent={'center'}>
          <Stack>
            <Box>
              <Typography variant="subtitle1" lineHeight={1}>
                {translate('moisture')}
              </Typography>
            </Box>
            <Box>
              <Typography variant="h6">{weather?.instant?.details?.relative_humidity}%</Typography>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </GenericCard>
  )
}

export default WeatherCard
