import { Box, Card, Typography, css, useTheme, styled, IconButton } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { AppRoutesNavigation } from '../../app-routes'
import { ArrowDropDown, ArrowRight } from '@mui/icons-material'
import { ReactComponent as SettingsIcon } from '../../assets/svg/settings.svg'
import { GetRGBA } from 'src/utils'
import { Flex } from 'src/styles/flexComponent'

interface CustomerHeaderProps {
  id: number
  name: string
  image: string
  active?: boolean
  onClick?: (e: any) => void
  hasActiveColor: boolean
  MultipleSelectComponent?: React.ReactNode
  hasAdminAccess?: boolean
}

interface ShadowedCardProps {
  changeColor?: boolean;
  padding?: string;
}

const roundedFull = css`border-radius: 100%;`
const CustomerLogo = styled('img')`${roundedFull}`
export const ShadowedCard = styled(Card)<ShadowedCardProps>(({ theme, changeColor, padding }) => ({
  '&.MuiPaper-root': {
    backgroundColor: (changeColor) ? theme.palette.primaryDark[theme.palette.mode] : theme.palette.white[theme.palette.mode],
    color: changeColor ? theme.palette.white[theme.palette.mode] : theme.palette.primaryDark[theme.palette.mode],
    boxShadow: `0px 0px 6px ${GetRGBA(theme.palette.black[theme.palette.mode], 0.29)}`
  },
  cursor: 'pointer',
  padding: padding
}))

export const Chevron = styled(Box)(({ theme }) => ({
  padding: '0 4px',
  alignContent: 'center',
  color: theme.palette.cadetBlue[theme.palette.mode]
}))

const Name = styled(Typography)(() => ({
  fontWeight: 'bold',
  paddingLeft: '12px',
  display: 'inline-block',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
}))

export const CustomerHeader = ({ id, name, image, active, onClick, hasActiveColor, MultipleSelectComponent, hasAdminAccess }: CustomerHeaderProps) => {
  return (
    <ShadowedCard changeColor={hasActiveColor && active} padding="6px 8px 6px 8px">
      <Flex.Row alignItems="center" justifyContent={'space-between'}>
        <Flex.Row alignItems="center" onClick={onClick}>
          <Chevron>{active ? <ArrowDropDown /> : <ArrowRight />}</Chevron>
          {image != null && <CustomerLogo sx={{ height: '32px', width: '32px' }} src={image} />}
          <Name variant="h5">{name}</Name>
        </Flex.Row>
        <Flex.Row alignItems="center">
          <Box sx={{ ml: 'auto', paddingRight: '8px', height: '44px' }}>{active && MultipleSelectComponent}</Box>
          {hasAdminAccess && (
            <CustomerActionRow customerId={id} active={hasActiveColor && active} />
          )}
        </Flex.Row>
      </Flex.Row>
    </ShadowedCard>
  )
}

const CustomerActionRow: React.FC<{ customerId: EntityId; active?: boolean }> = ({
  customerId,
  active,
}) => {
  const navigate = useNavigate()
  const theme = useTheme()

  return (
    <Flex.Row
      alignContent={'center'}
      color={theme.palette.cadetBlue[theme.palette.mode]}
      width={'44px'}
      height={'44px'}
      alignItems="center"
      justifyContent="center"
    >
      <IconButton
        onClick={e => {
          e.stopPropagation()
          navigate(AppRoutesNavigation.Customer.localities(customerId))
        }}
      >
        <SettingsIcon
          stroke={active ? theme.palette.white[theme.palette.mode] : theme.palette.cadetBlue[theme.palette.mode]}
        />
      </IconButton>
    </Flex.Row>
  )
}