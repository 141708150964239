import { Breadcrumbs, Link, Stack, StackProps, Typography, styled, useTheme } from '@mui/material'
import React, { FC } from 'react'
import { ArrowBack, Home, KeyboardArrowRightRounded } from '@mui/icons-material'
import { GridActionButton, GridActionButtonProps } from './GridActionButton'
import { Flex } from 'src/styles/flexComponent'
import { useNavigation } from 'src/context/NavigationContext'

interface TitleProps {
  title?: string
  subtitle?: string
  icon?: React.ReactNode
  titleComponent?: React.ReactElement
}

interface CommonPageLayoutProps extends StackProps {
  titleSection: TitleProps | IBreadcrumbProps
  topAction?: React.ReactNode[] | GridActionButtonProps[]
  topActionContainerProps?: StackProps
  bottomAction?: React.ReactNode[] | GridActionButtonProps[]
  bottomActionContainerProps?: StackProps
  specialAction?: React.ReactNode[] | GridActionButtonProps[]
  specialActionContainerProps?: StackProps
  childrenProps?: StackProps
  enableBackButton?: boolean
  warningNote?: React.ReactNode
}

const actionButtonMapping = (action: React.ReactNode | GridActionButtonProps, index: number) => {
  if (typeof action === 'object' && (action as GridActionButtonProps)?.buttonText != undefined)
    return <GridActionButton key={index} {...(action as GridActionButtonProps)} />
  return action
}

export const CommonPageLayout: FC<CommonPageLayoutProps> = ({
  children,
  titleSection,
  topAction,
  bottomAction,
  specialAction,
  childrenProps,
  topActionContainerProps,
  bottomActionContainerProps,
  specialActionContainerProps,
  enableBackButton,
  warningNote,
  ...props
}) => {
  const theme = useTheme()
  const breadcrumbContent = useGetBreadcrumbItem(titleSection as IBreadcrumbProps)
  const { navigateBack } = useNavigation()

  if (topAction) {
    topAction = topAction.map(actionButtonMapping)
  }

  if (bottomAction) {
    bottomAction = bottomAction.map(actionButtonMapping)
  }

  if (specialAction) {
    specialAction = specialAction.map(actionButtonMapping)
  }

  return (
    <Stack {...props}>
      <Flex.Row
        justifyContent="space-between"
        alignItems="flex-start"
        className={(titleSection as TitleProps)?.title ? 'pt-24-core pb-8-core' : 'pt-8-core pb-8-core'}
      >
        <Flex.Column spacing={'8px'}>
          {breadcrumbContent && (
            <Breadcrumbs separator={<KeyboardArrowRightRounded />} aria-label="breadcrumb">
              {breadcrumbContent}
            </Breadcrumbs>
          )}
          {(titleSection as TitleProps)?.title && (
            <Flex.Row justifyContent={'flex-start'} alignItems={'center'} gap={1}>
              {(titleSection as TitleProps)?.icon && (titleSection as TitleProps)?.icon}
              <Typography color={theme.palette.primaryMain[theme.palette.mode]} variant="h5" fontWeight={600}>
                {enableBackButton && <BackButton onClick={navigateBack} />}
                {(titleSection as TitleProps)?.title}
              </Typography>
              {warningNote != null && warningNote}
            </Flex.Row>
          )}
          {(titleSection as TitleProps)?.subtitle && (
            <Typography
              data-cy="title"
              color={theme.palette.primaryMain[theme.palette.mode]}
              variant="h6"
              fontWeight={500}
            >
              {(titleSection as TitleProps)?.subtitle}
            </Typography>
          )}
          {(titleSection as TitleProps)?.titleComponent}
        </Flex.Column>

        <Stack direction="row" spacing={1} {...topActionContainerProps}>
          {topAction}
        </Stack>
      </Flex.Row>
      <Flex.Column {...childrenProps}>{children}</Flex.Column>
      {(specialAction || bottomAction) && (
        <Stack
          direction="row"
          justifyContent={specialAction ? 'space-between' : 'flex-end'}
          alignItems="center"
          className="pt-24-core"
        >
          <Stack direction="row" spacing={1} {...specialActionContainerProps}>
            {specialAction}
          </Stack>
          <Stack direction="row" spacing={1} {...bottomActionContainerProps}>
            {bottomAction}
          </Stack>
        </Stack>
      )}
    </Stack>
  )
}

interface IStep {
  label: string
  action?: () => void
}

interface IBreadcrumbProps {
  home?: { display: boolean; icon?: React.ReactNode; action?: () => void }
  steps: IStep[]
  pageTitle: string
}

const useGetBreadcrumbItem = (props?: IBreadcrumbProps) => {
  const theme = useTheme()

  if (!props || !props.pageTitle) return null

  const { home, steps, pageTitle } = props as IBreadcrumbProps
  const itemArray: JSX.Element[] = []
  if (home && home.display) {
    itemArray.push(
      <Link
        sx={{ cursor: 'pointer' }}
        key="1"
        color="inherit"
        onClick={e => {
          e.stopPropagation()
          home.action && home.action()
        }}
      >
        {home?.icon ? home?.icon : <Home />}
      </Link>,
    )
  }
  steps.forEach((step, index) => {
    itemArray.push(
      <Link
        sx={{ cursor: 'pointer' }}
        underline={step.action ? 'hover' : 'none'}
        key="1"
        color="inherit"
        onClick={e => {
          e.stopPropagation()
          step.action && step.action()
        }}
      >
        <Typography
          variant="h5"
          key={`step-${index}`}
          color={theme.palette.secondaryDark[theme.palette.mode]}
          fontWeight={400}
        >
          {step.label}
        </Typography>
      </Link>,
    )
  })
  itemArray.push(
    <Typography color={theme.palette.primaryMain[theme.palette.mode]} variant="h5" fontWeight={600}>
      {pageTitle}
    </Typography>,
  )
  return itemArray
}

//styled
export const BackButton = styled(ArrowBack)`
  font-size: 34px;
  border: ${props => `1px solid ${props.theme.palette.secondaryLight[props.theme.palette.mode]}`};
  margin-right: 20px;
  border-radius: 4px;
  cursor: pointer;
`
