import { FC, useMemo } from 'react'
import { ShowDialogProps } from '../../../show-dialog'
import { GenericDialogLayout } from '../../../components/GenericDialogLayout'
import { AppForm, AppFormProps, FormSelectField, FormTextField } from '../../../components/FormCore'
import { useGetAllTypeServiceStations } from '../../../api/service-station'
import { z } from 'zod'
import { Box } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { useZodForm } from '../../../hooks/zod-form'
import { IServiceStation } from '../../../generated-types/service-station'
import { MainButton } from '../../../components/MainButton'
import { GroupHeader, GroupItems } from '../../localities/positions/components/MainComponentSendModal'
import { getServiceStationType } from '../../../utils/function'
import { useCreateServiceStationGroup, useUpdateServiceStationGroup } from '../../../api/service-station-group'
import { toast } from 'react-toastify'
import { invalidateServiceStationGroupsQueries } from '../../../api/service-station-group'
interface ServiceStationGroupDialogProps extends ShowDialogProps, AppFormProps {
  title: string
  customerId: string
}

const schema = z.object({
  id: z.number().optional().nullable(),
  name: z.string().nonempty(),
  description: z.string().optional().nullable(),
  serviceStations: z.array(z.object({ id: z.number() })).transform(data => data.map(({ id }) => id)),
})

export const ServiceStationGroupDialog: FC<ServiceStationGroupDialogProps> = ({
  title,
  customerId,
  onSubmit,
  initialValues,
  pop,
}) => {
  const { data } = useGetAllTypeServiceStations(customerId)
  const { mutateAsync: addServiceStationGroup } = useCreateServiceStationGroup(customerId)
  const { mutateAsync: updateServiceStationGroup } = useUpdateServiceStationGroup(customerId)

  const handleAddServiceStationGroup = async (values: any) => {
    await addServiceStationGroup({ ...values })
      .then(() => {
        invalidateServiceStationGroupsQueries.getServiceStationGroups(customerId as EntityId)
        toast.success(t('service-station-group-added-successfully', { ns: 'service-station-group' }))
        pop()
      })
      .catch(error => {
        toast.error(t('service-station-group-added-error', { ns: 'service-station-group' }))
      })
  }

  const handleUpdateServiceStationGroup = async (values: any) => {
    await updateServiceStationGroup({ ...values}, {
      onSuccess: () => {
        invalidateServiceStationGroupsQueries.getServiceStationGroups(customerId as EntityId)
        toast.success(t('service-station-group-updated-successfully', { ns: 'service-station-group' }))
        pop()
      },
      onError: () => {
        toast.error(t('service-station-group-updated-error', { ns: 'service-station-group' }))
      },
     })
  }

  const { t } = useTranslation(['service-station-group', 'common'])
  const form = useZodForm(schema, {
    defaultValues: { ...initialValues, serviceStations: initialValues?.serviceStations ?? [] },
  })

  const serviceStations = useMemo(() => {
    const services = data?.items ?? ([] as IServiceStation[])
    services.sort((a, b) => b.type.toString().localeCompare(a.type.toString()))
    return services
  }, [data?.items])

  return (
    <GenericDialogLayout
      title={title}
      pop={pop}
      sx={{ p: 1 }}
      actions={[
        <MainButton
          key="submit"
          onClick={() => {
            form.handleSubmit(initialValues?.id ? handleUpdateServiceStationGroup : handleAddServiceStationGroup)()
          }}
        >
          { t( initialValues?.id ?'update' :'add', { ns: 'common' })}
        </MainButton>,
      ]}
      removedCloseButton={false}
    >
      <AppForm form={form} onSubmit={onSubmit} hasInitialValues={!!initialValues}>
        <Box py={1}>
          <FormTextField fullWidth name="name" label={t('name', { ns: 'common' })} />
        </Box>
        <Box py={1}>
          <FormTextField fullWidth name="description" label={t('description', { ns: 'common' })} multiline rows={4} />
        </Box>
        <Box py={1}>
          <FormSelectField
            multiple
            name="serviceStations"
            data={serviceStations}
            defaultValue={initialValues?.serviceStations || []}
            label={t('service-stations', { ns: 'service-station-group' })}
            getOptionLabel={(option: IServiceStation) => option.name}
            renderOption={(props: any, option) => (
              <Box {...props} key={option.id}>
                {option.name}
              </Box>
            )}
            groupBy={(option: any) => option.type}
            renderGroup={params => (
              <li>
                <GroupHeader>{getServiceStationType(params.group)}</GroupHeader>
                <GroupItems>{params.children}</GroupItems>
              </li>
            )}
            isOptionEqualToValue={(o, v) => o.id === v.id}
          />
        </Box>
      </AppForm>
    </GenericDialogLayout>
  )
}
