import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import { MdDelete, MdEdit, MdTopic } from 'react-icons/md'
import { IProduct } from '../../../generated-types/product'
import { ShowDialogProps, showConfirmDialog } from '../../../show-dialog'
import { useMemo } from 'react'
import { GridActionsCellItem, GridColumns, GridNativeColTypes, GridRowId, useGridApiRef } from '@mui/x-data-grid-pro'
import {
  invalidateProductCache,
  productsEndpoints,
  useDeleteProduct,
  useGetProduct,
  useUpdateProduct,
} from '../../../api/product'
import { ProductForm } from './ProductForm'
import { DataGridWithServerPagination } from '../../../components/DataGridWithServerPagination'
import { CenteredSpinner } from '../../../components/Spinner'
import { useDialogCtx } from '../../../hooks/context-hooks'
import { CertificationListDialog } from './product-certification/CertificationListDialog'

interface UpdateProductProps extends ShowDialogProps {
  id: EntityId
}

const UpdateProductDialog: React.FC<UpdateProductProps> = ({ pop, id }) => {
  const mutation = useUpdateProduct(id)

  const onSubmit = async form => {
    await mutation.mutateAsync(form)

    invalidateProductCache.getProducts()
    pop(true)
  }

  const { data, isLoading } = useGetProduct(id)
  if (isLoading) return <CenteredSpinner />

  return (
    <Box>
      <ProductForm onSubmit={onSubmit} onCancel={() => pop()} initialValues={data as IProduct} />
    </Box>
  )
}

export const ProductTable: React.FC<{
  searchValue: string
}> = ({ searchValue = '' }) => {
  const { t: translate } = useTranslation(['product', 'common'])
  const apiRef = useGridApiRef()
  const { mutate, isLoading: isDeleting } = useDeleteProduct()
  const { showDialog } = useDialogCtx()
  const onDelete = (id: EntityId) => {
    showConfirmDialog(
      translate('deleteProduct', { ns: 'product' }),
      translate('areYouSureDeleteProduct', { ns: 'product' }),
    ).then(e => {
      if (e) {
        mutate(id)
        apiRef.current.updateRows([{ id, _action: 'delete' }])
      }
    })
  }

  const onUpdate = async (id: GridRowId) => {
    const added = await showDialog(UpdateProductDialog, {
      title: translate('updateProduct', { ns: 'product' }),
      opts: {
        maxWidth: 'md',
        fullWidth: true,
      },
      componentProps: { id: id },
    })
    if (!added) return

    invalidateProductCache.getProducts()
  }

  const onShowCertifications = async (id: EntityId) => {
    const added = await showDialog(
      CertificationListDialog,
      {
        componentProps: { productId: id },
        opts: {
          maxWidth: 'md',
          fullWidth: true,
        },
      },
      undefined,
      true,
    )
    if (!added) return
  }

  const STATIC_COLUMNS: GridColumns = [
    { field: 'number', headerName: translate('number'), flex: 1, cellClassName: 'font-weight-semi-bold' },
    {
      field: 'description',
      headerName: translate('description', { ns: 'common' }),
      flex: 1,
      cellClassName: 'font-weight-semi-bold',
    },
    {
      field: 'supplier',
      headerName: translate('supplier'),
      valueGetter: params => params.row.supplier.name,
      flex: 1,
    },
    {
      field: 'unit',
      headerName: translate('unit'),
      valueGetter: params => params.row.unit.name + ' (' + params.row.unit.abbreviation + ')',
      flex: 1,
    },
    { field: 'expectedLifetime', headerName: translate('expectedLifetime'), flex: 1 },
    { field: 'minimumBreakingLoad', headerName: translate('minimumBreakingLoad'), flex: 1 },
  ]

  const columns: GridColumns = useMemo(
    () => [
      ...STATIC_COLUMNS,
      {
        field: 'actions',
        type: 'actions' as GridNativeColTypes,
        width: 10,
        getActions: ({ id }) => [
          <GridActionsCellItem
            key={id}
            label={translate('certificationList', { ns: 'product-certification' })}
            icon={<MdTopic size={24} />}
            onClick={() => onShowCertifications(id)}
            disabled={isDeleting}
            color="primary"
            showInMenu
          />,
          <GridActionsCellItem
            key={id}
            label={translate('edit', { ns: 'common' })}
            icon={<MdEdit size={24} />}
            onClick={() => onUpdate(id)}
            disabled={isDeleting}
            color="primary"
            showInMenu
          />,
          <GridActionsCellItem
            key={id}
            label={translate('delete', { ns: 'common' })}
            icon={<MdDelete size={24} />}
            onClick={() => onDelete(id)}
            disabled={isDeleting}
            color="primary"
            showInMenu
          />,
        ],
      },
    ],
    [isDeleting],
  )

  return (
    <>
      <DataGridWithServerPagination
        queryUrl={productsEndpoints.getProducts(searchValue)}
        onCellClick={cell => console.log(cell)}
        columns={columns}
      />
    </>
  )
}
