import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { MainButton } from '../../../components/MainButton'
import { ScreenTitle } from '../../../components/ScreenTitle'
import { showConfirmDialog, showDialog } from '../../../show-dialog'
import {
  CreateMainComponentTypeDialog,
  CreateMainComponentTypeProps,
} from '../mainComponents/MainComponentDashboard'
import { MainComponentTypeTable } from '../../main-component-type/MainComponentTypeTable'
import {
  useDeleteMainComponentType,
  invalidateMainComponentTypeCache,
  customerMainComponentTypeEndpoints,
} from '../../../api/main-component-type'
import { UpdateMainComponentTypeDialog } from './CustomerUpdateMainComponentTypeDialog'
import { useEffect } from 'react'
import { toast } from 'react-toastify'
import { COMPONENT_EXISTS } from '../../../constant'
import { IPrivilegeLevel } from '../../../enums'

export const MainComponentTypesContainer = () => {
  const { t: translate } = useTranslation(['main-component-type', 'common'])
  const { id: customerId } = useParams()

  const onAddMainComponentType = async () => {
    const added = await showDialog(CreateMainComponentTypeDialog, {
      componentProps: {
        customerId: customerId as EntityId,
        privilegeLevel: IPrivilegeLevel.Admin,
      } as CreateMainComponentTypeProps,
    })
    if (!added) return
  }

  const { mutateAsync, isLoading: isDeleting, error } = useDeleteMainComponentType(customerId as EntityId)
  useEffect(() => {
    if (error && error['data'] == COMPONENT_EXISTS) {
      toast(translate('component-exists'), { type: 'error' })
    }
  }, [error])

  const onDelete = (id: EntityId) => {
    showConfirmDialog(translate('deleteMainComponentType'), translate('areYouSureDeleteMainComponentType')).then(
      async e => {
        if (e) {
          await mutateAsync(id, {
            onSuccess: () => {
              toast(translate('type-deleted'), { type: 'success' })
              invalidateMainComponentTypeCache.useGetMainComponentTypes(customerId as EntityId)
              invalidateMainComponentTypeCache.useGetMainComponentTypeWithMainComponentCount(customerId as EntityId)
            },
            onError: () => {
              toast.error(translate('deletingError', { ns: 'common' }))
            },
          })
        }
      },
    )
  }

  const onUpdate = async id => {
    const added = await showDialog(UpdateMainComponentTypeDialog, {
      componentProps: { id: id as EntityId, customerId: customerId as EntityId },
    })
    if (!added) return
  }

  return (
    <>
    <Box className="mb-2 py-2 px-4 mt-2 bg-white shadow-md">
      <Box className="flex justify-between mb-5">
        <ScreenTitle title={translate('main-component-types')} />
        <MainButton onClick={() => onAddMainComponentType()}>{translate('add', { ns: 'common' })}</MainButton>
      </Box>
      <MainComponentTypeTable
        baseQuery={customerMainComponentTypeEndpoints.getComponentTypes(customerId as EntityId)}
        onDelete={onDelete}
        isDeleting={isDeleting}
        onUpdate={onUpdate}
        isAdmin={false}
      />

    </Box>
    </>
  )
}
