import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useCustomerCtx } from '../../../hooks/context-hooks'
import { ServiceStationGroupsTable } from './ServiceStationGroupsTable'
import { useDialogCtx } from 'src/hooks/context-hooks'
import { ServiceStationGroupDialog } from './ServiceStationGroupDialog'
import { FC, useCallback } from 'react'
import { ServiceStationGroupContainer } from './ServiceStationGroupContainer'
import { AlertDialog } from '@lovoldsystem/ui'
import { toast } from 'react-toastify'
import {
  invalidateServiceStationGroupsQueries,
  serviceStationGroupsEndpoints,
  useDeleteServiceStationGroup,
  useUpdateServiceStationGroup,
} from '../../../api/service-station-group'
import { IServiceStationGroup } from '../../../generated-types'
import { ResourceScreenProps } from '../../../generated-types/resource-screen'
import { ScreenTitleBar } from '../../localities/components/ScreenTitleBar'
import { serviceStationGroupQueryKey } from '../../../query-keys'

export const ServiceStationGroupsScreen: FC<ResourceScreenProps> = ({
  renderTableExpand,
  query,
  params,
  generateQueryKey,
  handleUpdate,
  handleDelete,
  handleAdd,
  isActionAvailable = true,
  isTableExpandable = true,
}) => {
  const { customer } = useCustomerCtx()
  const { t } = useTranslation('service-station-group')
  const { showDialog } = useDialogCtx()

  const handleGroupAdd = useCallback(() => {
    showDialog(
      ServiceStationGroupDialog,
      {
        opts: {
          maxWidth: 'md',
          fullWidth: true,
        },
        componentProps: {
          customerId: customer.id,
          title: t('add-service-station-group', { ns: 'service-station-group' }),
        },
      },
      undefined,
      true,
    )
  }, [customer.id, t])

  const { mutateAsync: deleteServiceStationGroup } = useDeleteServiceStationGroup(customer.id)
  const { mutateAsync: updateServiceStationGroup } = useUpdateServiceStationGroup(customer.id)

  const handleGroupEdit = useCallback(
    (initialValues: IServiceStationGroup) => {
      showDialog(
        ServiceStationGroupDialog,
        {
          opts: {
            maxWidth: 'md',
            fullWidth: true,
          },
          componentProps: {
            customerId: customer.id,
            title: t('update-service-station-group', { ns: 'service-station-group' }),
            initialValues: initialValues,
            onSubmit: async values => {
              await updateServiceStationGroup({ ...values })
                .then(() => {
                  toast.success(t('service-station-group-updated-successfully', { ns: 'service-station-group' }))
                })
                .catch(() => {
                  toast.error(t('service-station-group-update-error', { ns: 'service-station-group' }))
                })
            },
          },
        },
        undefined,
        true,
      )
    },
    [customer.id, t],
  )

  const handleGroupDelete = useCallback(
    async id => {
      showDialog(
        AlertDialog,
        {
          componentProps: {
            title: t('delete-service-station-group', { ns: 'service-station-group' }),
            acceptText: t('delete', { ns: 'common' }),
            text: t('delete-confirmation-message', { ns: 'service-station-group' }),
            onConfirm: async pop => {
              await deleteServiceStationGroup(id, {
                onSuccess: () => {
                  invalidateServiceStationGroupsQueries.getServiceStationGroups(customer.id as EntityId)
                  toast.success(t('service-station-group-deleted-successfully', { ns: 'service-station-group' }))
                },

                onError: () => {
                  toast.error(t('service-station-group-deleted-error', { ns: 'service-station-group' }))
                },
              })
              pop()
            },
          },
        },
        undefined,
        true,
      )
    },
    [customer.id],
  )

  return (
    <Box className="px-4 py-2 mt-2 mb-2 bg-white shadow-md">
      <ScreenTitleBar
        title={t('service-station-group')}
        addNewItemText={t('add', { ns: 'common' })}
        onAddNewItemClick={handleAdd ?? handleGroupAdd}
      />
      <ServiceStationGroupsTable
        queryUrl={query ?? serviceStationGroupsEndpoints.getServiceStationGroups(customer.id)}
        queryParams={params}
        generateQueryKey={generateQueryKey ?? ((page: number, pageSize: number) => serviceStationGroupQueryKey.GET_SERVICE_STATION_GROUPS(customer.id, { page, pageSize }))}
        getDetailPanelContent={
          isTableExpandable
            ? renderTableExpand ??
            (({ row }) => {
              return <ServiceStationGroupContainer serviceStationGroupId={row.id} serviceStationName={row.name} />
            })
            : undefined
        }
        handleEdit={isActionAvailable ? handleUpdate ?? handleGroupEdit : undefined}
        handleRemove={isActionAvailable ? handleDelete ?? handleGroupDelete : undefined}
      />
    </Box>
  )
}
