import { Autocomplete, Box, Grid, MenuItem, TextField } from '@mui/material'
// import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'
import { useGetLocalities } from '../../../../api/localities'
import { CancelButton } from '../../../../components/CancelButton'
import { AppForm, AppFormProps, FormSelectField, FormSubmitButton, FormTextField } from '../../../../components/FormCore'
import { CenteredSpinner } from '../../../../components/Spinner'
import { ILocality, IMooring } from '../../../../generated-types'
import { useZodForm } from '../../../../hooks/zod-form'
import { useCallback, useState } from 'react'
import { APIResultCount } from '../../../../constant'
import { useGetMoorings, useGetMooringsForService } from '../../../../api/moorings'

export interface AddLocalityToServiceFormProps extends AppFormProps {
  customerId: EntityId
  serviceId?: EntityId
}

export const AddLocalityToServiceForm: React.FC<AddLocalityToServiceFormProps> = ({
  onSubmit,
  onCancel,
  initialValues,
  customerId,
  serviceId,
}) => {
  const { t: translate } = useTranslation(['customer-locality', 'common'])
  const [selectedLocalityId, setSelectedLocalityId] = useState<EntityId | undefined>()

  const localityServiceSchema = z.object({
    localityId: z.number(),
    mooringIds: z.array(z.object({id: z.number()})).nonempty().transform(data => data.map(({ id }) => id)),
    serviceId: z.number().optional(),
    startDate: z.string().nonempty(translate('provideValidInput', { ns: 'common' })),
    endDate: z.string().transform(value => (value === '' ? null : value)),
  })

  const form = useZodForm(localityServiceSchema, { defaultValues: {...initialValues,...{mooringIds:[]}} })
  const { data, isLoading } = useGetLocalities(customerId, { page: 0, pageSize: APIResultCount.Max })

  const formatInputDate = (date: Date) => {
    return date.toISOString().split('T')[0]
  }
  if (isLoading) return <CenteredSpinner />

  return (
    <AppForm form={form} onSubmit={onSubmit} hasInitialValues={initialValues}>
      <Box py={1}>
        <Autocomplete
          options={(data?.items as ILocality[]) ?? []}
          autoHighlight
          getOptionLabel={o => o.name}
          onChange={(_, value) => {
            form.setValue('localityId', (value as ILocality).id)
            setSelectedLocalityId((value as ILocality).id as EntityId)
          }}
          renderOption={(props, o) => (
            <MenuItem key={o.id} value={o.id} {...props}>
              {o.name}
            </MenuItem>
          )}
          renderInput={params => (
            <TextField
              {...params}
              label={translate('selectLocality', { ns: 'customer-locality' })}
              inputProps={{
                ...params.inputProps,
              }}
            />
          )}
          isOptionEqualToValue={(option, value) => option.id === value.id}
        />
      </Box>
      {selectedLocalityId && (
        <SelectMooring
          customerId={customerId}
          localityId={selectedLocalityId}
          serviceId={serviceId as EntityId}
          disabled={false}
          data-cy='selectLocality'
        />
      )}
      <Box py={1}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormTextField
              name="startDate"
              label={translate('startDate', { ns: 'common' })}
              type="date"
              defaultValue={formatInputDate(new Date())}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextField
              name="endDate"
              label={translate('endDate', { ns: 'common' })}
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box className="flex justify-end gap-4 pt-4">
        <CancelButton onClick={onCancel}>{translate('cancel', { ns: 'common' })}</CancelButton>
        <FormSubmitButton createText={translate('add', { ns: 'common' })} />
      </Box>
    </AppForm>
  )
}

const SelectMooring: React.FC<{
  customerId: EntityId
  localityId: EntityId
  serviceId: EntityId
  disabled: boolean
}> = ({ customerId, localityId, serviceId, disabled = true }) => {
  const { data: moorings, isLoading } = useGetMoorings(customerId, localityId as EntityId, undefined, 0, APIResultCount.Max)
  const { t: translate } = useTranslation(['mooring'])

    const { data: alreadyAddedMoorings, isLoading: isAlreadyAddedMooringsLoading } = useGetMooringsForService(
      customerId,
      serviceId as EntityId,
      0,
      10000,
    )

    const isMooringInService = useCallback(
      mooringId => {
        return alreadyAddedMoorings?.items.some(mooring => mooring.id === mooringId)
      },
      [alreadyAddedMoorings],
    )

    if (isLoading || isAlreadyAddedMooringsLoading) return <CenteredSpinner />

  return (
    <Box py={1}>
      <FormSelectField
        disabled={disabled}
        name="mooringIds"
        multiple
        data={(moorings?.items.filter(f => !isMooringInService(f.id)) as IMooring[]) ?? []}
        label={translate('selectMooring', { ns: 'mooring' })}
        getOptionLabel={option => option.name}
        isOptionEqualToValue={(o, v) => o.id === v.id}
        renderOption={(props: any, option) => (
          <Box {...props} key={option.id}>
            {option.name}
          </Box>  
        )}
      /> 
    </Box>
  )
}
