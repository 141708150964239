import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { ALREADY_EXISTS, NOT_FOUND, SUCCESSFULLY_ADDED, SUCCESSFULLY_REMOVED } from '../constant'

export const ShowToastMessage: React.FC<{ code: string }> = ({ code }) => {
  const { t: translate } = useTranslation(['common-api-message', 'common'])

  if (code == ALREADY_EXISTS) {
    toast(translate('AlreadyExists', { ns: 'common-api-message' }), { type: 'error' })
  }
  if (code == NOT_FOUND) {
    toast(translate('notFound', { ns: 'common-api-message' }), { type: 'error' })
  }

  if (code == SUCCESSFULLY_REMOVED) {
    toast(translate('successfullyRemoved', { ns: 'common-api-message' }), { type: 'success' })
  }
  if (code == SUCCESSFULLY_ADDED) {
    toast(translate('successfullyAdded', { ns: 'common-api-message' }), { type: 'success' })
  }

  return <></>
}
