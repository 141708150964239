import { Grid, Stack, Box, Typography, useTheme } from '@mui/material'
import React, { FC } from 'react'
import GenericCard, { IGenericCard } from 'src/components/GenericCard'
import { StatusIndicator } from 'src/components/common/StatusIndicator'
import { IMooringType } from 'src/generated-types'
import mooringTypeIcon from 'src/assets/svg/mooringType.svg'
import { useTranslation } from 'react-i18next'
import { Padding } from 'src/styles/paddingComponent'

interface MooringSelectCardProps extends Omit<IGenericCard, 'actions'> { 
    mooring: any
    isSelected: boolean
    onClick: () => void
}
const MooringSelectCard:FC<MooringSelectCardProps> = ({ mooring, isSelected, onClick,...props }) => {
    const { t: translate } = useTranslation(['common', 'mooring'])
    const theme = useTheme()
    return (
        <GenericCard
            title={mooring.name}
            headerIcon={undefined}
            maxHeight={'110px'}
            isKebabMenu={false}
            isSelected={isSelected}
            titleTypographyProps={{ variant: 'h5' }}
            minWidth={'230px'}
            maxLength={13}
            actions={[]}
            onClick={() => onClick()}
            sx={{ cursor: 'pointer' }}
            {...props}
        >
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <Grid container spacing={2} pt={1}>
                            <Grid item xs={12}>
                                {mooring.active ? (
                                    <StatusIndicator
                                        statusText={translate('active')}
                                        bgcolor={isSelected ? theme.palette.white[theme.palette.mode] : theme.palette.activeGreen.main}
                                        height="24px"
                                        width="58px"
                                        fontColor={
                                            isSelected ? theme.palette.success[theme.palette.mode] : theme.palette.black[theme.palette.mode]
                                        }
                                        borderRadius="4px"
                                    />
                                ) : (
                                    <StatusIndicator
                                        statusText={translate('inactive')}
                                        bgcolor={isSelected ? theme.palette.white[theme.palette.mode] : theme.palette.inactiveRed.main}
                                        height="24px"
                                        width="64px"
                                        fontColor={
                                            isSelected ? theme.palette.error[theme.palette.mode] : theme.palette.black[theme.palette.mode]
                                        }
                                        borderRadius="4px"
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={9} textAlign={'right'}>
                        <Padding.pt16>
                            <Stack direction={'row'} gap={1} justifyContent={'flex-end'} alignItems={'center'}>
                                <Box>
                                    <img src={mooringTypeIcon} />
                                </Box>
                                <Box>
                                    <Typography
                                        sx={{ color: isSelected ? theme.palette.white[theme.palette.mode] : '' }}
                                        variant={'body2'}
                                        fontWeight={'normal'}
                                    >
                                        {translate('type')} :
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography
                                        sx={{ color: isSelected ? theme.palette.white[theme.palette.mode] : '' }}
                                        variant={'body2'}
                                        fontWeight={'bold'}
                                    >
                                        {translate(IMooringType[mooring.type], { ns: 'mooring' })}
                                    </Typography>
                                </Box>
                            </Stack>
                        </Padding.pt16>
                    </Grid>
                </Grid>
            </Grid>
        </GenericCard>
    )
}

export default MooringSelectCard