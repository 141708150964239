/* eslint-disable max-lines */
import React, { Fragment, ReactElement, useCallback, useEffect, useRef, useState } from 'react'
import {
  DataGridPro,
  GridCallbackDetails,
  GridCellParams,
  GridColumns,
  GridEditMode,
  GridEditRowsModel,
  GridEventListener,
  GridInputSelectionModel,
  GridRowClassNameParams,
  GridRowHeightParams,
  GridRowHeightReturnValue,
  GridRowId,
  GridRowParams,
  GridSelectionModel,
  GridSortModel,
  GridToolbar,
} from '@mui/x-data-grid-pro'
import { Box, IconButton, Pagination, PaginationItem, Stack, useMediaQuery } from '@mui/material'
import useTheme from '@mui/material/styles/useTheme'
import { ColumnOptions } from './ColumnOptions'
import Tooltip from '@mui/material/Tooltip'
import { CustomDataGridWithPagination } from './CustomDataGridWithPagination'
import { useTranslation } from 'react-i18next'
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro'
import { QuickFilterBounceTime } from '../../constant'
import { QueryKeyT } from '../../api/reactQuery'
import { NextArrow, PreviousArrow } from './PaginationArrows'
import { styles, useStyles } from './GenericTable.style'
import MobileCardList from './MobileCardList'
import { styled } from '@mui/material'
import { ArrowRight, ArrowDropDown, Settings, Download, Downloading } from '@mui/icons-material'

export interface IToolbarOptions {
  label: string
  Icon?: React.ReactNode
  onClick?: () => void
  Component?: React.ReactNode
  showSeparator?: boolean
}

const VerticalSeparator = styled('hr')`
  width: 0;
  height: 49px;
  border: none;
  border-left: 1px solid ${props => props.theme.palette.secondaryMain[props.theme.palette.mode]};
  opacity: 1;
`

export const StyledIconButton = styled(IconButton)`
  color: ${props => props.theme.palette.primaryMain[props.theme.palette.mode]};
  &:hover {
    background-color: ${props => props.theme.palette.secondaryLight[props.theme.palette.mode]};
  }
  &:disabled {
    color: ${props => props.theme.palette.secondaryLight[props.theme.palette.mode]};
  }
  width: 32px;
  height: 32px;
`

interface ITableProps {
  styledTable?: boolean
  id: string
  data?: any[] //data
  columns: GridColumns //columns
  pageSize?: number //page size
  onDownload?: () => void
  defaultColumnOption?: string[] //should be column header names
  fixedColumns?: string[]
  actionColumns?: string[]
  enableColumnOptions?: boolean
  enablePagination?: boolean
  enableExport?: boolean
  enableToolbarFilter?: boolean
  enableToolbar?: boolean
  pageOptions?: number[]
  initialPage?: number
  enableServerPagination?: boolean
  enablePaginationButtons?: {
    showFirstButton?: boolean
    showLastButton?: boolean
  }
  queryUrl?: string
  queryParams?: object
  onCellClick?: GridEventListener<'cellClick'> | undefined
  onRowClick?: GridEventListener<'rowClick'> | undefined
  toolbarOptions?: IToolbarOptions[]
  toolbarFilter?: ReactElement
  rowColor?: string
  mobileViewCard?: (row: any) => ReactElement
  enableFilter?: boolean
  isDownloading?: boolean
  noRowsMessage?: string
  onSelectionModelChange?: ((selectionModel: GridSelectionModel, details: GridCallbackDetails<any>) => void) | undefined
  selectionModel?: GridInputSelectionModel | undefined
  onCellEditCommit?: GridEventListener<'cellEditCommit'> | undefined
  isCellEditable?: ((params: GridCellParams<any, any, any>) => boolean) | undefined
  checkboxSelection?: boolean
  isRowSelectable?: (params: GridRowParams<any>) => boolean
  disableSelectionOnClick?: boolean
  sortModel?: GridSortModel | undefined
  getRowHeight?: ((params: GridRowHeightParams) => GridRowHeightReturnValue) | undefined
  apiRef?: React.MutableRefObject<GridApiPro>
  loading?: boolean
  enableQuickFilter?: boolean
  getRowClassName?: (params: GridRowClassNameParams<any>) => string
  getDetailPanelContent?: ((params: GridRowParams<any>) => React.ReactNode) | undefined
  getDetailPanelHeight?: ((params: GridRowParams<any>) => number | 'auto') | undefined
  onDetailPanelExpandedRowIdsChange?: ((ids: GridRowId[], details: GridCallbackDetails<any>) => void) | undefined
  detailPanelExpandedRowIds?: GridRowId[] | undefined
  generateQueryKey?: (page: number, pageSize: number) => QueryKeyT | undefined
  customStyles?: any
  hideDefaultFooterPagination?: boolean
  hideFooterSelectedRowCount?: boolean
  rowTopSpacing?: number
  rowBottomSpacing?: number
  className?: any
  height?: number | string
  autoHeight?: boolean
  getRowId?: (params: GridRowParams<any>) => GridRowId
  editMode?: GridEditMode | undefined
  onRowEditStart?: GridEventListener<'rowEditStart'> | undefined
  onRowEditStop?: GridEventListener<'rowEditStop'> | undefined
  onEditRowsModelChange?: ((editRowsModel: GridEditRowsModel, details: GridCallbackDetails<any>) => void) | undefined
  isApplyLastRowStyle?: boolean
  isViewAllRecords?: boolean
  headerColor?: string
}

const GenericTable: React.FC<ITableProps> = ({
  id,
  styledTable,
  data: d,
  columns: c,
  onDownload, //will be implement in future
  defaultColumnOption,
  fixedColumns = [] as string[],
  actionColumns = [] as string[],
  enableColumnOptions = false,
  enablePagination = false,
  enableExport = false,
  enableServerPagination = false,
  enableToolbar = false,
  enableToolbarFilter = false,
  queryUrl,
  queryParams,
  pageSize: ps = 5,
  pageOptions = [5, 10, 20],
  initialPage = 0,
  toolbarOptions,
  toolbarFilter,
  rowColor,
  enableFilter = false,
  isDownloading,
  noRowsMessage,
  onSelectionModelChange,
  onRowClick,
  disableSelectionOnClick,
  getRowClassName = params => (params.indexRelativeToCurrentPage % 2 == 0 ? 'even' : 'odd'),
  sortModel,
  getRowHeight = params => 35,
  loading = false,
  enableQuickFilter = false,
  getDetailPanelContent,
  onDetailPanelExpandedRowIdsChange,
  enablePaginationButtons: enablePaginationButton = { showFirstButton: true, showLastButton: true },
  generateQueryKey,
  customStyles,
  hideDefaultFooterPagination,
  hideFooterSelectedRowCount,
  rowTopSpacing = 0,
  rowBottomSpacing = 0,
  className,
  height,
  autoHeight = true,
  mobileViewCard,
  isApplyLastRowStyle = false,
  headerColor,
  ...props
}) => {
  const { t } = useTranslation(['common'])
  const initialRef: any = null
  const gridRef = useRef(initialRef)
  const [page, setPage] = useState(initialPage)
  const [pageSize, setPageSize] = useState(ps)
  const [data, setData] = useState(d)
  const [columns, setColumns] = useState(
    c.filter(
      column =>
        !fixedColumns?.includes(column.headerName as string) && !actionColumns?.includes(column.headerName as string),
    ),
  )
  const [open, setOpen] = useState(false)
  const [columnOptions, setColumnOptions] = useState(
    defaultColumnOption != undefined
      ? (defaultColumnOption.filter(dc => !fixedColumns?.includes(dc) && !actionColumns?.includes(dc)) as string[])
      : [c[0].headerName],
  )
  const [columnArray, setColumnArray] = useState([] as string[])
  const theme = useTheme()
  const classing = useStyles({ theme, rowColor, onRowClick, isCellEditable: props.isCellEditable, getRowHeight: getRowHeight,hideDefaultFooterPagination, headerColor,isApplyLastRowStyle } as any)
  const withoutclasses = styles({ theme, rowColor, onRowClick, isCellEditable: props.isCellEditable, getRowHeight: getRowHeight,hideDefaultFooterPagination, headerColor,isApplyLastRowStyle } as any)
  const isSmall = useMediaQuery<any>(theme.breakpoints.down('sm'))

  const classes = styledTable ? classing : withoutclasses
  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    // eslint-disable-next-line max-lines
    setOpen(false)
  }

  const handlePageChange = useCallback((_, page) => {
    setPage(page)
  }, [])

  const handleChangeRowsPerPage = useCallback((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPageSize(parseInt(event.target.value, 10))
    setPage(0)
  }, [])

  useEffect(() => {
    const fetchDate = async () => {
      setData(d)
      if (enableColumnOptions && enableToolbar) {
        const op = await sessionStorage.getItem(`columnOptions-${id}`)
        if (op) {
          const options = JSON.parse(op)
          setColumns(
            c
              .filter(
                f =>
                  options.includes(f.headerName) &&
                  !fixedColumns?.includes(f.headerName as string) &&
                  !actionColumns?.includes(f.headerName as string),
              )
              .sort((a, b) => options.findIndex(c => c == a.headerName) - options.findIndex(c => c == b.headerName)),
          )
          setColumnOptions(options)
          setColumnArray(
            c
              .filter(
                column =>
                  !fixedColumns?.includes(column.headerName as string) &&
                  !actionColumns?.includes(column.headerName as string),
              )
              .map(f => f.headerName)
              .filter(f => !options.includes(f)) as string[],
          )
        } else {
          setColumns(
            c
              .filter(
                f =>
                  columnOptions.includes(f.headerName) &&
                  !fixedColumns?.includes(f.headerName as string) &&
                  !actionColumns?.includes(f.headerName as string),
              )
              .sort(
                (a, b) =>
                  columnOptions.findIndex(c => c == a.headerName) - columnOptions.findIndex(c => c == b.headerName),
              ),
          )
          setColumnArray(
            c
              .filter(
                column =>
                  !fixedColumns?.includes(column.headerName as string) &&
                  !actionColumns?.includes(column.headerName as string),
              )
              .map(f => f.headerName)
              .filter(f => !columnOptions.includes(f)) as string[],
          )
        }
      } else {
        setColumns(c)
      }
      setPageSize(ps)
    }
    fetchDate()
  }, [d, c, ps])

  return (
    <>
      {enableToolbar && (enableToolbarFilter || Boolean(toolbarOptions?.length && toolbarOptions?.length > 0) || enableColumnOptions || enableExport) && (
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          {enableToolbarFilter ? <Box key="toolbarFilter">{toolbarFilter}</Box> : <Box></Box>}
          <Stack direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={1} py={1}>
            {toolbarOptions?.map((option, index) => {
              if (option.Icon) {
                return (
                  <Tooltip title={option.label} key={index}>
                  <StyledIconButton onClick={option.onClick}>{option.Icon}</StyledIconButton>
                </Tooltip>
                )
              } else {
                return (
                  <Fragment key={index}>
                    <Tooltip title={option.label} key={index}>
                      <Box>{option.Component}</Box>
                    </Tooltip>
                    {option.showSeparator && (
                      <Box>
                        <VerticalSeparator />
                      </Box>
                    )}
                  </Fragment>
                )
              }
            })}
            {enableColumnOptions && (
              <Tooltip title="Column Options">
                <StyledIconButton
                  onClick={() => {
                    handleOpen()
                  }}
                >
                  <Settings />
                </StyledIconButton>
              </Tooltip>
            )}
            {enableExport && (
              <Tooltip title="Export Excel">
                <StyledIconButton
                  onClick={() => {
                    !isDownloading && onDownload && onDownload()
                  }}
                >
                  {!isDownloading ? <Download /> : <Downloading />}
                </StyledIconButton>
              </Tooltip>
            )}
          </Stack>
        </Stack>
      )}
      {!enableServerPagination ? (
        <>
          {isSmall ? (
            <MobileCardList
              getDetailPanelContent={getDetailPanelContent}
              columns={[
                ...c.filter(c => fixedColumns.includes(c.headerName as string)),
                ...columns,
                ...c.filter(c => actionColumns.includes(c.headerName as string)),
              ]}
              rows={data ?? []}
              onRowClick={onRowClick}
              mobileViewCard={mobileViewCard}
            />
          ) : (
            <Box sx={{ height: height }}>
              <DataGridPro
                ref={gridRef!}
                columns={[
                  ...c.filter(c => fixedColumns.includes(c.headerName as string)),
                  ...columns,
                  ...c.filter(c => actionColumns.includes(c.headerName as string)),
                ]}
                getRowClassName={Params => (getRowClassName ? getRowClassName(Params) : 'bg-white ')}
                rows={data ?? []}
                rowCount={data?.length ?? 0}
                autoHeight={autoHeight}
                disableColumnFilter={!enableFilter}
                disableDensitySelector
                disableMultipleColumnsSorting
                disableColumnMenu
                disableMultipleSelection
                loading={loading}
                disableColumnSelector
                disableColumnResize
                hideFooter={!enablePagination}
                pagination={enablePagination}
                rowsPerPageOptions={pageOptions}
                page={page}
                pageSize={pageSize}
                onPageSizeChange={newPageSize => setPageSize(newPageSize)}
                onRowClick={onRowClick}
                getRowHeight={getRowHeight}
                getRowSpacing={_ => {
                  return {
                    top: rowTopSpacing ?? 0,
                    bottom: rowBottomSpacing ?? 0,
                  }
                }}
                className={`${classes.datGrid} ${customStyles ?? ''} ${className ?? ''}`}
                components={{
                  Toolbar: GridToolbar,
                  NoResultsOverlay: () => (
                    <Box className="font-semibold text-center text-l text-grey-600">
                      {noRowsMessage ?? t('noResultFound')}
                    </Box>
                  ),
                  NoRowsOverlay: () => (
                    <Box
                      className="font-semibold text-center text-l text-grey-600"
                      display={'flex'}
                      flexDirection={'column'}
                      alignItems={'center'}
                      p={6}
                    >
                      {noRowsMessage ?? t('noResultFound')}
                    </Box>
                  ),
                  DetailPanelExpandIcon: ArrowRight,
                  DetailPanelCollapseIcon: ArrowDropDown,
                }}
                hideFooterPagination={hideDefaultFooterPagination}
                hideFooterSelectedRowCount={hideFooterSelectedRowCount}
                componentsProps={{
                  pagination: {
                    count: data?.length ?? 0,
                    page: page,
                    onPageChange: handlePageChange,
                    onRowsPerPageChange: handleChangeRowsPerPage,
                    rowsPerPage: pageSize,
                    rowsPerPageOptions: pageOptions,
                    showFirstButton: enablePaginationButton?.showFirstButton ?? false,
                    showLastButton: enablePaginationButton?.showLastButton ?? false,
                  },
                  toolbar: {
                    printOptions: { disableToolbarButton: true },
                    csvOptions: { disableToolbarButton: true },
                    showQuickFilter: enableQuickFilter,
                    quickFilterProps: { debounceMs: QuickFilterBounceTime },
                  },
                }}
                onSelectionModelChange={onSelectionModelChange}
                disableSelectionOnClick={disableSelectionOnClick}
                getDetailPanelContent={getDetailPanelContent}
                  onDetailPanelExpandedRowIdsChange={onDetailPanelExpandedRowIdsChange}
                initialState={{
                  sorting: {
                    sortModel: sortModel,
                  },
                }}
                {...props}
              />
            </Box>
          )}
          {data?.length != 0 && enablePagination && (hideDefaultFooterPagination || isSmall) && (
            <Box display={'flex'} justifyContent={'center'} alignItems={'center'} padding={'8px'}>
              <Pagination
                size="small"
                count={(data && Math.ceil(data?.length / pageSize)) ?? 0}
                page={page + 1}
                onChange={(event, page) => handlePageChange(event, page - 1)}
                className={classes.paginationComponent}
                renderItem={item => (
                  <PaginationItem
                    components={{ next: NextArrow, previous: PreviousArrow }}
                    {...item}
                    classes={{ selected: classes.selected }}
                  />
                )}
              />
            </Box>
          )}
        </>
      ) : (
        <CustomDataGridWithPagination
          queryUrl={queryUrl as string}
          generateQueryKey={generateQueryKey}
          queryParams={queryParams}
          enablePagination={enablePagination}
          columns={[
            ...c.filter(c => fixedColumns.includes(c.headerName as string)),
            ...columns,
            ...c.filter(c => actionColumns.includes(c.headerName as string)),
          ]}
          initialPage={initialPage}
          mobileViewCard={mobileViewCard}
          pageOptions={pageOptions}
          pageSize={pageSize}
          classes={classes}
          loading={loading}
          getRowClassName={getRowClassName}
          setPageSize={setPageSize}
          noRowsMessage={noRowsMessage}
          onSelectionModelChange={onSelectionModelChange}
          enableFilter={enableFilter}
          onRowClick={onRowClick}
          enableQuickFilter={enableQuickFilter}
          getDetailPanelContent={getDetailPanelContent}
          enablePaginationButtons={enablePaginationButton}
          onDetailPanelExpandedRowIdsChange={onDetailPanelExpandedRowIdsChange}
          getRowHeight={getRowHeight}
          hideDefaultFooterPagination={hideDefaultFooterPagination}
          hideFooterSelectedRowCount={hideFooterSelectedRowCount}
          customStyles={customStyles}
          rowTopSpacing={rowTopSpacing}
          rowBottomSpacing={rowBottomSpacing}
          disableSelectionOnClick={disableSelectionOnClick}
          className={className}
          height={height}
          autoHeight={autoHeight}
          sortModel={sortModel}
          {...props}
        />
      )}
      {enableColumnOptions && (
        <ColumnOptions
          id={id}
          open={open}
          handleClose={handleClose}
          columns={c}
          fixedColumns={fixedColumns}
          actionColumns={actionColumns}
          columnOptions={columnOptions as string[]}
          setColumnOptions={setColumnOptions}
          columnArray={columnArray}
          setColumnArray={setColumnArray}
          setColumns={setColumns}
          classes={classes}
        />
      )}
    </>
  )
}

export default GenericTable
