import { makeStyles } from '@material-ui/core'
import { muiTheme } from '../../mui-theme'

export const useStyles = makeStyles(_ => ({
  tab: {
    margin: 4,
    width: 'auto',
    maxHeight: 'auto',
  },
  selectedTab: {
    width: 'auto',
    maxHeight: 'auto',
    color: `${muiTheme.palette.primaryMain[muiTheme.palette.mode]} !important`,
    backgroundColor: `${muiTheme.palette.secondaryLight[muiTheme.palette.mode]}`,
  },
  app_bar: {
    width: '100%',
    boxShadow: 'none !important',
    backgroundColor: muiTheme.palette.white.main,
    overflowX: 'auto',
  },
  content: {
    minHeight: (props: any) =>
      props.height ?? (isNaN(props.height) ? parseInt(props.height) : window.innerHeight * 0.5),
    color: muiTheme.palette.black.main,
    padding: 8,
  },
  body: {
    width: '100%',
  },
  icon: {
    color: muiTheme.palette.primaryLight.light,
    '& span': {
      fontSize: '16px !important',
    },
  },
  tab_content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))
