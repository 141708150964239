import { makeStyles } from '@material-ui/core'
import { gridClasses } from '@mui/x-data-grid-pro'
import { muiTheme } from '../../mui-theme'

export const styles = makeStyles(_ => ({
  columnSelector: {
    position: 'fixed',
    top: '50%',
    left: '87%',
    height: '98%',
    width: '25%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: (props: any) => props.theme?.palette.background.paper,
    boxShadow: (props: any) => props.theme?.shadows[5],
    padding: '10px',
    outline: 'none',
  },

  datGrid: {
    [`& .${gridClasses.row}`]: {
      cursor: (props: any) => (props.onRowClick ? 'pointer' : ''),
    },
    [`& .${gridClasses['cell']}:focus`]: {
      outline: (props: any) =>
        props.isCellEditable ? `solid ${props.theme.palette.primaryMain[props.theme.palette.mode]} 1px` : 'none',
    },
    [`& .${gridClasses['row']}:focus`]: {
      outline: (props: any) =>
        props.onRowClick ? `solid ${props.theme.palette.primaryMain[props.theme.palette.mode]} 1px` : 'none',
    },
  },
  title: {},
  columnOptions: {},
  optionTitle: {},
  textField: {},
  closeButton: {
    color: ' red',
  },
  icon: {
    color: ' rgb(0 82 107)',
    '& span': {
      fontSize: '16px !important',
    },
  },
  paginationComponent: {
    '& .MuiPaginationItem-root': { border: 0, backgroundColor: muiTheme.palette.white.main },
  },
  selected: {
    backgroundColor: `${muiTheme.palette.white.main} !important`,
    outline: `1px solid ${muiTheme.palette.azureBlue.main}`,
    borderRadius: '8px',
  },
}))

export const useStyles = makeStyles(_ => ({
  columnSelector: {
    position: 'fixed',
    top: '50%',
    left: '87%',
    height: '98%',
    width: '25%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: (props: any) => props.theme?.palette.background.paper,
    boxShadow: (props: any) => props.theme?.shadows[5],
    padding: '10px',
    outline: 'none',
  },
  datGrid: {
    border: 'none',
    color: 'black',
    [`& .${gridClasses.row}`]: {
      border: (props: any) => `1px solid ${props.theme.palette.secondaryLight[props.theme.palette.mode]}`,
      boxSizing: 'border-box',
      letterSpacing: 1,
      maxWidth: 'calc(100% - 2px)',
      height: (props: any) => (props.getRowHeight() == 'auto' ? 'auto' : '52px'),
      minHeight: '25px !important',
      borderRadius: '0px',
      overflowWrap: 'break-word',
      cursor: (props: any) => (props.onRowClick ? 'pointer' : ''),
      '& :focus': {
        outline: (props: any) =>
          props.onRowClick ? `solid ${props.theme.palette.primaryMain[props.theme.palette.mode]} 1px` : 'none',
      }
    },
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: (props: any) => props.theme.palette.white[props.theme.palette.mode],
    },
    [`& .${gridClasses.row}.odd`]: {
      backgroundColor: (props: any) => props.theme.palette.infoLight[props.theme.palette.mode],
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      whiteSpace: 'normal',
      lineHeight: 'normal',
    },
    '& .MuiDataGrid-row.MuiDataGrid-row--lastVisible.MuiDataGrid-row': {
      border: (props: any) =>
        props.isApplyLastRowStyle
          ? `1px dashed ${props.theme.palette.primaryMain[props.theme.palette.mode]}`
          : `1px solid ${props.theme.palette.secondaryLight[props.theme.palette.mode]}`,
      borderRadius: '4px',
      overflow: 'hidden',
    },

    [`& .${gridClasses.columnHeaders}`]: {
      color: (props: any) => (props.theme.palette.mode === 'light' ? `${muiTheme.palette.blueGray.main}` : 'white'),
      borderRadius: '5px',
      minHeight: '40px !important',
      maxHeight: '40px !important',
      lineHeight: '40px !important',
      border: 'none',
      '& .MuiDataGrid-columnHeaderTitleContainer': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'left',
      },
      '& .MuiDataGrid-columnHeaderTitle': {
        fontSize: '14px',
        fontWeight: '400',
        color: (props: any) => props.headerColor ?? props.theme.palette.black.main,
      },
    },
    [`& .${gridClasses['columnSeparator']}`]: {
      visibility: 'hidden',
    },
    [`& .${gridClasses['cell']}`]: {
      border: 'none',
      fontSize: '13.5px',
      fontWeight: '450',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'left',
      '&:focus': {
        outline: (props: any) => (props.isCellEditable ? 'solid #00526B 1px' : 'none'),
      },
    },
    [`& .${gridClasses['footerContainer']}`]: {
      border: 'none',
      display: (props: any) => (props.hideDefaultFooterPagination ? 'none' : undefined),
    },
  },
  title: {
    color: ' rgb(0 82 107)',
    fontWeight: 'bold',
  },
  columnOptions: {
    color: ' rgb(0 82 107)',
    margin: '20px',
  },
  optionTitle: {
    fontSize: '0.8rem',
  },
  textField: {
    '& .MuiOutlinedInput-root': {
      padding: 0,
    },
  },
  closeButton: {
    color: ' red',
  },
  icon: {
    color: ' rgb(0 82 107)',
    '& span': {
      fontSize: '16px !important',
    },
  },
  paginationComponent: {
    '& .MuiPaginationItem-root': { border: 0, backgroundColor: muiTheme.palette.white.main },
  },
  selected: {
    backgroundColor: `${muiTheme.palette.white.main} !important`,
    outline: `1px solid ${muiTheme.palette.azureBlue.main}`,
    borderRadius: '8px',
  },
}))
