import { Box, Grid, Typography } from '@mui/material'
import { useCustomerCtx } from '../../../../hooks/context-hooks'
import { useGetLocalityNames } from '../../../../api/localities'
import { useGetAllResources } from '../../../../api/task-resource'
import { useTranslation } from 'react-i18next'
import { AppForm, FormSelectField, FormSubmitButton } from '../../../../components/FormCore'
import { z } from 'zod'
import { useZodForm } from '../../../../hooks/zod-form'
import { useEffect } from 'react'
import BasicDatePicker from '../../../../components/DateTimePicker'

const TasksFilter = ({ onChange, defaultValues, isDisabled = false }) => {
    const { customer } = useCustomerCtx()
    const { t } = useTranslation(['common', 'task'])
    const { data } = useGetLocalityNames(customer?.id)
    const { data: resources } = useGetAllResources(customer?.id)

    const taskFilterSchema = z.object({
        locality: z.array(z.object({
            id: z.number()
        })).nullable(),
        resource: z.array(z.object({
            id: z.number()
        })).nullable(),
        startDate: z.date().optional().nullable(),
        endDate: z.date().optional().nullable()
    }).refine(data => data.endDate == undefined || data.startDate == undefined ||
        (data.endDate != undefined && data.startDate != undefined && data.endDate > data.startDate), {
        message: t('endStartDateError', { ns: 'common' }),
        path: ['endDate'],
    })

    const form = useZodForm(taskFilterSchema, { defaultValues: { locality: [], resource: [] } })

    useEffect(() => {
        form.reset(defaultValues)
    }, [defaultValues])

    return (
        <Box border={'1px solid gray'} borderRadius={2} marginTop={2} width={'100%'} paddingBottom={1}>
            <AppForm form={form} onSubmit={onChange}>
                <Typography marginTop={-1.5} bgcolor={'white'} width={60} marginLeft={1} paddingLeft={1}>
                    {t('filters')}
                </Typography>
                <Grid container >
                    <Grid container md={11} >
                        <Grid item md={3} padding={1}>
                            <FormSelectField
                                label={t('locality')}
                                variant='outlined'
                                getOptionLabel={row => row.name}
                                data={data || []}
                                name={'locality'}
                                multiple
                            />
                        </Grid>
                        <Grid item md={4} padding={1}>
                            <FormSelectField
                                variant='outlined'
                                getOptionLabel={row => row.name}
                                label={t('resource', { ns: 'task' })}
                                data={resources?.items || []}
                                name={'resource'}
                                multiple
                            />
                        </Grid>
                        <Grid item md={5} paddingTop={0.5}>
                            <Grid container >
                                <Grid item md={6} paddingLeft={1} >
                                    <BasicDatePicker disabled={isDisabled} name='startDate' label={t('startDate')} />
                                </Grid>
                                <Grid item md={6} paddingLeft={1}>
                                    <BasicDatePicker disabled={isDisabled} name='endDate' label={t('endDate')} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={1} padding={1}>
                        <FormSubmitButton createText={t('filter', { ns: 'common' })} />
                    </Grid>
                </Grid>
            </AppForm>
        </Box>
    )
}

export default TasksFilter