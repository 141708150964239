import {
  GridActionsCellItem,
  GridColumns,
  GridNativeColTypes,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  GridRowParams,
} from '@mui/x-data-grid-pro'
import { FC, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { MdDelete, MdEdit } from 'react-icons/md'
import GenericTable, { IToolbarOptions } from '../../../components/table/GenericTable'
import { IServiceStationGroup } from '../../../generated-types'
import { GenericToggle } from '../../../components/GenericToggle'
import { QueryKeyT } from '@lovoldsystem/core.client'

interface IServiceStationGroupTableProps {
  toolbarOptions?: IToolbarOptions[]
  handleRemove?: (id: EntityId) => void
  handleEdit?: (initialValues: IServiceStationGroup) => void
  getDetailPanelContent?: ((params: GridRowParams<any>) => React.ReactNode) | undefined
  queryUrl: string
  queryParams?: object | undefined
  generateQueryKey?:(page:number,pageSize:number)=>QueryKeyT | undefined 
}
export const ServiceStationGroupsTable: FC<IServiceStationGroupTableProps> = ({
  toolbarOptions,
  handleRemove,
  handleEdit,
  getDetailPanelContent,
  queryUrl,
  queryParams,
  generateQueryKey,
}) => {
  const { t } = useTranslation(['common'])
  const renderActions = useCallback(({ row, id }) => {
    const actions: JSX.Element[] = []
    if (handleEdit) {
      actions.push(
        <GridActionsCellItem
          key={id}
          label={t('edit', { ns: 'common' })}
          icon={<MdEdit size={24} />}
          onClick={() => {
            handleEdit(row)
          }}
          color="primary"
        />,
      )
    }
    if (handleRemove) {
      actions.push(
        <GridActionsCellItem
          key={id}
          label={t('delete', { ns: 'common' })}
          icon={<MdDelete size={24} />}
          onClick={() => {
            handleRemove(row.id)
          }}
          color="primary"
        />,
      )
    }
    return actions
  }, [])

  const STATIC_COLUMNS: GridColumns = [
    { field: 'name', headerName: t('name', { ns: 'common' }), flex: 1 },
    { field: 'description', headerName: t('description', { ns: 'common' }), flex: 1 },
  ]

  const columns: GridColumns = useMemo(
    () => [
      {
        ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
        flex: 0.2,
        align: 'center',
        renderCell: params => (
          <GenericToggle
            id={params.id}
            value={params.value}
            expandTitle={t('view-service-station-group', { ns: 'service-station-group' })}
          />
        ),
      },
      ...STATIC_COLUMNS,
      {
        field: 'actions',
        type: 'actions' as GridNativeColTypes,
        headerName: t('actions', { ns: 'common' }),
        flex: 0.5,
        getActions: renderActions,
      },
    ],
    [t],
  )

  return (
    <GenericTable
      id="service-station-groups-table"
      pageSize={10}
      pageOptions={[10, 25, 50]}
      queryUrl={queryUrl}
      queryParams={queryParams}
      generateQueryKey={generateQueryKey}
      columns={columns.filter(c => handleEdit || handleRemove || c.field !== 'actions') as GridColumns}
      enableToolbar={true}
      getDetailPanelContent={getDetailPanelContent}
      getDetailPanelHeight={() => 'auto'}
      enablePagination
      enableServerPagination
      toolbarOptions={toolbarOptions}
    />
  )
}
