import { Box, Button, IconButton, Stack, Tooltip, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { showDialog } from '../../show-dialog'
import FormSchemasDialog from './FormSchemasDialog'
import { useParams } from 'react-router-dom'
import { IFormSchema, IFormSchemaDto, IFormSchemaReport, formSchemaReportTypes } from '../../generated-types/form-schema'
import { GridColumns } from '@mui/x-data-grid-pro'
import {
  invalidateSchemaForm,
  useBulkExcelExport,
  useDeleteFormSchemaReportById,
  useGetFormSchemaReports,
} from '../../api/form-schema'
import ReportViewer from './ReportViewer'
import { AddCircleOutline, Delete, Download, InfoOutlined } from '@mui/icons-material'
import { makeStyles } from '@material-ui/core'
import { downloadFileLocal } from '../../utils/downloadFile'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Spinner } from '../Spinner'
import { useDialogCtx } from '../../hooks/context-hooks'
import { toast } from 'react-toastify'
import { invalidateLocalityFrequencyCache } from '../../api/locality-frequency'
import { AuthorizationProvider } from '../common/Authorization'
import { AuthorizationType } from '../../enums/auth'
import { CommonPageLayout } from '../common/CommonPageLayout'
import { Padding } from 'src/styles/paddingComponent'
import { ITaskFormSchema } from 'src/generated-types/taskFormSchema'
import { getThemeColor } from 'src/utils'
import { MUIThemeColors } from 'src/mui-theme'
import { CompressedTable } from '../table/CompressedTable'

interface IOverallReport {
  deviationId?: string | EntityId
  localityFrequencyId?: number
  groupId?: string
  mainComponentId?: string
  type: formSchemaReportTypes
  formInitialValues: object
  disabled?: boolean
  title?: string
  context?: React.Context<any> | null
  usedSchemas?: (schemas: (number | undefined)[] | undefined) => void
  scheduledTaskId?: number
  requiredReports?: ITaskFormSchema[]
  createPermissions: number[]
  deletePermissions: number[]
  height?: number
}

const useStyle = makeStyles(() => ({
  button: {
    cursor: 'pointer',
    color: 'gray',
    '&:hover': {
      color: '#00526B',
    },
    margin: 5,
  },
}))

const OverallReport: React.FC<IOverallReport> = ({
  deviationId,
  type,
  localityFrequencyId,
  groupId,
  formInitialValues,
  mainComponentId,
  disabled,
  title,
  usedSchemas = null,
  scheduledTaskId,
  context,
  requiredReports,
  createPermissions,
  deletePermissions,
  height = 300,
}) => {
  const { id, customerId, localityId } = useParams()
  const [bulkUploadLoading, setBulkUploadLoading] = useState(0)
  const { t } = useTranslation(['overall-reports', 'task'])
  const { showConfirmDialog } = useDialogCtx()
  const classes = useStyle()
  const theme = useTheme()
  const { data, isLoading } = useGetFormSchemaReports(customerId ?? (id as EntityId), {
    type,
    localityFrequencyId,
    groupId,
    deviationId: deviationId as string | undefined,
    mainComponentId,
    scheduledTaskId,
  })
  const { mutateAsync: bulkExport } = useBulkExcelExport(customerId as EntityId ?? (id as EntityId))
  const { mutateAsync: deleteReport } = useDeleteFormSchemaReportById(customerId as EntityId ?? (id as EntityId))

  const requiredReportsList = useMemo(() => requiredReports
    ?.map((value, index) => {
      if (index === requiredReports?.length - 1) {
        return value.formSchema.name
      } else {
        return value.formSchema.name + ' | '
      }
    })
    .join(''), [requiredReports])

  const handleExport = async (row: IFormSchemaDto) => {
    setBulkUploadLoading(row.id as number)
    await bulkExport({
      formSchemaId: row.id,
      type,
      localityFrequencyId,
      groupId,
      deviationId,
      mainComponentId,
      scheduledTaskId,
      userTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    })
      .then(res => {
        downloadFileLocal(res, row?.name)
      })
      .finally(() => {
        setBulkUploadLoading(0)
      })
  }

  const onAdd = () => {
    showDialog(FormSchemasDialog, {
      componentProps: {
        customerId: customerId ?? (id as EntityId),
        localityId: localityId as any,
        property: {
          deviationId: deviationId,
          localityFrequencyId: localityFrequencyId,
          groupId: groupId,
          mainComponentId: mainComponentId,
          type: type,
          scheduledTaskId: scheduledTaskId,
        },
        formInitialValues: formInitialValues
      },
    })
  }

  const handleDelete = useCallback((id: number) => {
    showConfirmDialog(
      t('deleteFormSchemaReportTitle', { ns: 'form-schema-report' }),
      t('deleteFormSchemaReportMessage', { ns: 'form-schema-report' }),
      {
        acceptText: t('yes', { ns: 'common' }),
        cancelText: t('no', { ns: 'common' }),
      },
      async pop => {
        await deleteReport(id)
          .then(() => {
            toast.success(t('form-schema-report-deleted', { ns: 'form-schema-report' }))
            invalidateSchemaForm.getFormSchemaReports(customerId as EntityId)
            invalidateLocalityFrequencyCache.getAllLocalityFrequencies(customerId as EntityId, localityId as EntityId)
            pop()
          })
          .catch(() => {
            toast.error(t('error-deleting-form-schema-report', { ns: 'form-schema-report' }))
          })
      },
    )
  }, [])

  useEffect(() => {
    const formSchemaIds = data
      ?.filter(s => s?.formSchemaReports != null && s?.formSchemaReports?.length > 0)
      .map(s => s.id)
    if (usedSchemas) {
      usedSchemas(formSchemaIds)
    }
  }, [data])

  const renderExpandedRow = useCallback(
    ({ row: schema }) => {
      return schema?.formSchemaReports != undefined && schema?.formSchemaReports?.length > 0 ? (
        <Box marginLeft={2} marginTop={1} paddingBottom={2}>
          {schema?.formSchemaReports?.map((report: IFormSchemaReport) => {
            return (
              <Stack key={report.id} direction={'row'} style={{ background: 'white', cursor: 'pointer' }} mr={1}>
                <Box
                  width={'100%'}
                  key={report?.id}
                  display={'flex'}
                  flexDirection={'row'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                  marginTop={1}
                  padding={1}
                  onClick={() => {
                    showDialog(ReportViewer, {
                      componentProps: {
                        layouts: JSON.parse(report?.formSchemaVersion?.layout || ''),
                        schema: schema,
                        report: report,
                        disabled: disabled,
                        createdAt: report?.createdAt,
                        createdByUser: report?.createdByUser,
                      },
                    })
                  }}
                >
                  <Box flex={1}>
                    <Typography>{`${report?.createdByUser?.firstName} ${report?.createdByUser?.lastName}`}</Typography>
                  </Box>
                  <Box flex={1}>
                    <Typography>{`${new Date(report?.createdAt + 'Z' || '').toLocaleString()}`}</Typography>
                  </Box>
                </Box>
                <AuthorizationProvider Context={context} permissions={deletePermissions} type={AuthorizationType.Disabled}>
                  <IconButton
                    className={classes.button}
                    onClick={async () => {
                      handleDelete(report.id as number)
                    }}
                    disabled={disabled}
                  >
                    <Delete />
                  </IconButton>
                </AuthorizationProvider>
              </Stack>
            )
          })}
        </Box>
      ) : null
    },
    [classes.button, disabled, handleDelete],
  )

  const onComplete = (row: IFormSchema | null | undefined) => {
    showDialog(FormSchemasDialog, {
      componentProps: {
        customerId: customerId ?? (id as EntityId),
        localityId: localityId as any,
        property: {
          deviationId: deviationId,
          localityFrequencyId: localityFrequencyId,
          groupId: groupId,
          mainComponentId: mainComponentId,
          type: type,
          scheduledTaskId: scheduledTaskId,
        },
        formInitialValues: formInitialValues,
        selectedSchemaForm: row
      },
    })
  }

  const STATIC_COLUMNS: GridColumns = [
    { field: 'name', headerName: t('name', { ns: 'common' }), flex: 1 },
    {
      field: 'description',
      headerName: t('description', { ns: 'common' }),
      flex: 1,
    },
    {
      field: 'actions',
      headerName: '',
      width: 140,
      renderCell: ({ row }) => {
        return row?.formSchemaReports == undefined ?
          <Padding.p8>
            <Button variant='outlined' sx={{ height: '30px' }} onClick={() => onComplete(row)} disabled={disabled}>
              {t('complete')}
            </Button>
          </Padding.p8>
          : bulkUploadLoading == row.id ? (
            <Spinner size={40} />
          ) : (
            <Box display={'flex'} flexDirection={'row'} justifyContent={'space-evenly'} width={180}>
              <AuthorizationProvider Context={context} permissions={createPermissions} type={AuthorizationType.Disabled}>
                <IconButton className={classes.button} onClick={() => handleExport(row)} disabled={disabled}>
                  <Download />
                </IconButton>
              </AuthorizationProvider>
            </Box>
          )
      },
    },
  ]

  const rows = useMemo(() => {
    const returnArray = data?.filter(s => s?.formSchemaReports != null && s?.formSchemaReports?.length > 0) || []
    const requiredArray = requiredReports
      ?.map(d => ({ ...d.formSchema, id: d.formSchemaId } as IFormSchema))
      .filter(ra => !returnArray.find(r => r.name == ra.name))

    return requiredArray ? requiredArray?.concat(returnArray as IFormSchema[]) : returnArray
  }, [data])

  return (
    <CommonPageLayout
      titleSection={{ subtitle: title ? title : t('overall-reports') }}
      topAction={[
        {
          buttonText: t('new-form'),
          buttonIcon: <AddCircleOutline />,
          onClick: onAdd,
          context: context,
          disabled: disabled,
          permissionBinaryValues: createPermissions,
          variant: 'outlined',
          shadowWeight: 0,
        },
      ]}
      warningNote={requiredReports != null ? <Tooltip sx={{ cursor: 'pointer' }} title={`${t('rerquired-reports', { ns: 'task' })} ${requiredReportsList}`} >
        <InfoOutlined />
      </Tooltip> : undefined}
    >
      <Padding.p8 borderRadius={'4px'} bgcolor={getThemeColor(theme,MUIThemeColors.white)}>
        <CompressedTable
          id={'overall-report-table'}
          columns={STATIC_COLUMNS}
          data={rows}
          loading={isLoading}
          autoHeight={false}
          getDetailPanelHeight={() => 'auto'}
          height={height}
          className={'scrollbar'}
          getDetailPanelContent={params => renderExpandedRow(params)}
          getRowHeight={() => 40}
          styledTable={true}
          getRowClassName={params => (params.indexRelativeToCurrentPage % 2 == 0 ? 'even' : 'odd')}
        />
      </Padding.p8>
    </CommonPageLayout>
  )
}

export default OverallReport
