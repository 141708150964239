import { t as translate } from 'i18next'
import { useGetPositions } from '../../../api/positions'
import { GridColumns } from '@mui/x-data-grid-pro'
import { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'
import { IMooring, IPosition, IPositionType } from '../../../generated-types'
import { APIResultCount } from '../../../constant'
import { useFormContext } from 'react-hook-form'
import { useGetMoorings } from 'src/api/moorings'
import { Flex } from 'src/styles/flexComponent'
import MooringSelectCard from '../MooringSelectCard'
import { Padding } from 'src/styles/paddingComponent'
import GenericTable from 'src/components/table/GenericTable'
import { PositionTableFilter } from 'src/screens/localities/moorings/PositionTableFilter'
import { Box } from '@mui/material'

interface ISelectPositionStepProps {
  customerId: EntityId
  localityId: EntityId
  setDisabled: Dispatch<SetStateAction<boolean>>
  handleNext: () => void
}
const SelectPositionStep: FC<ISelectPositionStepProps> = ({ customerId, localityId, setDisabled, handleNext }) => {
  const { getValues, reset } = useFormContext()
  const [filteredTypes, setFilteredTypes] = useState<IPositionType[]>([])
  const [mooringId, setMooringId] = useState<number>()
  const { data: positions, isLoading, refetch } = useGetPositions(customerId, localityId, mooringId, 0, 1000)
  const { data: moorings } = useGetMoorings(
    customerId,
    localityId,
    { getOnlyActiveMooring: true },
    0,
    APIResultCount.Max,
  )

  const filteredPositions = useMemo(() => {
    return filteredTypes.length === 0
      ? positions?.items
      : positions?.items?.filter(p => filteredTypes.includes(p.type)) ?? []
  }, [filteredTypes, positions?.items])

  const STATIC_COLUMNS: GridColumns = useMemo(
    () => [
      {
        field: 'name',
        headerName: translate('name', { ns: 'common' }),
        flex: 1,
        cellClassName: 'font-weight-semi-bold',
        filterable: true,
      },
      {
        field: 'type',
        headerName: translate('type', { ns: 'common' }),
        flex: 1,
        valueGetter: value => translate(IPositionType[value.value]),
      },
      {
        field: 'mooringName',
        headerName: translate('mooring', { ns: 'common' }),
        flex: 1,
      },
    ],
    [],
  )

  const columns: GridColumns = useMemo(() => [...STATIC_COLUMNS], [])

  useEffect(() => {
    if (getValues('position')?.id === undefined) {
      setDisabled(true)
    } else {
      setDisabled(false)
    }
  }, [getValues('position'), setDisabled])

  useEffect(() => {
    refetch()
  }, [getValues('mooring')?.id])

  const handleRowClick = useCallback(
    params => {
      if (params.row.id !== getValues('position')?.id) {
        reset({
          ...getValues(),
          position: params.row as IPosition,
          mooring: { id: params.row.mooringId, name: params.row.mooringName } as IMooring,
          component: undefined,
          mainComponent: undefined,
        })
      } else {
        reset({
          ...getValues(),
          position: params.row as IPosition,
          mooring: { id: params.row.mooringId, name: params.row.mooringName } as IMooring,
        })
      }
      setDisabled(false)
      handleNext()
    },
    [handleNext, setDisabled],
  )

  return (
    <Box width={'100%'}>
      <Padding.pv16>
        <Flex.Row overflow={'auto'} className='p-8-core' >
          {moorings?.items.map(mooring => (
            <Padding.pr16 key={mooring.id} >
              <MooringSelectCard mooring={mooring} onClick={() => setMooringId(mooring.id)} isSelected={mooring?.id == mooringId} />
            </Padding.pr16>
          ))}
        </Flex.Row>
      </Padding.pv16>
      <GenericTable
        columns={columns}
        data={[...([...((filteredPositions) || [])]?.sort(x => parseInt(x.name))?.reverse() || [])] as IPosition[]}
        enableToolbar={true}
        height={400}
        styledTable={true}
        enableToolbarFilter={true}
        className={'scrollbar'}
        toolbarFilter={<PositionTableFilter setFilteredTypes={setFilteredTypes} filteredTypes={filteredTypes} />}
        loading={isLoading}
        onRowClick={handleRowClick}
        selectionModel={getValues('position') ? [getValues('position').id] : []}
        id={'create-deviation-positions'}
        autoHeight={false}
      />
    </Box>
  )
}

export default SelectPositionStep
