import { t as translate } from 'i18next'
import { IDeviationType } from '../../generated-types/deviation-type'
import { z } from 'zod'

export const deviationSchema = z.object({
  type: z
    .nativeEnum(IDeviationType, {
      errorMap: () => {
        return { message: translate('selectOneOfTheGivenOptions', { ns: 'common' }) }
      },
    })
    .or(z.string())
    .or(z.number())
    .transform(value => {
      if (typeof value === 'string') {
        return parseInt(value)
      } else {
        return value
      }
    }),
  positionId: z
    .any()
    .optional()
    .transform(val => (val === '' ? undefined : Number(val))),
  localityFrequencyIds: z.any().optional(),
  localityId: z
    .any()
    .optional()
    .transform(val => (val === '' ? undefined : Number(val))),
  mooringId: z
    .any()
    .optional()
    .transform(val => (val === '' ? undefined : Number(val))),
  componentId: z
    .any()
    .optional()
    .transform(val => (val === '' ? undefined : Number(val))),
  mainComponentId: z
    .any()
    .optional()
    .transform(val => (val === '' ? undefined : Number(val))),
  scheduledTaskId: z
    .any()
    .optional()
    .transform(val => (val === '' ? undefined : Number(val))),
  title: z.string().nonempty(translate('provideValidInput', { ns: 'common' })),
  priority: z
    .object({
      id: z.number(),
    })
    .transform(value => value.id),
  responsiblePersonId: z
    .object(
      {
        id: z.string().nonempty(translate('selectOneOfTheGivenOptions', { ns: 'common' })),
      },
      {
        required_error: translate('selectOneOfTheGivenOptions', { ns: 'common' }),
        invalid_type_error: translate('selectOneOfTheGivenOptions', { ns: 'common' }),
      },
    )
    .transform(value => value.id),
  dueDate: z.date(),
  visualDeviation: z.boolean().optional(),
  measuredDeviation: z.boolean().optional(),
  normalWear: z.boolean().optional(),
  abnormalWear: z.boolean().optional(),
  keywords: z
    .any({
      errorMap: () => {
        return { message: translate('selectOneOfTheGivenOptions', { ns: 'common' }) }
      },
    })
    .optional(),
  measure: z
    .object({
      id: z.number(),
    })
    .transform(value => value.id),
  eventSupervision: z
    .object({
      id: z.number(),
    })
    .transform(value => value.id),
  performedBy: z.string().nonempty(translate('provideValidInput', { ns: 'common' })),
  description: z.string().nonempty(),
  reason: z.string(),
  measures: z.string(),
  fileIds: z.union([z.array(z.string()), z.array(z.number())]),
  position: z.any().optional(),
  mooring: z.any().optional(),
  component: z.any().optional(),
  mainComponent: z.any().optional(),
  locality: z.any().optional(),
})
