import { useTranslation } from 'react-i18next'
import { ShowDialogProps } from '../../../../show-dialog'
import { toast } from 'react-toastify'
import { APIResultCount } from '../../../../constant'
import { invalidationActivityLogCache, useAddCustomCageLog } from 'src/api/activity-log'
import { CustomCageLogForm } from './CustomCageLogForm'

interface CageCustomLogAddDialogProps extends ShowDialogProps {
  positionId: EntityId
  customerId: EntityId
  localityId: EntityId
  mooringId: EntityId
}

export const CageCustomLogAddDialog: React.FC<CageCustomLogAddDialogProps> = ({
  pop,
  positionId,
  customerId,
  localityId,
  mooringId,
}) => {
  const { t } = useTranslation(['activity-logs', 'common'])
  const mutation = useAddCustomCageLog()

  const onSubmit = async form => {
    form.localityId = Number(localityId)
    form.positionId = Number(positionId)

    await mutation.mutateAsync(form, {
      onSuccess: () => {
        invalidationActivityLogCache.getCageActivityLogs({
          page: 0,
          pageSize: APIResultCount.Max,
        })
        toast.success(t('success-adding-custom-cage-log', { ns: 'activity-logs' }))
        pop()
      },
      onError: () => {
        toast(t('error-adding-custom-log', { ns: 'activity-logs' }), { type: 'error' })
        pop()
      },
    })
  }

  return (
    <CustomCageLogForm
      title={t('create-cage-custom-logs', { ns: 'activity-logs' })}
      customerId={customerId}
      localityId={localityId}
      mooringId={mooringId}
      positionId={positionId}
      onSubmit={onSubmit}
      onCancel={() => pop()}
    />
  )
}
