import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { useCreateMainComponent, invalidateMainComponentCache } from '../../../api/main-component'
import { invalidateMainComponentTypeCache } from '../../../api/main-component-type'
import { IMainComponent } from '../../../generated-types/main-component'
import { ShowDialogProps } from '../../../show-dialog'
import MainComponentForm from './MainComponentFrom'
import { getTranslatedText } from '../../../utils/translations'

interface CreateMainComponentDialogProps extends ShowDialogProps {
  customerId: EntityId
  componentTypeId?: number
  mainComponentTypeName?: string
  initialValues?: IMainComponent
}
export const CreateMainComponentDialog: React.FC<CreateMainComponentDialogProps> = ({
  pop,
  customerId,
  componentTypeId,
  mainComponentTypeName,
  initialValues,
}) => {
  const { t } = useTranslation(['main-component', 'common', 'certificate'])

  const mutation = useCreateMainComponent(customerId)

  useEffect(() => {
    if (mutation.error != null) {
      if (mutation.error['data'] == 'SameMainComponentName') {
        toast(t('sameMainComponentName'), { type: 'error' })
      }
      if (mutation.error['data'] == 'SerialNumberExists') {
        toast(t('sameSerialNumber'), { type: 'error' })
      }
      if (mutation.error['data'] == 'NotAValidCertificate') {
        toast(t('NotAValidCertificate', { ns: 'certificate' }), { type: 'error' })
      }
      if (mutation.error['data'] == 'ValidUntilLessThanCertificateDate') {
        toast(t('ValidUntilLessThanCertificateDate', { ns: 'certificate' }), { type: 'error' })
      }
    }
  }, [mutation.error, t])

  const onSubmit = async (form, isCloneable = false) => {
    await mutation.mutateAsync(form).then(() => {
      toast(t('successfully-added'), { type: 'success' })
      invalidateMainComponentCache.getMainComponentsByComponentType(customerId, form.typeId as EntityId)
      invalidateMainComponentTypeCache.useGetMainComponentTypeWithMainComponentCount(customerId)
      if (!isCloneable) {
        pop()
      }
    })
  }

  return (
    <MainComponentForm
      title={`${t('addMainComponent')} - ${getTranslatedText(mainComponentTypeName)}`}
      onSubmit={onSubmit}
      onSubmitAlter={onSubmit}
      onCancel={() => pop()}
      initialValues={
        initialValues ??
        ({
          mainComponentTypeName: mainComponentTypeName,
          typeId: componentTypeId,
        } as IMainComponent)
      }
      customerId={customerId}
    />
  )
}
