import { useState, useEffect } from 'react'
import {
  useUpdateMainComponent,
  invalidateMainComponentCache,
  useGetMainComponentsByMainComponentId,
} from '../../../api/main-component'
import { CenteredSpinner } from '../../../components/Spinner'
import { ICertificate } from '../../../generated-types/Certificate'
import { ICertificateDocumentType } from '../../../generated-types/certificate-document-type'
import { IMainComponent } from '../../../generated-types/main-component'
import MainComponentForm from './MainComponentFrom'
import { ShowDialogProps } from '../../../show-dialog'
import { useTranslation } from 'react-i18next'
import { getTranslatedText } from '../../../utils/translations'

interface UpdateMainComponentProps extends ShowDialogProps {
  id: EntityId
  mainComponentTypeName: string
  customerId: EntityId
}

export const UpdateMainComponentDialog: React.FC<UpdateMainComponentProps> = ({
  pop,
  id,
  mainComponentTypeName,
  customerId,
}) => {
  const { t: translate } = useTranslation(['main-component', 'common'])
  const mutation = useUpdateMainComponent(customerId as EntityId, id as EntityId)
  const [certificate, setCertificate] = useState<ICertificate>()
  const onSubmit = async form => {
    await mutation.mutateAsync(form)
    invalidateMainComponentCache.getMainComponentsByComponentType(customerId, form.typeId)
    invalidateMainComponentCache.getMainComponentByIdAdmin(customerId, id)
    invalidateMainComponentCache.getMainComponentById(customerId as EntityId, id as EntityId)
    pop(true)
  }

  const { data, isLoading } = useGetMainComponentsByMainComponentId(customerId, id)

  useEffect(() => {
    if (data) {
      const { certificates } = data
      const productCertificate = certificates?.filter(
        c => c.certificateDocumentType == ICertificateDocumentType.ProductCertificate,
      )[0]
      if (productCertificate) {
        setCertificate(productCertificate)
      }
    }
  }, [data])

  if (isLoading) return <CenteredSpinner />
  return (
    <MainComponentForm
      title={`${translate('updateMainComponent')} - ${getTranslatedText(mainComponentTypeName)}`}
      onSubmit={onSubmit}
      onCancel={() => pop()}
      initialValues={
        { ...data, certificate: certificate, mainComponentTypeName: mainComponentTypeName } as IMainComponent
      }
      customerId={customerId}
    />
  )
}
