import React from 'react'
import { GridColumns } from '@mui/x-data-grid-pro'
import { Box, Button, Icon, IconButton, Modal, Stack, Typography } from '@mui/material'
import { MdCancel, MdHighlightOff } from 'react-icons/md'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { useTranslation } from 'react-i18next'
import { ClassNameMap } from '@material-ui/core/styles/withStyles'

interface IColumnOptionsProps {
  id: string
  open: boolean
  handleClose: () => void
  columns: GridColumns
  fixedColumns: string[]
  actionColumns: string[]
  columnOptions: string[]
  setColumnOptions: (columnOptions: string[]) => void
  columnArray: string[]
  setColumnArray: (columnArray: string[]) => void
  setColumns: (columns: GridColumns) => void
  classes: ClassNameMap
}
export const ColumnOptions: React.FC<IColumnOptionsProps> = ({
  id,
  open,
  handleClose,
  columns,
  fixedColumns,
  actionColumns,
  columnOptions,
  setColumnOptions,
  columnArray,
  setColumnArray,
  setColumns,
  classes,
}) => {
  const { t } = useTranslation(['generic-table', 'common'])

  const ColumnOptionRow: React.FC<{ value: string; isColumnOption?: boolean }> = ({ value, isColumnOption = false }) => {
    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
        width={'100%'}
        height={'40px'}
      >
        <Box height={'100%'} width={'100%'}>
          <Autocomplete
            className={classes.textField}
            disablePortal
            id="combo-box-demo"
            options={columnArray}
            value={value}
            onChange={(event, v) => {
              if (v != value && v != null) {
                setColumnArray([...columnArray.filter(a => a != v), value])
                const index = columnOptions.indexOf(value)
                columnOptions[index] = v as string
                setColumnOptions([...columnOptions])
              }
            }}
            sx={{ width: '100%', height: '20px' }}
            renderInput={params => <TextField {...params} label="" />}
            disabled={!isColumnOption}
          />
        </Box>
        <Box width={isColumnOption ? 'auto' : '48px'}>
          {isColumnOption && (
            <IconButton
              className={classes.closeButton}
              onClick={() => {
                setColumnOptions(columnOptions.filter(a => a != value))
                setColumnArray([...columnArray, value])
              }}
            >
              <Icon>
                <MdHighlightOff />
              </Icon>
            </IconButton>
          )}
        </Box>
      </Stack>
    )
  }

  return (
    <Modal
    open={open}
    onClose={handleClose}
    aria-labelledby="json-modal-title"
    aria-describedby="json-modal-description"
  >
    <div className={classes.columnSelector}>
      <Stack direction="column" justifyContent="space-between" spacing={2} height={'100%'}>
        <Box>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} width={'100%'}>
            <Typography variant="h6" className={classes.title}>
              {t('column-options')}
            </Typography>
            <IconButton className={classes.title}>
              <Icon>
                <MdCancel
                  onClick={() => {
                    handleClose()
                  }}
                />
              </Icon>
            </IconButton>
          </Stack>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={2}
            className={classes.columnOptions}
          >
            <Typography variant="body1" className={classes.optionTitle}>
              {t('add-or-remove-columns')}
            </Typography>
            {fixedColumns.map((value, index) => {
              return <ColumnOptionRow key={index} value={value} />
            })}
            {columnOptions.map((value, index) => {
              return <ColumnOptionRow key={index} value={value} isColumnOption={true} />
            })}
            {actionColumns.map((value, index) => {
              return <ColumnOptionRow key={index} value={value} />
            })}
            {columnArray.length != 0 && (
              <Box
                onClick={() => {
                  setColumnOptions([...columnOptions, columnArray[0]])
                  setColumnArray(columnArray.filter((a, i) => i != 0))
                }}
                sx={{ cursor: 'pointer' }}
              >
                <Typography variant="body1" className={classes.optionTitle}>
                  {`+ ${t('add-column')}`}
                </Typography>
              </Box>
            )}
          </Stack>
        </Box>
        <Stack direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={2} width={'100%'}>
          <Button
            variant="contained"
            onClick={() => {
              setColumns(
                columns
                  .filter(
                    f =>
                      columnOptions.includes(f.headerName as string) &&
                      !fixedColumns?.includes(f.headerName as string) &&
                      !actionColumns?.includes(f.headerName as string),
                  )
                  .sort(
                    (a, b) =>
                      columnOptions.findIndex(c => c == a.headerName) -
                      columnOptions.findIndex(c => c == b.headerName),
                  ),
              )
              sessionStorage.setItem(`columnOptions-${id}`, JSON.stringify(columnOptions))
              handleClose()
            }}
          >
            {t('save', { ns: 'common' })}
          </Button>
          <Button onClick={handleClose}>{t('cancel', { ns: 'common' })}</Button>
        </Stack>
      </Stack>
    </div>
  </Modal>
  )
}
