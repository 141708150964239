import { Button, ButtonProps, useTheme } from '@mui/material'
import React from 'react'
import { Spinner } from './Spinner'

export interface MainButtonProps extends ButtonProps {
  loading?: boolean
}

export const MainButton: React.FC<MainButtonProps> = ({ children, variant, loading, disabled, ...rest }) => {
  const theme = useTheme()
  return (
    <Button
      variant={variant || 'contained'}
      disabled={disabled || loading}
      sx={{
        fontSize: {
          sm: '10px',
          md: '12px',
          lg: '14px',
        },
        fontWeight: '600',
        textTransform: 'none',
        borderRadius: '5px',
      }}
      {...rest}
    >
      {loading && <Spinner size={20} />}
      {children}
    </Button>
  )
}
