import { DialogActions, DialogActionsProps, DialogContent, styled, SxProps, Theme } from '@mui/material'
import { CustomDialogTitle } from './GenericDialog'
import { t } from 'i18next'
import { CancelButton } from './CancelButton'

interface IGenericDialogProps {
  title?: React.ReactNode
  pop?: any
  topActions?: React.ReactNode[]
  actions?: React.ReactNode[]
  topActionsProps?: DialogActionsProps
  sx?: SxProps<Theme> | undefined
  removedCloseButton?: boolean
}
export const GenericDialogLayout: React.FC<IGenericDialogProps> = ({
  title,
  children,
  pop,
  topActions,
  actions,
  topActionsProps,
  sx,
  removedCloseButton = true,
}) => {
  return (
    <>
      {title && <CustomDialogTitle onClose={pop}>{title}</CustomDialogTitle>}
      {topActions && <DialogActions {...topActionsProps}>{topActions}</DialogActions>}
      <DialogContent sx={{ width: '100%', height: '100% ', ...sx } ?? { padding: 1, width: '100%', height: '100% ' }}>
        {children}
      </DialogContent>
      <DialogActions>
        {[
          !removedCloseButton ? (
            <CancelButton onClick={pop} key="cancel-btn" data-cy='cancel'>
              {t('cancel', { ns: 'common' })}
            </CancelButton>
          ) : (
            <></>
          ),
          ...(actions ? actions : []),
        ]}
      </DialogActions>
    </>
  )
}

export const GenericDialogLayoutStyled: React.FC<IGenericDialogProps> = ({
  title,
  children,
  pop,
  topActions,
  actions,
  removedCloseButton = true,
}) => {
  return (
    <>
      {title && <CustomDialogTitleStyled onClose={pop}>{title}</CustomDialogTitleStyled>}
      {topActions && <DialogTopActionsStyled>{topActions}</DialogTopActionsStyled>}
      <DialogContentStyled sx={{ width: '100%', height: '100% ' }}>
        {children}
      </DialogContentStyled>
      <DialogActionsStyled>
        {[
          !removedCloseButton ? (
            <CancelButton onClick={pop} key="cancel-btn">
              {t('cancel', { ns: 'common' })}
            </CancelButton>
          ) : (
            <></>
          ),
          ...(actions ? actions : []),
        ]}
      </DialogActionsStyled>
    </>
  )
}

const CustomDialogTitleStyled = styled(CustomDialogTitle)`
  padding: 40px;
  padding-bottom:10px;
  & .MuiIconButton-sizeMedium {
    margin-top: 40px;
    margin-right: 20px;
  }
`

const DialogContentStyled = styled(DialogContent)`
  padding-left: 40px;
  padding-right: 40px;
`

const DialogTopActionsStyled = styled(DialogContent)`
  padding-left: 40px;
  padding-right: 40px;
`

const DialogActionsStyled = styled(DialogActions)`
  padding: 40px;
  padding-top:10px;
`