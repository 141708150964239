import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useCustomerCtx } from '../../../hooks/context-hooks'
import { LocalityGroupsTable } from './LocalityGroupsTable'
import { useDialogCtx } from 'src/hooks/context-hooks'
import { CreateLocalityGroupDialog } from './CreateLocalityGroupDialog'
import { FC } from 'react'
import { ResourceScreenProps } from '../../../generated-types/resource-screen'
import {
  invalidateLocalityGroupsQueries,
  localityGroupsEndpoints,
  useDeleteLocalityGroup,
} from '../../../api/locality-groups'
import { toast } from 'react-toastify'
import UpdateLocalityGroup from './UpdateLocalityGroup'
import LocalityGroupDetailPanel from './LocalityGroupDetailPanel'
import { ILocalityGroup } from '../../../generated-types'
import { ScreenTitleBar } from '../../localities/components/ScreenTitleBar'

export const LocalityGroupsScreen: FC<ResourceScreenProps> = ({
  renderTableExpand,
  query,
  params,
  generateQueryKey,
  handleUpdate,
  handleDelete,
  handleAdd,
  isActionAvailable = true,
  isTableExpandable = true,
}) => {
  const { customer } = useCustomerCtx()
  const { t } = useTranslation('common')
  const { showConfirmDialog, showDialog } = useDialogCtx()
  const { mutateAsync: deleteGroup } = useDeleteLocalityGroup(customer?.id)

  const onAdd = async () => {
    const added = await showDialog(
      CreateLocalityGroupDialog,
      {
        title: t('create-locality-group', { ns: 'access-control' }),
        opts: {
          maxWidth: 'md',
          fullWidth: true,
        },
        componentProps: {
          customerId: customer.id as EntityId,
        },
      },
      undefined,
    )
    if (!added) return
  }

  const deleteLocalityGroupAction = async (id:EntityId) => {
    showConfirmDialog(
      t('delete-confirm-locality-group-title', { ns: 'locality-group' }),
      t('delete-confirm-locality-group-description', { ns: 'locality-group' }),
      { acceptText: t('yes', { ns: 'common' }), cancelText: t('no', { ns: 'common' }) },
      async pop => {
        await deleteGroup(id)
        invalidateLocalityGroupsQueries.invalidateGetLocalityGroups(customer?.id)
        toast(t('successfullyRemoved', { ns: 'common-api-message' }), { type: 'success' })
        pop()
      },
    )
  }

  const editLocalityGroup = async id => {
    showDialog(UpdateLocalityGroup, {
      componentProps: {
        id: id,
        customerId: customer.id as EntityId,
      },
      ops: {
        maxWidth: 'md',
      },
      title: t('update-locality-group', { ns: 'access-control' }),
    })
  }

  return (
    <Box className="mb-2 py-2 px-4 mt-2 bg-white shadow-md">
      <ScreenTitleBar
        title={t('locality-groups', { ns: 'locality-group' })}
        addNewItemText={t('add', { ns: 'common' })}
        onAddNewItemClick={handleAdd ?? onAdd}
      />
      <LocalityGroupsTable
        onUpdate={isActionAvailable ? handleUpdate ?? editLocalityGroup : undefined}
        onDelete={isActionAvailable ? handleDelete ?? deleteLocalityGroupAction : undefined}
        queryUrl={query ?? localityGroupsEndpoints.getLocalityGroups(customer.id)}
        queryParams={params}
        generateQueryKey={generateQueryKey}
        getDetailPanelContent={
          isTableExpandable
            ? renderTableExpand ?? (params => <LocalityGroupDetailPanel localityGroup={params.row as ILocalityGroup} />)
            : undefined
        }
      />
    </Box>
  )
}
