import { Box, Button, Typography, useTheme } from '@mui/material'
import { FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { invalidateDocument, useGetDocument, useUpdateDocument } from 'src/api/document'
import { GenericDialogLayout } from 'src/components/GenericDialogLayout'
import { ShowDialogProps } from 'src/show-dialog'
import { DocumentDetailsSection } from './DocumentDetailsSection'
import { DocumentHistoryTable } from '../DocumentHistoryTable'
import { Flex } from 'src/styles/flexComponent'
import { MdEdit } from 'react-icons/md'
import { DocumentEditSection } from './DocumentEditSection'
import { IDocument } from 'src/generated-types/document'
import { invalidateDocumentCategoryCache } from 'src/api/document-category'
import { invalidateLocalityCache } from 'src/api/localities'
import { invalidateDocumentCategory } from 'src/api/users/document-category'
import { useZodForm } from 'src/hooks/zod-form'
import { z } from 'zod'
import { useGetAllMooringsByLocalityId } from 'src/api/moorings'
import { AppForm } from 'src/components/FormCore'

interface DocumentDetailDialogProps extends ShowDialogProps {
  id: EntityId
}

export const DocumentDetailDialog: FC<DocumentDetailDialogProps> = ({ pop, id }) => {
  const { t } = useTranslation(['common', 'document'])
  const { customerId, localityId, positionId } =
    useParams<{ customerId: string; localityId: string; positionId: string }>()
  const theme = useTheme()
  const { data: document } = useGetDocument(customerId as EntityId, id as EntityId)
  const [editMode, seteditMode] = useState(false)
  const mutate = useUpdateDocument(customerId as EntityId, document?.id as EntityId)
  const { data: moorings } = useGetAllMooringsByLocalityId(customerId as EntityId, localityId as EntityId)

  const renderSectionHeader = (title: string) => (
    <Box mb={2} mt={2} color={theme.palette.primaryMain[theme.palette.mode]}>
      <Typography fontWeight="600" variant="h5">
        {title}
      </Typography>
    </Box>
  )

  const schema = z.object({
    title: z.string().min(1),
    documentCategoryId: z
      .object({
        id: z.number(),
      })
      .transform(value => value.id),
    mooringId: z
      .object({
        id: z.number(),
      })
      .or(z.null())
      .optional()
      .transform(value => (typeof value == 'object' ? value?.id : value)),
    dueDate: z.date().or(z.string().nullable()).optional(),
    notes: z.string().optional(),
    version: z.string().optional(),
    fileId: z.number().default(document?.fileId as number),
  })

  const form = useZodForm(schema, {
    defaultValues: (() => {
      const { documentCategory, mooringId, ...rest } = (document ?? {}) as IDocument
      return { ...rest, documentCategoryId: documentCategory, mooringId: moorings?.find(m => m.id == mooringId) }
    })(),
  })

  const onSubmit = useCallback(
    async form => {
      await mutate.mutateAsync(form)
      if (document?.documentCategory?.id !== form.documentCategoryId) {
        invalidateDocumentCategoryCache.getDocumentCategoriesWithDocuments()
        invalidateDocument.getDocuments(customerId as EntityId)
      } else {
        invalidateDocument.getDocuments(customerId as EntityId)
      }
      invalidateDocument.getDocumentsHistory(customerId as EntityId, document?.id as EntityId)
      invalidateDocument.getExpireCounts(customerId, localityId)
      invalidateDocumentCategory.getDocumentCategoriesWithDocuments(customerId, { positionId })
      invalidateLocalityCache.getLocalitiesWithInfoCount(customerId)
      seteditMode(false)
    },
    [mutate, document?.documentCategory?.id, document?.id, customerId, localityId, positionId],
  )

  const changeMode = () => {
    seteditMode(true)
  }

  const handleCancel = () => {
    seteditMode(false)
  }

  const handleSave = () => {
    form.handleSubmit(onSubmit)()
  }

  return (
    <GenericDialogLayout
      title={t('document-details', { ns: 'document' })}
      pop={pop}
      topActions={[
        <Flex.Row
          width={'100%'}
          key={'actions'}
          justifyContent={'space-between'}
          alignItems={'center'}
          marginLeft={'16px'}
        >
          {document && renderSectionHeader(document.title ?? '')}
          <Flex.Row alignContent={'center'} justifyContent={'center'} marginRight={'16px'}>
            {!editMode && (
              <Button variant="outlined" onClick={changeMode} startIcon={<MdEdit />}>
                {t('edit')}
              </Button>
            )}
            {editMode && (
              <Box>
                <Button variant="contained" onClick={handleSave}>
                  {t('save')}
                </Button>
                <Button variant="outlined" onClick={handleCancel} sx={{ marginLeft: '8px' }}>
                  {t('cancel')}
                </Button>
              </Box>
            )}
          </Flex.Row>
        </Flex.Row>,
      ]}
    >
      {!editMode && <Box width="100%">{document && <DocumentDetailsSection data={document} />}</Box>}
      {editMode && (
        <AppForm form={form}>
          <DocumentEditSection
            customerId={customerId as EntityId}
            localityId={localityId as EntityId}
            document={document as IDocument}
          />
        </AppForm>
      )}
      {!editMode && (
        <Box>
          {renderSectionHeader(t('document-update-history', { ns: 'document' }))}
          <Box
            sx={{
              height: '300px',
              overflowY: 'auto',
              overflowX: 'hidden',
            }}
          >
            <DocumentHistoryTable
              customerId={customerId as EntityId}
              localityId={localityId as EntityId}
              documentId={id as EntityId}
            />
          </Box>
        </Box>
      )}
    </GenericDialogLayout>
  )
}
