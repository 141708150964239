import { AlertSnackbar } from './components/AlertSnackbar'
import { FC, useMemo, useState } from 'react'
import ReactDOM from 'react-dom'
import {
  AlertColor,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  SxProps,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { MainButton } from './components/MainButton'
import { QueryClientProvider } from 'react-query'
import { queryClient } from './query-client'
import { Box, ThemeProvider } from '@mui/system'
import { muiTheme, MUIThemeColors } from './mui-theme'
import { CancelButton } from './components/CancelButton'
import { GenericDialog } from './components/GenericDialog'
import { generateUUID } from './utils/function'
import { Flex } from './styles/flexComponent'
import { getThemeColor } from './utils'
import { GridActionButton } from './components/common/GridActionButton'
import { Padding } from './styles/paddingComponent'
import { UIProps } from './context/DialogContext'

const getRootEl = () => document.getElementById('dialog-root')!

export const closeAllDialog = () => {
  ReactDOM.unmountComponentAtNode(getRootEl())
}

export interface ShowDialogParams<T> {
  componentProps?: T
  scroll?: 'body' | 'paper'
  pop?: (...args: any[]) => any
  opts?: {
    fullWidth: boolean
    maxWidth: DialogProps['maxWidth']
    fullScreen?: DialogProps['fullScreen']
  }
  isAutoCloseDisabled?: boolean
  id?: string
  sx?: SxProps<Theme> | undefined
}

export function showMessage(msg: string, severity: AlertColor = 'success') {
  const formatted = typeof msg === 'string' ? msg : JSON.stringify(msg)

  ReactDOM.render(<Message msg={formatted} severity={severity} />, getRootEl())
}

interface MessageProps {
  msg: string
  severity?: AlertColor
}

const Message = ({ msg, severity }: MessageProps) => {
  const [open, setOpen] = useState(true)

  const onClose = () => {
    setOpen(false)
    setTimeout(() => {
      ReactDOM.unmountComponentAtNode(getRootEl()!)
    }, 100)
  }

  return <AlertSnackbar open={open} msg={msg} onClose={onClose} severity={severity} />
}

export interface ShowDialogProps<T = any> {
  pop: (...args: any[]) => T
}

export function showDialog<T = any, K = any>(
  Content: FC<K & ShowDialogProps<T>>,
  options: ShowDialogParams<K> = {},
): Promise<T | undefined> {
  console.warn('showDialog : function will be deprecated in future. Please use useDialogCtx')
  const { opts, componentProps, scroll, pop, isAutoCloseDisabled, sx } = options
  return new Promise<any>(resolve => {
    ReactDOM.render(
      <DialogWrapper
        pop={pop ?? resolve}
        scroll={scroll}
        content={Content}
        opts={opts}
        contentProps={componentProps}
        isAutoCloseDisabled={isAutoCloseDisabled}
      />,
      getRootEl(),
    )
  })
}

export function showDialogInDiv<T = any, K = any>(
  Content: FC<K & ShowDialogProps<T>>,
  options: ShowDialogParams<K> = {},
  id: string,
): Promise<T | undefined> {
  const { opts, componentProps, scroll, pop, isAutoCloseDisabled } = options
  const element = document.getElementById(id)
  return new Promise<any>(resolve => {
    ReactDOM.render(
      <DialogWrapper
        pop={pop ?? resolve}
        scroll={scroll}
        content={Content}
        opts={opts}
        contentProps={componentProps}
        isAutoCloseDisabled={isAutoCloseDisabled}
        element={element as any}
      />,
      element,
    )
  })
}

export const DialogWrapper = ({
  content: Content,
  pop,
  scroll = undefined as DialogProps['scroll'],
  contentProps = {},
  opts = {},
  id = '',
  isAutoCloseDisabled = false,
  element = null,
}) => {
  const { fullWidth = true, maxWidth = 'md' } = opts as any
  const theme = useTheme()
  const isSmall = useMediaQuery<any>(theme.breakpoints.down('sm'))
  const onPop = (data?: any) => {
    ReactDOM.unmountComponentAtNode(element ?? getRootEl())
    return pop(data)
  }

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={muiTheme}>
        <Dialog
          // style={{ zIndex: 1000000 }} TODO: Figure out why this was here
          scroll={scroll}
          id={id}
          keepMounted={false}
          open
          onClose={!isAutoCloseDisabled ? () => onPop() : undefined}
          fullWidth={fullWidth}
          fullScreen={isSmall}
          maxWidth={maxWidth}
        >
          <Content {...contentProps} pop={onPop} />
        </Dialog>
      </ThemeProvider>
    </QueryClientProvider>
  )
}

export const DialogContainer = ({
  content: Content,
  pop,
  contentProps = {},
  opts = {},
  isAutoCloseDisabled = false,
  title,
  isWithLayout = false,
  sx = {},
}) => {
  const { maxWidth = 'md', fullWidth, fullScreen } = opts as any
  const theme = useTheme()
  const isSmall = useMediaQuery<any>(theme.breakpoints.down('sm'))
  const onPop = (data?: any) => {
    return pop(data)
  }

  return (
    <GenericDialog
      open
      disableAutoClose={isAutoCloseDisabled}
      onClose={onPop}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      title={title}
      removedCloseButton={true}
      isWithLayout={isWithLayout}
      fullScreen={isSmall || fullScreen}
      maxHeight={isSmall ? '100vh' : '95vh'}
      sx={sx}
    >
      <Content {...contentProps} pop={onPop} />
    </GenericDialog>
  )
}

export const AlertDialog = ({
  title,
  text,
  acceptText = 'Accept',
  cancelText = 'Cancel',
  pop = (status: boolean) => { },
  onConfirm,
}) => {
  const acceptButton = useMemo(
    () => (
      <MainButton key="accept" variant="contained" onClick={() => (onConfirm ? onConfirm(pop) : pop(true))}>
        {acceptText}
      </MainButton>
    ),
    [acceptText, pop, onConfirm],
  )
  return (
    <>
      <GenericDialog
        open
        sx={{
          fontWeight: 'bolder',
        }}
        title={title}
        onClose={() => pop(false)}
        removedCloseButton={cancelText != 'Cancel'}
        actions={
          cancelText != 'Cancel'
            ? [
              <Button key={generateUUID()} onClick={() => pop(false)} variant="outlined">
                {cancelText}
              </Button>,
              acceptButton,
            ]
            : [acceptButton]
        }
      >
        {text && <DialogContentText>{text}</DialogContentText>}
        <DialogActions className="mt-4"></DialogActions>
      </GenericDialog>
    </>
  )
}

export const AlertDialogCore = ({
  title,
  text,
  acceptText = 'Accept',
  cancelText = 'Cancel',
  pop = (status: boolean) => { },
  onConfirm,
  UIProperties
}) => {
  const theme = useTheme()
  const UIProp: UIProps = UIProperties
  return (
    <>
      <Padding.p16>
        <Flex.Row justifyContent={'center'} >
          <Box height={'80px'} width={'80px'} sx={{ backgroundColor: UIProp.iconBGColor }} padding={'15px'} borderRadius={'8px'} >
            {UIProp.Icon}
          </Box>
        </Flex.Row>
      </Padding.p16>
      {title && <Padding.p8><Typography textAlign={'center'} variant='h5' color={getThemeColor(theme, MUIThemeColors.primaryDark)}
        fontWeight={600}>{title}</Typography></Padding.p8>}
      {text && <Padding.p8><Typography textAlign={'center'} variant='subtitle1' >{text}</Typography></Padding.p8>}
      <DialogActions className="mt-4">
        <Flex.Row flex={1}>
          <Padding.p8 width='50%' >
            <GridActionButton width='100%' shadowWeight={0} key={generateUUID()}
              onClick={() => pop(false)} variant="outlined" buttonText={cancelText} height='64px'
              sx={{ border: '1px solid ' + getThemeColor(theme, MUIThemeColors.secondaryDark) }} />
          </Padding.p8>
          <Padding.p8 width='50%' >
            <GridActionButton width='100%' bgColor={UIProp.buttonBGColor}
              buttonTextProps={{ style: { color: UIProp.buttonTextColor } }} shadowWeight={0} key={generateUUID()}
              onClick={() => (onConfirm ? onConfirm(pop) : pop(true))} variant="outlined" buttonText={acceptText} height='64px' />
          </Padding.p8>
        </Flex.Row>
      </DialogActions>
    </>
  )
}

export const AlertDialogContent = ({
  text,
  acceptText = 'Accept',
  cancelText = 'Cancel',
  pop = (status: boolean) => { },
  onConfirm,
}) => {
  const acceptButton = useMemo(
    () => (
      <MainButton key="accept" variant="contained" onClick={onConfirm ? () => {
        onConfirm()
        pop(true)
      } : () => pop(true)}>
        {acceptText}
      </MainButton>
    ),
    [acceptText, pop, onConfirm],
  )
  return (
    <>
      {text && <DialogContentText>{text}</DialogContentText>}
      <DialogActions className="mt-4">
        {cancelText != 'Cancel'
          ? [
            <MainButton variant='text' key="cancel" onClick={() => pop(false)}>
              {cancelText}
            </MainButton>,
            acceptButton,
          ]
          : [acceptButton]}
      </DialogActions>
    </>
  )
}

export const AlertDialogPopup = ({ title, text, acceptText = 'Accept', cancelText = 'Cancel', pop, open, onConfirm }) => {
  return <Dialog open={open}>
    <AlertDialog title={title} text={text} acceptText={acceptText} cancelText={cancelText} pop={pop} onConfirm={onConfirm} />
  </Dialog>
}

export const SimpleDialog = ({ title, children, pop, actions, cancelText, open, maxWidth }) => {
  return (
    <Dialog open={open} fullWidth maxWidth={maxWidth}>
      <DialogTitle sx={{
        fontWeight: 'bolder',
      }}
        className='text-primary'>{title}</DialogTitle>
      <DialogContent>
        {children}
      </DialogContent>
      <DialogActions className='mt-4'>
        {actions}
        <CancelButton variant="text" onClick={() => pop(false)}>
          {cancelText}
        </CancelButton>
      </DialogActions>
    </Dialog>
  )
}

export function showConfirmDialog(
  title: string,
  text?: string,
  { acceptText = undefined, cancelText = undefined }: { acceptText?: string | undefined, cancelText?: string | undefined } = {},
) {
  return new Promise<boolean>(resolve => {
    ReactDOM.render(
      <DialogWrapper
        pop={resolve}
        opts={{ fullWidth: false }}
        content={AlertDialog}
        contentProps={{ title, text, acceptText, cancelText }}
      />,
      getRootEl(),
    )
  })
}
export function showDeleteDialog(
  title: string,
  text?: string,
  { acceptText = 'Delete', cancelText = 'Cancel' } = {},
) {
  return new Promise<boolean>(resolve => {
    ReactDOM.render(
      <DialogWrapper
        pop={resolve}
        opts={{ fullWidth: false }}
        content={AlertDialog}
        contentProps={{ title, text, acceptText, cancelText }}
      />,
      getRootEl(),
    )
  })
}