import React, { useCallback, useEffect, useState } from 'react'
import { invalidateTaskCache, useCreateTask } from '../../../../api/tasks'
import InformationForm from './InformationForm'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { INVALID_END_DATE, SUCCESSFULLY_ADDED } from '../../../../constant'
import { invalidateScheduledTaskCache } from 'src/api/scheduledTask'
import { z } from 'zod'
import { useZodForm } from 'src/hooks/zod-form'
import { taskTypeList } from './task-constants'
import { TaskTypes } from 'src/enums'
import { dateWithoutTimeConvert } from 'src/utils/function'
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { AppForm } from 'src/components/FormCore'
import { GenericDialogLayout } from 'src/components/GenericDialogLayout'
import { ShowDialogProps } from 'src/show-dialog'
import { MainButton } from 'src/components/MainButton'
import { Flex } from 'src/styles/flexComponent'

const durationTimeline = [1, 5, 7, 14, 21, 30, 60, 90, 120, 180, 365]

interface AddTasksProps extends ShowDialogProps {
  customerId: any
  localityId?: EntityId
}

const AddTasksDialog: React.FC<AddTasksProps> = ({ pop, customerId, localityId }) => {
  const { mutateAsync } = useCreateTask(customerId)
  const { t: translate } = useTranslation(['task', 'common'])
  const [formType, setFormType] = useState<TaskTypes>(TaskTypes.Once)
  const [isDurationDisabled, setIisDurationDisabled] = useState(false)

  const genericSchema = z.object({
    id: z.number(),
    name: z.string(),
  })

  useEffect(() => { 
    form.setValue('endDate', undefined)
    form.setValue('interval', undefined)
    setIisDurationDisabled(false)
  }, [formType])

  const taskSchema = z
    .object({
      title: z
        .string()
        .nonempty(translate('nameIsRequired', { ns: 'common' }))
        .max(200),
      description: z
        .string()
        .nonempty(translate('descriptionIsRequired', { ns: 'common' }))
        .max(400),
      localityIds: z.array(genericSchema).min(1),
      responsiblePerson: z
        .object({
          id: z.string(),
          email: z.string().email(),
          firstName: z.string().nullable(),
          lastName: z.string().nullable(),
        })
        .optional(),
      formSchemaIds: z.array(genericSchema).optional().nullable(),
      type: z
        .number()
        .default(TaskTypes.Once),
      interval: z
        .object({
          value: z.number(),
        })
        .optional(),
      startDate: z.date(),
      endDate: z.date().optional(),
      duration: z.string().refine(s => parseInt(s) > 0, { message: translate('required', { ns: 'common' }) }),
      fileId: z.union([z.string(), z.number()]).optional(),
      file: z.any().or(z.null()).optional(),
    })
    .refine(data => data.type == TaskTypes.Once || (data.type == TaskTypes.Repeatable && data?.endDate), {
      message: translate('required', { ns: 'common' }),
      path: ['endDate'],
    })
    .refine(
      data =>
        data.type == TaskTypes.Once ||
        (data.type == TaskTypes.Repeatable &&
          data?.endDate != undefined &&
          dateWithoutTimeConvert(data?.endDate).getTime() >= dateWithoutTimeConvert(data.startDate).getTime()),
      {
        message: translate('endStartDateError', { ns: 'common' }),
        path: ['endDate'],
      },
    )
    .refine(
      values =>
        dateWithoutTimeConvert(values.startDate.toDateString()).getTime() >=
        dateWithoutTimeConvert(new Date(Date.now()).toDateString()).getTime(),
      {
        message: translate('startDateWithDateNowValidation', { ns: 'common' }),
        path: ['startDate'],
      },
    )
    .refine(data => data.type == TaskTypes.Repeatable || data.duration != '', {
      message: translate('required', { ns: 'common' }),
      path: ['duration'],
    })
    .refine(
      data =>
        data.interval == undefined ||
        data.interval.value < 1 ||
        parseInt(data.duration) <= durationTimeline[data.interval.value],
      {
        message: translate('duration-validation', { ns: 'task' }),
        path: ['duration'],
      },
    )

  const form = useZodForm(taskSchema, { defaultValues: { type: formType } })

  const onSubmit = useCallback(
    async form => {
      form.interval = form?.interval?.value
      const { file: _, ...taskValues } = form
      form.localityIds = taskValues.localityIds.map(l => l.id)
      form.responsibleUserId = taskValues.responsiblePerson.id
      form.formSchemaIds = taskValues.formSchemaIds.map(l => l.id)
      await mutateAsync(
        { ...taskValues, ...form },
        {
          onSuccess: ({ data }) => {
            if (data == SUCCESSFULLY_ADDED) {
              toast.success(translate('taskAddedSuccessfully', { ns: 'task' }))
              invalidateTaskCache.getCustomerAdminTaskByFilter(customerId)
              if (localityId) {
                invalidateScheduledTaskCache.useScheduledTasks(localityId)
              }
              invalidateScheduledTaskCache.invalidateAllScheduledTasks(localityId as EntityId)
              pop()
            }
          },
          onError: error => {
            if (error['data'] == INVALID_END_DATE) {
              toast.error(translate('invalid-end-date', { ns: 'task' }))
            } else {
              toast.error(translate('addingError', { ns: 'common' }))
            }
          },
        },
      )
    },
    [mutateAsync, translate, customerId, localityId, pop, formType],
  )

  return (
    <GenericDialogLayout
      title={translate('add-task', { ns: 'task' })}
      pop={pop}
      removedCloseButton={false}
      sx={{ py: 0,pt:0 }}
      actions={[
        <MainButton
          key="locality-form-button"
          variant="contained"
          onClick={async () => {
            form.handleSubmit(await onSubmit)()
          }}
        >
          {translate('add', { ns: 'common' })}
        </MainButton>,
      ]}
      topActions={[
        <Flex.Row key={'top-action-list'} width={'100%'} px={2}>
          <FormControl key={'task-type-buttons-group'}>
            <RadioGroup
              value={formType}
              onChange={(_, v) => {
                setFormType(v as unknown as TaskTypes)
                form.setValue('type', parseInt(v))
              }}
              row
              name="task-type-buttons-group"
              sx={{gap: 2}}
            >
              {taskTypeList.map((taskType, index) => {
                return (
                  <FormControlLabel
                    key={index}
                    value={taskType.value}
                    control={<Radio />}
                    label={`${taskType.option}`}
                  />
                )
              })}
            </RadioGroup>
          </FormControl>
        </Flex.Row>,
      ]}
    >
      <AppForm form={form} onSubmit={onSubmit}>
        <InformationForm customerId={customerId} localityId={localityId} formType={formType} isDurationDisabled={isDurationDisabled}  setIisDurationDisabled={setIisDurationDisabled}/>
      </AppForm>
    </GenericDialogLayout>
  )
}

export default AddTasksDialog
