import { TextField, TextFieldProps, useTheme } from '@mui/material'
import React from 'react'
import { MUIThemeColors } from 'src/mui-theme'
import { getThemeColor } from 'src/utils'

export type AppTextFieldProps = TextFieldProps

export const AppTextField: React.FC<AppTextFieldProps> = ({ InputProps, variant = 'filled', children, ...rest }) => {
  const theme = useTheme()
  return (
    // @ts-ignore
    <TextField
      variant={'filled'}
      {...rest}
      InputLabelProps={{ style: { fontSize: '14px', opacity: '0.5' } }}
      InputProps={{
        ...InputProps,
        disableUnderline: true,
        sx: {
          border: `1px solid ${getThemeColor(theme, MUIThemeColors.secondaryLight)}`,
          borderRadius: '4px',
          fontSize: '14px',
          fontWeight: '500',
        }
      }}
      size="small"
      autoComplete="off"
    >
      {children}
    </TextField>
  )
}
