import { useTranslation } from 'react-i18next'
import { CertificateTable } from '../../Certificates/Components/CertificateTable'
import { permissionValue } from '../../../../enums/permissionValue'
import { CommonPageLayout } from 'src/components/common/CommonPageLayout'
import { AddCircleOutline } from '@mui/icons-material'
import { useTheme } from '@mui/material'
import { getThemeColor } from 'src/utils'
import { MUIThemeColors } from 'src/mui-theme'
import { Padding } from 'src/styles/paddingComponent'

interface CertificateTabProps {
  onAddCertificateForComponent: () => void
  mainComponentId: EntityId
  positionId: EntityId
  customerId: EntityId
  Context?: React.Context<any> | null
}

export default function CertificateTab({
  onAddCertificateForComponent,
  mainComponentId,
  positionId,
  customerId,
  Context = null,
}: CertificateTabProps) {
  const { t } = useTranslation(['certificate', 'common'])
  const theme = useTheme()
  return (
    <CommonPageLayout
      titleSection={{ subtitle: t('Certificates', { ns: 'certificate' }) }}
      topAction={[
        {
          buttonText: t('Certificates', { ns: 'certificate' }),
          buttonIcon: <AddCircleOutline />,
          onClick: onAddCertificateForComponent,
          permissionBinaryValues: [permissionValue.Locality.MainComponent.Certificate.Write, permissionValue.ClientAdminPermission, permissionValue.ServiceStation.Write],
          variant: 'outlined',
          shadowWeight: null,
          context: Context
        },
      ]}
    >
      <Padding.p10 border={`1px solid ${getThemeColor(theme, MUIThemeColors.secondaryLight)}`}>
        <CertificateTable
          mainComponentId={mainComponentId!}
          positionId={positionId}
          customerId={customerId}
          certificatesParams={{}}
          height={window.innerHeight / 3}
        />
      </Padding.p10>
    </CommonPageLayout>
  )
}
