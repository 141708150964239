import { Box, Breadcrumbs, Link, Typography } from '@mui/material'
import { ScreenTitleBar } from '../../localities/components/ScreenTitleBar'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { FC } from 'react'
import { UserTable } from './UserTable'
import { t } from 'i18next'
import { MdNavigateNext } from 'react-icons/md'
import { AppRoutesNavigation } from '../../../app-routes'
import { RoleTypes } from '../../../enums'
import { permissionBinding, permissionLevel } from '../../../constant'
import { useDialogCtx } from 'src/hooks/context-hooks'
import { getCustomerUsers } from '../../../http'
import { invalidateCustomer } from '../../../api/customer'
import { AssignUsers } from '@lovoldsystem/authorization.client'
import { FunctionElementsParentsIds } from '../../../enums/auth'

interface INavigationState {
  customerName: string
}

export const UsersContainer: FC<{ isAdminLayout?: boolean }> = ({ isAdminLayout }) => {
  const { t: translate } = useTranslation(['users', 'common'])
  const { id } = useParams()
  const navigate = useNavigate()
  const { state } = useLocation()
  const { showDialog } = useDialogCtx()

  const breadcrumbsItems = [
    <Link
      sx={{ cursor: 'pointer' }}
      underline="hover"
      key="1"
      color="inherit"
      onClick={e => {
        e.stopPropagation()
        navigate(AppRoutesNavigation.Admin.customers)
      }}
    >
      {t('customers', { ns: 'users' })}
    </Link>,
    <Typography key="4" color="text.primary">
      {(state as INavigationState)?.customerName}
    </Typography>,
  ]

  const handleNewUserAdd = () => {
    showDialog(
      AssignUsers,
      {
        title: translate('add-client-users', { ns: 'users' }),
        componentProps: {
          clientId: id as any,
          getUsersAction: getCustomerUsers,
          roleTypes: [RoleTypes.Client],
          assignCompleted: () => {
            invalidateCustomer.getCustomerUsers(id as EntityId, true)
          },
          permissionLevel: permissionLevel,
          permissionBinding: permissionBinding,
          assignToAlert: t('permission-types', { ns: 'user-resources' }),
          readOnly: true,
          functionElementParents: [FunctionElementsParentsIds.SystemPortal, FunctionElementsParentsIds.LocalityPortal, FunctionElementsParentsIds.ServiceStationPortal]
        },
        opts: {
          maxWidth: 'md',
          fullWidth: true,
        },
      },
      undefined,
    )
  }

  return (
    <Box className="p-3 mb-2 bg-white shadow-md" borderRadius={2}>
      {isAdminLayout && (
        <Box className="flex items-center justify-between px-4 py-2 mb-2" borderRadius={2}>
          <Breadcrumbs separator={<MdNavigateNext size={20} />} aria-label="breadcrumb">
            {breadcrumbsItems}
          </Breadcrumbs>
        </Box>
      )}
      <ScreenTitleBar
        title={translate('users', { ns: 'users' })}
        addNewItemText={translate('addUser', { ns: 'users' })}
        onAddNewItemClick={() => handleNewUserAdd()}
      />
      <UserTable customerId={id as EntityId} isAdminLayout={isAdminLayout} />
    </Box>
  )
}
