import { Box } from '@mui/material'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'
import {
  AppForm,
  FormTextField,
  FormSelectField,
  FormSubmitButton,
  FormFileCoreUpload,
} from '../../../../components/FormCore'
import { useZodForm } from '../../../../hooks/zod-form'
import { getUserDetail, UserDetail } from '../../../../utils/getUserDetails'
import { useGetCustomerUsers } from '../../../../api/customer'
import { taskIntervalList } from './task-constants'
import BasicDatePicker from '../../../../components/DateTimePicker'
import { TaskTypes } from '../../../../enums'
import { invalidateTaskCache, useGetTask, useUpdateTask } from '../../../../api/tasks'
import { IUser } from '../../../../generated-types'
import { AppTextField } from '../../../../components/AppTextField'
import { INVALID_END_DATE } from '../../../../constant'
import { toast } from 'react-toastify'
import { invalidateScheduledTaskCache } from 'src/api/scheduledTask'
import { getUTCDate } from 'src/utils/function'
import { CenteredSpinner } from 'src/components/Spinner'

interface TaskEditFormProp {
  initialValues?: any
  customerId: EntityId
  pop: any
  tasksId?: EntityId
  localityId?: EntityId
}

const TaskEditForm: React.FC<TaskEditFormProp> = ({ initialValues, customerId, pop, tasksId, localityId }) => {
  const { t: translate } = useTranslation(['locality', 'common'])
  const { data: customerUsers } = useGetCustomerUsers(customerId as EntityId)
  const { data: task, isLoading } = useGetTask(localityId as EntityId, tasksId as EntityId)

  useEffect(() => {
    if (task) {
      form.reset({ ...task, ...{ interval: taskIntervalList.find(ti => ti.value == task?.interval) } })
    }
  }, [task])

  const { mutateAsync } = useUpdateTask(initialValues?.localityId ?? task?.localityId, initialValues?.id ?? task?.id)
  const formType = useMemo(() => initialValues?.type, [initialValues])
  const [selectedStartDate, setSelectedStartDate] = useState<Date>()
  const [isDurationDisabled, setIisDurationDisabled] = useState(initialValues?.interval == 0)

  const taskSchema = z
    .object({
      title: z
        .string()
        .nonempty(translate('nameIsRequired', { ns: 'common' }))
        .max(200),
      description: z
        .string()
        .nonempty(translate('descriptionIsRequired', { ns: 'common' }))
        .max(400),
      responsibleUserId: z.string(),
      startDate: z.date().or(z.string().transform(v => getUTCDate(new Date(v)) || new Date(v))),
      endDate: z
        .date()
        .or(z.string().transform(v => getUTCDate(new Date(v)) || new Date(v)))
        .nullable()
        .optional(),
      duration: z
        .number()
        .or(z.string().transform(v => parseInt(v)))
        .optional(),
      fileId: z.union([z.string(), z.number()]).nullable().optional(),
      interval: z
        .object({
          value: z.number(),
        })
        .optional(),
    })
    .refine(
      data =>
        (initialValues?.interval == undefined && data.endDate == undefined) ||
        (data.endDate != undefined && data.endDate > data.startDate),
      {
        message: translate('endStartDateError', { ns: 'common' }),
        path: ['endDate'],
      },
    )
    .refine(
      form => {
        return !(form.endDate != null && (form.endDate as Date) < (form.startDate as Date))
      },
      {
        message: translate('endStartDateError', { ns: 'common' }),
      },
    )

  const form = useZodForm(taskSchema, {
    defaultValues: {
      ...initialValues,
      ...{ interval: taskIntervalList.find(ti => ti.value == initialValues?.interval) },
    },
  })

  const onSubmit = useCallback(
    async values => {
      values.interval = values?.interval?.value
      await mutateAsync({ ...(initialValues ?? task), ...values },{
        onSuccess: () => {
          toast.success(translate('taskUpdatedSuccessfully', { ns: 'task' }))
          invalidateTaskCache.getCustomerAdminTaskByFilter(customerId)
          invalidateScheduledTaskCache.useSingleScheduledTask(initialValues?.localityId ?? task?.localityId, undefined)
          if (task?.localityId) {
            invalidateScheduledTaskCache.useScheduledTasks(task?.localityId as EntityId)
            invalidateTaskCache.useGetTask(task?.localityId, task?.id)
          }
          invalidateScheduledTaskCache.invalidateAllScheduledTasks(localityId as EntityId)
          pop()
        },
        onError: (error) => {
          if (error['data'] == INVALID_END_DATE) {
            toast.error(translate('invalid-end-date', { ns: 'task' }))
          }
          else{
            toast.error(translate('updatingError', { ns: 'common' }))
          }
        },
      })
    },
    [customerId, initialValues, mutateAsync, pop, translate],
  )

  useEffect(() => {
    setSelectedStartDate(initialValues?.startDate)
  }, [initialValues?.startDate])

  if (isLoading) return <CenteredSpinner />
  return (
    <AppForm form={form} onSubmit={onSubmit} hasInitialValues={initialValues}>
      <Box py={1}>
        <AppTextField
          fullWidth
          disabled
          className="input-localityform"
          label={translate('locality', { ns: 'common' })}
          defaultValue={initialValues?.locality?.name ?? task?.locality?.name}
        />
      </Box>
      <Box py={1}>
        <FormTextField
          className="input-localityform"
          name="title"
          label={translate('title', { ns: 'common' })}
          defaultValue={initialValues?.title}
        />
      </Box>
      <Box py={1}>
        <FormTextField name="description" label={translate('description', { ns: 'common' })} multiline rows={3} />
      </Box>
      <Box py={1}>
        {customerUsers && (
          <FormSelectField
            name="responsibleUser"
            data={customerUsers!}
            label={translate('responsibleUser', { ns: 'locality' })}
            getOptionLabel={option => getUserDetail(option, UserDetail.NameAndEmail)}
            renderOption={(props: any, option) => (
              <Box {...props} key={option.id}>
                {getUserDetail(option, UserDetail.NameAndEmail)}
              </Box>
            )}
            defaultValue={initialValues?.responsibleUser}
            clearOnBlur={false}
            clearOnEscape={false}
            isOptionEqualToValue={(o, v) => o.id === v.id}
            onChange={(e, value) => form.setValue('responsibleUserId', (value as IUser).id)}
          />
        )}
      </Box>
      {formType == TaskTypes.Repeatable && (
        <Box py={1}>
          <FormSelectField
            name="interval"
            data={taskIntervalList}
            disablePortal={false}
            label={translate('interval', { ns: 'common' })}
            getOptionLabel={option => option.option}
            renderOption={(props: any, option) => (
              <Box {...props} key={option.value}>
                {option.option}
              </Box>
            )}
            clearOnBlur={false}
            clearOnEscape={false}
            isOptionEqualToValue={(o, v) => o.value === v.value}
            onChange={(eve, value) => {
              form.setValue('interval', value as any)
              if (value == taskIntervalList[0]) {
                setIisDurationDisabled(true)
                form.setValue('duration', 1)
              } else {
                setIisDurationDisabled(false)
              }
            }}
          />
        </Box>
      )}
      <Box py={1}>
        <BasicDatePicker
          name="startDate"
          label={translate('startDate', { ns: 'common' })}
          onChange={date => {
            form.setValue('startDate', date)
            setSelectedStartDate(date)
          }}
        />
      </Box>
      {formType == TaskTypes.Repeatable && (
        <Box py={1}>
          <BasicDatePicker name="endDate" label={translate('endDate', { ns: 'common' })} minDate={selectedStartDate} />
        </Box>
      )}
      <Box py={1}>
        <FormTextField
          className="input-localityform"
          type="number"
          name="duration"
          label={translate('duration', { ns: 'task' })}
          InputProps={{
            readOnly: isDurationDisabled,
          }}
          focused={!!form.getValues('duration')}
        />
      </Box>
      <Box py={1}>
        <FormFileCoreUpload
          label={translate('fileName', { ns: 'common' })}
          name="fileId"
          mapFileKey="id"
          initialFiles={initialValues?.file ?? task?.file}
          height="100px"
        />
      </Box>
      <Box display={'flex'} flexDirection={'row'} justifyContent={'end'}>
        <FormSubmitButton createText={translate('edit', { ns: 'common' })} />
      </Box>
    </AppForm>
  )
}

export default TaskEditForm
