import { ScreenTitleBar } from '../../localities/components/ScreenTitleBar'
import { useTranslation } from 'react-i18next'
import { useCustomerCtx, useDialogCtx } from '../../../hooks/context-hooks'
import { Box } from '@mui/material'
import { ServiceStationsTable } from './ServiceStationsTable'
import { FC, useCallback } from 'react'
import { ServiceStationDialog } from './ServiceStationDialog'
import { ServiceStationContainer } from './ServiceStationContainer'
import { serviceStationEndpoints, useDeleteServiceStation } from '../../../api/service-station'
import { IServiceStation } from '../../../generated-types/service-station'
import { toast } from 'react-toastify'
import { AlertDialog } from '../../../show-dialog'

export const ServiceStationScreen: FC = () => {
  const { customer } = useCustomerCtx()
  const { t: translate } = useTranslation(['service-station', 'common'])
  const { mutateAsync: deleteServiceStation } = useDeleteServiceStation(customer.id)

  const { showDialog } = useDialogCtx()

  const handleServiceStationDelete = useCallback(
    async id => {
      showDialog(
        AlertDialog,
        {
          componentProps: {
            title: translate('deleteServiceStation', { ns: 'service-station' }),
            acceptText: translate('delete', { ns: 'common' }),
            text: translate('areYouSureDeleteServiceStation', { ns: 'service-station' }),
            onConfirm: async pop => {
              await deleteServiceStation(id)
                .then(() => {
                  toast.success(translate('service-station-deleted-successfully', { ns: 'service-station' }))
                })
                .catch(error => {
                  if (error?.data) {
                    toast(translate('CannotDeleteServiceStation', { ns: 'service-station' }), { type: 'error' })
                    return
                  }
                  toast.error(translate('service-station-deleted-error', { ns: 'service-station' }))
                })
              pop()
            },
          },
        },
        undefined,
        true,
      )
    },
    [customer.id],
  )

  const handleServiceStation = useCallback(
    (initialValues?: IServiceStation) => {
      showDialog(
        ServiceStationDialog,
        {
          opts: {
            maxWidth: 'md',
            fullWidth: true,
          },
          componentProps: {
            customerId: customer.id,
            title: initialValues?.id
              ? translate('update-service-station', { ns: 'service-station' })
              : translate('add-service-station', { ns: 'service-station' }),
            initialValues: initialValues,
          },
        },
        undefined,
        true,
      )
    },
    [customer.id, translate],
  )

  return (
    <>
      <Box className="px-4 py-2 mt-2 mb-2 bg-white shadow-md" borderRadius={2}>
        <ScreenTitleBar
          title={translate('service-stations')}
          addNewItemText={translate('add', { ns: 'common' })}
          onAddNewItemClick={handleServiceStation}
        />
        <ServiceStationsTable
          queryUrl={serviceStationEndpoints.getAllTypeServiceStations(customer.id)}
          queryParams={{ isIgnoreQueryFilters: true }}
          getDetailPanelContent={({ row }) => {
            return (
              <ServiceStationContainer serviceStationId={row.id} serviceStationName={row.name} groups={row.groups} />
            )
          }}
          handleEdit={handleServiceStation}
          handleRemove={handleServiceStationDelete}
        />
      </Box>
    </>
  )
}
