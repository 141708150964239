import {
  Breakpoint,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  SxProps,
  Theme,
  useTheme
} from '@mui/material'
import { makeStyles } from '@material-ui/core'
import { MdClose } from 'react-icons/md'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { generateUUID } from '../utils/function'

const useStyles = makeStyles(_ => ({
  dialog: {
    '& .MuiDialog-container': {
      '& .MuiPaper-root': {
        maxWidth: (props: any) => props.maxWidth ?? '85vw',
        maxHeight: (props: any) => props.maxHeight ?? '95vh',
        backgroundColor: (props: any) => props.bgColor ?? 'undefined',
        borderRadius: '8px',
      },
    },
  },
  modal: {
    backgroundColor: (props: any) => props.bgColor ?? '#ffff',
    maxWidth: (props: any) => props.maxWidth ?? '85vw',
    maxHeight: (props: any) => props.maxHeight ?? '95vh',
    minWidth: (props: any) => props.minWidth ?? '15vw',
    minHeight: (props: any) => props.minHeight ?? '5vh',
  },
  icon: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: 'white',
  },
}))

interface IGenericDialogProps {
  title: string
  open: boolean
  onClose?: () => void
  height?: string | number
  width?: string | number
  minHeight?: string | number
  minWidth?: string | number
  maxWidth?: false | Breakpoint | undefined
  maxHeight?: string | number
  bgColor?: string
  disableAutoClose?: boolean
  actions?: React.ReactNode[]
  removedCloseButton?: boolean
  fullWidth?: boolean
  sx?: SxProps<Theme> | undefined
  isWithLayout?: boolean
  fullScreen?: boolean | undefined
}
export const GenericDialog: React.FC<IGenericDialogProps> = ({
  title,
  open,
  onClose,
  height,
  width,
  minHeight,
  minWidth,
  maxHeight,
  maxWidth,
  bgColor,
  disableAutoClose,
  actions,
  removedCloseButton,
  fullWidth = false,
  children,
  sx,
  isWithLayout = false,
  fullScreen = false,
}) => {
  const classes = useStyles({ height, width, minHeight, minWidth, maxHeight, maxWidth, bgColor, actions })
  const { t } = useTranslation()
  const DefaultFormat = useMemo(() => {
    return (
      <>
        <CustomDialogTitle onClose={onClose}>{title}</CustomDialogTitle>
        <DialogContent className={classes.modal}>{children}</DialogContent>
        {(!removedCloseButton || actions) && (
          <DialogActions>
            {[
              !removedCloseButton ? (
                <Button key={generateUUID()} onClick={onClose} variant="outlined">
                  {t('cancel', { ns: 'common' })}
                </Button>
              ) : (
                <></>
              ),
              ...(actions ? actions : []),
            ]}
          </DialogActions>
        )}
      </>
    )
  }, [actions, children, classes.modal, onClose, removedCloseButton, t, title])
  return (
    <>
      <Dialog
        open={open}
        onClose={(e, reason) => {
          if (disableAutoClose && reason === 'backdropClick') return
          if (onClose)
            onClose()
        }}
        disableEscapeKeyDown
        onBackdropClick={_ => { }}
        className={classes.dialog}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        sx={sx}
        fullScreen={fullScreen}
      >
        {isWithLayout ? children : DefaultFormat}
      </Dialog>
    </>
  )
}

export interface DialogTitleProps {
  children?: React.ReactNode
  onClose?: () => void
}

export const CustomDialogTitle: React.FC<DialogTitleProps> = props => {
  const theme = useTheme()
  const { children, onClose, ...other } = props
  const classes = useStyles({})

  return (
    <DialogTitle sx={{ fontWeight: '600' }} className="bg-white border-none text-primary" {...other}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        {children}
        {onClose ? (
          <IconButton aria-label="close" onClick={onClose} className={classes.icon} >
            <MdClose color={theme.palette.primary.main} />
          </IconButton>
        ) : null}
      </Stack>
    </DialogTitle>
  )
}
