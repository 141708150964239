import { Box, Grid, Typography, styled, useTheme } from '@mui/material'
import React, { FC, useMemo } from 'react'
import { DynamicTextTypography } from 'src/components/DynamicTextTypography'
import { useTranslation } from 'react-i18next'
import { IDeviation } from 'src/generated-types/deviation'
import { IDeviationEventSupervision } from 'src/generated-types/deviation-event-supervision'
import { IDeviationKeywords } from 'src/generated-types/deviation-keywords'
import { IDeviationMeasure } from 'src/generated-types/deviation-measure'
import { IDeviationPriority } from 'src/generated-types/deviation-priority'
import { Flex } from 'src/styles/flexComponent'
import { getFormattedDate, getThemeColor } from 'src/utils'
import { getLocalDateTime } from 'src/utils/function'
import { StyledDetailCard } from 'src/styles/Index'
import { Chip } from 'src/components/common/Chip'
import { MUIThemeColors } from 'src/mui-theme'

export const DeviationDetailSection: FC<{ deviation: IDeviation }> = ({ deviation }) => {
  const { t } = useTranslation(['deviation', 'common'])
  const theme = useTheme()
  const deviationType = useMemo(() => {
    const deviationType: string[] = []
    if (deviation?.visualDeviation) deviationType.push('visualDeviation')
    if (deviation?.abnormalWear) deviationType.push('abnormalWear')
    if (deviation?.measuredDeviation) deviationType.push('measuredDeviation')
    if (deviation?.normalWear) deviationType.push('normalWear')
    return deviationType
  }, [deviation?.visualDeviation, deviation?.abnormalWear, deviation?.measuredDeviation, deviation?.normalWear])

  return (
    <StyledDetailCard elevation={0} className="pl-22-core pt-24-core pb-24-core pr-22-core">
      <Grid container spacing={2}>
        <Grid item xs={6} md={3}>
          <Flex.Column data-cy='status'>
            <TitleTypography variant="subtitle2">{t('status', { ns: 'common' })}</TitleTypography>
            {
              !deviation?.status ?
                <ContentTypography variant="subtitle1">
                  {t('open', { ns: 'common' })}
                </ContentTypography>
                :
                <Box>
                  <Chip label={t('closed', { ns: 'common' })} size='medium'
                    sx={{
                      backgroundColor: getThemeColor(theme, MUIThemeColors.successLight),
                      color: getThemeColor(theme, MUIThemeColors.white),
                      fontSize: '16px !important'
                    }} />
                </Box>
            }
          </Flex.Column>
        </Grid>
        <Grid item xs={6} md={3}>
          <Flex.Column data-cy='responsiblePerson'>
            <TitleTypography variant="subtitle2">{t('responsiblePerson')}</TitleTypography>
            <ContentTypography variant="subtitle1">{deviation?.responsiblePersonName ?? '-'}</ContentTypography>
          </Flex.Column>
        </Grid>
        <Grid item xs={6} md={3}>
          <Flex.Column data-cy='priority'>
            <TitleTypography variant="subtitle2">{t('priority', { ns: 'common' })}</TitleTypography>
            <ContentTypography variant="subtitle1">
              {deviation?.priority != null ? t(IDeviationPriority[deviation?.priority as IDeviationPriority]) : '-'}
            </ContentTypography>
          </Flex.Column>
        </Grid>
        <Grid item xs={6} md={3}>
          <Flex.Column data-cy='dueDate'>
            <TitleTypography variant="subtitle2">{t('dueDate', { ns: 'common' })}</TitleTypography>
            <ContentTypography variant="subtitle1">
              {deviation?.dueDate ? getFormattedDate(getLocalDateTime(deviation?.dueDate)) : '-'}
            </ContentTypography>
          </Flex.Column>
        </Grid>
        <Grid item xs={6} md={3}>
          <Flex.Column data-cy='eventSupervision'>
            <TitleTypography variant="subtitle2">{t('eventSupervision')}</TitleTypography>
            <ContentTypography variant="subtitle1">
              {deviation?.eventSupervision != null
                ? t(IDeviationEventSupervision[deviation?.eventSupervision as IDeviationEventSupervision], {
                  ns: 'deviation',
                })
                : '-'}
            </ContentTypography>
          </Flex.Column>
        </Grid>
        <Grid item xs={6} md={3}>
          <Flex.Column data-cy='measure'>
            <TitleTypography variant="subtitle2">{t('measure')}</TitleTypography>
            <ContentTypography variant="subtitle1">
              {deviation?.measure != null ? t(IDeviationMeasure[deviation?.measure as IDeviationMeasure]) : '-'}
            </ContentTypography>
          </Flex.Column>
        </Grid>
        <Grid item xs={12} md={3}>
          <Flex.Column>
            <TitleTypography variant="subtitle2">{t('keywords', { ns: 'common' })}</TitleTypography>
            <Flex.Row display={'flex'} flexWrap={'wrap'} gap={1}>
              {deviation?.keywordsArray && deviation?.keywordsArray.length > 0
                ? deviation?.keywordsArray.map((keyword, index) => (
                  <StyledChip variant="outlined" key={index} label={t(IDeviationKeywords[keyword])} />
                ))
                : '-'}
            </Flex.Row>
          </Flex.Column>
        </Grid>
        <Grid item xs={6} md={3}>
          <Flex.Column data-cy='measures'>
            <TitleTypography variant="subtitle2">{t('measures')}</TitleTypography>
            <ContentTypography variant="subtitle1">{deviation?.measures ?? '-'}</ContentTypography>
          </Flex.Column>
        </Grid>
        <Grid item xs={6} md={3}>
          <Flex.Column data-cy='description'>
            <TitleTypography variant="subtitle2">{t('description', { ns: 'common' })}</TitleTypography>
            <ContentTypography variant="subtitle1" >{deviation?.description ?? '-'}</ContentTypography>
          </Flex.Column>
        </Grid>
        <Grid item xs={6} md={9}>
          <Flex.Column data-cy='reason'>
            <TitleTypography variant="subtitle2">{t('reason')}</TitleTypography>
            <ContentTypography variant="subtitle1">{deviation?.reason ?? '-'}</ContentTypography>
          </Flex.Column>
        </Grid>
        <Grid item xs={12} md={3}>
          <Flex.Column>
            <TitleTypography variant="subtitle2">{t('type', { ns: 'common' })}</TitleTypography>
            <Flex.Row display={'flex'} flexWrap={'wrap'} gap={1}>
              {deviationType.length > 0
                ? deviationType?.map((keyword, index) => (
                  <StyledChip variant="outlined" key={index} label={t(keyword)} />
                ))
                : '-'}
            </Flex.Row>
          </Flex.Column>
        </Grid>
      </Grid>
    </StyledDetailCard>
  )
}

//styled components

export const TitleTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.black[theme.palette.mode],
  fontWeight: 400,
}))

export const ContentTypography = styled(Typography)(({ theme, color }) => ({
  color: color ? color as string : theme.palette.primaryMain[theme.palette.mode],
  fontWeight: 500,
}))

export const DescriptionTypography = styled(DynamicTextTypography)(({ theme, color }) => ({
  color: color ? color as string : theme.palette.primaryMain[theme.palette.mode],
  fontWeight: 500,
}))

export const StyledChip = styled(Chip)({
  borderRadius: 5,
})
