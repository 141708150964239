import { useTranslation } from 'react-i18next'
import { MdDelete, MdEdit, MdToggleOn, MdToggleOff } from 'react-icons/md'
import { ICustomerAdminSupplier, ISupplier } from '../../../generated-types/supplier'
import { CenteredSpinner } from '../../../components/Spinner'
import { showDialog, ShowDialogProps, showConfirmDialog } from '../../../show-dialog'
import { useCallback, useMemo } from 'react'
import { GridActionsCellItem, GridColumns, GridNativeColTypes, useGridApiRef } from '@mui/x-data-grid-pro'
import {
  invalidateSupplierCache,
  suppliersEndpoints,
  useActivateOrDeActivateAdminSupplier,
  useDeleteAdminSupplier,
  useDeleteCustomerSupplier,
  useGetAdminSupplier,
  useGetCustomerSupplier,
  useUpdateAdminSupplier,
  useUpdateCustomerSupplier,
} from '../../../api/supplier'
import { SupplierForm } from './SupplierForm'
import GenericTable from '../../../components/table/GenericTable'
import { GenericSupplierColumns } from './constants'
import { IApiResult } from '../../../generated-types/api-result'
import { UseMutateAsyncFunction } from 'react-query'
import { AxiosError, AxiosResponse } from 'axios'
import { toast } from 'react-toastify'
import { Chip } from '@mui/material'

interface UpdateSupplierProps extends ShowDialogProps {
  id: EntityId
  customerId?: EntityId
}

const UpdateGlobalSupplierDialog: React.FC<UpdateSupplierProps> = ({ pop, id }) => {
  const { t: translate } = useTranslation(['supplier', 'common'])
  const { mutateAsync } = useUpdateAdminSupplier(id as EntityId)

  const onSubmit = async form => {
    await mutateAsync(form)
      .then(({ data }: { data: IApiResult<ISupplier> }) => {
        toast.success(translate(data.message, { ns: 'supplier' }))
        invalidateSupplierCache.useGetSuppliers()
        pop(true)
      })
      .catch(({ data }: { data: string }) => {
        toast.error(translate(data, { ns: 'supplier' }))
      })
  }

  const { data, isLoading } = useGetAdminSupplier(id)
  if (isLoading) return <CenteredSpinner />

  return (
    <SupplierForm
      isSuperAdmin={true}
      onSubmit={onSubmit}
      onCancel={() => pop()}
      initialValues={data as ISupplier}
      title={translate('updateSupplier', { ns: 'supplier' })}
    />
  )
}

const UpdateCustomerAdminSupplierDialog: React.FC<UpdateSupplierProps> = ({ pop, id, customerId }) => {
  const { t: translate } = useTranslation(['supplier', 'common'])
  const { mutateAsync } = useUpdateCustomerSupplier(id as EntityId)

  const onSubmit = async form => {
    form.customerId = customerId
    await mutateAsync(form)
      .then(({ data }: { data: IApiResult<ISupplier> }) => {
        toast.success(translate(data.message, { ns: 'supplier' }))
        invalidateSupplierCache.useGetSuppliers()
        pop(true)
      })
      .catch(({ data }: { data: string }) => {
        toast.error(translate(data, { ns: 'supplier' }))
      })
  }

  const { data, isLoading } = useGetCustomerSupplier(id)
  if (isLoading) return <CenteredSpinner />

  return (
    <SupplierForm
      isSuperAdmin={false}
      onSubmit={onSubmit}
      onCancel={() => pop()}
      initialValues={data as ICustomerAdminSupplier}
      title={translate('updateSupplier', { ns: 'supplier' })}
    />
  )
}

interface SupplierTableProps {
  isSuperAdmin: boolean
  customerId?: EntityId
}

export const SupplierTable: React.FC<SupplierTableProps> = ({ isSuperAdmin, customerId }) => {
  const { t: translate } = useTranslation(['supplier', 'common'])
  const apiRef = useGridApiRef()

  const STATIC_COLUMNS: GridColumns = useMemo(() => {
    return isSuperAdmin
      ? [
          ...GenericSupplierColumns,
          { field: 'phone', headerName: translate('phone', { ns: 'supplier' }), flex: 1 },
          { field: 'streetAddress', headerName: translate('streetAddress', { ns: 'supplier' }), flex: 1 },
          { field: 'city', headerName: translate('city', { ns: 'supplier' }), flex: 1 },
          {
            field: 'isActive',
            headerName: translate('active', { ns: 'common' }),
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ value }) => (
              <Chip
                label={translate(value ? 'yes' : 'no', { ns: 'common' })}
                color={value ? 'success' : 'error'}
                sx={{ fontWeight: 'bold', width: '30%' }}
              />
            ),
          },
        ]
      : [
        ...GenericSupplierColumns,
        {
          field: 'parentSupplier',
          headerName: translate('globalSupplier', { ns: 'supplier' }),
          flex: 1,
          valueGetter: ({ value }) => {
            return value?.name ?? '-'
          },
        },
      ]
  }, [isSuperAdmin, translate])

  const { mutateAsync: globalSupplierDeletingMutation, isLoading: isDeleting } = useDeleteAdminSupplier()
  const { mutateAsync: customerSupplierDeleteMutation } = useDeleteCustomerSupplier()
  const { mutateAsync: activeOrDeActive, isLoading: isActiveOrDeActivating } = useActivateOrDeActivateAdminSupplier()

  const handleDelete = useCallback(
    (
      mutationAsync: UseMutateAsyncFunction<AxiosResponse<any, any>, AxiosError<any, any>, string | number, unknown>,
      id: EntityId,
    ) => {
      mutationAsync(id)
        .then(({ data }: { data: string }) => {
          toast.success(translate(data, { ns: 'supplier' }))
          invalidateSupplierCache.useGetSuppliers()
        })
        .catch(({ data }: { data: string }) => {
          toast.error(translate(data, { ns: 'supplier' }))
        })
      apiRef.current.updateRows([{ id, _action: 'delete' }])
    },
    [apiRef],
  )

  const onDeleteSupplier = useCallback(
    async (id: EntityId) => {
      showConfirmDialog(
        translate('deleteSupplier', { ns: 'supplier' }),
        translate('areYouSureDeleteSupplier', { ns: 'supplier' }),
      ).then(e => {
        if (e) {
          isSuperAdmin
            ? handleDelete(globalSupplierDeletingMutation, id)
            : handleDelete(customerSupplierDeleteMutation, id)
        }
      })
    },
    [translate, isSuperAdmin, handleDelete, globalSupplierDeletingMutation, customerSupplierDeleteMutation],
  )

  const onGlobalSupplierUpdate = useCallback(async id => {
    const added = await showDialog(UpdateGlobalSupplierDialog, {
      componentProps: { id: id as EntityId },
    })
    if (!added) return

    invalidateSupplierCache.useGetSuppliers()
    invalidateSupplierCache.getSupplier(id)
  }, [])

  const onCustomerAdminSupplierUpdate = useCallback(
    async id => {
      const added = await showDialog(UpdateCustomerAdminSupplierDialog, {
        componentProps: { id: id as EntityId, customerId: customerId },
      })
      if (!added) return

      invalidateSupplierCache.useGetSuppliers()
      invalidateSupplierCache.getSupplier(id)
    },
    [customerId],
  )

  const handleActiveOrDeActivating = async (id: EntityId) => {
    await activeOrDeActive({ id: id as number })
    invalidateSupplierCache.useGetSuppliers()
    invalidateSupplierCache.getSupplier(id)
  }

  const renderActions = useCallback(
    ({ id, row }) => {
      const AdminActions = [
        <GridActionsCellItem
          key="activeOrDeActive"
          label={translate(row.isActive ? 'deactivate' : 'activate', { ns: 'common' })}
          icon={row.isActive ? <MdToggleOff size={24} /> : <MdToggleOn size={24} />}
          onClick={() => handleActiveOrDeActivating(id)}
          disabled={isActiveOrDeActivating || (row.isActive && row.childSuppliersCount > 0)}
          color="primary"
          showInMenu
        />,
      ]
      const CommonActions = [
        <GridActionsCellItem
          key="edit"
          label={translate('edit', { ns: 'common' })}
          icon={<MdEdit size={24} />}
          onClick={() => (isSuperAdmin ? onGlobalSupplierUpdate(id) : onCustomerAdminSupplierUpdate(id))}
          disabled={isDeleting}
          color="primary"
          showInMenu
        />,
        <GridActionsCellItem
          key="delete"
          label={translate('delete', { ns: 'common' })}
          icon={<MdDelete size={24} />}
          onClick={() => onDeleteSupplier(id)}
          disabled={isDeleting}
          color="primary"
          showInMenu
        />,
      ]
      switch (isSuperAdmin) {
        case true:
          return [...AdminActions, ...CommonActions]
        case false:
          return CommonActions
        default:
          return CommonActions
      }
    },
    [isSuperAdmin, isDeleting, isActiveOrDeActivating],
  )

  const columns: GridColumns = useMemo(
    () => [
      ...STATIC_COLUMNS,
      {
        field: 'actions',
        type: 'actions' as GridNativeColTypes,
        getActions: renderActions,
      },
    ],
    [STATIC_COLUMNS, renderActions],
  )

  return (
    <GenericTable
      id="supplier-table"
      enablePagination
      enableServerPagination
      pageSize={10}
      pageOptions={[10, 25, 50]}
      queryUrl={isSuperAdmin ? suppliersEndpoints.getAdminSuppliers() : suppliersEndpoints.getCustomerSuppliers()}
      queryParams={!isSuperAdmin ? { customerId: customerId } : undefined}
      onRowClick={({ id }) => (isSuperAdmin ? onGlobalSupplierUpdate(id) : onCustomerAdminSupplierUpdate(id))}
      columns={columns}
      enableToolbar={true}
      getRowHeight={() => 'auto'}
    />
  )
}
