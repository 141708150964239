import React from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { makeStyles } from '@material-ui/core'
import i18n from '../../../../i18n'

const localizer = momentLocalizer(moment)

const useStyle = makeStyles(() => ({
    calendar: {
        height: 500
    }
}))

const TaskCalendar = ({ onClick, events, onMonthChange }) => {
    const classes = useStyle()

    return (
        <Calendar
            culture={i18n.language}
            startAccessor={(event) => { return new Date(event.startDate) }}
            endAccessor={(event) => { return event.endDate ? new Date(event.endDate) : new Date(event.startDate) }}
            localizer={localizer}
            events={events}
            className={classes.calendar}
            onSelectEvent={onClick}
            onNavigate={date => onMonthChange(date)}
            views={{ week: false, month: true, day: true }}
            popup
        />
    )
}

export default TaskCalendar