import { Box } from '@mui/material'
import { GridColumns } from '@mui/x-data-grid-pro'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { certificateEndpoints, invalidateCertificateCache, useDeleteCertificate } from '../api/Certificates'
import { ScreenTitleBar } from '../screens/localities/components/ScreenTitleBar'
import moment from 'moment'
import { invalidateMainComponentCache } from '../api/main-component'
import { CreateCertificateExtensionDialog } from './main-component/CreateCertificateExtensionDialog'
import { useDialogCtx } from '../hooks/context-hooks'
import { GridActionButton } from './common/GridActionButton'
import { Delete, Visibility } from '@mui/icons-material'
import GenericTable from './table/GenericTable'
import { toast } from 'react-toastify'

export interface ICertificatesTableProps {
  mainComponentId: EntityId
  customerId: EntityId
  positionId: EntityId
  showCertificatesHeader?: boolean
  height?: number
}
export const CertificatesTableGeneric: React.FC<ICertificatesTableProps> = ({
  mainComponentId,
  customerId,
  positionId,
  showCertificatesHeader: showCertificatesHeader,
  height,
}) => {
  const { t } = useTranslation(['document', 'common'])
  const { mutateAsync, isLoading: isDeleting, error } = useDeleteCertificate(mainComponentId)
  const { showDialog, showConfirmDialog } = useDialogCtx()

  useEffect(() => {
    if (error && error['data'] == 'CanNotDeleteCertificateOfaComponentNotInService') {
      toast(t('CanNotDeleteCertificateOfaComponentNotInService', { ns: 'certificate' }), { type: 'error' })
    }
  }, [error, t])

  const onDeleteCertificate = (id: EntityId) => {
    showConfirmDialog(
      t('deleteCertificate', { ns: 'certificate' }),
      t('areYouSureDeleteCertificate', { ns: 'certificate' }),
      {
        acceptText: t('yes', { ns: 'common' }),
        cancelText: t('no', { ns: 'common' }),
      },
      async pop => {
        await mutateAsync(id, {
          onSuccess: () => {
            toast.success(t('CertificateDeletedSuccussfully', { ns: 'certificate' }))
            invalidateCertificateCache.getCertificates(mainComponentId)
            invalidateMainComponentCache.getMainComponentById(customerId, mainComponentId)
            pop()
          },
          onError: () => {
            toast.success(t('ErrorDeletingCertificate', { ns: 'certificate' }))
          },
        })
      },
    )
  }

  const isDisable = (expiresIn: number, numberOfExtensions: number) => {
    return expiresIn > 92 || numberOfExtensions >= 2
  }

  const onAddExtension = async (id: EntityId) => {
    const added = await showDialog(CreateCertificateExtensionDialog, {
      title: t('addExtension', { ns: 'certificateExtension' }),
      componentProps: {
        certificateId: id as EntityId,
        mainComponentId: mainComponentId as EntityId,
        customerId: customerId as EntityId,
        positionId: positionId as EntityId,
      },
      opts: {
        maxWidth: 'md',
        fullWidth: true,
      },
    })
    if (!added) return
  }

  const STATIC_COLUMNS: GridColumns = [
    {
      field: 'certificateNumber',
      headerName: t('cert.NR  ', { ns: 'certificate' }).toUpperCase(),
      flex: 1,
      cellClassName: 'font-weight-semi-bold',
    },
    { field: 'supplierName', headerName: t('certifiedBy', { ns: 'certificate' }).toUpperCase(), flex: 1 },
    {
      field: 'validUntil',
      headerName: t('validUntil', { ns: 'certificate' }).toUpperCase(),
      flex: 1,
      valueGetter: value => (value?.value ? moment(value.value).format('MM/DD/YYYY') : ' - '),
    },
    {
      field: 'numberOfExtensions',
      headerName: t('extensions', { ns: 'certificate' }).toUpperCase(),
      flex: 1,
      renderCell: params => {
        return `#${params.value}`
      },
    },
  ]

  const columns: GridColumns = useMemo(
    () => [
      ...STATIC_COLUMNS,
      {
        field: 'action',
        headerName: '',
        width: 250,
        renderCell: ({ id, row }) => (
          <Box justifyContent={'space-evenly'} flexDirection={'row'} display={'flex'} width={'100%'}>
            <GridActionButton
              variant='outlined'
              buttonText={t('addExtension', { ns: 'certificateExtension' })}
              disabled={isDisable(row.expiresIn, row.numberOfExtensions)}
              onClick={() => !isDisable(row.expiresIn, row.numberOfExtensions) && onAddExtension(id)}
              height='36px'
              shadowWeight={0}
            />
            <a href={row?.fileUrl} target="_blank" rel="noreferrer">
              <GridActionButton buttonIcon={<Visibility />}
                variant='outlined'
                height='36px'
                width='36px'
                shadowWeight={0}
              />
            </a>
            <GridActionButton
              variant='outlined'
              buttonIcon={<Delete />}
              disabled={!row.isDeletable || isDeleting}
              onClick={() => onDeleteCertificate(id)}
              height='36px'
              width='36px'
              shadowWeight={0}
            />
          </Box>
        ),
      },
    ],
    [isDeleting],
  )

  return (
    <>
      {showCertificatesHeader && <ScreenTitleBar title={t('certificates', { ns: 'certificate' })} />}
      <GenericTable
        queryUrl={certificateEndpoints.getCertificates(mainComponentId)}
        enableServerPagination
        onCellClick={cell => { }}
        columns={columns}
        id={'certificates table'}
        styledTable={true}
        autoHeight={false}
        height={height}
        className={'scrollbar'}
        getRowHeight={() => 'auto'}
        getRowClassName={() => 'p-8-core'}
      />
    </>
  )
}
