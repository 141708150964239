import { Box, Collapse } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { To, useLocation, useNavigate, useParams } from 'react-router-dom'
import { AppRoutesNavigation } from '../../app-routes'
import { CenteredSpinner } from '../../components/Spinner'
import { ICustomer } from '../../generated-types'
import { useGetCustomersServiceStationsAdministrators } from '../../api/customer'
import { IServiceStation } from '../../generated-types/service-station'
import { useSidebarCtx } from '../../hooks/context-hooks'
import { GridView, SvgIconComponent } from '@mui/icons-material'
import { CustomerHeader } from 'src/components/sidemenu/CustomerHeader'
import ServiceStationCard from './container/ServiceStationCard'
import classNames from 'classnames'
import { IconType } from 'react-icons'
import MenuItemTemplate from 'src/components/sidemenu/MenuItemTemplate'
import { CollapsedSideMenu } from 'src/components/sidemenu/CollapsedSideMenu'

export interface IMenuItem {
  title: string
  Icon: SvgIconComponent | IconType
  isMinified: boolean
  onClick?: (e) => void
  activePath?: string
  disabled?: boolean
  className?: string
  path?: string
  count?: number
  countBgColor?: string
}

export const ServiceStationSidebar = () => {
  const { setCollapsibleSidebarOpen } = useSidebarCtx()

  useEffect(() => {
    setCollapsibleSidebarOpen(true)
  }, [])

  return (
    <Sidebar />
  )
}

export const Sidebar: React.FC = () => {
  const { data, isLoading } = useGetCustomersServiceStationsAdministrators()
  const navigate = useNavigate()
  const [path, setPath] = useState<string>('')
  const { t } = useTranslation(['common', 'sidebar'])
  const { customerId, serviceStationId } = useParams()
  const { collapsibleSidebarOpen, setCollapsibleSidebarOpen } = useSidebarCtx()

  const getOnClick = (e: { stopPropagation: () => void }, navigationPath: To) => {
    e.stopPropagation()
    navigate(navigationPath)
  }

  function GetMenuItems() {
    const menuItems: IMenuItem[] = [
      { title: t('dashboard', { ns: 'sidebar' }), Icon: GridView, isMinified: true, onClick: (e) => getOnClick(e, AppRoutesNavigation.serviceStation.serviceStation(customerId as EntityId, serviceStationId as EntityId)) }
    ]

    return menuItems
  }

  useEffect(() => {
    if (path && path != '') {
      window.location.pathname != '/service-stations' ? navigate(window.location.pathname) : navigate(path)
    }
  }, [path])

  if (isLoading) return <CenteredSpinner />

  return (
    <>
      <Box sx={{ width: '84px' }} className={!collapsibleSidebarOpen ? 'block h-full' : 'hidden'}>
        <CollapsedSideMenu
          menuItems={GetMenuItems()}
          customer={data?.find(value => value.id == (customerId as EntityId))}
          setPath={setPath}
          setToggleSidebar={setCollapsibleSidebarOpen}
          collapsibleSidebarOpen={!collapsibleSidebarOpen}
        />
      </Box>

      {data?.map(customer => (
        <React.Fragment key={customer.id}>
          <CustomersContainer customer={customer} getMenuItems={GetMenuItems} />
          <GetServiceStation customer={customer} setPath={setPath} />
        </React.Fragment>
      ))}
    </>
  )
}

const GetServiceStation: React.FC<{ customer: ICustomer; setPath: React.Dispatch<React.SetStateAction<string>> }> = ({
  customer,
  setPath,
}) => {
  const { serviceStations } = customer
  useEffect(() => {
    if (serviceStations && serviceStations.length > 0) {
      setPath(prev =>
        prev == ''
          ? AppRoutesNavigation.serviceStation.serviceStation(customer.id, serviceStations[0].id as EntityId)
          : prev,
      )
    }
  }, [serviceStations])
  return <></>
}

const CustomersContainer: React.FC<{ customer: ICustomer, getMenuItems: (serviceStation: IServiceStation) => IMenuItem[] }> = ({ customer, getMenuItems }) => {
  const { customerId, serviceStationId } = useParams()
  const [open, setOpen] = useState(customer.id == (customerId as EntityId))

  useEffect(() => {
    setOpen(customer.id == (customerId as EntityId))
  }, [customerId])

  const selectedServiceStation = customer?.serviceStations?.find(value => value.id == (serviceStationId as EntityId))

  if (customer?.serviceStations && selectedServiceStation) {
    const index = customer?.serviceStations.indexOf(selectedServiceStation)
    customer?.serviceStations.splice(index, 1)
    customer?.serviceStations.unshift(selectedServiceStation)
  }

  const { t } = useTranslation('service-station')

  return (
    <Box className={open ? 'bg-primary bg-opacity-10   rounded-lg my-1' : '  rounded-lg my-1'}>
      <Box key={customer.id}>
        <Box sx={{ padding: '8px' }} onClick={() => setOpen(!open)}>
          <CustomerHeader hasAdminAccess={customer.isAdminCustomer} hasActiveColor={true} {...customer} active={open} />
        </Box>
      </Box>

      <Box>
        <Collapse in={open}>
          <>
            {customer?.serviceStations?.length ? (
              customer?.serviceStations?.map(serviceStation => (
                <Box key={serviceStation.id} className="pt-0 pb-0 pr-0">
                  <CustomerServiceStationsContainer
                    serviceStation={serviceStation}
                    open={serviceStation.id == (serviceStationId as any)}
                    menuItems={getMenuItems(serviceStation)}
                  />
                </Box>
              ))
            ) : (
              <Box className="font-semibold text-center text-gray-600 text-l">
                {t('thisCustomerHasNoServiceStations', { ns: 'sidebar' })}
              </Box>
            )}
          </>
        </Collapse>
      </Box>
    </Box>
  )
}

const CustomerServiceStationsContainer: React.FC<{
  serviceStation: IServiceStation
  open: boolean
  menuItems: IMenuItem[]

}> = ({ serviceStation, open, menuItems }) => {
  const { t } = useTranslation('service-station')
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const active = !!pathname && pathname.split('/')[6]

  const menuItemList = menuItems.map((menuItem, index) => (
    <MenuItemTemplate key={index}
      isMinified={false}
      title={menuItem.title}
      Icon={menuItem.Icon}
      active={active === menuItem.path}
      onClick={menuItem.onClick}
      count={menuItem.count}
      countBgColor={menuItem.countBgColor}
    />
  ))

  return (

    <Box
      className={classNames('rounded-md bg-white shadow-md ', {
        'hover:bg-gray-200': !open,
        'cursor-pointer': !open,
      })} >

      <ServiceStationCard serviceStation={serviceStation} active={open}
        onClick={() => { navigate(AppRoutesNavigation.serviceStation.serviceStation(serviceStation.customerId, serviceStation.id)) }} />

      <Collapse in={open}>
        {menuItemList}
      </Collapse>
    </Box>
  )
}