import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { MainButton } from '../../../components/MainButton'
import { ScreenTitle } from '../../../components/ScreenTitle'
import { showDialog } from '../../../show-dialog'
import { CustomPropertyTable } from './CustomPropertyTable'
import { CreateCustomPropertyDialog } from './CustomPropertyAddDialog'

export const CustomPropertyContainer = ({ isSuperAdmin = false }) => {
  const { t: translate } = useTranslation(['custom-property', 'common', 'admin-sidebar'])
  const { id: customerId } = useParams()

  const onAddCustomProperty = async () => {
    const added = await showDialog(CreateCustomPropertyDialog, {
      componentProps: {
        customerId: customerId as EntityId,
        isAdmin: isSuperAdmin,
      },
    })
    if (!added) return
  }

  return (
    <>
    <Box className="mb-2 py-2 px-4 mt-2 bg-white shadow-md">
      <Box className="flex justify-between mb-4">
        <ScreenTitle title={translate('custom-properties', {ns: 'admin-sidebar'})} />
        <MainButton onClick={() => onAddCustomProperty()}>{translate('add', { ns: 'common' })}</MainButton>
      </Box>
      <CustomPropertyTable customerId={customerId as EntityId} isSuperAdmin={isSuperAdmin} />

    </Box>
    </>
  )
}