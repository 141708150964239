import { Stack, useTheme } from '@mui/material'
import { Route, Routes } from 'react-router-dom'
import { AppRoutesRouter } from '../../app-routes'
import { lazy, Suspense } from 'react'
import { CenteredSpinner } from '../../components/Spinner'
import { useDialogCtx } from '../../hooks/context-hooks'
import { DialogContextType } from '../../enums'
import { LocationContextProvider } from '../../context/FacilityContext'

const LocalityDashBoardContainer = lazy(async () => await import('src/screens/localities/locality-dashboard/LocalityDashBoardContainer'))
const MooringsPage = lazy(async () => await import('src/screens/localities/moorings/MooringsPage'))
const DeviationsPage = lazy(async () => await import('src/screens/localities/DeviationsPage'))
const DeviationContainer = lazy(async () => await import('src/screens/localities/deviations/DeviationContainer'))
const DocumentsPage = lazy(async () => await import('src/pages/locality/DocumentsPage'))
const DocumentPage = lazy(async () => await import('src/pages/locality/DocumentPage'))
const MainComponentInLocality = lazy(async () =>
  await import('src/screens/localities/mainComponents/components/MainComponentInLocality'))
const PositionMainPage = lazy(async () => await import('src/screens/localities/positions/PositionMainPage'))
const WorkAssignmentPage = lazy(async () => await import('src/pages/locality/WorkAssignmentPages'))
const WorkAssignmentDetailPage = lazy(async () => await import('src/screens/localities/workAssignments/WorkAssignmentDetailPage'))
const ServicesPage = lazy(async () => await import('src/pages/locality/ServicesPage'))
const SingleLocalityFrequency = lazy(async () => await import('src/screens/localities/locality-services/SingleLocalityFrequency'))
const LocalityFrequencyGroup = lazy(async () => await import('src/screens/localities/locality-services/LocalityFrequencyGroup'))
const EquipmentPage = lazy(async () => await import('src/pages/locality/EquipmentPage'))
const TasksPage = lazy(async () => await import('src/pages/locality/TasksPage'))
const TaskPage = lazy(async () => await import('src/pages/locality/TaskPage'))
const ComponentDeviationPage = lazy(async () => await import('src/screens/localities/positions/ComponentDeviationPage'))

export const LocalitiesRoutes = () => {
  const { dialogsCustom } = useDialogCtx()
  const theme = useTheme()

  return (
    <LocationContextProvider>
      {Object.values(dialogsCustom[DialogContextType.localityContext] ?? {})}
      <Stack
        style={{ backgroundColor: theme.palette.white[theme.palette.mode] }}
        width={'100%'}
        minHeight={'calc(100vh - 200px)'}
        className='pb-4-core'
      >
        <Suspense fallback={<CenteredSpinner />}>
          <Routes>
            <Route path={AppRoutesRouter.Localities.locality} element={<LocalityDashBoardContainer />} />
            <Route path={AppRoutesRouter.Localities.moorings} element={<MooringsPage />} />
            <Route path={AppRoutesRouter.Localities.deviations} element={<DeviationsPage />} />
            <Route path={AppRoutesRouter.Localities.componentDeviation} element={<ComponentDeviationPage />} />
            <Route path={AppRoutesRouter.Localities.deviation} element={<DeviationContainer />} />
            <Route path={AppRoutesRouter.Localities.documents} element={<DocumentsPage />} />
            <Route path={AppRoutesRouter.Localities.document} element={<DocumentPage />} />
            <Route path={AppRoutesRouter.Localities.mainComponent} element={<MainComponentInLocality />} />
            <Route path={AppRoutesRouter.Localities.position} element={<PositionMainPage />} />
            <Route path={AppRoutesRouter.Localities.workAssignments} element={<WorkAssignmentPage />} />
            <Route path={AppRoutesRouter.Localities.workAssignment} element={<WorkAssignmentDetailPage />} />
            <Route path={AppRoutesRouter.Localities.localityServices} element={<ServicesPage />} />
            <Route path={AppRoutesRouter.Localities.localityService} element={<SingleLocalityFrequency />} />
            <Route path={AppRoutesRouter.Localities.localityServiceGroup} element={<LocalityFrequencyGroup />} />
            <Route path={AppRoutesRouter.Localities.equipments} element={<EquipmentPage />} />
            <Route path={AppRoutesRouter.Localities.tasks} element={<TasksPage />} />
            <Route path={AppRoutesRouter.Localities.task} element={<TaskPage />} />
            <Route path="*" element={<div></div>} />
          </Routes>
        </Suspense>
      </Stack>
    </LocationContextProvider>
  )
}
