import { Box, Collapse, Grid, Stack, Typography, styled, useTheme } from '@mui/material'
import { ICustomer, ILocality, ILocalityGroup } from '../../../generated-types'
import { AppRoutesNavigation } from '../../../app-routes'
import { useNavigate } from 'react-router-dom'
import { Chevron, CustomerHeader, ShadowedCard } from 'src/components/sidemenu/CustomerHeader'
import { ArrowDropDown, ArrowRight } from '@mui/icons-material'
import MultipleSelectChip from '../components/LocalitiesFilter'
import * as React from 'react'
import Chip from '@mui/material/Chip'
import { useTranslation } from 'react-i18next'
import { LocationHeader } from 'src/components/sidemenu/LocationHeader'
import { useGetGrouppedLocalities } from 'src/api/localities'
import { CenteredSpinner } from 'src/components/Spinner'

const Area = styled(Typography)(() => ({
  fontWeight: 'bold',
  paddingLeft: '4px'
}))

const removeDuplicatedLocalities = (localities: ILocality[], localityGroups: ILocalityGroup[]): ILocality[] => {
  if (localityGroups?.length > 0) {
    const localityIdsInGroups = new Set<number>()
    localityGroups?.forEach(group => {
      group.localities?.forEach(locality => {
        localityIdsInGroups.add(locality.id)
      })
    })

    const filteredLocalities = localities.filter(locality => !localityIdsInGroups?.has(locality.id))
    return filteredLocalities
  }

  return localities
}

const AllLocalities: React.FC<{ customers: ICustomer[] | undefined, open: number, setOpen: (value: any) => void }> = ({ customers, open, setOpen }) => {
  return (
    <Box >
      {customers?.map((customer: ICustomer, index: number) => {
        return <Box key={index} style={{ maxHeight: '82vh' }} className='overflow-y-auto'>
          <CustomerLocalitySection customer={customer} open={open} setOpen={setOpen} key={index} />
        </Box>
      })}
    </Box>
  )
}

export default AllLocalities

interface ICustomerLocalitySectionProps {
  open: number
  setOpen: (value: any) => void
  customer: ICustomer
}

const CustomerLocalitySection: React.FC<ICustomerLocalitySectionProps> = ({ open, setOpen, customer }) => {
  const [areas, setAreas] = React.useState<string[]>([])
  const [otherItems, setOtherItems] = React.useState<string[]>([])
  const { t } = useTranslation('localities')
  const theme = useTheme()
  const { data, isLoading } = useGetGrouppedLocalities(customer.id)

  const handleDelete = (item: string) => {
    setAreas((prevAreas) => prevAreas.filter((area) => area !== item))
    setOtherItems((prevItems) =>
      prevItems.filter((otherItem) => otherItem !== item)
    )
  }

  const renderSelectedChips = () => {
    return (
      (areas?.length > 0 || otherItems?.length > 0) && (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, padding: '16px 0px' }}>
          {areas.map((value) => (
            <Chip
              key={`area-${value}`}
              label={value}
              onDelete={() => handleDelete(value)}
              sx={{
                backgroundColor: theme.palette.primaryMain[theme.palette.mode],
                color: theme.palette.white[theme.palette.mode],
                '& .MuiChip-deleteIcon': {
                  color: theme.palette.white[theme.palette.mode]
                }
              }}

            />
          ))}
          {otherItems.map((value) => (
            <Chip
              key={`other-${value}`}
              label={t(value)}
              onDelete={() => handleDelete(value)}
              sx={{
                backgroundColor: theme.palette.primaryMain[theme.palette.mode],
                color: theme.palette.white[theme.palette.mode],
                '& .MuiChip-deleteIcon': {
                  color: theme.palette.white[theme.palette.mode]
                }
              }}
            />
          ))}
        </Box>
      )
    )
  }

  return (
    <>
      <Box padding={0.5}>
        <CustomerHeader hasAdminAccess={customer.isAdminCustomer} hasActiveColor={false} {...customer} onClick={() => { setOpen(value => value == customer.id ? 0 : customer.id) }} active={open == customer.id} MultipleSelectComponent={
          <MultipleSelectChip
            areas={areas}
            setAreas={setAreas}
            otherItems={otherItems}
            setOtherItems={setOtherItems}
            localityGroups={data?.localityGroups?.map(localityGroup => localityGroup.name)}
          />
        } />
        {open == customer.id && renderSelectedChips()}
      </Box>

      <Box className='overflow-y-auto'>
        <Collapse in={open == customer.id} >
          <CustomerContentSection areas={areas} otherItems={otherItems} isLoading={isLoading} localities={data?.localityCards || []} localityGroups={data?.localityGroups || []}></CustomerContentSection>
        </Collapse>
      </Box>
    </>
  )
}

const CustomerContentSection: React.FC<{ areas: string[], otherItems: string[], localityGroups: ILocalityGroup[], localities: ILocality[], isLoading: boolean }> = ({ areas, otherItems, localityGroups, localities, isLoading }) => {
  const { t } = useTranslation('localities')

  function filterLocalities(localities: ILocality[]) {
    let filteredLocalities: ILocality[] = localities
    if (otherItems.includes('has_due_documents'))
      filteredLocalities = filteredLocalities.filter(item => item.expiredDocumentsCount != null && item.expiredDocumentsCount > 0)

    if (otherItems.includes('has_deviations'))
      filteredLocalities = filteredLocalities.filter(item => item.deviationCount != null && item.deviationCount > 0)

    if (otherItems.includes('has_due_services'))
      filteredLocalities = filteredLocalities.filter(item => item.servicesCount != null && item.servicesCount > 0)

    return filteredLocalities
  }

  if (isLoading) return <CenteredSpinner />

  return (
    <Box >
      {localityGroups && localityGroups.length > 0 ? (
        <>
          {localityGroups
            .filter(lg => areas.length === 0 || areas.includes(lg.name))
            .map((localityGroup, index) => (
              <LocalityGroupSection
                groupIndex={index}
                key={index}
                localities={filterLocalities(localityGroup.localities)}
                localityGroupName={localityGroup.name}
              />
            ))}
          {areas.length === 0 && (
            <LocalityGroupSection
              groupIndex={0}
              localities={removeDuplicatedLocalities(filterLocalities(localities), localityGroups)}
              localityGroupName={t('not-grouped')}
            />
          )}
        </>
      ) : (
        <LocalityGroupSection
          groupIndex={0}
          localities={removeDuplicatedLocalities(filterLocalities(localities), localityGroups || [])}
        />
      )}
    </Box>
  )
}

interface ILocalityGroupSectionProps {
  localityGroupName?: string
  localities: ILocality[]
  groupIndex: number
}

const LocalityGroupSection: React.FC<ILocalityGroupSectionProps> = ({ localityGroupName, localities, groupIndex }) => {
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = React.useState(true)

  const handleToggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    localities?.length > 0 ? (
      <>
        <Box key={groupIndex} padding={'2px 4px'}>
          {localityGroupName && (
            <ShadowedCard onClick={handleToggle}>
              <Stack direction={'row'} alignItems="center">
                <Chevron>
                  {isOpen ? <ArrowDropDown /> : <ArrowRight />}
                </Chevron>
                <Area variant="body2">{localityGroupName}</Area>
              </Stack>
            </ShadowedCard>
          )}

          <Collapse in={isOpen} sx={{ paddingTop: '2px' }}>
            <Grid container>
              {localities?.map((locality, index) => (
                <Grid item md={4} key={index} width={'100%'}>
                  <LocationHeader
                    locality={locality}
                    onClick={() => {
                      navigate(AppRoutesNavigation.Localities.locality(locality.customerId, locality.id))
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </Collapse>
        </Box>
      </>
    ) : null
  )
}