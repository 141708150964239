import { Grid } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetCustomerSuppliers } from '../../../api/supplier'
import BasicDatePicker from '../../../components/DateTimePicker'
import { FormFileUpload, FormTextField } from '../../../components/FormCore'
import { ICustomerAdminSupplier } from '../../../generated-types'
import { Months } from '../../../enums'
import { MainComponentCertificationAdd } from './MainComponentCertificationAdd'
import { SuppliersFormField } from '../../../components/SuppliersFormField'
import { APIResultCount } from '../../../constant'

const MainComponentStaticFormFields = ({
  form,
  initialValues,
  file,
  initialCertificateFiles,
  setInitialCertificateFiles,
  customerId,
}) => {
  const { t: translate } = useTranslation(['main-component', 'common'])
  const [year, setYear] = useState<Date | null | string>(form.getValues('productionYear'))
  const [month, setMonth] = useState<Date | null | string>(`${Months[form.getValues('productionMonth')]} ${year}`)

  const { data: suppliers } = useGetCustomerSuppliers(customerId, 0, APIResultCount.Max)

  useEffect(() => {
    if (initialValues['supplierId'])
      form.setValue(
        'supplierId',
        suppliers?.items.find(s => s.id == initialValues['supplierId']) ?? suppliers?.items[0],
      )
  }, [suppliers, initialValues, form])

  return (
    <Grid container spacing={2} pt={2} className="!flex !flex-wrap justify-between">
      <Grid item xs={12} md={6} lg={6}>
        <FormTextField name="id" sx={{ display: 'none' }} />
        <FormTextField name="name" label={translate('name', { ns: 'common' })} />
      </Grid>
      <Grid item xs={6}>
        <BasicDatePicker label={translate('dateProduced', { ns: 'main-component' })} name="dateProduced" />
      </Grid>
      <Grid item xs={12}>
        <FormTextField name="description" label={translate('description', { ns: 'common' })} multiline rows={3} />
      </Grid>
      <Grid item xs={6}>
        <FormTextField name="serialNumber" label={translate('serialNumber', { ns: 'main-component' })} />
      </Grid>
      <Grid item xs={6}>
        <FormTextField name="orderNumber" label={translate('orderNumber', { ns: 'main-component' })} />
      </Grid>
      <Grid item xs={6}>
        <FormTextField name="reference" label={translate('reference', { ns: 'main-component' })} />
      </Grid>
      <Grid item xs={6}>
        <FormTextField type={'number'} name="lifetime" label={translate('lifetime', { ns: 'main-component' })} />
      </Grid>
      <Grid item xs={6}>
        <SuppliersFormField
          suppliers={suppliers?.items as ICustomerAdminSupplier[]}
          name="supplierId"
          label={translate('supplier', { ns: 'common' })}
        />
      </Grid>
      <Grid item xs={6}>
        <BasicDatePicker
          name="productionYear"
          views={['year']}
          inputFormat={'yyyy'}
          label={translate('productionYear', { ns: 'main-component' })}
          openTo={'year'}
          onChange={newValue => {
            form.setValue('productionYear', new Date(newValue || 0).getFullYear().toString())
            setYear(newValue)
          }}
          value={year}
        />
      </Grid>
      <Grid item xs={6}>
        <BasicDatePicker
          name="productionMonth"
          views={['year', 'month']}
          inputFormat={'MMMM'}
          label={translate('productionMonth', { ns: 'main-component' })}
          openTo={'month'}
          onChange={newValue => {
            form.setValue('productionMonth', new Date(newValue || 0).getMonth())
            setMonth(newValue)
          }}
          value={month}
          disabled={!year}
        />
      </Grid>
      <Grid item xs={6}>
        <FormFileUpload
          label={translate('image', { ns: 'common' })}
          name="fileId"
          mapFileKey="id"
          initialFiles={file}
          acceptedFileTypes={['image/png', 'image/jpeg', 'image/gif', 'image/jpg']}
        />
      </Grid>
      <Grid item xs={12}>
        <MainComponentCertificationAdd
          suppliers={suppliers}
          initialValues={initialValues}
          accordingSummary={'product-certificate'}
          form={form}
          isProductCertificateAdd={true}
          initialCertificateFiles={initialCertificateFiles}
          setInitialCertificateFiles={setInitialCertificateFiles}
        />
      </Grid>
    </Grid>
  )
}

export default MainComponentStaticFormFields
