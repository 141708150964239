import { Box, Tooltip, Typography, styled } from '@mui/material'
import { firstLetterToUpperCase } from '../../utils/function'
import { GetRGBA } from 'src/utils'
import { Flex } from 'src/styles/flexComponent'

const Number = styled(Typography)(() => ({
  paddingLeft: '3px',
  textAlign: 'right',
  fontWeight: 600,
  marginLeft: 'auto'
}))

const IconBox = styled(Box)(() => ({
  padding: '3px',
  height: '16px',
  width: '16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '8px'
}))

const StyledCard = styled(Box)(({ theme }) => ({
  width: '42px',
  height: '22px',
  padding: '2px 3px',
  fontSize: '12px',
  display: 'flex',
  flexDirection: 'row',
  borderRadius: '11px',
  border: `0.5px solid ${GetRGBA(theme.palette.secondaryMain[theme.palette.mode], 1)}`
}))

const CountWithIconBox = ({ Component, value, iconBgColor, title, color }) => {
  return (
    <Tooltip title={firstLetterToUpperCase(title)}>
      <StyledCard>
        <Flex.Row alignItems='center' justifyContent='center'>
          <IconBox sx={{ backgroundColor: iconBgColor }}>
            <Component />
          </IconBox>
          <Number variant='body2' color={color}>{value}</Number>
        </Flex.Row>
      </StyledCard>
    </Tooltip>
  )
}

export default CountWithIconBox