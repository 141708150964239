import { GridColumns, GridNativeColTypes, GridActionsCellItem } from '@mui/x-data-grid-pro'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { MdDelete, MdAutorenew } from 'react-icons/md'
import { invalidateUserInviteCache, useDeleteUserInvite, useRenewUserInvite, userInvitesEndpoints } from '../../../api/user-invites'
import GenericTable from '../../../components/table/GenericTable'
import { userInviteQueryKey } from '../../../query-keys'
import { useDialogCtx } from '../../../hooks/context-hooks'
import { toast } from 'react-toastify'
import moment from 'moment'
import { getFormattedDate } from '../../../utils'

interface Props {
  customerId: EntityId
}

export const UserInvitesTable: React.FC<Props> = ({ customerId }) => {
  const { t } = useTranslation(['user-invite'])
  const { showConfirmDialog } = useDialogCtx()
  const { mutateAsync, isLoading } = useDeleteUserInvite(customerId)
  const { mutateAsync: renewUserInvite, isLoading: isRenewing } = useRenewUserInvite(customerId)

  const onDeleteInvite = async (id: EntityId) => {
    showConfirmDialog(
      t('remove-invite', { ns: 'user-invite' }),
      t('remove-invite-message', { ns: 'user-invite' }),
      {
        acceptText: t('yes', { ns: 'common' }),
        cancelText: t('no', { ns: 'common' }),
      },
      async pop => {
        await mutateAsync(id).then(() => {
          invalidateUserInviteCache.useGetUserInvites(customerId)
          toast.success(t('successfullyRemoved', { ns: 'common-api-message' }))
        }).catch(() => {
          toast.error(t('unExpectedError', { ns: 'common-api-message' }))
        })
        pop()
      },
    )
  }

  const onRenewInvite = async (id: EntityId) => {
    showConfirmDialog(
      t('renew-invite', { ns: 'user-invite' }),
      t('renew-invite-message', { ns: 'user-invite' }),
      {
        acceptText: t('yes', { ns: 'common' }),
        cancelText: t('no', { ns: 'common' }),
      },
      async pop => {
        await renewUserInvite({ id }).then(() => {
          invalidateUserInviteCache.useGetUserInvites(customerId)
          toast.success(t('successfullyUpdated', { ns: 'common-api-message' }))
        }).catch(() => {
          toast.error(t('unExpectedError', { ns: 'common-api-message' }))
        })
        pop()
      },
    )
  }

  const STATIC_COLUMNS: GridColumns = [
    { field: 'userEmail', headerName: 'User email', flex: 1 },
    { field: 'validUntil', headerName: 'Valid until', flex: 1, valueFormatter: ({ value }) => getFormattedDate(value), }
  ]
  const columns: GridColumns = useMemo(
    () => [
      ...STATIC_COLUMNS,
      {
        field: 'actions',
        type: 'actions' as GridNativeColTypes,
        headerName: t('actions', { ns: 'common' }),
        flex: 1,
        getActions: ({ id, row }) => [
          <GridActionsCellItem
            key={id}
            label={t('renew', { ns: 'common' })}
            icon={<MdAutorenew className={isRenewing ? 'spinner' : undefined} size={24} />}
            onClick={() => onRenewInvite(id)}
            disabled={moment(row.validUntil).isAfter(new Date()) || isRenewing}
            color="primary"
          />,
          <GridActionsCellItem
            key={id}
            label={t('delete', { ns: 'common' })}
            icon={<MdDelete size={24} />}
            onClick={() => onDeleteInvite(id)}
            disabled={isLoading}
            color="primary"
          />,
        ],
      },
    ],
    [isLoading],
  )

  return (
    <>
      <GenericTable
        id="user-invites"
        columns={columns}
        enablePagination={true}
        enableServerPagination={true}
        queryUrl={userInvitesEndpoints.getUserInvites(customerId)}
        generateQueryKey={(page, pageSize) => userInviteQueryKey.GET_USER_INVITES(customerId, { page, pageSize })}
        pageSize={20}
        rowColor="#e5e4e2"
        enableToolbar={true}
        getRowHeight={() => 'auto'}
      /></>
  )
}
