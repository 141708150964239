import { Box, InputBase } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MdSearch } from 'react-icons/md'
import { invalidateProductCache, useAddProduct } from '../../../api/product'
import { MainButton } from '../../../components/MainButton'
import { ScreenTitle } from '../../../components/ScreenTitle'
import { ShowDialogProps } from '../../../show-dialog'
import { ProductForm } from './ProductForm'
import { ProductTable } from './ProductTable'
import { invalidatePositionDrawingCache } from '../../../api/position-drawing'
import { useDialogCtx } from '../../../hooks/context-hooks'

interface CreateProductProps extends ShowDialogProps {
  positionDrawingId?: EntityId
  positionDrawingNumber?: string
}

export const CreateProductDialog: React.FC<CreateProductProps> = ({
  positionDrawingId,
  positionDrawingNumber,
  pop,
}) => {
  const mutation = useAddProduct()

  const onSubmit = async form => {
    await mutation.mutateAsync(form)

    invalidateProductCache.getProducts()
    positionDrawingId && invalidatePositionDrawingCache.getPositionDrawingById(positionDrawingId)
    pop(true)
  }

  return (
    <Box>
      <ProductForm
        onSubmit={onSubmit}
        onCancel={() => pop()}
        initialValues={{ positionDrawingId: positionDrawingId, positionDrawingNumber: positionDrawingNumber }}
      />
    </Box>
  )
}

export const ProductContainer = () => {
  const { t: translate } = useTranslation(['component', 'common'])
  const { showDialog } = useDialogCtx()

  const [searchInput, setSearchInput] = useState<string>('')

  const onAdd = async () => {
    const added = await showDialog(CreateProductDialog, {
      title: translate('addProduct', { ns: 'product' }),
      opts: {
        maxWidth: 'md',
        fullWidth: true,
      },
      componentProps: {},
    })
    if (!added) return
  }

  const onSearchInputChange = (searchInput: string) => {
    setSearchInput(searchInput)
  }

  return (
    <>
      <Box className="mb-2 bg-white shadow-md p-3" borderRadius={2}>
        <Box className="flex flex-wrap justify-between mb-2">
          <ScreenTitle title={translate('products', { ns: 'product' })} />
          <Box className="flex flex-wrap-reverse justify-end items-center">
            <Box
              className="flex gap-3 mr-2 items-center w-30 md:w-40 lg:w-80"
              sx={{ borderRadius: 2, borderColor: 'gray', borderWidth: '1px' }}
            >
              <Box className="ml-3">
                <MdSearch color="gray" size={20} />
              </Box>
              <InputBase
                sx={{ borderColor: 'white' }}
                autoFocus={searchInput != '' ? true : false}
                placeholder={translate('search', { ns: 'common' }) + '...'}
                inputProps={{ 'aria-label': 'search' }}
                defaultValue={searchInput}
                onChange={event => onSearchInputChange(event.target.value)}
              />
            </Box>
            <Box>
              <MainButton onClick={onAdd}>{translate('add', { ns: 'common' })}</MainButton>
            </Box>
          </Box>
        </Box>
        <ProductTable searchValue={searchInput} />
      </Box>
    </>
  )
}
