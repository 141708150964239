import { MdGpsFixed, MdGpsNotFixed, MdLocationOn, MdOutlineOpenInNew, MdOutlineSettings, MdPerson } from 'react-icons/md'
import { Box, Grid, Stack, Typography, styled, useMediaQuery, useTheme } from '@mui/material'
import { useParams } from 'react-router-dom'
import { CalculateGpsToDms } from '../../../../components/GpsToDms'
import { useTranslation } from 'react-i18next'
import { getLocalDateTime } from '../../../../utils/function'
import { FC, Fragment, useMemo } from 'react'
import { UpdateLocalityDialog } from '../../UpdateLocalityDialog'
import { useDialogCtx } from '../../../../hooks/context-hooks'
import { ILocality, IUser } from '../../../../generated-types'
import { UserDetail, getUserDetail } from '../../../../utils/getUserDetails'
import { GenericCardMinimalVersion } from '../../../../components/GenericCardMinimalVersion'
import { getFormattedDate } from '../../../../utils'
import { permissionValue } from 'src/enums/permissionValue'

interface LocalityDetailsProps {
  locationNr: string
  name: string
  latitude: number | undefined
  longitude: number | undefined
  fileUrl?: string | undefined
  responsibleUser?: IUser
  certificateDueDate?: Date
  hs?: number | undefined
  vc5m?: number | undefined
  vc15m?: number | undefined
  selectedLocality?: ILocality
  removeActions?: boolean
}

const UnderlinedTypography = styled(Typography)({
  textDecoration: 'underline',
  cursor: 'pointer'
})

const formatString = (data: string) => {
  const numberCoordinatePart = data.split(' ')[0]
  const decimalCoordinatePart = numberCoordinatePart.split('.')[1]
  let formattedDecimalCoornate
  if (decimalCoordinatePart.length > 3) {
    formattedDecimalCoornate = decimalCoordinatePart.slice(0, 2)
  }
  formattedDecimalCoornate = decimalCoordinatePart.slice(0, 1)
  return numberCoordinatePart.split('.')[0] + '.' + formattedDecimalCoornate + data.split(' ')[1]
}

const TideCard = ({ title, value, bgColor }) => {
  const theme = useTheme()
  return (
    <Box
      display={'flex'}
      flexDirection={'row'}
      alignItems={'center'}
      justifyContent={'center'}
      bgcolor={bgColor}
      height={'fit-content'}
      width={'fit-content'}
      borderRadius={'4px'}
      className='p-4-core'
    >
      <Typography variant='body2' fontWeight={600} color={theme.palette.white.main} className='px-1'>{title}</Typography>
      <Typography variant='body2' fontWeight={600} color={theme.palette.white.main}>{value}</Typography>
    </Box>
  )
}


export const LocalityDetailsCard : FC<LocalityDetailsProps> = ({ locationNr, latitude, longitude, fileUrl, name, certificateDueDate, responsibleUser, hs, vc5m, vc15m, selectedLocality,removeActions }) => {
  const theme = useTheme()
  const { customerId } = useParams()
  const { showDialog, openFilePreviewDialog } = useDialogCtx()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { t: translate } = useTranslation(['common', 'locality', 'customer-locality'])

  const handleEmailClick = () => {
    window.location.href = `mailto:${responsibleUser?.email}`
  }

  const localityDetails = [
    {
      icon: <MdLocationOn size={12} />,
      title: translate('localityNumber', { ns: 'common' }),
      data: `#${locationNr}`
    }, {
      icon: <MdGpsFixed size={12} />,
      title: translate('gpsLatitude', { ns: 'common' }),
      data: CalculateGpsToDms(latitude, false)
    }, {
      icon: <MdGpsNotFixed size={12} />,
      title: translate('gpsLongitude', { ns: 'common' }),
      data: CalculateGpsToDms(longitude, true)
    }]

  const responsibleUserFullName = useMemo(() => {
    if (responsibleUser?.firstName && responsibleUser?.lastName) {
      return getUserDetail(responsibleUser, UserDetail.FullName)
    } else if (responsibleUser?.firstName && !responsibleUser?.lastName) {
      return getUserDetail(responsibleUser, UserDetail.FirstName)
    } else if (!responsibleUser?.firstName && responsibleUser?.lastName) {
      return getUserDetail(responsibleUser, UserDetail.LastName)
    } else { return translate('n/a', { ns: 'common' }) }
  }, [responsibleUser])

  const onUpdateLocality = async () => {
    await showDialog(
      UpdateLocalityDialog,
      {
        componentProps: { customerId: customerId as EntityId, t: translate, locality: selectedLocality },
        opts: {
          maxWidth: 'md',
          fullWidth: true,
        },
      },
      undefined,
      true,
    )
  }

  return (
    <GenericCardMinimalVersion
      title={name}
      titleTypographyProps={{ maxLength: isMobile ? 8 : 12 }}
      actions={
        !removeActions
          ? [
              {
                label: translate('viewCertificate', { ns: 'common' }),
                onClick: fileUrl ? () => openFilePreviewDialog(fileUrl) : () => {},
                icon: <MdOutlineOpenInNew size={20} color={theme.palette.primaryMain[theme.palette.mode]} />,
                disabled: !fileUrl,
              },
              {
                label: translate('updateLocality', { ns: 'customer-locality' }),
                onClick: () => onUpdateLocality(),
                icon: <MdOutlineSettings size={20} color={theme.palette.primaryMain[theme.palette.mode]} />,
                permissionBinaryValues: [permissionValue.Locality.Update],
              },
            ]
          : []
      }
      maxHeight={'180px'}
    >
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Grid container display={'flex'} flexDirection={'row'}>
            {localityDetails.map((item, index) => (
              <Fragment key={index}>
                <Grid key={index} item display={'flex'} alignItems={'center'} justifyContent={'left'} className='pr-1' color={theme.palette.primaryMain[theme.palette.mode]}>
                  {item.icon}
                </Grid>
                <Grid key={index} item display={'flex'} alignItems={'center'} justifyContent={'left'} className='pr-3'>
                  <Typography variant="subtitle2" fontWeight={600}>
                    {item.data ? item.data[0] == '#' ? item.data : item.data.length >= 8 ? formatString(item.data) : item.data : translate('n/a', { ns: 'common' })}
                  </Typography>
                </Grid>
              </Fragment>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {responsibleUser && (
            <>
              <Grid display={'flex'} container xs={12} flexDirection={'row'}>
                <Grid item display={'flex'} alignItems={'center'} justifyContent={'center'} paddingRight={'4.5px'}>
                  <MdPerson width={'9px'} height={'9px'} color={theme.palette.primaryMain[theme.palette.mode]} />
                </Grid>
                <Grid item display={'flex'} alignItems={'center'} >
                  <UnderlinedTypography variant="body2" color={theme.palette.primaryLight[theme.palette.mode]} onClick={handleEmailClick}>
                    {responsibleUserFullName}
                  </UnderlinedTypography>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
        <Grid item xs={12}>
          <Grid container xs={12} justifyContent={'right'} alignItems={'center'} className='pt-8-core pb-8-core'>
            <Grid item>
              <Stack direction={'row'} spacing={'3px'}>
                <TideCard title={translate('hs')} value={hs ?? translate('n/a')} bgColor={theme.palette.successMain[theme.palette.mode]} />
                <TideCard title={translate('vc5m')} value={vc5m ?? translate('n/a')} bgColor={theme.palette.successMain[theme.palette.mode]} />
                <TideCard title={translate('vc15m')} value={vc15m ?? translate('n/a')} bgColor={theme.palette.errorMain[theme.palette.mode]} />
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Typography variant="body2" fontWeight={400} color={theme.palette.secondaryDark[theme.palette.mode]}>
            {translate('certificateDueDate', { ns: 'locality' })}: {certificateDueDate
              ? getFormattedDate(getLocalDateTime(certificateDueDate))
              : translate('n/a', { ns: 'common' })}
          </Typography>
        </Grid>
      </Grid>
    </GenericCardMinimalVersion>
  )
}
