import { ICustomerLocalities } from 'src/generated-types/customerLocalities'
import { IDeviationCounts, IDocumentsCount, ILocalityGroup, IExpiringComponentsCount, ILocalityEnvironmentDetails, ILocality } from '../generated-types'
import { ILocalityRequestDto } from '../generated-types/locality-request-dto'
import { IMainComponentStatus } from '../generated-types/main-component-status'
import { queryClient } from '../query-client'
import { localityQueryKeys } from '../query-keys'
import { IPaginatedResult } from './paginated-result'
import { useDelete, useGet, usePost, usePut } from './reactQuery'

const baseUrl = (customerId: EntityId) => `customers/${customerId}/localities`

const cageStatusUrl = (customerId: EntityId, localityId: EntityId) => `${baseUrl(customerId)}/${localityId}/cage-status`

export const endpoints = {
  getLocalities(customerId: EntityId) {
    return `${baseUrl(customerId)}`
  },
  getLocalitiesWithInfoCount(customerId: EntityId) {
    return `${baseUrl(customerId)}/get-all-with-info-count`
  },
  getGrouppedLocalitiesWithInfoCount(customerId: EntityId) {
    return `${baseUrl(customerId)}/get-grouped-localities`
  },
  getLocalitiesForDropDown(customerId: EntityId) {
    return `${baseUrl(customerId)}/names`
  },
  getLocalitiesWithInfo(customerId: EntityId) {
    return `${baseUrl(customerId)}?withInfo=true`
  },
  getCageStatus(customerId: EntityId, localityId: EntityId) {
    return `${cageStatusUrl(customerId, localityId)}`
  },
  getLocality(customerId: EntityId, id: EntityId) {
    return `${baseUrl(customerId)}/${id}`
  },
  getWorkAssignmentCount(customerId: EntityId, localityId: EntityId, mooringId: EntityId) {
    return `${baseUrl(customerId)}/${localityId}/getWorkAssignmentCount?mooringId=${mooringId}`
  },
  getLocalityDeviationCounts(customerId: EntityId, localityId: EntityId, mooringId: EntityId) {
    return `${baseUrl(customerId)}/${localityId}/get-deviation-counts?mooringId=${mooringId}`
  },
  getLocalityEnvironmentDetails(customerId: EntityId, localityId: EntityId, mooringId: EntityId) {
    return `${baseUrl(customerId)}/${localityId}/get-environment-details?mooringId=${mooringId}`
  },
  getExpiringComponents(customerId: EntityId, localityId: EntityId, mooringId: EntityId) {
    return `${baseUrl(customerId)}/${localityId}/get-expiring-components?mooringId=${mooringId}`
  },
  getDocumentsCount(customerId: EntityId, localityId: EntityId) {
    return `${baseUrl(customerId)}/${localityId}/getDocumentsCount`
  },
  createLocality(customerId: EntityId) {
    return `${baseUrl(customerId)}`
  },
  updateLocality(customerId: EntityId, id: EntityId) {
    return `${baseUrl(customerId)}/${id}`
  },
  deleteLocality(customerId: EntityId) {
    return `${baseUrl(customerId)}`
  },
  getLocalityGroups(customerId: EntityId, id: EntityId) {
    return `${baseUrl(customerId)}/${id}/locality-groups`
  },
}

export const invalidateLocalityCache = {
  getDocumentsCount(customerId: EntityId, localityId: EntityId) {
    queryClient.invalidateQueries([endpoints.getDocumentsCount(customerId, localityId)])
  },
  getLocalities(customerId?: EntityId, queryParams?: ILocalityRequestDto) {
    queryClient.invalidateQueries(localityQueryKeys.GET_LOCALITIES(customerId, queryParams))
  },
  getLocality(customerId: EntityId, id: EntityId, mainComponentId?: EntityId) {
    queryClient.invalidateQueries(localityQueryKeys.GET_LOCALITY(customerId, id, { mainComponentId: mainComponentId }))
  },
  getLocalityGroups(customerId: EntityId, id: EntityId) {
    queryClient.invalidateQueries([endpoints.getLocalityGroups(customerId, id)])
  },
  getCageStatus(customerId?: EntityId, localityId?: EntityId, queryParams?: object) {
    queryClient.invalidateQueries(localityQueryKeys.GET_CAGE_STATUS(customerId, localityId, queryParams))
  },
  getLocalitiesWithInfoCount(customerId?: EntityId) {
    queryClient.invalidateQueries(localityQueryKeys.GET_LOCALITY_WITH_INFO_COUNT(customerId))
  },
  getLocalityDeviationCounts(customerId?: EntityId, localityId?: EntityId, mooringId?: EntityId) {
    queryClient.invalidateQueries(localityQueryKeys.GET_LOCALITY_DEVIATION_COUNT(customerId, localityId, mooringId))
  },
}

export const useGetAllWithInfoCount = (customerId: EntityId) =>
  useGet<ILocality[]>(endpoints.getLocalitiesWithInfoCount(customerId), undefined, { queryKey: localityQueryKeys.GET_LOCALITY_WITH_INFO_COUNT(customerId) })

export const useGetGrouppedLocalities = (customerId: EntityId) =>
  useGet<ICustomerLocalities>(endpoints.getGrouppedLocalitiesWithInfoCount(customerId), undefined, { queryKey: localityQueryKeys.GET_GROUPPED_LOCALITY_WITH_INFO_COUNT(customerId) })

export const useGetLocalities = (customerId: EntityId, queryParams?: ILocalityRequestDto) =>
  useGet<IPaginatedResult<ILocality>>(
    endpoints.getLocalities(customerId),
    { ...queryParams },
    { queryKey: localityQueryKeys.GET_LOCALITIES(customerId) },
  )

export const useGetCustomerLocalities = ({ clientId }) =>
  useGet<ILocality[]>(endpoints.getLocalitiesForDropDown(clientId))

export const useGetCageStatus = (
  customerId: EntityId,
  localityId: EntityId,
  queryParams?: { page?: number; pageSize?: number; isIgnoreQueryFilters?: boolean },
) =>
  useGet<IMainComponentStatus[]>(
    endpoints.getCageStatus(customerId, localityId),
    { ...queryParams },
    { queryKey: localityQueryKeys.GET_CAGE_STATUS(customerId, localityId, queryParams) },
  )

export const useGetLocality = (customerId: EntityId, id: EntityId, queryParams?: ILocalityRequestDto) =>
  useGet<ILocality>(
    endpoints.getLocality(customerId, id ?? 0),
    { ...queryParams },
    {
      enabled: !!id,
      queryKey: localityQueryKeys.GET_LOCALITY(customerId, id, { mainComponentId: queryParams?.mainComponentId })
    },
  )

export const useAddLocality = (customerId: EntityId) =>
  usePost<ILocality, ILocality>(endpoints.createLocality(customerId))

export const useGetLocalityNames = (customerId: EntityId) =>
  useGet<ILocality[]>(endpoints.getLocalitiesForDropDown(customerId))

export const useUpdateLocality = (customerId: EntityId, id: EntityId) =>
  usePut<ILocality, ILocality>(endpoints.updateLocality(customerId, id))

export const useDeleteLocality = (customerId: EntityId) => useDelete<ILocality>(endpoints.deleteLocality(customerId))

export const useGetLocalityGroups = (customerId: EntityId, id: EntityId) =>
  useGet<ILocalityGroup[]>(endpoints.getLocalityGroups(customerId, id))

export const useAssignLocalityGroups = (customerId: EntityId, id: EntityId) =>
  usePost(endpoints.getLocalityGroups(customerId, id))

export const useGetWorkAssignmentCount = (customerId: EntityId, localityId: EntityId, mooringId: EntityId) =>
  useGet<number>(endpoints.getWorkAssignmentCount(customerId, localityId, mooringId))

export const useGetLocalityDeviationCounts = (
  customerId: EntityId,
  localityId: EntityId,
  mooringId: EntityId,
  queryParams?: any,
) =>
  useGet<IDeviationCounts>(
    endpoints.getLocalityDeviationCounts(customerId, localityId, mooringId),
    { ...queryParams },

    {
      queryKey: localityQueryKeys.GET_LOCALITY_DEVIATION_COUNT(customerId, localityId, mooringId ?? 0),
      enabled: mooringId != null && queryParams?.positionId == null,
    },
  )

export const useGetLocalityEnvironmentDetails = (customerId: EntityId, localityId: EntityId, mooringId: EntityId) =>
  useGet<ILocalityEnvironmentDetails>(
    endpoints.getLocalityEnvironmentDetails(customerId, localityId, mooringId),
    {},
    { enabled: mooringId != null },
  )

export const useGetExpiringComponents = (customerId: EntityId, localityId: EntityId, mooringId: EntityId) =>
  useGet<IExpiringComponentsCount[]>(endpoints.getExpiringComponents(customerId, localityId, mooringId))

export const useGetDocumentsCount = (customerId: EntityId, localityId: EntityId) =>
  useGet<IDocumentsCount>(endpoints.getDocumentsCount(customerId, localityId))
