import { Box } from '@mui/material'
import React from 'react'
import { useController } from 'react-hook-form'
import { z } from 'zod'
import { useGetCustomerLocalities } from '../api/localities'
import { AppForm, AppFormProps, FormSubmitButton, FormTextField, useFormFieldError } from '../components/FormCore'
import { SelectField } from '../components/SelectField'
import { ILocality } from '../generated-types/locality'
import { useZodForm } from '../hooks/zod-form'
import { useTranslation } from 'react-i18next'

const schema = z
  .object({
    name: z.string().nonempty(),
    localities: z.array(z.any()).nonempty(),
  })
  .transform(({ name, localities }) => {
    return {
      name,
      localityIds: localities.map(l => l.id as number),
    }
  })

export type LocalityGroupFormType = z.infer<typeof schema>

interface Props {
  customerId: EntityId
}

export const LocalityGroupForm: React.FC<AppFormProps & Props> = ({ onSubmit, initialValues, customerId }) => {
  const form = useZodForm(schema, { defaultValues: initialValues })
  const { t } = useTranslation(['access-control'])

  return (
    <AppForm form={form} onSubmit={onSubmit} hasInitialValues={!!initialValues}>
      <Box py={1}>
        <FormTextField name="name" label={t('access-locality-name')} />
      </Box>
      <Box py={1}>
        <LocalitySelectField customerId={customerId} />
      </Box>
      <Box py={1} display={'flex'} flexDirection={'row'} justifyContent={'flex-end'}>
        <FormSubmitButton />
      </Box>
    </AppForm>
  )
}

const LocalitySelectField = ({ customerId }) => {
  const { hasError, message } = useFormFieldError('localities')
  const { field } = useController({ name: 'localities' })
  const { data, isLoading } = useGetCustomerLocalities({ clientId: customerId })
  const { t } = useTranslation(['access-control'])

  return (
    <SelectField
      data={data as ILocality[]}
      multiple
      label={t('localities')}
      defaultValue={field.value ?? []}
      helperText={message}
      error={hasError}
      loading={isLoading}
      getOptionLabel={o => o.name}
      onChange={(_, v) => field.onChange(v)}
      renderOption={(props: any, o) => (
        <Box {...props} key={o.id}>
          {o.name}
        </Box>
      )}
      isOptionEqualToValue={(o, v) => o.id === v.id}
    />
  )
}
