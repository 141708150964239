import { DataGridPro } from '@mui/x-data-grid-pro'
import { useCustomerCtx, useDialogCtx } from '../../../hooks/context-hooks'
import { invalidateSchemaForm, useDeleteFormSchemaById, useGetAllFormSchema } from '../../../api/form-schema'
import { useTranslation } from 'react-i18next'
import { Box, Button } from '@mui/material'
import { t } from 'i18next'
import { Link, useNavigate } from 'react-router-dom'
import { AppRoutesNavigation } from '../../../app-routes'
import { Delete, Edit, RemoveRedEye } from '@mui/icons-material'
import { showDeleteDialog } from '../../../show-dialog'
import Form from './Form'
import { makeStyles } from '@material-ui/core'
import { toast } from 'react-toastify'
import { ScreenTitle } from '../../../components/ScreenTitle'

const useStyles = makeStyles(theme => ({
    button: {
        cursor: 'pointer'
    }
}))

const FormSchemas = () => {
    const {showDialog} = useDialogCtx()
    const { customer } = useCustomerCtx()
    const { data, isLoading } = useGetAllFormSchema(customer?.id as EntityId)
    const mutate = useDeleteFormSchemaById(customer?.id as EntityId)
    const navigate = useNavigate()
    const classes = useStyles()
    const { t: translate } = useTranslation(['common', 'form-schema'])
    const STATIC_COLUMNS = [
        {
            field: 'name',
            headerName: translate('name', { ns: 'common' }),
            flex: 5
        },
        {
            field: 'description',
            headerName: translate('description', { ns: 'common' }),
            flex: 5
        },
        {
            field: 'isActive',
            headerName: translate('isActive', { ns: 'common' }),
            flex: 2,
            renderCell: params => params.row.isActive ? 'Yes' : 'No'
        },
        {
            field: 'actions',
            headerName: t('actions', { ns: 'common' }),
            flex: 2,
            renderCell: (params) => <Box width={200} display={'flex'} flexDirection={'row'} justifyContent={'space-evenly'}>
                <Edit
                    className={classes.button}
                    onClick={() => { navigate(AppRoutesNavigation.Customer.editFormSchema(customer?.id, params.row?.id)) }}

                />
                
    <RemoveRedEye
            className={classes.button}
            onClick={() => {
              const formSchemaVersion = params?.row?.versions.reduce((previous, current) => {
                return previous.version > current.version ? previous : current
              })
              showDialog(
                Form,
                {
                  componentProps: {
                    disabled: false,
                    form: undefined,
                    onDrop: () => {},
                    addLayout: () => {},
                    deleteLayout: () => {},
                    selectFormField: () => {},
                    layouts: JSON.parse(formSchemaVersion?.layout),
                    title: params?.row?.name,
                    onRearrange: undefined,
                    pop: () => {},
                    cancelEnabled: true,
                  },
                  opts: {
                    maxWidth: 'md',
                    fullWidth: true,
                  },
                  isAutoCloseDisabled: true,
                },
                undefined,
                true,
              )
            }}
          />
                <Delete
                    className={classes.button}
                    onClick={() => {
                        showDeleteDialog(translate('delete-from-title', { ns: 'form-schema' }), translate('delete-from-content', { ns: 'form-schema' }))
                            .then(async value => {
                                if (value) {
                                    await mutate.mutateAsync(params?.row?.id)
                                    invalidateSchemaForm.getAllFormSchema(customer?.id as EntityId)
                                    toast.success(translate('deletedSuccessfully', { ns: 'common' }))
                                }
                            })
                    }}
                />
            </Box>
        },
    ]
    return (
        <Box marginTop={1} className="px-4 py-2 mt-2 mb-2 bg-white shadow-md">
            <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} marginBottom={1} >
                <ScreenTitle title={translate('form-schema', { ns: 'form-schema' })} />

                <Link to={AppRoutesNavigation.Customer.formGenerator(customer?.id)}>
                    <Button variant='contained'>{translate('add', { ns: 'common' })}</Button>
                </Link>
            </Box>
            <DataGridPro
                columns={STATIC_COLUMNS}
                rows={data || []}
                rowCount={data?.length}
                autoHeight
                loading={isLoading}
            />
        </Box>
    )
}

export default FormSchemas