import { TFunction } from 'i18next'
import { useEffect, useCallback } from 'react'
import { toast } from 'react-toastify'
import { invalidateCustomer } from '../../api/customer'
import { useGetFile } from '../../api/file'
import { useUpdateLocality, invalidateLocalityCache } from '../../api/localities'
import { LocalityForm } from '../../forms/LocalityForm'
import { IFile, ILocality } from '../../generated-types'
import { ShowDialogProps } from '../../show-dialog'
import { handleLocalityErrorResponse } from './utils'

interface UpdateServiceProps extends ShowDialogProps {
  customerId: EntityId
  locality: ILocality
  t: TFunction
}

export const UpdateLocalityDialog: React.FC<UpdateServiceProps> = ({ pop, customerId, t, locality }) => {
  const { data, refetch } = useGetFile(locality.fileId ? locality.fileId : 0)
  const { mutateAsync, isLoading } = useUpdateLocality(customerId as EntityId, locality.id)
  useEffect(() => {
    if (locality.fileId) {
      refetch()
    }
  }, [locality.fileId])

  const onSubmit = useCallback(
    async form => {
      await mutateAsync(form, {
        onSuccess: () => {
          invalidateLocalityCache.getLocalities(customerId)
          invalidateLocalityCache.getLocality(customerId, locality?.id)
          invalidateCustomer.getCustomerLocalities()
          invalidateLocalityCache.getLocalitiesWithInfoCount(customerId)
          toast.success(t('updatedSuccessfully', { ns: 'common' }))
          pop()
        },
        onError: (error: any) => {
          handleLocalityErrorResponse(error.data)
        },
      })
    },
    [customerId, locality?.id],
  )

  return (
    <LocalityForm
      isLoading={isLoading}
      onSubmit={onSubmit}
      onCancel={() => pop()}
      initialValues={locality}
      file={data as IFile}
      customerId={locality.customerId}
      title={t('updateLocality', { ns: 'customer-locality' })}
    />
  )
}
