import { Box, Button, Grid, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'
import BasicDatePicker from '../components/DateTimePicker'
import {
  AppForm,
  AppFormProps,
  FormCheckbox,
  FormFileCoreUpload,
  FormImageFileUpload,
  FormSelectField,
  FormTextField,
} from '../components/FormCore'
import { IFile } from '../generated-types'
import { useZodForm } from '../hooks/zod-form'
import { GenericDialogLayout } from '../components/GenericDialogLayout'
import { useGetCustomerUsers } from '../api/customer'
import { UserDetail, getUserDetail } from '../utils/getUserDetails'

export interface LocalityFormProps extends AppFormProps {
  file?: IFile
  title?: string
  isLoading?: boolean
  customerId?: number
}

export const LocalityForm: React.FC<LocalityFormProps> = ({
  onSubmit,
  onCancel,
  initialValues,
  file,
  title,
  isLoading,
  customerId,
}) => {
  const { t: translate } = useTranslation(['locality', 'common'])

  const schema = z.object({
    name: z.string().nonempty(translate('nameIsRequired')),
    locationNr: z.string().nonempty(translate('locationNrIsRequired')),
    latitude: z
      .string()
      .or(z.number())
      .or(z.null())
      .transform(value => (typeof value === 'string' ? parseFloat(value) : value))
      .optional(),
    longitude: z
      .string()
      .or(z.number())
      .or(z.null())
      .transform(value => (typeof value === 'string' ? parseFloat(value) : value))
      .optional(),
    notificationActive: z.boolean().default(false),
    active: z.boolean().default(true),
    fileId: z.any().optional(),
    logoId: z.any(),
    certifiedDate: z.date().or(z.string()).optional(),
    certificateDueDate: z.date().or(z.string()).or(z.null()).optional(),
    responsibleUserId: z.string().or(z.null()).optional(),
    responsibleUser: z
      .object({
        id: z.string(),
        email: z.string(),
        firstName: z.string().nullable().optional(),
        lastName: z.string().nullable().optional(),
        phoneNumber: z.string().nullable().optional(),
      })
      .optional()
      .nullable()
      .transform(value => (value ? value.id : undefined)),
  })

  const handleChange = (e, value) => {
    form.setValue('responsibleUser', value)
    form.setValue('responsibleUserId', value?.id)
  }

  const form = useZodForm(schema, { defaultValues: initialValues })

  const isUpdateForm = initialValues?.id
  const { data: customerUsers } = useGetCustomerUsers(customerId as EntityId)


  return (
    <GenericDialogLayout
      title={title}
      pop={onCancel}
      actions={[
        <Button
          key="locality-form-button"
          variant="contained"
          disabled={isLoading}
          onClick={() => {
            form.handleSubmit(onSubmit)()
          }}
          data-cy="UpdateLocality_submit"
        >
          {isUpdateForm ? translate('update', { ns: 'common' }) : translate('add', { ns: 'common' })}
        </Button>,
      ]}
      sx={{ p: 1 }}
      removedCloseButton={false}
    >
      <AppForm form={form} onSubmit={onSubmit} hasInitialValues={initialValues}>
        <Box py={1}>
          <FormTextField className=" input-localityform" name="name" label="Name" defaultValue={initialValues?.Name} />
        </Box>
        <Grid container spacing={2}>
          <Grid item sm={8}>
            <Box py={1}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormTextField className=" input-localityform" name="locationNr" label="Location number" />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormTextField className=" input-localityform" name="latitude" label="Gps latitude" type="number" />
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Stack spacing={1}>
                    <FormTextField className=" input-localityform" name="longitude" label="Gps longitude" />
                    <Box className="input-localityform">
                      <BasicDatePicker
                        label={translate('certificateDueDate', { ns: 'locality' })}
                        name="certificateDueDate"
                      />
                    </Box>
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box className=" input-localityform">
                    <BasicDatePicker label={translate('certifiedDate', { ns: 'locality' })} name="certifiedDate" />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item sm={4} display={'flex'} flexDirection={'row'} justifyContent={'center'} py={1}>
            <FormImageFileUpload
              label={translate('logo-image', { ns: 'common' })}
              name="logoId"
              mapFileKey="id"
              initialFiles={initialValues?.logoUrl}
              required
              inactiveMsg={translate('chooseCertificate', { ns: 'locality' })}
            />
          </Grid>
        </Grid>
        <Box py={1}>
          <FormSelectField
            name="responsibleUser"
            data={customerUsers!}
            label={translate('responsibleUser', { ns: 'locality' })}
            getOptionLabel={option => getUserDetail(option, UserDetail.NameAndEmail)}
            renderOption={(props: any, option) => (
              <Box {...props} key={option}>
                {getUserDetail(option, UserDetail.NameAndEmail)}
              </Box>
            )}

            isOptionEqualToValue={(o, v) => o.id === v.id}
            onChange={handleChange}
          />
        </Box>
        <Box py={1} className=" input-localityform">
          <FormFileCoreUpload
            label={translate('fileName', { ns: 'common' })}
            name="fileId"
            mapFileKey="id"
            initialFiles={file}
            inactiveMsg={translate('chooseCertificate', { ns: 'locality' })}
            height='100px'
          />
        </Box>
        <Box py={1}>
          <FormCheckbox name="notificationActive" label={translate('notificationActive', { ns: 'locality' })} />
          <FormCheckbox name="active" label={translate('active', { ns: 'locality' })} />
        </Box>
      </AppForm>
    </GenericDialogLayout>
  )
}
