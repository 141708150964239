import { GridColumns, GridNativeColTypes } from '@mui/x-data-grid-pro'
import React, { useCallback } from 'react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import GenericTable from '../../../components/table/GenericTable'
import {
  globalDocumentEndpoints,
  invalidateGlobalDocument,
  useWithdrawGlobalDocumentVersion,
} from '../../../api/global-documents'
import moment from 'moment'
import { getLocalDateTime } from '../../../utils/function'
import { Box } from '@mui/material'
import { MainButton } from '../../../components/MainButton'
import { IForApprovalGlobalDocument } from '../../../generated-types/global-documents'
import { AlertDialog } from '../../../show-dialog'
import { GlobalDocumentDialog } from './GlobalDocumentDialog'
import { toast } from 'react-toastify'
import { SUCCESSFULLY_WITHDREW } from '../../../constant'
import { useDialogCtx } from '../../../hooks/context-hooks'

export const invalidateAllGlobalDocuments = () => {
  invalidateGlobalDocument.forApproval()
  invalidateGlobalDocument.approved()
  invalidateGlobalDocument.getCategories()
  invalidateGlobalDocument.getCounts()
}

export const GlobalDocumentsTable: React.FC<{
  customerId: EntityId
  documentCategoryId?: EntityId
  IsApprovedDocs: boolean
}> = ({ customerId, documentCategoryId, IsApprovedDocs }) => {
  const { mutateAsync: withdrawDocument } = useWithdrawGlobalDocumentVersion()
  const { t } = useTranslation(['global-documents', 'common'])
  const { showDialog } = useDialogCtx()

  const onApprove = useCallback(
    async (document: IForApprovalGlobalDocument) => {
      const added = await showDialog(
        GlobalDocumentDialog,
        {
          opts: {
            maxWidth: 'lg',
            fullWidth: true,
          },
          componentProps: {
            customerId: customerId as EntityId,
            globalDocument: document,
            pop: () => {},
            title: t('for-approval', { ns: 'global-documents' }),
          },
          isAutoCloseDisabled: true,
        },
        undefined,
        true,
      )
      if (!added) return
    },
    [customerId, showDialog, t],
  )

  const onWithdraw = useCallback(
    async (document: IForApprovalGlobalDocument) => {
      const added = await showDialog(
        GlobalDocumentDialog,
        {
          opts: {
            maxWidth: 'lg',
            fullWidth: true,
          },
          componentProps: {
            title: `${t('withdraw-document', { ns: 'global-documents' })}`,
            customerId: customerId as EntityId,
            globalDocument: document,
            pop: () => {},
          },
          isAutoCloseDisabled: true,
        },
        undefined,
        true,
      )
      if (!added) return
    },
    [customerId, showDialog, t],
  )

  const handleWithdraw = useCallback(
    async row => {
      showDialog(AlertDialog, {
        componentProps: {
          title: t('withdraw-document', { ns: 'global-documents' }),
          acceptText: t('withdraw', { ns: 'global-documents' }),
          text: t('areYouSureWithdrawDocument', { ns: 'global-documents' }),
          onConfirm: async pop => {
            withdrawDocument({ customerId: customerId, documentVersionId: row.documentVersionId }).then(
              ({ data }) => {
                if (data === SUCCESSFULLY_WITHDREW) {
                  toast.success(t('successfullyWithdrew', { ns: 'global-documents' }))
                }
                invalidateAllGlobalDocuments()
                pop()
              },
              () => {
                toast.error(t('unExpectedError', { ns: 'common-api-message' }))
              },
            )
          },
        },
      })
    },
    [showDialog, t, withdrawDocument, customerId],
  )

  const staticColumn: GridColumns = useMemo(
    () => [
      { field: 'title', headerName: t('title', { ns: 'common' }), flex: 1 },
      {
        field: 'version',
        headerName: t('version', { ns: 'global-documents' }),
        flex: 1,
        valueGetter: ({ value }) => (value ? value : ' - '),
      },
      {
        field: 'supplierName',
        headerName: t('supplier', { ns: 'global-documents' }),
        flex: 1,
        valueGetter: ({ value }) => (value ? value : ' - '),
      },
    ],
    [t],
  )

  const columnsForPending: GridColumns = useMemo(
    () => [
      {
        field: 'createdAt',
        headerName: t('createdAt', { ns: 'global-documents' }),
        flex: 1,
        valueGetter: value => (value?.value ? moment(getLocalDateTime(value?.value)).format('MM/DD/YYYY') : ' - '),
      },
    ],
    [t],
  )

  const columnsForApproved: GridColumns = useMemo(
    () => [
      {
        field: 'approvedAt',
        headerName: t('approvedAt', { ns: 'global-documents' }),
        flex: 1,
        valueGetter: value => (value?.value ? moment(getLocalDateTime(value?.value)).format('MM/DD/YYYY') : ' - '),
      },
      {
        field: 'approvedBy',
        headerName: t('approvedBy', { ns: 'global-documents' }),
        flex: 1,
        valueGetter: ({ value }) => (value ? value : ' - '),
      },
      {
        field: 'actions',
        type: 'actions' as GridNativeColTypes,
        headerName: 'Actions',
        width: 300,
        getActions: ({ row }) => [
          <Box key={'withdraw'} paddingLeft={1}>
            <MainButton
              variant="contained"
              className="!py-1 !h-[30px] !text-xs !my-1"
              sx={{ letterSpacing: 1 }}
              onClick={() => {
                handleWithdraw(row)
              }}
            >
              {t('withdraw', { ns: 'global-documents' })}
            </MainButton>
          </Box>,
        ],
      },
    ],
    [handleWithdraw, t],
  )

  const columns: GridColumns = useMemo(
    () => [...staticColumn, ...(IsApprovedDocs ? columnsForApproved : columnsForPending)],
    [IsApprovedDocs, columnsForApproved, columnsForPending, staticColumn],
  )

  return (
    <GenericTable
      id="global-document-table"
      columns={columns}
      enablePagination={true}
      enableServerPagination={true}
      queryUrl={IsApprovedDocs ? globalDocumentEndpoints.approved() : globalDocumentEndpoints.forApproval()}
      queryParams={{ customerId, documentCategoryId }}
      onRowClick={({ row }) => {
        if (IsApprovedDocs) {
          onWithdraw(row)
        } else {
          onApprove(row)
        }
      }}
      pageSize={10}
      rowColor="#e5e4e2"
      enableToolbar={true}
      noRowsMessage={t('no-documents', { ns: 'global-documents' })}
    />
  )
}
