import { t as translate } from 'i18next'
import { GridColumns } from '@mui/x-data-grid-pro'
import { useCallback, useEffect, useMemo } from 'react'
import { CenteredSpinner } from '../../../components/Spinner'
import { IMainComponent } from '../../../generated-types/main-component'
import { useGetMainComponentsList } from '../../../api/main-component'
import { useFormContext } from 'react-hook-form'
import { Box, styled } from '@mui/material'
import GenericTable from 'src/components/table/GenericTable'
import { padding } from 'src/styles/paddingCss'

export interface ISelectMainComponentStepProps {
  customerId: EntityId
  setDisabled: React.Dispatch<React.SetStateAction<boolean>>
  handleNext: () => void
}

interface IGenericMainComponentSelectStep {
  setDisabled: React.Dispatch<React.SetStateAction<boolean>>
  handleNext: () => void
  mainComponents: IMainComponent[]
  isLoading: boolean
}

export const GenericMainComponentSelectStep: React.FC<IGenericMainComponentSelectStep> = ({
  setDisabled,
  handleNext,
  mainComponents,
  isLoading,
}) => {
  const { getValues, reset } = useFormContext()

  useEffect(() => {
    if (getValues('mainComponent')?.id === undefined) {
      setDisabled(true)
    } else {
      setDisabled(false)
    }
  }, [getValues('mainComponent'), setDisabled])

  const STATIC_COLUMNS: GridColumns = useMemo(
    () => [
      { field: 'name', headerName: translate('name', { ns: 'common' }), flex: 1 },
      {
        field: 'description',
        headerName: translate('description', { ns: 'common' }),
        flex: 1,
        cellClassName: 'font-weight-semi-bold',
      },
      { field: 'serialNumber', headerName: translate('serialNumber', { ns: 'main-component' }), flex: 1 },
    ],
    [],
  )

  const handleRowClick = useCallback(
    params => {
      reset({
        ...getValues(),
        mainComponent: params.row as IMainComponent,
      })
      setDisabled(false)
      handleNext()
    },
    [setDisabled, handleNext],
  )

  if (isLoading) return <CenteredSpinner />

  return (<Box width={'100%'}>
    <StyledGenericTable
      columns={STATIC_COLUMNS}
      data={mainComponents}
      autoHeight
      styledTable
      loading={isLoading}
      selectionModel={getValues('mainComponent') ? [getValues('mainComponent').id] : []}
      onRowClick={handleRowClick}
      enableQuickFilter
      id={'main-component-step'} />
  </Box>
  )
}

export const SelectMainComponentStep: React.FC<ISelectMainComponentStepProps> = ({
  customerId,
  setDisabled,
  handleNext,
}) => {
  const { getValues } = useFormContext()

  const { data: components, isLoading } = useGetMainComponentsList(customerId, getValues('position')?.id as EntityId)

  return (
    <GenericMainComponentSelectStep
      mainComponents={components?.items as IMainComponent[]}
      isLoading={isLoading}
      setDisabled={setDisabled}
      handleNext={handleNext}
    />
  )
}

const StyledGenericTable = styled(GenericTable)`
  .MuiDataGrid-toolbarContainer .MuiFormControl-root{
    width:100%;
  }
  .MuiDataGrid-toolbarContainer .MuiInputBase-root{
    border: 1px solid ${props => props.theme.palette.secondaryLight[props.theme.palette.mode]};
    border-radius: 4px;
    ${padding.pt12}
    ${padding.pb12}
    ${padding.pl16}
    ${padding.pr16}
  }
`