import { useTranslation } from 'react-i18next'
import { FormDialog } from '../../forms/FormDialog'
import { ShowDialogProps } from '../../show-dialog'
import { SendToLocalityDialogForm } from './SendToLocalityDialogForm'
import { useGetMainComponentById } from '../../api/main-component'
import { IMainComponentType } from '../../enums'

interface SendToLocalityDialogProps extends ShowDialogProps {
  customerId: EntityId
  mainComponentId: EntityId
  onSubmit: (...args: any[]) => any
}

export const SendToLocalityDialog: React.FC<SendToLocalityDialogProps> = ({
  pop,
  customerId,
  mainComponentId,
  onSubmit,
}) => {
  const { t } = useTranslation(['main-component', 'common'])
  const { data: mainComponent, isLoading } = useGetMainComponentById(customerId, mainComponentId)
  const isCertificateAddFormVisible = mainComponent?.isValidCertificateAvailable ?? false
  const isPlacementVisible =
    mainComponent?.type.componentType == IMainComponentType.Net ||
    mainComponent?.type.componentType == IMainComponentType.Ring ||
    mainComponent?.type.componentType == IMainComponentType.LiceSkirt

  return (
    <FormDialog
      title={t('sendToLocality', { ns: 'main-component' })}
      isLoading={isLoading}
      form={
        <SendToLocalityDialogForm
          onSubmit={form => {
            {
              form.mainComponentTypeId = mainComponent?.typeId
              form.mainComponentId = mainComponent?.id
              const {
                certificateNumber,
                certificateDate,
                validUntil,
                certificateDocumentType,
                certificateFileId,
                certificateSupplierId,
                ...rest
              } = form
              rest.certificate = {
                certificateNumber,
                certificateDate,
                validUntil,
                certificateDocumentType,
                fileId: certificateFileId,
                supplierId: certificateSupplierId,
                mainComponentId: mainComponent?.id,
              }
              !isCertificateAddFormVisible ? onSubmit(rest, pop) : onSubmit(form, pop)
            }
          }}
          onCancel={pop}
          customerId={customerId}
          isCertificateAddFormVisible={isCertificateAddFormVisible}
          isPlacementVisible={isPlacementVisible}
        />
      }
    />
  )
}
