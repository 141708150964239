import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import { z } from 'zod'
import { AppForm, FormSubmitButton, FormTextField, AppFormProps } from '../../../components/FormCore'
import { useZodForm } from '../../../hooks/zod-form'
import { CancelButton } from '../../../components/CancelButton'

export const DocumentCategoryForm: React.FC<AppFormProps> = ({ onSubmit, onCancel, initialValues }) => {
  const { t: translate } = useTranslation(['documentcategory', 'common'])

  const schema = z.object({
    categoryName: z.string().nonempty(translate('categoryNameIsRequired')),
  })

  const form = useZodForm(schema, {
    defaultValues: initialValues,
  })

  const isUpdateForm = initialValues?.id

  const handleSubmit = async form => {
    onSubmit(form)
  }

  return (
    <AppForm form={form} onSubmit={handleSubmit} hasInitialValues={initialValues}>
      {isUpdateForm && <FormTextField name="id" type="hidden" />}
      <Box py={1}>
        <FormTextField name="categoryName" label="Category Name" />
      </Box>
      <Box className="flex gap-4 pt-4 justify-end">
        <FormSubmitButton
          createText={isUpdateForm ? translate('update', { ns: 'common' }) : translate('add', { ns: 'common' })}
        />
        <CancelButton onClick={onCancel}>{translate('cancel', { ns: 'common' })}</CancelButton>
      </Box>
    </AppForm>
  )
}
