import { Avatar, PaperProps, Stack, styled } from '@mui/material'
import { Flex } from '../../styles/flexComponent'

export const TopBarIcons = styled(Stack)`
    padding:4px;
    width: 44px;
    height:44px;
    cursor:pointer;
    :hover {
        background-color:${props => props.theme.palette.lightGray[props.theme.palette.mode]};
        border-radius:100%;
    }
    svg{
        height:32px;
        width:32px;
    }
    img{
        height:32px;
        width:28px;
        margin-left:5px;
    }
`

export const NavigationContainer = styled(Flex.Row) <{ active: boolean }>`
    border-bottom: ${props => props.active ? '3px solid ' + props.theme.palette.primary[props.theme.palette.mode] : 0};
    color:${props => props.active ? props.theme.palette.primary[props.theme.palette.mode] : props.theme.palette.black[props.theme.palette.mode]};
    padding: 14px 18px 14px 18px;
    cursor: pointer;
`
export const NavigationColumnContainer = styled(Flex.Row)<{ active: boolean }>`
  border-bottom: ${props => (props.active ? '1px solid ' + props.theme.palette.primary[props.theme.palette.mode] : 0)};
  color: ${props =>
    props.active
      ? props.theme.palette.primary[props.theme.palette.mode]
      : props.theme.palette.black[props.theme.palette.mode]};
  padding: 5px 0px 0px 0px;
  cursor: pointer;
`

export const LogoContainer = styled(Flex.Row)`
    flex:1;
    padding-left:70px;
    @media screen and (max-width: 992px) {
        padding-left:10px;
}
`
export const ProfileAvatar = styled(Avatar)`
    width: 30;
    height: 30;
    font-size: 14px;
`

export const PaperProperties: PaperProps = {
    elevation: 0,
    sx: {
        overflow: 'visible',
        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
        mt: 1.5,
        '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
        },
        '&::before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
        },
    },
}