import { useTranslation } from 'react-i18next'
import { Box, DialogContent, DialogTitle } from '@mui/material'
import { MdDelete, MdEdit } from 'react-icons/md'
import { CenteredSpinner } from '../../../components/Spinner'
import { showDialog, ShowDialogProps, showConfirmDialog } from '../../../show-dialog'
import { useMemo } from 'react'
import { GridActionsCellItem, GridColumns, GridNativeColTypes, useGridApiRef } from '@mui/x-data-grid-pro'
import {
  invalidateSensorTypeCache,
  sensorTypesEndpoints,
  useGetSensorType,
  useUpdateSensorType,
  useDeleteSensorType
} from '../../../api/sensortype'
import { SensorTypeForm } from './SensorTypeForm'
import { DataGridWithServerPagination } from '../../../components/DataGridWithServerPagination'
import { ISensorType } from '../../../generated-types/sensortype'

interface UpdateSensorTypeProps extends ShowDialogProps {
  id: EntityId
}

const UpdateSensorTypeDialog: React.FC<UpdateSensorTypeProps> = ({ pop, id }) => {
  const { t: translate } = useTranslation(['sensortype', 'common'])
  const mutation = useUpdateSensorType(id as EntityId)

  const onSubmit = async form => {
    await mutation.mutateAsync(form)
    
    invalidateSensorTypeCache.useGetSensorTypes()
    pop(true)
  }

  const { data, isLoading } = useGetSensorType(id)
  if (isLoading) return <CenteredSpinner />

  return (
    <Box>
      <DialogTitle
        sx={{
          fontWeight: 'bold',
          fontSize: '1.8rem',
        }}
        className="text-primary"
      >
        {translate('updateSensorType', { ns: 'sensor-type' })}
      </DialogTitle>
      <DialogContent>
        <SensorTypeForm onSubmit={onSubmit} onCancel={() => pop()} initialValues={data as ISensorType} />
      </DialogContent>
    </Box>
  )
}

export const SensorTypeTable: React.FC = () => {
  const { t: translate } = useTranslation(['sensortype', 'common'])
  const apiRef = useGridApiRef()

  const STATIC_COLUMNS: GridColumns = [
    { field: 'name', headerName: translate('name', { ns: 'common' }), flex: 1, cellClassName: 'font-weight-semi-bold' },
    { field: 'unitType', headerName: translate('unitType', { ns: 'sensor-type' }), flex: 1 },
  ]

  const { mutate, isLoading: isDeleting } = useDeleteSensorType()

  const onDeleteSensorType = (id: EntityId) => {
    showConfirmDialog(
      translate('deleteSensorType', { ns: 'sensor-type' }),
      translate('areYouSureDeleteSensorType', { ns: 'sensor-type' }),
    ).then(e => {
      if (e) {
        mutate(id)
        apiRef.current.updateRows([{ id, _action: 'delete' }])
      }
    })
  }

  const onUpdate = async id => {
    const added = await showDialog(UpdateSensorTypeDialog, {
      componentProps: { id: id as EntityId },
    })
    if (!added) return

    invalidateSensorTypeCache.useGetSensorTypes()
    invalidateSensorTypeCache.getSensorType(id)
  }

  const columns: GridColumns = useMemo(
    () => [
      ...STATIC_COLUMNS,
      {
        field: 'actions',
        type: 'actions' as GridNativeColTypes,
        width: 10,
        getActions: ({ id }) => [
          <GridActionsCellItem
            key={id}
            label={translate('edit', { ns: 'common' })}
            icon={<MdEdit size={24} />}
            onClick={() => onUpdate(id)}
            disabled={isDeleting}
            color="primary"
            showInMenu
          />,
          <GridActionsCellItem
            key={id}
            label={translate('delete', { ns: 'common' })}
            icon={<MdDelete size={24} />}
            onClick={() => onDeleteSensorType(id)}
            disabled={isDeleting}
            color="primary"
            showInMenu
          />,
        ],
      },
    ],
    [],
  )

  return (
    <DataGridWithServerPagination
      queryUrl={sensorTypesEndpoints.getSensorTypes()}
      onCellClick={cell => console.log(cell)}
      columns={columns}
    />
  )
}
