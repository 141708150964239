import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { TimeReportTypeTable } from './TimeReportTypeTable'
import { ScreenTitleBar } from '../../localities/components/ScreenTitleBar'
import { useCallback } from 'react'
import TimeReportTypeForm from './TimeReportTypeForm'
import { invalidateTimeReportTypeCache, useAddTimeReportType } from '../../../api/time-report-type'
import { useDialogCtx } from '../../../hooks/context-hooks'
import { toast } from 'react-toastify'
import { SUCCESSFULLY_ADDED, CANNOT_ADD } from '../../../constant'

export const TimeReportTypeContainer = () => {
  const { showDialog } = useDialogCtx()
  const { mutateAsync } = useAddTimeReportType()
  const { t: translate } = useTranslation(['time-report-types', 'common'])

  const onAddTimeReportType = useCallback(() => {
    showDialog(TimeReportTypeForm, {
      title: translate('addTimeReportType'),
      opts: {
        maxWidth: 'md',
        fullWidth: true,
      },
      componentProps: {
        onSubmit: async values => {
          await mutateAsync(values,{
            onSuccess: (data) => {
              if (data.data === SUCCESSFULLY_ADDED) {
                invalidateTimeReportTypeCache.useGetTimeReportTypes()
                toast.success(translate('timeReportTypeAddedSuccessfully', { ns: 'time-report-types' }))
              }
            },
            onError: (error) => {
              if (error['data'] === CANNOT_ADD) {
                toast.error(translate('cannot-add-same-time-report-type-name'))
              }
            }
          })
        },
        initialValues: {},
      },
    })
  }, [mutateAsync, showDialog, translate])

  return (
    <>
      <Box className="mb-2 bg-white shadow-md p-3" borderRadius={2}>
        <ScreenTitleBar
          title={translate('timeReportTypes', { ns: 'time-report-types' })}
          addNewItemText={translate('add', { ns: 'common' })}
          onAddNewItemClick={onAddTimeReportType}
        />
        <TimeReportTypeTable />
      </Box>
    </>
  )
}
