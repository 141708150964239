import { Edit, RemoveRedEye } from '@mui/icons-material'
import { Box, Button, TextField, Typography } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { FormField, FormRow } from '../../../generated-types/form-field'
import FormSettings from './FormSettings'
import { showConfirmDialog } from '../../../show-dialog'
import Form from './Form'
import { makeStyles } from '@material-ui/core'
import { IFormSchemaGeneric, IFormSchemaVersion } from '../../../generated-types/form-schema'
import { toast } from 'react-toastify'
import FormGenericData from './FormGenericData'
import { useTranslation } from 'react-i18next'
import { generateUUID } from '../../../utils/function'
import { useDialogCtx } from 'src/hooks/context-hooks'

const useStyles = makeStyles(theme => ({
    preview: {
        cursor: 'pointer',
        marginTop: 5
    }
}))

interface IFormGeneratorProps {
    buttonTitle: string
    onSubmit: (value: FormRow[], details: IFormSchemaGeneric) => void
    initialData?: IFormSchemaVersion
    isEdit?: boolean
    onCancel: () => void
}



const FormGenerator: React.FC<IFormGeneratorProps> = ({ buttonTitle = 'Save', onSubmit, onCancel, isEdit = false, initialData }) => {
    const [layouts, setLayouts] = useState<FormRow[]>([{ columns: [{ fields: [] }] }])
    const [fieldDetails, setFieldDetails] = useState<IFormSchemaGeneric>({ name: '', description: '', hasVideoUpload: false, isActive: false })
    const [selectedFieldType, setSelectedFieldType] = useState(0)
    const [selectedSetting, setSelectedSettingField] = useState<FormField>()
    const [selectedFieldPosition, setSelectedFieldPosition] = useState({ row: 0, column: 0, field: 0 })
    const [fieldsNameArray, setFieldsNameArray] = useState({})
    const [rearrangeProperty, setRearrangeProperty] = useState({ layIndex: -1, colIndex: -1, fieldIndex: -1 })
    const [openTab, setOpenTab] = useState(0)
    const classes = useStyles()
    const { t } = useTranslation(['form-schema', 'common'])
    const onDrag = (value: number) => {
        setSelectedFieldType(value)
    }
    const {showDialog} = useDialogCtx()

    useEffect(() => {
        changeFieldNameArray(selectedSetting?.identifier, selectedSetting?.name)
    }, [selectedSetting?.name])

    const changeFieldNameArray = useCallback(
        (identifier, value) => {
            const fieldArray = fieldsNameArray
            fieldArray[identifier] = value
            setFieldsNameArray(fieldArray)
        },
        [fieldsNameArray],
    )

    useEffect(() => {
        if (isEdit && initialData) {
            setFieldDetails({
                name: initialData?.formSchema?.name || '',
                description: initialData?.formSchema?.description || '',
                hasVideoUpload: initialData?.formSchema?.hasVideoUpload || false,
                isActive: initialData?.formSchema?.isActive || false,
            })
            setLayouts(JSON.parse(initialData?.layout || ''))
        }
    }, [isEdit, initialData])

    const onDrop = useCallback((layoutIndex, columnIndex, fieldIndex) => {
        const fieldArray = layouts
        if (selectedFieldType != 0) {
            const field: FormField = {
                type: selectedFieldType,
                name: t('field-name'),
                identifier: generateUUID()
            }
            changeFieldNameArray(field?.identifier, field?.name)
            if (fieldArray[layoutIndex].columns[columnIndex].fields.length < fieldIndex) {
                fieldArray[layoutIndex].columns[columnIndex].fields.push(field)
            } else {
                fieldArray[layoutIndex].columns[columnIndex].fields.splice(fieldIndex, 0, field)
            }
        } else if (rearrangeProperty.layIndex != -1) {
            const field = fieldArray[rearrangeProperty.layIndex].columns[rearrangeProperty.colIndex].fields[rearrangeProperty.fieldIndex]
            if (field) {
                fieldArray[rearrangeProperty.layIndex].columns[rearrangeProperty.colIndex].fields.splice(rearrangeProperty.fieldIndex, 1)
                if (fieldArray[layoutIndex].columns[columnIndex].fields.length < fieldIndex) {
                    fieldArray[layoutIndex].columns[columnIndex].fields.push(field)
                } else {
                    fieldArray[layoutIndex].columns[columnIndex].fields.splice(fieldIndex, 0, field)
                }
            }
        }
        setLayouts([...fieldArray])
        setRearrangeProperty({ layIndex: -1, colIndex: -1, fieldIndex: -1 })
        setSelectedFieldType(0)
    }, [layouts, selectedFieldType, rearrangeProperty])

    const addLayout = (rowIndex, columnIndex: number | null = null) => {
        const layoutsList = layouts
        if (columnIndex == null) {
            layoutsList.splice(rowIndex, 0, { columns: [{ fields: [] }] })
        } else {
            layoutsList[rowIndex].columns.splice(columnIndex, 0, { fields: [] })
        }
        setLayouts([...layoutsList])
    }

    const deleteLayout = useCallback((rowIndex, colIndex) => {
        showConfirmDialog(t('delete-column'), t('delete-column-content')).then((isSuccess: boolean) => {
            if (isSuccess) {
                const layoutsList = layouts
                layoutsList[rowIndex].columns.splice(colIndex, 1)
                if (layoutsList[rowIndex].columns.length == 0) {
                    layoutsList.splice(rowIndex, 1)
                }
                setLayouts([...layoutsList])
            }
        })
    }, [layouts])

    const selectFormField = (field: FormField, rowIndex: number, columnIndex: number, fieldIndex: number) => {
        setSelectedSettingField({ ...field })
        setSelectedFieldPosition({ row: rowIndex, column: columnIndex, field: fieldIndex })
        setOpenTab(1)
    }

    const updateField = (field: FormField, rowIndex: number, columnIndex: number, fieldIndex: number) => {
        const layoutsList = layouts
        layoutsList[rowIndex].columns[columnIndex].fields[fieldIndex] = field
        setLayouts([...layoutsList])
    }

    const onSubmitForm = () => {
        const duplicate = Object.values(fieldsNameArray).map((value, index, array) => {
            const duplicateIndex = Object.values(fieldsNameArray).findIndex((valueDup, indexDup) => valueDup == value && indexDup != index)
            return duplicateIndex != -1 && duplicateIndex != index
        })
        if (duplicate.indexOf(true) != -1) {
            toast.error(t('duplicate-field-name'))
        } else if (fieldDetails.name == '') {
            toast.error(t('form-title-required'))
        } else {
            onSubmit(layouts, fieldDetails)
        }
    }

    const viewForm = () => {
        showDialog(Form, 
            {
            componentProps: {
                form: undefined,
                disabled: false,
                onDrop: () => { },
                addLayout: () => { },
                deleteLayout: () => { },
                selectFormField: () => { },
                layouts: layouts,
                title: fieldDetails.name,
                onRearrange: () => { },
                cancelEnabled: true,
            },
            opts: {
                maxWidth: 'md',
                fullWidth: true,
              },
              isAutoCloseDisabled: true,
            
        }, 
        undefined,
        true
    )
    }

    return (
        <Box minHeight={400}>
            <Box borderBottom={1}>
                <Box padding={1} paddingLeft={3} display={'flex'} flexDirection={'row'} justifyContent={'space-evenly'}>
                    <Box flex={3}>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                            {t('form-generator')}
                        </Typography>
                    </Box>
                    <Box flex={3}>
                        <TextField placeholder={t('title', { ns: 'common' })} variant='standard' value={fieldDetails.name} disabled />
                        <Edit style={{ cursor: 'pointer' }} onClick={() => {
                            showDialog(FormGenericData, {
                                componentProps: {
                                    fieldDetails: fieldDetails || {},
                                    setFieldDetails: setFieldDetails
                                },
                                opts: {
                                    maxWidth: 'md',
                                    fullWidth: true,
                                  }
                            })
                        }} />
                    </Box>
                    <Box width={220} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                        <RemoveRedEye className={classes.preview} onClick={() => viewForm()} />
                        <Box width={180} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                            <Button variant='contained' onClick={() => { onSubmitForm() }}>{buttonTitle}</Button>
                            <Button variant='outlined' onClick={() => { onCancel() }}>{t('cancel', { ns: 'common' })}</Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box display={'flex'} flexDirection={'row'} width={'100%'} >
                <FormSettings onDelete={() => {
                    const layoutsList = layouts
                    layoutsList[selectedFieldPosition.row].columns[selectedFieldPosition.column].fields.splice(selectedFieldPosition.field, 1)
                    setLayouts([...layoutsList])
                    setOpenTab(0)
                    setSelectedSettingField(undefined)
                }}
                    onDrag={onDrag}
                    openTab={openTab}
                    setOpenTab={setOpenTab}
                    setLayouts={setLayouts}
                    selectedSetting={selectedSetting}
                    selectedRowIndex={selectedFieldPosition.row}
                    selectedColumnIndex={selectedFieldPosition.column}
                    selectedFieldIndex={selectedFieldPosition.field}
                    updateField={updateField}
                    setSelectedSettingField={setSelectedSettingField} />
                <Form form={undefined} title={fieldDetails.name} layouts={layouts} disabled={true}
                    onDrop={onDrop} addLayout={addLayout} deleteLayout={deleteLayout} withColorLayout={true}
                    selectFormField={selectFormField}
                    onRearrange={(layIndex, colIndex, fieldIndex) => setRearrangeProperty({ layIndex, colIndex, fieldIndex })}
                />
            </Box>
        </Box>

    )
}

export default FormGenerator