import { Card, Stack, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ReactComponent as FlagIcon } from '../../../../assets/svg/flag.svg'
import { useGetLocalityDeviationCounts } from '../../../../api/localities'
import { Flex } from 'src/styles/flexComponent'
import { styled } from '@mui/system'
import { useGetCagePositionDeviations } from 'src/api/positions'
import { useGetMainComponentDeviationCount } from 'src/api/main-component'

interface IDeviationCountCardParams {
  customerId: EntityId
  localityId: EntityId
  positionId?: EntityId
  selectedMooringId?: EntityId | undefined
  mainComponentId?: EntityId
}

export const CardRow = styled(Flex.Row)({
  gap: '16px',
  paddingLeft: '16px',
  paddingRight: '16px',
  height: '72px',
  borderRadius: '4px',
  width: '100%',
})

export const CardColumn = styled(Flex.Column)({
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
})

export const DeviationCountCard: React.FC<IDeviationCountCardParams> = ({
  customerId,
  localityId,
  selectedMooringId,
  positionId,
  mainComponentId,
}) => {
  const { t: translate } = useTranslation(['common'])
  const theme = useTheme()
  const { data: deviationCounts } = useGetLocalityDeviationCounts(
    customerId as EntityId,
    localityId as EntityId,
    selectedMooringId as EntityId,
    { positionId }
  )

  const { data: positionDeviationCounts } = useGetCagePositionDeviations(
    customerId as EntityId,
    localityId as EntityId,
    { positionId }
  )

  const { data: mainComponentDeviationCounts } = useGetMainComponentDeviationCount(
    customerId as EntityId,
    mainComponentId as EntityId
  )

  return (
    <Stack
      direction="column"
      component={Card}
      elevation={3}
      maxHeight={'180px'}
      sx={{ p: 2 }}
      justifyContent={'center'}
    >
      <CardColumn gap={1}>
        <CardRow bgcolor={theme.palette.errorMain[theme.palette.mode]}>
          <CardColumn width={'50%'}>
            <Typography variant="subtitle2" fontWeight={600} color={theme.palette.white[theme.palette.mode]}>
              {translate('critical-deviations', { ns: 'deviation' })}
            </Typography>
          </CardColumn>
          <Flex.Column width={'40%'} justifyContent={'center'} height={'100%'}>
            <Typography variant="h1" textAlign={'center'} fontWeight={700} color={theme.palette.white[theme.palette.mode]}>
              {((mainComponentId ? mainComponentDeviationCounts?.criticalDeviationsCount : positionId ? positionDeviationCounts?.criticalDeviationsCount : deviationCounts?.criticalDeviationsCount) ?? 0).toString().padStart(2, '0')}
            </Typography>
          </Flex.Column>
          <Flex.Column p={1} width={'30px'}>
            <FlagIcon />
          </Flex.Column>
        </CardRow>
        <CardRow bgcolor={theme.palette.infoDark[theme.palette.mode]}>
          <CardColumn width={'50%'}>
            <Typography variant="subtitle2" fontWeight={600} color={theme.palette.white[theme.palette.mode]}>
              {translate('normal-deviations', { ns: 'deviation' })}
            </Typography>
          </CardColumn>
          <Flex.Column width={'40%'} justifyContent={'center'} height={'100%'}>
            <Typography variant="h1" textAlign={'center'} fontWeight={700} color={theme.palette.white[theme.palette.mode]}>
              {((mainComponentId ? mainComponentDeviationCounts?.normalDeviationsCount : positionId ? positionDeviationCounts?.normalDeviationsCount : deviationCounts?.normalDeviationsCount) ?? 0).toString().padStart(2, '0')}
            </Typography>
          </Flex.Column>
          <Flex.Column p={1} width={'30px'}>
          </Flex.Column>
        </CardRow>
      </CardColumn>
    </Stack>
  )
}