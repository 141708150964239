import { Box, Autocomplete, Chip, TextField } from '@mui/material'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetFile } from 'src/api/file'
import { AppForm, AppFormProps, FormFileUpload, FormTextField } from 'src/components/FormCore'
import { GenericDialogLayout } from 'src/components/GenericDialogLayout'
import { MainButton } from 'src/components/MainButton'
import { FileType } from 'src/enums'
import { useZodForm } from 'src/hooks/zod-form'
import { z } from 'zod'

interface ProductCertificationFormProps extends AppFormProps {
  initialValues?: any
  title: string
}

export const ProductCertificationForm: React.FC<ProductCertificationFormProps> = ({
  onSubmit,
  onCancel,
  initialValues,
  title,
}) => {
  const { t: translate } = useTranslation(['product-certification', 'common'])
  const { data: file } = useGetFile(initialValues?.fileId ?? 0)

  const schema = z.object({
    notes: z.string().nonempty(translate('notesIsRequired')),
    fileId: z.union([z.string(), z.number()]).or(z.null()).optional(),
    batchNumbers: z.string().nonempty(),
  })

  const form = useZodForm(schema, { defaultValues: initialValues })

  const isUpdateForm = initialValues?.id

  const batchNumbers = useMemo(() => {
    if (initialValues) return initialValues.batchNumbers?.split(',').map(option => option) ?? []
    else return []
  }, [initialValues])

  return (
    <GenericDialogLayout
      title={title}
      pop={onCancel}
      sx={{ padding: 2 }}
      actions={[
        <MainButton
          key={'product-certification-submit-btn'}
          onClick={() => {
            form.handleSubmit(onSubmit)()
          }}
        >
          {isUpdateForm ? translate('update', { ns: 'common' }) : translate('add', { ns: 'common' })}
        </MainButton>,
      ]}
      removedCloseButton={false}
    >
      <AppForm form={form} onSubmit={onSubmit} hasInitialValues={initialValues != null}>
        <Box m={1} py={1}>
          <FormFileUpload
            label={translate('file', { ns: 'product-certification' })}
            name="fileId"
            mapFileKey="id"
            type={FileType.File}
            initialFiles={file}
          />
        </Box>
        <Box m={1} py={1}>
          <FormTextField name="notes" label={translate('notes', { ns: 'product-certification' })} multiline rows={2} />
        </Box>
        <Box m={1}>
          <Autocomplete
            multiple
            componentName="batchNumbers"
            id="batchNumbersList"
            options={batchNumbers}
            defaultValue={batchNumbers}
            onChange={(_, v) => {
              form.setValue('batchNumbers', v.join(',') as string)
            }}
            freeSolo
            renderTags={(value: readonly string[], getTagProps) =>
              value?.map((option: string, index: number) => (
                // eslint-disable-next-line react/jsx-key
                <Chip
                  sx={{ blockSize: 25, fontSize: 12 }}
                  variant="outlined"
                  label={option}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={params => (
              <TextField
                {...params}
                name="batchNumbersList"
                error={form.formState.errors.batchNumbers?.message != null}
                helperText={translate('addBatchNumber', { ns: 'product-certification' })}
                variant="filled"
                label="Batch Numbers"
              />
            )}
          />
        </Box>
      </AppForm>
    </GenericDialogLayout>
  )
}
