import { deviationQueryKeys } from 'src/query-keys'
import {
  ICreateDeviation,
  IDeviation,
  IDeviationFilters,
  IDeviationWeeklyCount,
  IUpdateDeviation,
} from '../generated-types/deviation'
import { IDeviationDocument } from '../generated-types/deviation-document'
import { ICreateDeviationFollowUp, IDeviationFollowUp } from '../generated-types/deviation-follow-up'
import { queryClient } from '../query-client'
import { apiConsts } from './apiConsts'
import { IPaginatedResult } from './paginated-result'
import { useDelete, useGet, usePost, usePut } from './reactQuery'
import { invalidateMooringCache } from './moorings'
import { invalidateLocalityCache } from './localities'
import { invalidateCustomer } from './customer'
import { invalidateComponentsCache } from './components'
import { CreateAttachments } from 'src/generated-types/attachment'
import { IApiResult } from 'src/generated-types/api-result'

const baseUrl = (customerId: EntityId) => `customers/${customerId}/deviations`

const baseUrlAdmin = () => 'admin/deviations'

export const deviationsEndpoints = {
  getDeviations(customerId: EntityId) {
    return `${baseUrl(customerId)}`
  },
  getDeviation(customerId: EntityId, id: EntityId) {
    return `${baseUrl(customerId)}/${id}`
  },
  createDeviation(customerId: EntityId) {
    return `${baseUrl(customerId)}`
  },
  deleteDeviation(customerId: EntityId) {
    return `${baseUrl(customerId)}`
  },
  updateDeviation(customerId: EntityId, id: EntityId) {
    return `${baseUrl(customerId)}/${id}`
  },
  getDeviationDocuments(customerId: EntityId, id: EntityId) {
    return `${baseUrl(customerId)}/${id}/documents`
  },
  getDeviationFollowUps(customerId: EntityId, deviationId: EntityId) {
    return `${baseUrl(customerId)}/${deviationId}/followups`
  },
  createDeviationFollowUp(customerId: EntityId, deviationId: EntityId) {
    return `${baseUrl(customerId)}/${deviationId}/followups`
  },
  deleteDeviationFollowUp(customerId: EntityId, deviationId: EntityId) {
    return `${baseUrl(customerId)}/${deviationId}/followups`
  },
  getDeviationsAdmin() {
    return baseUrlAdmin()
  },
  getDeviationAdmin(id: EntityId) {
    return `${baseUrlAdmin()}/${id}`
  },
  deleteDeviationAdmins() {
    return `${baseUrlAdmin()}`
  },
  updateDeviationAdmin(id: EntityId) {
    return `${baseUrlAdmin()}/${id}`
  },
  getDeviationFollowUpsAdmin(deviationId: EntityId) {
    return `${baseUrlAdmin()}/${deviationId}/followups`
  },
  createDeviationFollowUpAdmin(deviationId: EntityId) {
    return `${baseUrlAdmin()}/${deviationId}/followups`
  },
  deleteDeviationFollowUpAdmin(deviationId: EntityId) {
    return `${baseUrlAdmin()}/${deviationId}/followups`
  },
  getWeeklyCount(customerId: EntityId, localityId: EntityId) {
    return `${baseUrl(customerId)}/locality/${localityId}/weekly-count`
  },
}

export const invalidateDeviation = {
  getDeviation(customerId?: EntityId, id?: EntityId) {
    queryClient.invalidateQueries(deviationQueryKeys.GET_DEVIATION(customerId, id))
  },
  getWeeklyCount(customerId?: EntityId, localityId?: EntityId) {
    queryClient.invalidateQueries(deviationQueryKeys.GET_WEEKLY_COUNT(customerId, localityId))
  },
  getDeviations(customerId?: EntityId) {
    queryClient.invalidateQueries(deviationQueryKeys.GET_DEVIATIONS(customerId))
  },
  getDeviationDocuments(customerId: EntityId, id: EntityId) {
    queryClient.invalidateQueries(deviationQueryKeys.GET_DEVIATION_DOCUMENTS(customerId, id))
  },
  getDeviationFollowUps(customerId: EntityId, deviationId: EntityId) {
    queryClient.invalidateQueries(deviationsEndpoints.getDeviationFollowUps(customerId, deviationId))
  },
  getDeviationAdmin(id?: EntityId) {
    queryClient.invalidateQueries(deviationQueryKeys.GET_ADMIN_DEVIATION(id))
  },
  getDeviationsAdmin() {
    queryClient.invalidateQueries(deviationsEndpoints.getDeviationsAdmin())
  },
  getDeviationFollowUpsAdmin(deviationId: EntityId) {
    queryClient.invalidateQueries(deviationsEndpoints.getDeviationFollowUpsAdmin(deviationId))
  },
  invalidateAllDeviations(
    customerId?: EntityId,
    localityId?: EntityId,
    mooringId?: EntityId,
    id?: EntityId,
    positionId?: EntityId,
  ) {
    invalidateDeviation.getDeviationAdmin(id)
    invalidateDeviation.getDeviationsAdmin()
    invalidateDeviation.getWeeklyCount(customerId, localityId)
    invalidateMooringCache.getMooringsForMap(customerId, localityId)
    invalidateLocalityCache.getLocalitiesWithInfoCount(customerId)
    invalidateLocalityCache.getLocalityDeviationCounts(customerId, localityId, mooringId)
    invalidateDeviation.getDeviation(customerId, id)
    invalidateDeviation.getDeviations(customerId)
    invalidateMooringCache.getGetMooring(customerId, localityId, mooringId)
    invalidateCustomer.getLocalitiesForMap()
    invalidateMooringCache.getMapSvg(customerId, localityId, mooringId)
    invalidateMooringCache.getSvgForPosition(customerId, localityId, mooringId)
    invalidateComponentsCache.getComponents(customerId as EntityId, positionId as EntityId)
  },
}

export const useGetDeviations = (
  customerId: EntityId,
  localityId?: EntityId,
  mooringId?: EntityId,
  positionId?: EntityId,
  componentId?: EntityId,
  mainComponentId?: EntityId,
  localityFrequencyId?: EntityId,
  localityFrequencyGroupId?: string,
  scheduledTaskId?: EntityId,
  page: number = apiConsts.Page,
  pageSize: number = apiConsts.PageSize,
  filterPagination?: IDeviationFilters,
) =>
  useGet<IPaginatedResult<IDeviation>>(
    deviationsEndpoints.getDeviations(customerId),
    {
      localityId,
      mooringId,
      positionId,
      componentId,
      mainComponentId,
      localityFrequencyId,
      localityFrequencyGroupId,
      scheduledTaskId,
      page,
      pageSize,
      ...filterPagination,
    },
    {
      queryKey: deviationQueryKeys.GET_DEVIATIONS(customerId, {
        localityId,
        mooringId,
        positionId,
        componentId,
        mainComponentId,
        localityFrequencyId,
        localityFrequencyGroupId,
        scheduledTaskId,
        page,
        pageSize,
        ...filterPagination,
      }),
    },
  )

export interface IGetDeviationParams {
  mainComponentId?: EntityId
  mooringId?: EntityId
  positionId?: EntityId
  componentId?: EntityId
  deviationId?: EntityId
  localityId?: EntityId
}

export const useGetDeviation = (customerId: EntityId, id: EntityId) =>
  useGet<IDeviation>(deviationsEndpoints.getDeviation(customerId, id), undefined, {
    queryKey: deviationQueryKeys.GET_DEVIATION(customerId, id),
  })

export const useGetDeviationDocuments = (customerId: EntityId, id: EntityId) =>
  useGet<IDeviationDocument[]>(deviationsEndpoints.getDeviationDocuments(customerId, id), undefined, {
    queryKey: deviationQueryKeys.GET_DEVIATION_DOCUMENTS(customerId, id),
  })

export const useAddDeviation = (customerId: EntityId) =>
  usePost<IDeviation, ICreateDeviation>(deviationsEndpoints.createDeviation(customerId))

export const useUpdateDeviation = (customerId: EntityId, id: EntityId) =>
  usePut<IDeviation, IUpdateDeviation>(deviationsEndpoints.updateDeviation(customerId, id))

export const useDeviationCount = (customerId: EntityId, localityId: EntityId) =>
  useGet<IDeviationWeeklyCount[]>(deviationsEndpoints.getWeeklyCount(customerId, localityId), undefined, {
    queryKey: deviationQueryKeys.GET_WEEKLY_COUNT(customerId, localityId),
  })

export const useDeleteDeviation = (customerId: EntityId) =>
  useDelete<IDeviation>(deviationsEndpoints.deleteDeviation(customerId))

export const useGetDeviationFollowUps = (
  customerId: EntityId,
  localityId: EntityId,
  deviationId: EntityId,
  page: number = apiConsts.Page,
  pageSize: number = apiConsts.PageSize,
) =>
  useGet<IPaginatedResult<IDeviationFollowUp>>(deviationsEndpoints.getDeviationFollowUps(customerId, deviationId), {
    page,
    pageSize,
  })

export const useAddDeviationFollowUp = (customerId: EntityId, deviationId: EntityId) =>
  usePost<IDeviationFollowUp, ICreateDeviationFollowUp>(
    deviationsEndpoints.createDeviationFollowUp(customerId, deviationId),
  )

export const useDeleteDeviationFollowUp = (customerId: EntityId, deviationId: EntityId) =>
  useDelete<IDeviationFollowUp>(deviationsEndpoints.deleteDeviationFollowUp(customerId, deviationId))

export const useGetDeviationsAdmin = (page: number = apiConsts.Page, pageSize: number = apiConsts.PageSize) =>
  useGet<IPaginatedResult<IDeviation>>(deviationsEndpoints.getDeviationsAdmin(), {
    page,
    pageSize,
  })

export const useGetDeviationAdmin = (id: EntityId) =>
  useGet<IDeviation>(deviationsEndpoints.getDeviationAdmin(id), undefined, {
    queryKey: deviationQueryKeys.GET_ADMIN_DEVIATION(id),
  })

export const useUpdateDeviationAdmin = (id: EntityId) =>
  usePut<IDeviation, IUpdateDeviation>(deviationsEndpoints.updateDeviationAdmin(id))

export const useDeleteDeviationAdmin = () => useDelete<IDeviation>(deviationsEndpoints.deleteDeviationAdmins())

export const useGetDeviationFollowUpsAdmin = (
  deviationId: EntityId,
  page: number = apiConsts.Page,
  pageSize: number = apiConsts.PageSize,
) =>
  useGet<IPaginatedResult<IDeviationFollowUp>>(deviationsEndpoints.getDeviationFollowUpsAdmin(deviationId), {
    page,
    pageSize,
  })

export const useAddDeviationFollowUpAdmin = (deviationId: EntityId) =>
  usePost<IDeviationFollowUp, ICreateDeviationFollowUp>(deviationsEndpoints.createDeviationFollowUpAdmin(deviationId))

export const useDeleteDeviationFollowUpAdmin = (deviationId: EntityId) =>
  useDelete<IDeviationFollowUp>(deviationsEndpoints.deleteDeviationFollowUpAdmin(deviationId))

export const useAddAttachments = (customerId: EntityId,id:EntityId) =>
  usePost<IApiResult, CreateAttachments>(deviationsEndpoints.getDeviationDocuments(customerId,id))
