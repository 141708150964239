import { IFile } from '../generated-types'
import { useDelete, useGet, usePost } from './reactQuery'

const baseUrl = 'files'

export const endpoints = {
  getFile(fileId: EntityId) {
    return `${baseUrl}/${fileId}`
  },

  getSharedFileUrl(fileUrl: string) {
    return `${baseUrl}/shared?fileUrl=${fileUrl}`
  },

  downloadFile(viewToken: string) {
    return `${baseUrl}/download-file/${viewToken}`
  },

  uploadFile() {
    return `${baseUrl}/upload-file`
  },

  uploadImage() {
    return `${baseUrl}/upload-image`
  },

  deleteFile() {
    return `${baseUrl}/delete-file`
  },

  deleteFileByViewToken(viewToken: string) {
    return `${baseUrl}/delete-file-by-viewtoken/${viewToken}`
  },
}

export const useGetFile = (fileId: EntityId) => {
  const sendRequest = !!fileId
  return useGet<IFile>(endpoints.getFile(fileId), undefined, { enabled: sendRequest })
}

export const useGetSharedFileUrl = (fileUrl: string) => {
  return useGet<string>(endpoints.getSharedFileUrl(fileUrl))
}

export const useDownloadFile = (viewToken: string) => {
  return useGet<any>(endpoints.downloadFile(viewToken))
}

export const useUploadFile = (isImage: boolean) => {
  return usePost<IFile, FormData>(isImage ? endpoints.uploadImage() : endpoints.uploadFile())
}

export const useDeleteFile = () => {
  return useDelete(endpoints.deleteFile())
}

export const useDeleteFileByViewToken = (viewToken: string) => {
  return useDelete(endpoints.deleteFileByViewToken(viewToken))
}
