import { GridActionsCellItem, GridColumns, GridNativeColTypes, useGridApiRef } from '@mui/x-data-grid-pro'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { MdDelete, MdEdit } from 'react-icons/md'
import {
  invalidateProductCertificationByProductIdCache,
  useDeleteProductCertification,
  useGetProductCertificationByProductId,
} from '../../../../api/product-certification'
import GenericTable from 'src/components/table/GenericTable'
import { AddCircleOutline } from '@mui/icons-material'
import { useDialogCtx } from 'src/hooks/context-hooks'
import { CreateProductCertificateDialog } from './AddProductCertificateDialog'
import { IProductCertification } from 'src/generated-types/product-certification'
import { Flex } from 'src/styles/flexComponent'
import { FileUrlLoader } from 'src/components/FileUploader'
import { UpdateProductCertificateDialog } from './UpdateProducCertificateDialog'
import { GridActionButton } from 'src/components/common/GridActionButton'
import { useTheme } from '@mui/material'
import { toast } from 'react-toastify'

interface ProductCertificationTableProps {
  productId: EntityId
}

export const ProductCertificationTable: React.FC<ProductCertificationTableProps> = ({ productId }) => {
  const apiRef = useGridApiRef()
  const { t } = useTranslation(['product-certification', 'common', 'certificate'])
  const { data, isLoading } = useGetProductCertificationByProductId(productId)
  const { mutate, isLoading: isDeleting } = useDeleteProductCertification()
  const { showDialog, showConfirmDialog } = useDialogCtx()
  const theme = useTheme()

  const deleteProductCertification = async (id: EntityId) => {
    showConfirmDialog(
      t('deleteCertificate', { ns: 'certificate' }),
      t('areYouSureDeleteCertificate', { ns: 'certificate' }),
      {
        acceptText: t('yes', { ns: 'common' }),
        cancelText: t('no', { ns: 'common' }),
      },
      async pop => {
        mutate(id, {
          onSuccess: () => {
            invalidateProductCertificationByProductIdCache.getProductCertificationByProductId(productId as EntityId)
            toast.success(t('CertificateDeletedSuccussfully', { ns: 'certificate' }))
            pop()
          },
          onError: () => {
            toast.error(t('ErrorDeletingCertificate', { ns: 'certificate' }))
          },
        })
      },
    )
  }

  const onAdd = async () => {
    const added = await showDialog(
      CreateProductCertificateDialog,
      {
        componentProps: {
          productId: productId as EntityId,
        },
        opts: {
          maxWidth: 'md',
          fullWidth: true,
        },
      },
      undefined,
      true,
    )
    if (!added) return
  }

  const onEdit = async (id: EntityId) => {
    const added = await showDialog(
      UpdateProductCertificateDialog,
      {
        componentProps: {
          productId: productId as EntityId,
          certificationId: id as EntityId,
        },
        opts: {
          maxWidth: 'md',
          fullWidth: true,
        },
      },
      undefined,
      true,
    )
    if (!added) return
  }

  const STATIC_COLUMNS: GridColumns = [
    { field: 'notes', headerName: t('notes', { ns: 'product-certification' }), flex: 1 },
    { field: 'batchNumbers', headerName: t('batchNumbers', { ns: 'product-certification' }), flex: 1 },
  ]
  const columns: GridColumns = useMemo(
    () => [
      ...STATIC_COLUMNS,
      {
        field: 'file',
        headerName: t('file', { ns: 'common' }),
        maxWidth: 50,
        sortable: false,
        renderCell: params => {
          const productCertificate = params.row as IProductCertification
          return (
            <Flex.Column justifyContent={'center'} alignItems={'center'} width={'32px'} height={'32px'}>
              <FileUrlLoader
                contentTypeValue={params?.row?.fileContentType}
                fileUrl={productCertificate.fileUrl}
                openOnClick
              />
            </Flex.Column>
          )
        },
      },
      {
        field: 'actions',
        type: 'actions' as GridNativeColTypes,
        width: 10,
        getActions: ({ id }) => [
          <GridActionsCellItem
            key={id}
            label={t('edit', { ns: 'common' })}
            icon={<MdEdit size={24} />}
            onClick={() => onEdit(id)}
            disabled={isDeleting}
            color="primary"
            showInMenu
          />,
          <GridActionsCellItem
            key={id}
            label={t('delete', { ns: 'common' })}
            icon={<MdDelete size={24} />}
            onClick={() => deleteProductCertification(id)}
            disabled={isDeleting}
            color="primary"
            showInMenu
          />,
        ],
      },
    ],
    [isDeleting],
  )

  return (
    <GenericTable
      id="product-certificate-table"
      columns={columns}
      data={data}
      loading={isLoading || isDeleting}
      autoHeight={false}
      height={500}
      hideDefaultFooterPagination={true}
      getRowHeight={() => 'auto'}
      getRowClassName={params => (params.indexRelativeToCurrentPage % 2 == 0 ? 'even' : 'odd')}
      styledTable={true}
      apiRef={apiRef}
      enableToolbar={true}
      toolbarOptions={[
        {
          label: t('add-certification', { ns: 'product' }),
          Component: (
            <GridActionButton
              buttonText={t('add', { ns: 'common' })}
              buttonIcon={<AddCircleOutline htmlColor={theme.palette.primaryMain[theme.palette.mode]} />}
              height="36px"
              shadowWeight={0}
              variant="outlined"
              onClick={() => {
                onAdd()
              }}
            />
          ),
        },
      ]}
    />
  )
}
