import { Button, Divider } from '@mui/material'
import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetFile } from '../../../api/file'
import { useGetMainComponentCustomProperties } from '../../../api/main-component-type'
import { AppForm, AppFormProps, FormTextField } from '../../../components/FormCore'
import { ICustomProperty } from '../../../generated-types/customProperty'
import { IMainComponent } from '../../../generated-types/main-component'
import { useZodForm } from '../../../hooks/zod-form'
import { CustomMainComponentFields } from './CustomMainComponentFields'
import MainComponentStaticFormFields from './MainComponentStaticFormFields'
import { addCustomFields, getMainComponentSchema } from './mainComponentSchema'
import { IFormType } from '../../../generated-types/form-type'
import { GenericDialogLayout } from '../../../components/GenericDialogLayout'
import { IFile } from '../../../generated-types'
import { CenteredSpinner } from 'src/components/Spinner'

interface MainComponentFormProps extends AppFormProps<IMainComponent> {
  onSubmitAlter?: (form: any, isCloneable: boolean) => void
  title: string
  customerId: EntityId
}

export const MainComponentForm: React.FC<MainComponentFormProps> = ({
  onSubmit,
  onCancel,
  initialValues,
  onSubmitAlter,
  title,
  customerId,
}) => {
  const [initialCertificateFiles, setInitialCertificateFiles] = useState<IFile | null>()

  const isUpdateForm = initialValues?.id
  const { data: customFields, isLoading } = useGetMainComponentCustomProperties(
    initialValues?.typeId as EntityId,
    customerId as EntityId,
  )

  const { data } = useGetFile(initialValues?.fileId ? initialValues?.fileId : 0)
  const { data: certificateFileData } = useGetFile(
    initialValues?.certificate?.fileId ? initialValues?.certificate?.fileId : 0,
  )

  const { t: translate } = useTranslation(['main-component', 'common'])

  const form = useZodForm(
    (customFields as Array<ICustomProperty>)?.length > 0
      ? addCustomFields(
          isUpdateForm
            ? getMainComponentSchema(IFormType.Update, translate)
            : getMainComponentSchema(IFormType.Create, translate),
          customFields,
        )
      : isUpdateForm
      ? getMainComponentSchema(IFormType.Update, translate)
      : getMainComponentSchema(IFormType.Create, translate),
    {
      defaultValues: {
        ...initialValues,
        ...JSON.parse(initialValues?.customPropertyValues || '{}'),
      },
    },
  )

  const handleSubmit = useCallback(
    async (f, isCloneable = false) => {
      const {
        certificateId,
        certificateNumber,
        certificateDate,
        validUntil,
        certificateDocumentType,
        certificateFileId,
        certificateSupplierId,
        ...rest
      } = f
      rest.certificate = {
        id: certificateId,
        certificateNumber,
        certificateDate,
        validUntil,
        certificateDocumentType,
        fileId: certificateFileId,
        supplierId: certificateSupplierId,
        mainComponentId: f.id,
      }
      const customProperties = new Map<string, any>()
      customFields
        ?.map(field => JSON.parse(field.label as unknown as string).en.toLowerCase())
        .forEach(async key => {
          customProperties.set(key, f[key])
        })

      if (isCloneable) {
        form.setValue('serialNumber', '')
        form.setValue('name', '')
        form.setValue('certificateNumber', '')
        form.setValue('certificateSupplierId', null)
        form.setValue('certificateDate', null)
        form.setValue('validUntil', null)
        form.setValue('certificateFileId', null)
        setInitialCertificateFiles(null)
        onSubmitAlter &&
          onSubmitAlter(
            { ...rest, customPropertyValues: JSON.stringify(Object.fromEntries(customProperties)) },
            isCloneable,
          )
      } else {
        onSubmit({ ...rest, customPropertyValues: JSON.stringify(Object.fromEntries(customProperties)) })
      }
    },
    [onSubmitAlter, form, customFields],
  )

  useEffect(() => {
    setInitialCertificateFiles(certificateFileData)
  }, [certificateFileData])

  // If the data is still loading, render a loading state or nothing
  if (isLoading || !customFields) {
    return <CenteredSpinner />
  }

  return (
    <GenericDialogLayout
      title={title}
      pop={onCancel}
      sx={{ padding: 2 }}
      actions={[
        <Fragment key={'main-component-form-actions'}>
          {!isUpdateForm && (
            <Button
              variant="contained"
              onClick={() => {
                form.handleSubmit(f => {
                  handleSubmit(f, true)
                })()
              }}
            >
              {translate('add-clone')}
            </Button>
          )}
          <Button
            variant="contained"
            onClick={() => {
              form.handleSubmit(handleSubmit)()
            }}
          >
            {isUpdateForm ? translate('update', { ns: 'common' }) : translate('add', { ns: 'common' })}
          </Button>
        </Fragment>,
      ]}
      removedCloseButton={false}
    >
      <AppForm form={form} onSubmit={handleSubmit}>
        <FormTextField type="hidden" name="customPropertyValues" value={'test'} sx={{ display: 'none' }} />
        <MainComponentStaticFormFields
          form={form}
          initialValues={initialValues}
          file={data}
          initialCertificateFiles={initialCertificateFiles}
          setInitialCertificateFiles={setInitialCertificateFiles}
          customerId={customerId as EntityId}
        />
        {customFields && customFields?.length > 0 && <Divider className="pt-4" />}
        <CustomMainComponentFields customFields={customFields as Array<ICustomProperty>} />
      </AppForm>
    </GenericDialogLayout>
  )
}

export default MainComponentForm
