import { Grid } from '@mui/material'
import { useGetCustomersLocalitiesForMap } from '../../api/customer'
import { MapComponent } from './components/map/MapComponent'
import { CenteredSpinner } from '../../components/Spinner'
import { ILocality } from '../../generated-types'

export const LocalitiesMapComponent: React.FC<{ selectedCustomer: EntityId; searchValue: string }> = ({
  selectedCustomer,
  searchValue,
}) => {
  const { data, isLoading } = useGetCustomersLocalitiesForMap(searchValue, selectedCustomer)
  return (
    <Grid item md={5} lg={5} sm={12} className="pl-16-core" height={window.innerHeight - 112}>
      {isLoading && <CenteredSpinner />}
      {data && (
        <MapComponent
          navigateLocalityWhenClick={true}
          localities={data as ILocality[]}
          key={'locality-main-page-map'}
          zoomControl={true}
          scrollWheelZoom={true}
        />
      )}
    </Grid>
  )
}
