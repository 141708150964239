import { string, z } from 'zod'
import { AppForm, AppFormProps, FormSubmitButton } from '../../components/FormCore'
import { useZodForm } from '../../hooks/zod-form'
import { Autocomplete, Box, MenuItem, TextField } from '@mui/material'
import { ILocality, IPosition } from '../../generated-types'
import { CancelButton } from '../../components/CancelButton'
import { useTranslation } from 'react-i18next'
import BasicDatePicker from '../../components/DateTimePicker'
import { useState } from 'react'
import { useGetLocalities } from '../../api/localities'
import { useGetCagePositionsByLocality } from '../../api/positions'
import { APIResultCount } from '../../constant'
import { MainComponentCertificationAdd } from '../customer/mainComponents/MainComponentCertificationAdd'
import { useGetCustomerSuppliers } from '../../api/supplier'
import { getCageName } from '../../utils/function'
import { Padding } from 'src/styles/paddingComponent'

export interface SendToLocalityDialogFormProps extends AppFormProps {
  customerId: EntityId
  isCertificateAddFormVisible: boolean
  isPlacementVisible: boolean
}

export const SendToLocalityDialogForm: React.FC<SendToLocalityDialogFormProps> = ({
  onSubmit,
  onCancel,
  customerId,
  isCertificateAddFormVisible,
  isPlacementVisible,
}) => {
  const { t } = useTranslation(['service-station', 'common'])

  const MainComponentSchema = z.object({
    selectedLocalityId: z.number(),
    installationDate: z.date().or(z.string()).optional(),
    selectedPositionId: z.number().optional(),
    selectedMooringId: z.number().optional(),
  })

  const MainComponentSchemaWithCertificate = z
    .object({
      certificateNumber: z
        .string()
        .nonempty()
        .regex(/^(?! *$).+/, t('certificateNumberIsInvalid', { ns: 'certificate' })),
      certificateDate: z.date().or(string()),
      validUntil: z.date().or(z.string()),
      certificateDocumentType: z.number(),
      certificateFileId: z.union([z.string(), z.number()]),
      certificateSupplierId: z
        .object({
          id: z.number(),
        })
        .transform(value => value.id),
    })
    .merge(MainComponentSchema)

  const { data } = useGetLocalities(customerId, { page: 0, pageSize: APIResultCount.Max })
  const [selectedLocalityId, setSelectedLocalityId] = useState<EntityId | undefined>()
  const { data: suppliers } = useGetCustomerSuppliers(customerId, 0, APIResultCount.Max)

  const form = useZodForm(isCertificateAddFormVisible ? MainComponentSchema : MainComponentSchemaWithCertificate)

  return (
    <AppForm form={form} onSubmit={onSubmit} hasInitialValues={false}>
      <Padding.pt8 >
        <Autocomplete
          onChange={(_, value) => {
            form.setValue('selectedLocalityId', (value as ILocality).id)
            setSelectedLocalityId((value as ILocality).id as EntityId)
          }}
          options={(data?.items ?? []) as ILocality[]}
          autoHighlight
          getOptionLabel={o => o.name}
          renderOption={(props, o) => (
            <MenuItem key={o.id} value={o.id} {...props}>
              {o.name}
            </MenuItem>
          )}
          renderInput={params => (
            <TextField
              {...params}
              label={t('selectALocality', { ns: 'service-station' })}
              inputProps={{
                ...params.inputProps,
              }}
            />
          )}
          isOptionEqualToValue={(option, value) => option.id === value.id}
        />
        {selectedLocalityId && !isPlacementVisible && (
          <SelectPlacement
            customerId={customerId}
            localityId={selectedLocalityId}
            disabled={isPlacementVisible}
            form={form}
          />
        )}

        {!isCertificateAddFormVisible && (
          <MainComponentCertificationAdd
            accordingSummary={'certificate'}
            form={form}
            suppliers={suppliers}
            isProductCertificateAdd={false}
          />
        )}
        <Box className="flex justify-end gap-4 pt-4">
          <CancelButton onClick={onCancel}>{t('cancel', { ns: 'common' })}</CancelButton>
          <FormSubmitButton createText={t('Send', { ns: 'common' })} />
        </Box>
      </Padding.pt8>
    </AppForm>
  )
}

const SelectPlacement: React.FC<{
  customerId: EntityId
  localityId: EntityId
  disabled: boolean
  form: any
}> = ({ customerId, localityId, form, disabled }) => {
  const { data: cages } = useGetCagePositionsByLocality(customerId, localityId)
  const { t } = useTranslation(['service-station', 'common'])

  return (
    <>
      <Box py={1}>
        <Autocomplete
          onChange={(_, value) => {
            form.setValue('selectedPositionId', (value as IPosition).id)
            form.setValue('selectedMooringId', (value as IPosition).mooringId)
          }}
          disabled={disabled}
          groupBy={option => option.mooringName ?? ''}
          options={(cages ?? []) as IPosition[]}
          autoHighlight
          getOptionLabel={o => getCageName(o.name)}
          renderOption={(props, o) => (
            <MenuItem key={o.id} value={o.id} {...props}>
              {getCageName(o.name)}
            </MenuItem>
          )}
          renderInput={params => (
            <TextField
              {...params}
              label={t('selectPlacement', { ns: 'main-component' })}
              inputProps={{
                ...params.inputProps,
              }}
            />
          )}
          isOptionEqualToValue={(option, value) => option.id === value.id}
        />
      </Box>
      <Box py={1}>
        <BasicDatePicker
          label={t('installationDate', { ns: 'main-component' })}
          name="installationDate"
          disabled={disabled}
        />
      </Box>
    </>
  )
}
