import { Card } from '@material-ui/core'
import { Box, Stack, Typography, useTheme } from '@mui/material'
import { ILocality } from '../../generated-types'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/system'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
import { ReactComponent as MaintenanceIcon } from '../../assets/svg/maintenance.svg'
import { ReactComponent as PhoneIcon } from '../../assets/svg/phoneIcon.svg'
import { ReactComponent as PersonIcon } from '../../assets/svg/personIcon.svg'
import { ReactComponent as DocumentIcon } from '../../assets/svg/documents-icon.svg'
import { ReactComponent as DeviationIcon } from '../../assets/svg/deviation.svg'
import { ReactComponent as FishIcon } from '../../assets/svg/fish.svg'
import { ReactComponent as CertificateIcon } from '../../assets/svg/certificate.svg'
import { GetRGBA } from 'src/utils'
import { sumTwoNumbers } from 'src/utils/function'
import CountWithIconBox from '../common/CountWithIconBox'

interface LocationHeaderProps {
  locality: ILocality
  classname?: string
  onClick: (e: any) => void
  active?: boolean
}

interface StyledIconProps extends SvgIconProps {
  icon: React.ElementType<SvgIconProps>
}

const PhoneSVGIcon = (props: SvgIconProps) => (
  <SvgIcon component={PhoneIcon} viewBox="0 0 10 10" {...props} />
)

const PersonSVGIcon = (props: SvgIconProps) => (
  <SvgIcon component={PersonIcon} viewBox="0 0 10 10" {...props} />
)

const Name = styled(Typography)(() => ({
  verticalAlign: 'center',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  width: 'auto',
  display: 'inline-block',
  fontWeight: 'bold',
}))

const StyledIcon = styled(({ icon: IconComponent, ...props }: StyledIconProps) => <IconComponent {...props} />)({
  fontSize: '12px',
  marginRight: '2px',
})

const LocationHeaderCard = styled(Card)(({ theme }) => ({
  '&.MuiPaper-root': {
    backgroundColor: theme.palette.white[theme.palette.mode],
    color: theme.palette.primaryDark[theme.palette.mode],
    boxShadow: `0px 0px 6px ${GetRGBA(theme.palette.black[theme.palette.mode], 0.29)}`,
  },
  padding: '9px 8px 9px 8px',
  overflow: 'hidden',
  display: 'flex',
}))

const icons = [1, 2, 3, 4, 5]

function getRandomIcons() {
  const numberOfIcons = Math.floor(Math.random() * (icons.length + 1))
  const shuffledIcons = icons.sort(() => 0.5 - Math.random())
  return shuffledIcons.slice(0, numberOfIcons)
}

export const LocationHeader = ({ locality, classname, onClick, active }: LocationHeaderProps) => {
  const { t } = useTranslation(['common', 'sidebar'])
  const renderingIcons = getRandomIcons()
  const theme = useTheme()

  return (
    locality &&
    <Box sx={{ padding: '6px 8px' }}>
      <Box className={classname}>
        <LocationHeaderCard
          style={{
            cursor: 'pointer',
            backgroundColor: active
              ? theme.palette.primaryMain[theme.palette.mode]
              : theme.palette.inputSearch[theme.palette.mode],
            color: active ? theme.palette.white[theme.palette.mode] : theme.palette.primaryDark[theme.palette.mode],
          }}
          onClick={onClick}
        >
          <Stack direction={'row'} flex={1} sx={{ overflow: 'hidden', width: '100%' }}>
            <Box width={'68px'} className="place-content-center" flexShrink={0}>
              <img
                style={{ width: '68px', height: '98px' }}
                className="rounded-md"
                src={
                  locality.logoUrl ?? 'https://www.google.com/maps/d/thumbnail?mid=1xGlKujfj_P14EOUmHiVhNztorS4&hl=en'
                }
              />
            </Box>
            <Box className='pl-12-core' flexGrow={1} flexShrink={1} flexBasis="0" sx={{ overflow: 'hidden', width: '100%' }}>
              <Stack direction={'row'} alignItems={'center'} maxWidth={'100%'} sx={{ overflow: 'hidden', width: '100%' }}>
                <Name variant='subtitle1'>
                  {locality.name}
                </Name>
                <Typography sx={{ fontWeight: 500, paddingLeft: '4px' }} width='25%' variant='body2'>
                  {locality?.locationNr && '#'}{locality?.locationNr}
                </Typography>
                <Box className='pl-8-core' width={'40px'}>
                  {renderingIcons.includes(1) && (
                    <FishIcon color={active ? theme.palette.white[theme.palette.mode] : theme.palette.black[theme.palette.mode]} height={'20px'} width={'27px'} />
                  )}
                </Box>
              </Stack>

              <Stack direction={'column'} justifyContent={'left'}>
                {(locality?.responsibleUser?.firstName || locality?.responsibleUser?.lastName) &&
                  (<Stack sx={{ paddingLeft: '2px', display: 'flex', alignItems: 'center', justifyContent: 'left', overflow: 'hidden' }} direction={'row'}>
                    <StyledIcon height={10} width={10} icon={PersonSVGIcon} />
                    <Name variant='caption'>{`${locality?.responsibleUser?.firstName || ''} ${locality?.responsibleUser?.lastName || ''}`}</Name>
                  </Stack>)}

                {(locality?.responsibleUser?.phoneNumber != null && locality?.responsibleUser?.phoneNumber != '0') &&
                  (<Stack sx={{ paddingLeft: '2px', display: 'flex', alignItems: 'center', justifyContent: 'left' }} direction={'row'}>
                    <StyledIcon height={10} width={10} icon={PhoneSVGIcon} />
                    <Typography sx={{ paddingLeft: '2px' }} variant='caption'>{`${locality?.responsibleUser?.phoneNumber || ''}`}</Typography>
                  </Stack>)}
              </Stack>

              <Stack sx={{ paddingTop: '11px' }} direction={'row'}>
                <Box>
                  <CountWithIconBox
                    title={t('maintenance')}
                    Component={MaintenanceIcon}
                    value={locality?.servicesCount ?? 0}
                    iconBgColor={theme.palette.primaryDark[theme.palette.mode]}
                    color={active ? theme.palette.white[theme.palette.mode] : theme.palette.black[theme.palette.mode]}
                  />
                </Box>
                <Box sx={{ paddingLeft: '8px' }}>
                  <CountWithIconBox
                    title={t('deviations')}
                    Component={DeviationIcon}
                    value={locality?.deviationCount ?? 0}
                    iconBgColor={theme.palette.warning[theme.palette.mode]}
                    color={active ? theme.palette.white[theme.palette.mode] : theme.palette.black[theme.palette.mode]}
                  />
                </Box>
                <Box sx={{ paddingLeft: '8px' }}>
                  <CountWithIconBox
                    title={t('documents')}
                    Component={DocumentIcon}
                    value={sumTwoNumbers(locality?.expiredDocumentsCount, locality?.expiredDocumentsIn3MonthsCount)}
                    iconBgColor={theme.palette.primaryDark[theme.palette.mode]}
                    color={active ? theme.palette.white[theme.palette.mode] : theme.palette.black[theme.palette.mode]}
                  />
                </Box>
              </Stack>
            </Box>
            <Stack
              width={'21px'}
              className="pl-4-core"
              direction={'column'}
              justifyContent={'flex-start'}
              alignItems={'flex-start'}
              flexShrink={0}
            >
              {renderingIcons.includes(2) && (
                <Box paddingBottom={'2px'}>
                  <CertificateIcon height={'21px'} width={'21px'} />
                </Box>
              )}
              {renderingIcons.includes(3) && (
                <Box paddingBottom={'2px'}>
                  <CertificateIcon height={'21px'} width={'21px'} />
                </Box>
              )}
              {renderingIcons.includes(4) && (
                <Box paddingBottom={'2px'}>
                  <CertificateIcon height={'21px'} width={'21px'} />
                </Box>
              )}
              {renderingIcons.includes(5) && (
                <Box paddingBottom={'2px'}>
                  <CertificateIcon height={'21px'} width={'21px'} />
                </Box>
              )}
            </Stack>
          </Stack>
        </LocationHeaderCard>
      </Box>
    </Box>
  )
}
