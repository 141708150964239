import { useTranslation } from 'react-i18next'
import { DeviationsTable } from '../../deviations/DeviationsTable'
import { IGetDeviationParams } from '../../../../api/deviation'
import { CommonPageLayout } from 'src/components/common/CommonPageLayout'
import { AddCircleOutline } from '@mui/icons-material'
import { useTheme } from '@mui/material'
import React from 'react'
import { Padding } from 'src/styles/paddingComponent'
import { getThemeColor } from 'src/utils'
import { MUIThemeColors } from 'src/mui-theme'

interface IDeviationTab {
  onAddDeviationForComponent?: () => void
  customerId: EntityId
  localityId?: EntityId
  positionId?: EntityId
  mainComponentId?: EntityId
  componentId?: EntityId
  queryParams?: IGetDeviationParams
  scheduledTaskId?: EntityId
  isDisabledButton?: boolean
  addPermissions: number[]
  updatePermissions: number[]
  deletePermissions: number[]
  context?: React.Context<any>
  pop?: () => void
  height?: number,
  permission?: number
  isRequiredTopSpace?: boolean
  autoHeight?: boolean
  enablePagination?: boolean
}

const DeviationTab: React.FC<IDeviationTab> = ({
  onAddDeviationForComponent,
  customerId,
  localityId,
  positionId,
  mainComponentId,
  componentId,
  queryParams,
  scheduledTaskId,
  isDisabledButton,
  addPermissions,
  updatePermissions,
  deletePermissions,
  context = null,
  pop,
  height,
  isRequiredTopSpace = true,
  permission,
  autoHeight,
  enablePagination
}) => {
  const { t } = useTranslation(['deviation', 'common'])
  const theme = useTheme()
  return (
    <CommonPageLayout
      titleSection={{ subtitle: t('deviations', { ns: 'deviation' }) }}
      topAction={[
        {
          buttonText: t('deviations', { ns: 'deviation' }),
          buttonIcon: <AddCircleOutline />,
          onClick: onAddDeviationForComponent,
          permissionBinaryValues: [...addPermissions, ...updatePermissions],
          variant: 'outlined',
          shadowWeight: null,
          disabled: isDisabledButton,
          context: context,
        },
      ]}
      topActionContainerProps={isRequiredTopSpace ? { paddingBottom: '16px' } : undefined}
    >
      <Padding.p8 borderRadius={'4px'} bgcolor={getThemeColor(theme,MUIThemeColors.white)}>
        <DeviationsTable
          customerId={customerId!}
          localityId={localityId!}
          positionId={positionId}
          componentId={componentId}
          mainComponentId={mainComponentId}
          queryParams={queryParams}
          scheduledTaskId={scheduledTaskId}
          disabled={isDisabledButton}
          updatePermissions={updatePermissions}
          deletePermissions={deletePermissions}
          pop={pop}
          height={height}
          permission={permission}
          autoHeight={autoHeight}
          enablePagination={enablePagination}
        />
      </Padding.p8>
    </CommonPageLayout>
  )
}
export default DeviationTab
