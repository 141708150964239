import { Box } from '@mui/material'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { invalidateDocumentCategoryCache, useAddDocumentCategory } from '../../../api/document-category'
import { showDialog, ShowDialogProps } from '../../../show-dialog'
import { ScreenTitleBar } from '../../localities/components/ScreenTitleBar'
import { DocumentCategoryForm } from './DocumentCategoryForm'
import { DocumentCategoryTable } from './DocumentCategoryTable'
import { GenericDialogLayout } from '../../../components/GenericDialogLayout'

const CreateDocumentCategoryDialog: React.FC<ShowDialogProps> = ({ pop }) => {
  const { t: translate } = useTranslation(['documentcategory', 'common'])
  const mutation = useAddDocumentCategory()
  
  useEffect(() => {
    if (mutation.error != null) {
        toast(translate('CannotAddDocumentCategory', { ns: 'document-category' }), {type:'warning'})
    }
  }, [mutation.error])

  const onSubmit = async form => {
    await mutation.mutateAsync(form)

    invalidateDocumentCategoryCache.useGetDocumentCategories()
    pop(true)
  }

  return (
    <Box>
      <GenericDialogLayout title={translate('addDocumentCategory', { ns: 'document-category' })} pop={pop}>
        <DocumentCategoryForm onSubmit={onSubmit} onCancel={() => pop()} />
      </GenericDialogLayout>
    </Box>
  )
}

export const DocumentCategoryContainer = () => {
  const { t: translate } = useTranslation(['documentcategory', 'common'])

  const onAddDocumentCategory = async () => {
    const added = await showDialog(CreateDocumentCategoryDialog)
    if (!added) return
  }

  return (
    <>   
    
      <Box className="mb-2 bg-white shadow-md p-3" borderRadius={2}>
            <ScreenTitleBar
              title={translate('documentCategories', { ns: 'document-category' })}
              addNewItemText={translate('add', { ns: 'common' })}
              onAddNewItemClick={onAddDocumentCategory}
            />
           <DocumentCategoryTable />
          </Box>
    </>
  )
}
