import { Box, Grid } from '@mui/material'
import { ScreenTitleBar } from '../../../localities/components/ScreenTitleBar'
import { useTranslation } from 'react-i18next'
import TimeReportFilter from './TimeReportsFilter'
import { useCallback, useMemo, useState } from 'react'
import { GridColumns } from '@mui/x-data-grid-pro'
import moment from 'moment'
import GenericTable from '../../../../components/table/GenericTable'
import { timeReportsEndpoints } from '../../../../api/time-report'
import { useCustomerCtx } from '../../../../hooks/context-hooks'

const TimeReports = () => {
  const { t } = useTranslation(['time-reports', 'common'])
  const [queryParams, setQueryParams] = useState({})
  const { customer } = useCustomerCtx()

  const timeReportFilterChange = useCallback(params => {
    params.resourceIds = params.resource.map(re => re.id).join(',')
    params.localityIds = params.locality.map(re => re.id).join(',')
    params.timeReportTypeIds = params.timeReportType.map(re => re.id).join(',')
    delete params.locality
    delete params.resource
    delete params.timeReportType
    setQueryParams(params)
  }, [])

  const STATIC_COLUMNS: GridColumns = useMemo(
    () => [
      {
        field: 'description',
        headerName: t('description', { ns: 'common' }),
        flex: 1,
        cellClassName: 'font-weight-semi-bold',
        renderCell: row => row.row.description,
      },
      {
        field: 'fromDateTime',
        headerName: t('fromDateAndTime'),
        flex: 1,
        renderCell: ({ row }) => {
          if (!row) return '-'
          return moment(row.fromDateTime).format('MM/DD/YYYY | HH:MM')
        },
      },
      {
        field: 'toDateTime',
        headerName: t('toDateAndTime'),
        flex: 1,
        renderCell: ({ row }) => {
          if (!row) return '-'
          return moment(row.toDateTime).format('MM/DD/YYYY | HH:MM')
        },
      },
      {
        field: 'timeReportTypeName',
        headerName: t('timeReportType'),
        flex: 1,
        renderCell: row => row.row.timeReportTypeName,
      },
      {
        field: 'resourceName',
        headerName: t('resource', { ns: 'task' }),
        flex: 1,
        renderCell: row => row.row.resourceName,
      },
    ],
    [t],
  )

  return (
    <Box className="mb-2 py-2 px-4 mt-2 bg-white shadow-md" borderRadius={2}>
      <ScreenTitleBar title={t('timeReportManagement')} />

      <Grid container component={Box}>
        <TimeReportFilter onChange={timeReportFilterChange} />
      </Grid>
      <Box paddingTop={2}>
        <GenericTable
          queryUrl={timeReportsEndpoints.filterTimeReport(customer.id)}
          queryParams={queryParams}
          columns={STATIC_COLUMNS}
          enableServerPagination
          enablePagination
          id={'filtered-time-reports'}
        />
      </Box>
    </Box>
  )
}

export default TimeReports
