import React from 'react'
import { ShowDialogProps } from '../../../show-dialog'
import { Box, DialogTitle, DialogContent, DialogActions, Autocomplete, TextField, MenuItem } from '@mui/material'
import { invalidateMainComponentCache, useChangeCageInMainComponent } from '../../../api/main-component'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'
import { CancelButton } from '../../../components/CancelButton'
import { AppForm, FormSubmitButton } from '../../../components/FormCore'
import { useZodForm } from '../../../hooks/zod-form'
import { toast } from 'react-toastify'
import { getCageName } from '../../../utils/function'
import { invalidatePositionCache, useGetCagePositionsByLocality } from '../../../api/positions'
import { IPosition } from '../../../generated-types'
import { invalidationActivityLogCache } from 'src/api/activity-log'
import { APIResultCount } from 'src/constant'

interface ChangeCageDialogProps extends ShowDialogProps {
  id: EntityId
  customerId: EntityId
  localityId: EntityId
  positionId: EntityId
  mooringId: EntityId
}

const ChangeCageDialog: React.FC<ChangeCageDialogProps> = ({
  pop,
  id,
  customerId,
  localityId,
  positionId,
  mooringId,
}) => {
  const mutation = useChangeCageInMainComponent(customerId as EntityId, id as EntityId)
  const { data } = useGetCagePositionsByLocality(customerId, localityId)
  const { t } = useTranslation(['common', 'main-component'])

  const handleSubmit = async form => {
    await mutation.mutateAsync(
      { Id: form.position.id },
      {
        onSuccess: () => {
          toast.success(t('cage-change-message', { ns: 'main-component' }))
          pop()
          invalidateMainComponentCache.getMainComponentById(customerId, id)
          invalidateMainComponentCache.getMainComponents(
            customerId as EntityId,
            localityId.toString(),
            form.mooring.id.toString(),
          )
          invalidationActivityLogCache.getCageActivityLogs({
            page: 0,
            pageSize: APIResultCount.Max,
          })
          invalidatePositionCache.getCagePositionDeviations(customerId, localityId, form.position.id)
          invalidatePositionCache.getCagePositionDeviations(customerId, localityId, positionId)
        },
        onError: error => {
          if (error['data'] == 'ExceedMaxCountForCage') {
            toast.error(t('exceedMaxCountForCage', { ns: 'main-component' }))
          }
        },
      },
    )
  }

  const updateSchema = z.object({
    position: z.object({
      id: z.number(),
    }),
    mooring: z.object({
      id: z.number(),
    }),
  })

  const form = useZodForm(updateSchema, {
    defaultValues: {
      ...{ id: positionId },
    },
  })

  return (
    <Box>
      <DialogTitle
        sx={{
          fontWeight: 'bold',
          fontSize: '1.8rem',
        }}
        className="text-primary"
      >
        {t('change-cage', { ns: 'common' })}
      </DialogTitle>
      <AppForm form={form} onSubmit={handleSubmit}>
        <DialogContent>
          <Box py={1}>
            <Autocomplete
              onChange={(_, value) => {
                form.setValue('position', { id: (value as IPosition)?.id })
                form.setValue('mooring', { id: value?.mooringId || 0 })
              }}
              groupBy={option => option.mooringName ?? ''}
              options={(data ?? []) as IPosition[]}
              autoHighlight
              getOptionLabel={o => getCageName(o.name)}
              renderOption={(props, o) => (
                <MenuItem key={o.id} value={o.id} {...props}>
                  {getCageName(o.name)}
                </MenuItem>
              )}
              renderInput={params => (
                <TextField
                  {...params}
                  label={t('selectCage', { ns: 'main-component' })}
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Box className="flex justify-end gap-4 pt-4">
            <CancelButton onClick={() => pop()}>{t('cancel', { ns: 'common' })}</CancelButton>
            <FormSubmitButton createText={t('update', { ns: 'common' })} />
          </Box>
        </DialogActions>
      </AppForm>
    </Box>
  )
}

export default ChangeCageDialog