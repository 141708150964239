import { Box } from '@mui/system'
import { useParams } from 'react-router-dom'
import { ScreenTitle } from '../../../components/ScreenTitle'
import { UserInvitesTable } from './UserInvitesTable'

export const UserInvitesScreen = () => {
  const { id: customerId } = useParams()

  return (
    <>
    <Box className="mb-2 py-2 px-4 mt-2 bg-white shadow-md" borderRadius={2}>
      <ScreenTitle title="User invites" />
      <Box >
        <UserInvitesTable customerId={customerId!} />
      </Box>

    </Box>
    </>
  )
}
